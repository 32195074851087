KXL.module('HomeApp', function(HomeApp, KXL, Backbone, Marionette, $, _) {
	HomeApp.Router = Marionette.AppRouter.extend({
		appRoutes: {
			'': 'showHome',
			'home': 'showHome'
		}
	});

	var API = {
		showHome: function (reload) {
			KXL.navigate('/');
			if (KXL.currentUser) {
				KXL.execute('profile:show', KXL.currentUser.id);
			} else {
				KXL.execute('page:show', 'home');
				HomeApp.Home.Controller.showHome(reload);
			}
		},
		getGamesView: function (games) {
			return HomeApp.Games.Controller.getGamesView(KXL.staticGames);
		}
	};

	KXL.addInitializer(function () {
		new HomeApp.Router({
			controller: API
		});
	});

	KXL.commands.setHandlers({
		'home:show': function (reload) {
			API.showHome(reload);
		}
	});

	KXL.reqres.setHandlers({
		'new:games:view': function (games) {
			return API.getGamesView(games);
		}
	});
});

/* globals KXL, JSONSocket */
KXL.module('Websocket', function (Websocket, KXL, Backbone, Marionette, $, _) {

	var self = this;

	self.socket = new JSONSocket();

	self.connectSocket = function () {

		self.socket.connect({
			url: KXL.config.WEBSOCKET_URL + '/sockjs',
			authData: { token: KXL.currentUser.get('websocketToken') }
		});
	};

	self.disconnectSocket = function () {

		self.socket.disconnect();
	};

	self.on = self.socket.on.bind(self.socket);
	self.once = self.socket.once.bind(self.socket);
	self.off = self.socket.off.bind(self.socket);
	self.emit = self.socket.emit.bind(self.socket);

	// Listen for changes to the currentUser, when a currentUser is set, the user has logged in and the connection should be established,
	// when the currentUser is set to null, the user has logged out and the connection should be closed
	KXL.appModel.on('change:currentUser', function (app, currentUser) {
		if (!currentUser) {
			self.disconnectSocket();
		}
		else
		{
			self.connectSocket();
		}
	});

	KXL.commands.setHandlers({
		'websocket:connect': self.connectSocket,
		'websocket:disconnect': self.disconnectSocket,
		'websocket:subscribe': self.socket.subscribe.bind(self.socket),
		'websocket:unsubscribe': self.socket.unsubscribe.bind(self.socket),
		'websocket:unsubscribeAll': self.socket.unsubscribeAll.bind(self.socket)
	});
});

/* globals KXL */
KXL.module('AuthApp.Register.Deferred', function (Deferred, KXL, Backbone, Marionette, $, _) {

	Deferred.Controller = {
		start: function () {

			var split = this.getSplit();

			if (split && split.substr(0, 5) === 'timer') {
				this.startTimer((parseInt(split.substr(5), 10) * 1000 * 60), true);
			} else {
				this.setGameEventListener();
			}
		},

		restart: function () {

			// Are we in a deferred registration state?
			if (!KXL.request('auth:is:temp:user')) {
				return;
			}

			var split = this.getSplit();

			// If this is an event split, set the event handler
			if (split.substr(0, 5) !== 'timer') {
				return this.setGameEventListener();
			}

			// Otherwise, reset the timer

			// See if there is a cookie with the reminder time set
			var deferredRegistrationTime = parseInt(KXL.Common.Utils.CookieUtils.getCookie('kxl-dr-cookie'), 10) || 0;

			// If in the future, set the timer
			var now = Date.now();
			if (deferredRegistrationTime > now) {
				deferredRegistrationTime = deferredRegistrationTime - now;  // Set the popup for the time delta
			} else {
				deferredRegistrationTime = 5000;  // If timer is in the past, pop the complete registration dialog in 5 seconds
			}

			this.startTimer(deferredRegistrationTime, false);
		},

		gameEventHandler: function (event) {

			// Are we in a deferred registration state?  Is the user a temp user?
			if (!KXL.request('auth:is:temp:user')) {
				return;
			}

			// Ignore events we aren't interested in
			if (['tutorialCompletion', 'levelUp'].indexOf(event.event) === -1) {
				return;
			}

			var eventName = event.event;
			if (eventName === 'levelUp') {
				eventName = eventName.concat(event.level);
			}
			var split = this.getSplit();

			if (split !== eventName) {
				return this.checkEventPassed(event, split);
			}

			return this.show({ referrer: 'deferred-reg-event-' + split });
		},

		startTimer: function (delay, setCookie) {

			if (setCookie) {
				// Drop a cookie with the time to launch the cookie, in seconds
				var deferredRegistrationReminderTime = Date.now() + delay;
				document.cookie = "kxl-dr-cookie=" + deferredRegistrationReminderTime;
			}

			window.clearTimeout(this.deferredRegistrationTimer);

			this.deferredRegistrationTimer = setTimeout(this.show.bind(this, { referrer: 'deferred-reg-timer' }), delay);
		},

		cancelTimer: function () {

			// Cancel the window timer
			if (this.deferredRegistrationTimer) {
				window.clearTimeout(this.deferredRegistrationTimer);
			}

			// Remove the cookie
			document.cookie = "kxl-dr-cookie=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
		},

		checkGameAllowsTimedRegPopup: function (options) {

			var currentPlayCommonName = KXL.context && KXL.context.get('commonName');

			if (currentPlayCommonName &&
				!KXL.config.autoRegCommonNames[currentPlayCommonName].allowTimedRegPopUp &&
				options.referrer === 'deferred-reg-timer') {
				return false;
			}

			return true;
		},

		show: function (options) {

			// Ensure the timer is cleared
			this.cancelTimer();

			// Are we in a deferred registration state?
			if (!KXL.request('auth:is:temp:user')) {
				return;
			}

			// Show the dialog if the game allows it.
			if (this.checkGameAllowsTimedRegPopup(options)) {
				if (!KXL.appModel.get('deferredRegDialogOverridden')) {
					KXL.execute('show:delayed:register:dialog', options);
				}
				KXL.vent.trigger('delayed:register:dialog:shown');
			}
		},

		gate: function (options) {

			if (!KXL.request('auth:is:temp:user')) {
				return false;
			}

			this.show(options);

			return true;
		},

		getSplit: function () {

			// Hard coding to 10 minute timer
			return 'timer10';
		},

		setGameEventListener: function () {

			var listening = false;

			return function () {

				if (listening) {
					return;
				}
				listening = true;

				KXL.vent.on('game:event', Deferred.Controller.gameEventHandler.bind(Deferred.Controller));
			};
		}(),

		// Catch situations where the event that will trigger the register dialog has been missed
		checkEventPassed: function (event, split) {

			if (event.event !== 'levelUp' || KXL.appModel.get('deferredRegBarShowing')) {
				return;
			}
			if (split === 'tutorialCompletion' && event.level <= 2) {
				return;
			}
			if (split.substr(0, 7) === 'levelUp' && event.level < parseInt(split.substr(7), 10)) {
				return;
			}

			this.show({ referrer: 'deferred-reg-event-passed-' + split });
		}
	};
});

KXL.module('Components.ForumPosts', function(ForumPosts, KXL, Backbone, Marionette, $, _) {
	ForumPosts.Controller = {
		getForumPostsView: function(options) {
			var forumPosts  = KXL.request('forumposts:entities', options)._collection;
			return new ForumPosts.View({
				collection: forumPosts
			});
		}
	};
});

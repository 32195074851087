KXL.module('Analytics', function (Analytics, KXL, Backbone, Marionette, $, _) {
	Analytics.Controller = Marionette.Controller.extend({
	 	pollData: {
			'tag': 'session',
			'stage': 'sessioninpro'
		},
		pollHandle: null,
		timeout: 270, // 4.5 minutes to stay withing GA 5 minute 'active visitor' timeout
		initialize: function (options) {
			var self = this;
			//Short Circuit if the switch is turned off
			this.startListening();
			this.websocket = KXL.module('Websocket');

			if (!KXL.switches.analyticsHeartBeat) {
				return;
			}

			this.startSessionPolling();

			KXL.commands.setHandlers({
				'analytics:track:page:view': function (path) {
					self.trackPageView(path);
				},

				'analytics:ua:conversion': function (conversionType, game, options) {
					options = options || {};
					options.kxid = KXL.currentUser.id;
					var value = options.value || 0;
					var send_to = [KXL.config.googleAdsTagIDs[game] + '/' + KXL.config.googleAdsConversionIDs[conversionType][game]];
					self.googleTagTrackEvent(game, 'conversion', conversionType, value, false, send_to, options);
					options.game = game;
					self.facebookPixelEvent(KXL.config.facebookPixelEventNames[conversionType], options);
				}
			});
		},

		queryStringCookie: function (addGameUrlName) {
			var queryString = KXL.Common.Utils.CookieUtils.getJSONCookie('uaTrack.kxl.queryString');

			if (addGameUrlName && queryString && queryString.game) {
				var commonName = queryString.game === 'gp' ? 'vc' : queryString.game;
				var game = KXL.staticGames.findWhere({ commonName: commonName });
				queryString.gameUrlName = (game && game.get('urlName')) || commonName;
			}

			return queryString;
		},

		getUaGameUrlName: function () {
			var queryString = this.queryStringCookie(true);
			return queryString && queryString.gameUrlName;
		},

		/**
		 * Track a payments page view.  The path will be lowercased and
		 * prepended with 'payment.'.
		 *
		 * @param  {String} path The path to track.
		 */
		trackPaymentsPageView: function (path) {
			this.trackPageView('/payments/' + path.toLowerCase());
		},

		/**
		 * Track a virtual page view
		 *
		 * @param {String} path The path to track, assumed to originate from root (/)
		 */
		trackPageView: function (path) {
			if(KXL.config.includeGoogleTags) {
				var options = {
					'custom_map': {'dimension1': 'kxid'}
				};
				options.page_path = path ? path : window.location.pathname;
				gtag('config', KXL.config.googleAnalyticsTagID, options);
			}
		},

		/**
		 * Track an event through google analytics
		 *
		 * @param {Object} category Top level classification of the event
		 * @param {Object} action Second level event classification
		 * @param {Object} label Third level event classification
		 * @param {Object} value Numeric value to attach to the event. default 0. (optionsal)
		 * @param {Object} interaction If true, the event will be considered as an interaction.  default is false.
		 */
		googleTagTrackEvent: function (category, action, label, value, interaction, send_to, extra_options) {
			if(KXL.config.includeGoogleTags) {
				send_to = send_to || [];
				if(send_to.indexOf(KXL.config.googleAnalyticsTagID) === -1) {
					send_to.push(KXL.config.googleAnalyticsTagID);
				}

				extra_options = extra_options || {};
				var options = {
					'send_to': send_to,
					'event_category': category,
					'event_label': label || null,
					'event_value': value || 0,
					'non_interaction': !interaction
				};
				for(var key in extra_options) {
					options[key] = extra_options[key];
				}

				gtag('event', action, options);
			}
		},

		facebookPixelEvent: function (eventName, options) {
			if(KXL.config.includeFacebookPixel) {
				fbq('track', eventName, options);
			}
		},

		startSessionPolling: function () {
			var self = this;

			if (!this.pollHandle) {
				this.pollHandle = setInterval(function () {
					// send subsequent heartbeats (interaction event). include timeout
					// as the value param for ttl metrics
					// If the heartbeat is turned off then all heartbeats should stop
					self.googleTagTrackEvent('heartbeat', 'alive', null, self.timeout, true);
				}, self.timeout * 1000);

				//send first heartbeat (non_interaction event so bounce is not affected)
				this.googleTagTrackEvent('heartbeat', 'first');
			}
		},

		/**
		 * Construct a path segment giving the payment provider,
		 * an optional payment type, and the game name e.g.
		 * 'paypalhub.braintree.battlepirates'
		 *
		 * @param  {Object} data An object with keys for 'provider' and 'paymentType'
		 * @return {String}      The GA path segment
		 */
		getPaymentDetail: function (data) {
			var game = this.getCurrentGamePlaying();
			var detail = data.provider;
			if (data.paymentType) {
				detail += '.' + data.paymentType;
			}
			if (data.paymentMethod) {
				detail += '.' + data.paymentMethod;
			}
			if (game) {
				detail += '.' + game;
			}
			return detail;
		},

		getCurrentGamePlaying: function() {
			return KXL.context && KXL.context.get('urlName');
		},

		startListening: function () {
			var self = this;

			function makeTrackRoot(root, data) {
				var trackSource = data.trackSource || 'sourceunknown';

				return '/' + root + '/' +
					(data && data.wasDeferred ? 'delayed/' : '') +
					(data && data.trackControl ? data.trackControl : 'controlunknown') +
					'/' + trackSource + '/';
			}

			KXL.vent.on('info:page:nav', function (route) {
				self.trackPageView(route);
			});

			KXL.vent.on('info:game:page', function (gameUrlName, section) {
				if (gameUrlName && section === 'home' && !KXL.currentUser) {
					// track any logged out start now game page hit
					var game = KXL.games && KXL.games.findWhere({ urlName: gameUrlName });
					var commonName = game && game.get('commonName');
					if (commonName && _.has(KXL.config.autoRegCommonNames, commonName)) {
						self.trackPageView('/startnow/' + gameUrlName + '/page');
					}
				}
			});

			KXL.vent.on('info:game:load', function (gameUrlName) {
				self.googleTagTrackEvent('game load', gameUrlName);
			});

			/**
			 * Payments
			 */

			KXL.vent.on('info:payment:processor:init', function (data) {
				self.trackPaymentsPageView('processor/' + self.getPaymentDetail(data) + '/init');
			});

			KXL.vent.on('info:payment:processor:auth', function (data) {
				self.trackPaymentsPageView('processor/' + self.getPaymentDetail(data) + '/success');
			});

			KXL.vent.on('info:payment:processor:denied', function (data) {
				var reason = data.reason || 'unknown';
				self.trackPaymentsPageView('processor/' + self.getPaymentDetail(data) + '/denied/' + reason);
			});

			KXL.vent.on('info:payment:processor:error', function (data) {
				var reason = data.reason || 'unknown';
				self.trackPaymentsPageView('processor/' + self.getPaymentDetail(data) + '/error/' + reason);
			});

			KXL.vent.on('info:payment:init', function () {
				self.trackPaymentsPageView(self.getCurrentGamePlaying() + '/init');
			});

			KXL.vent.on('info:payment:success', function (data) {
				self.trackPaymentsPageView(self.getCurrentGamePlaying() + '/success');
			});

			KXL.vent.on('info:payment:error', function (data) {
				var reason = data.reason || 'unknown';
				self.trackPaymentsPageView(self.getCurrentGamePlaying() + '/error/' + reason);
			});

			KXL.vent.on('info:payment:failed', function (data) {
				self.trackPaymentsPageView(self.getCurrentGamePlaying() + '/failure');
			});

			KXL.vent.on('info:payment:denied', function (data) {
				var reason = data.reason || 'unknown';
				self.trackPaymentsPageView(self.getCurrentGamePlaying() + '/denied/' + reason);
			});

			KXL.vent.on('info:payment:settings', function () {
				self.trackPaymentsPageView(self.getCurrentGamePlaying() + '/settings');
			});

			KXL.vent.on('payment:success', function (data) {
				var game = self.getCurrentGamePlaying();
				if(['battlepirates', 'warcommander'].indexOf(game) > -1) {
					var options = {
						value: data.usdAmount,
						currency: 'USD',
						transaction_id: data.reference
					};
					KXL.execute('analytics:ua:conversion', 'purchase', game, options);
				}
			});

			/**
			 * Logins
			 */

			KXL.vent.on('info:login:success', function () {
				self.googleTagTrackEvent('Regflow', 'Login', 'success');
			});

			KXL.vent.on('info:login:error', function (data) {
				self.googleTagTrackEvent('Regflow', 'Login', 'error:' + data.error.error);
			});

			KXL.vent.on('info:fblogin:start', function (data) {
				self.trackPageView(makeTrackRoot('fbauth', data) + 'start');
			});

			KXL.vent.on('info:fblogin:success', function (data) {
				// track as a new kxl account if they are truely new or if they have never logged into kxl
				if (data && data.response && (data.response.isNew || !data.response.user || !data.response.user.lastLoggedIn)) {
					var user = data.response.user;
					var uaGameUrlName = self.getUaGameUrlName();
					self.trackPageView(makeTrackRoot('registration', data) +
						(uaGameUrlName ? uaGameUrlName + '/' : '') + 'success/fb');
					// done with this cookie now so we can delete it
					KXL.Common.Utils.CookieUtils.deleteCookie('uaTrack.kxl.queryString', true, '/');
				}
				self.trackPageView(makeTrackRoot('fbauth', data) + 'success');
			});

			KXL.vent.on('info:fblogin:error', function (data) {
				self.trackPageView(makeTrackRoot('fbauth', data) + 'error:' + (data && data.error && data.error.error));
			});

			KXL.vent.on('info:googlelogin:start', function (data) {
				self.trackPageView(makeTrackRoot('googleauth', data) + 'start');
			});

			KXL.vent.on('info:googlelogin:success', function (data) {
				if (data && data.response && (data.response.isNew || !data.response.user || !data.response.user.lastLoggedIn)) {
					var user = data.response.user;
					var uaGameUrlName = self.getUaGameUrlName();
					self.trackPageView(makeTrackRoot('registration', data) +
						(uaGameUrlName ? uaGameUrlName + '/' : '') + 'success/google');
					// done with this cookie now so we can delete it
					KXL.Common.Utils.CookieUtils.deleteCookie('uaTrack.kxl.queryString', true, '/');
				}
				self.trackPageView(makeTrackRoot('googleauth', data) + 'success');
			});

			KXL.vent.on('info:googlelogin:error', function (data) {
				self.trackPageView(makeTrackRoot('googleauth', data) + 'error:' + (data && data.error && data.error.error));
			});

			KXL.vent.on('info:steamlogin:start', function (data) {
				self.trackPageView(makeTrackRoot('steamauth', data) + 'start');
			});

			KXL.vent.on('info:steamlogin:success', function (data) {
				if (data && data.response && (data.response.isNew || !data.response.user || !data.response.user.lastLoggedIn)) {
					var user = data.response.user;
					var uaGameUrlName = self.getUaGameUrlName();
					self.trackPageView(makeTrackRoot('registration', data) +
						(uaGameUrlName ? uaGameUrlName + '/' : '') + 'success/steam');
					// done with this cookie now so we can delete it
					KXL.Common.Utils.CookieUtils.deleteCookie('uaTrack.kxl.queryString', true, '/');
				}
				self.trackPageView(makeTrackRoot('steamauth', data) + 'success');
			});

			KXL.vent.on('info:steamlogin:error', function (data) {
				self.trackPageView(makeTrackRoot('steamauth', data) + 'error:' + (data && data.error));
			});

			KXL.vent.on('info:register:start', function (data) {
				self.trackPageView(makeTrackRoot('registration', data) +
					(data.trackGameUrlName ? data.trackGameUrlName + '/' : '') + 'start');
			});

			KXL.vent.on('info:register:cancel', function (data) {
				self.trackPageView(makeTrackRoot('registration', data) +
					(data.trackGameUrlName ? data.trackGameUrlName + '/' : '') + 'cancel');
			});

			KXL.vent.on('info:register:submit', function (data) {
				self.trackPageView(makeTrackRoot('registration', data) +
					(data.trackGameUrlName ? data.trackGameUrlName + '/' : '') + 'submit');
			});

			KXL.vent.on('info:register:captcha:start', function (data) {
				self.trackPageView(makeTrackRoot('registration', data) +
					(data.trackGameUrlName ? data.trackGameUrlName + '/' : '') + 'captcha/start');
			});

			KXL.vent.on('info:register:success', function (data) {
				var uaGameUrlName = self.getUaGameUrlName() || data.trackGameUrlName;
				self.trackPageView(makeTrackRoot('registration', data) +
					(uaGameUrlName ? uaGameUrlName + '/' : '') + 'success/kxl');
				// done with this cookie now so we can delete it
				KXL.Common.Utils.CookieUtils.deleteCookie('uaTrack.kxl.queryString', true, '/');
			});

			KXL.vent.on('info:register:autoreg', function (gameUrlName) {
				self.trackPageView('/autoregistration/' + (gameUrlName ? gameUrlName + '/' : 'unknowngame/') + 'success');
			});

			KXL.vent.on('info:register:error', function (data) {
				self.trackPageView(makeTrackRoot('registration', data) +
					(data.trackGameUrlName ? data.trackGameUrlName + '/' : '') + 'regerror/' + data.error.error);
			});

			KXL.vent.on('info:landingpage:ctaclick', function (data) {
				self.trackPageView(makeTrackRoot('registration', data) + 'click');
			});

			// Auto Reg Errors
			KXL.vent.on('autoreg:error', function (data, gameUrlName) {
				self.googleTagTrackEvent('Autoreg', 'TempAccountCreate:' + (gameUrlName ? gameUrlName : 'unknowngame'), 'error:' + (data.error ? data.error : 'unknownerror' ));
			});

			KXL.vent.on('info:gameCTA:click', function (data) {
				var game = (data && data.game) || 'unknowngame';
				var cta = (data && data.cta) || 'unknowncta';
				self.trackPageView('/startnow/' + game + '/ctaclick/' + cta);
			});

			KXL.vent.on('info:startnow:menu', function (game) {
				self.trackPageView('/startnow/' + (game || 'unknowngame') + '/menu');
			});

			KXL.vent.on('info:startnow:new', function (game) {
				self.trackPageView('/startnow/' + (game || 'unknowngame') + '/new');
			});

			KXL.vent.on('info:startnow:fb', function (game) {
				self.trackPageView('/startnow/' + (game || 'unknowngame') + '/fb');
			});

			KXL.vent.on('info:startnow:fb:success', function (game) {
				self.trackPageView('/startnow/' + (game || 'unknowngame') + '/fb/success');
			});

			KXL.vent.on('info:ribbon:ctaClick', function () {
				self.trackPageView('/adtogame/ctaclick');
			});

		},

	});

	KXL.addInitializer(function(options) {
		new Analytics.Controller(options);
	});
});

KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {
	Entities.UserSettings = Entities.Model.extend({
		defaults: {
			searchForPlayersByKixeyeName: true,
			searchForPlayersByVegaConflictName: true,
			searchForPlayersByBattlePiratesName: true,
			searchForPlayersByWarCommanderName: true
		},
		idAttribute: 'userId',
		url: function () {
			return KXL.config.API_URL + '/users/' + KXL.currentUser.id + '/settings/';
		},
		// So that we can force a PATCH everytime.
		isNew: function () {
			return false;
		}
	});

	var API = {
		getUserSettings: function () {
			var userSettings = new Entities.UserSettings();
			var deferred = $.Deferred();
			userSettings.fetch({
				success: function (entity, response, options) {
					deferred.resolve(entity, response, options);
				},
				error: function (entity, response, options) {
					deferred.reject(entity, response, options);
				}
			});
			deferred._model = userSettings;
			return deferred;
		},
		getNewUserSettings: function(settings) {
			return new Entities.UserSettings(settings);
		}
	};
	KXL.reqres.setHandler('new:user:settings:entity', function (settings) {
		return API.getNewUserSettings(settings);
	});
	KXL.reqres.setHandler('user:settings:entity', function () {
		return API.getUserSettings();
	});
});

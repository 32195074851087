KXL.module('Entities', function (Entities, KXL, Backbone, Marionette, $, _) {

	var GET_FRIENDS_LIMIT = 25;
	var _getFriendsOffset = {};

	Entities.Friend = Entities.Model.extend({
		idAttribute: 'userId',
		defaults: {
			presence: 'unavailable'
		},
		// don't save the presence to the server
		blacklist: ['presence'],

		urlRoot: function () {
			return KXL.config.API_URL + '/users/' + KXL.request('get:current:user').id + '/friends';
		},
		isNew: function() {
			return true;
		}
	});

	Entities.Friends = Entities.Collection.extend({
		model: Entities.Friend,
		initialize: function (models, options) {
			this.id = options.id;
		},
		url: function () {
			return KXL.config.API_URL + '/users/' + this.id + '/friends';
		},
		fetch: function (options) {
			options = options || {};
			options.data = _.extend(options.data || {}, {
				expand: 'games'
			});
			return Entities.Collection.prototype.fetch.call(this, options);
		},
		parse: function (response, options) {
			var userId = this.id;

			if (response.meta && response.meta.totalAvailable) {
				KXL.request(
					'set:service:friends:count',
					userId,
					response.meta.totalAvailable
				);
			}

			if (response.paging && response.paging.nextOffset) {
				_getFriendsOffset[userId] = response.paging.nextOffset;
			} else {
				_getFriendsOffset[userId] = null;
			}

			if (response.data) {

				var avatarCacheUpdates = {};

				_.each(response.data, function (friend, index) {
					var userId = friend.userId;
					if (userId && friend.user) {
						if (friend.user.presence) {
							response.data[index].presence = friend.user.presence;
						}
						if (friend.user.avatars) {
							avatarCacheUpdates[userId] = friend.user.avatars;
						}
					}
				});

				if (!_.isEmpty(avatarCacheUpdates)) {
					// Send in batch to cache service for less event noise.
					KXL.request('set:services:avatars', avatarCacheUpdates);
				}

			}

			return (response.data) ? response.data : response;
		}
	});

	var API = {
		getFriends: function(userId, options) {
			options = options || {};

			var friends = new Entities.Friends(
				[],
				{
					id: userId
				}
			);

			// Want the deferred to resolve with Entities.Friends, not the Ajax response.
			var deferred = $.Deferred();

			// Is this request a paginated?
			var offset = !_.isUndefined(options.offset) ? options.offset :
				( (_getFriendsOffset[userId]) ? _getFriendsOffset[userId] : 0 );

			if (offset === -1) {
				return deferred.reject();
			}

			var fetchOptions = {
				offset: offset,
				limit: options.limit || GET_FRIENDS_LIMIT
			};

			if (options.presence) {
				fetchOptions.presence = options.presence;
			}

			var expand = [];
			if (options.expandGames) {
				expand.push('games');
			}

			// ensure we always add expand avatars
			expand.push('avatars');

			if (expand.length) {
				fetchOptions.expand = expand.join(',');
			}

			friends.fetch({
				data: fetchOptions,
				success: function (collection) {
					deferred.resolve(collection);
				},
				error: function (error) {
					deferred.reject(error);
				}
			});

			deferred._collection = friends;

			return (options.promise) ? deferred.promise() : deferred;
		},
		getFriendsPagingOffset: function (userId) {
			return (_getFriendsOffset[userId]) ? _getFriendsOffset[userId] : 0;
		}
	};

	KXL.reqres.setHandlers({
		'user:friend:entities': function (userId, options) {
			return API.getFriends(userId, options);
		},
		'get:user:friend:nextPageOffset:entity': function (userId) {
			return API.getFriendsPagingOffset(userId);
		}
	});
});

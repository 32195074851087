KXL.module('Common.Utils.Collection', function (Collection, KXL, Backbone, Marionette, $, _) {

	// return the previous model or the end if at
	// the beginning and wrap is turned on
	Collection.prev = function (col, model, wrap) {
		var next = col.indexOf(model) - 1;
		if (wrap) {
			next = next > -1 ? next : _.size(col) - 1;
		} else {
			next = next > -1 ? next : 0;
		}
		return col.at(next);
	};

	// return next model or the beginning if at
	// the end and wrap is turned on
	Collection.next = function (col, model, wrap) {
		var next = col.indexOf(model) + 1;
		var size = _.size(col);
		if (wrap) {
			next = next % size;
		}
		if (next >= size) {
			next = size - 1;
		}
		return col.at(next);
	};

});

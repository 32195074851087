KXL.module('GamesApp.PlayMessage', function (PlayMessage, KXL, Backbone, Marionette, $, _) {
	PlayMessage.PlayMessageBrowserLogoItem = KXL.Components.ItemView.extend({
		tagName: 'li',
		template: '#kx-play-game-message-browser-logo',
		className: 'kx-play-game-message-supported-logo',
		serializeData: function () {
			return {
				logoUrl: KXL.config.STATIC_BASE_URL + this.model.get('icon'),
				link: this.model.get('link')
			};
		}
	});

	PlayMessage.PlayMessageSupportedBrowserLogos = Marionette.CollectionView.extend({
		tagName: 'ul',
		className: 'kx-play-game-message-supported-logos',
		itemView: PlayMessage.PlayMessageBrowserLogoItem,
		buildItemView: function (model, ItemViewType, itemViewOptions) {
			var logo = new PlayMessage.PlayMessageBrowserLogoItem({
				model: model
			});
			return logo;
		}
	});

	PlayMessage.PlayMessageUnsupportedLayout = KXL.Components.Layout.extend({
		template: '#play-game-message-unsupported',
		className: 'kx-play-game-message kx-play-game-message-unsupported',
		regions: {
			browserLogosRegion: '.kx-browser-logos-region'
		},
		_renderSupportedBrowsers: function () {
			var tempSupportedCollection = new Backbone.Collection([
				{
					name: 'iOS',
					icon: '/modules/game/play_message/images/ios_logo.png',
					link: 'https://itunes.apple.com/ca/app/vega-conflict/id898383017'
				},
				{
					name: 'Android',
					icon: '/modules/game/play_message/images/android_logo.png',
					link: 'https://play.google.com/store/apps/details?id=com.kixeye.vegaconflict'
				},
				{
					name: 'Steam',
					icon: '/modules/game/play_message/images/steam_logo.png',
					link: 'http://store.steampowered.com/app/339600/'
				}]);

			var logos = new PlayMessage.PlayMessageSupportedBrowserLogos({
				collection: tempSupportedCollection
				//collection: this.options.supportedBrowsers
			});
			this.browserLogosRegion.show(logos);
		},
		onRender: function () {
			if (this.options.supportedBrowsers && this.options.supportedBrowsers.length) {
				this._renderSupportedBrowsers();
			}
		},
		serializeData: function () {
			return {
				logo: this.options.logo,
				messageText: this.options.messageText
			};
		}
	});
});

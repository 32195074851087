/* globals KXL */
KXL.module('InboxApp', function (InboxApp, KXL, Backbone, Marionette, $, _) {
	var InboxController = Marionette.Controller.extend({
		threadsRequest: null,
		threads: null,
		layout: null,
		panelLayout: null,
		threadsView: null,
		previousMessageScrollTop: 0,
		initialize: function() {
			var self = this;
			KXL.vent.on('inbox:more:messages:fetched', function(){
				self.hidePreloader();
				self.scrollToTop();
			});
			KXL.vent.on('inbox:more:messages:fetching', function(){
				self.showPreloader();
			});
		},
		scrollToBottom: function () {
			var threadBody = $('.thread-body-region');
			if (threadBody[0]) {
				threadBody[0].scrollTop = threadBody[0].scrollHeight;
			}
		},
		scrollToTop: function () {
			var threadBody = $('.thread-body-region');
			if (threadBody[0]) {
				threadBody[0].scrollTop = 2;
			}
		},

		setupLayout: function () {
			this.layout = new InboxApp.Views.Layout();
			KXL.inboxRegion.show(this.layout);
		},

		updateUnreadCount: function() {

			var unreadThreadCount = 0;
			var currentUserSettings = KXL.request('current:user:settings:entity');
			var inboxPanelLastViewed = currentUserSettings.get('inboxPanelLastViewed');
			this.threads.each(function (thread) {
				var fuzzyOffsetForServerTime = 2000;
				if ((!thread.get('lastReadAt') || thread.get('lastReadAt') < thread.get('lastMessageAt'))
					&& (!inboxPanelLastViewed || inboxPanelLastViewed < thread.get('lastMessageAt') + fuzzyOffsetForServerTime)) {
					unreadThreadCount++;
				}
			});
			KXL.vent.trigger('user:userInbox:unread:count:changed', unreadThreadCount);
		},

		getInboxPanelView: function() {
			var self = this;
			if (!self.panelLayout) {
				self.panelLayout = new InboxApp.InboxPanel.Views.MainLayout();
				self.listenToOnce(KXL.vent, 'app:reset', function () {
					self.panelLayout = null;
				});
				self.panelLayout.on('show', function() {
					self.loadInboxMessages(null, null, true);
				});
			} else {
				self.loadInboxMessages(null, null, true);
			}
			return this.panelLayout;
		},

		showInbox: function (selectedThreadId, startingThread) {

			var self = this;

			if (!KXL.appModel.get('currentUser')) {
				return KXL.execute('home:show');
			}

			if (!this.layout) {
				this.setupLayout();
			}

			if (!this.layout.actions.currentView) {
				var actionsView = new InboxApp.Views.Actions();
				this.layout.actions.show(actionsView);
			}

			this.loadInboxMessages(selectedThreadId, startingThread);
		},

		loadInboxMessages: function(selectedThreadId, startingThread, loadPanel) {
			var self = this;
			var loadPanelFlyout = loadPanel || false;

			if (!self.threadsRequest) {
				// Make sure the threadsRequest is redone when a new user is logged in
				self.listenToOnce(KXL.vent, 'app:reset', function () {
					self.threadsRequest = null;
				});
				self.threadsRequest = KXL.request('inbox:get:threads', { data: { limit: 10 } });
				self.threadsRequest.then(function (collection) {
					self.threads = _.extend(collection, new Backbone.Picky.SingleSelect(collection));
					self.threads.comparator = function (a, b) {
						if (a.get('lastMessageAt') > b.get('lastMessageAt')) {
							return -1;
						}
						return 1;
					};
					self.threads.on('change', function (model, options) {
						if (!options.suppressSort) {
							this.sort();
						}
					});

					// only create the inbox threads view once
					if (!loadPanel && !self.threadsView) {
						self.threadsView = new InboxApp.Views.Threads({
							collection: self.threads
						});
					}

					// if inbox layout is there but doesn't yet have the threads view
					if (self.layout && !self.layout.threadList.currentView) {
						self.layout.threadList.show(self.threadsView);
					}

					if (!startingThread) {
						self.selectThread(selectedThreadId, loadPanelFlyout);
					}

					// if header inbox panel not yet loaded
					if (self.panelLayout) {
						var panelThreadsView = new InboxApp.InboxPanel.Views.MessageItemsView({
							collection: self.threads
						});
						self.panelLayout.threadList.show(panelThreadsView);
					}

					KXL.vent.on('reset:available:inboxMessages', function () {
						var currentUserSettings = KXL.request('current:user:settings:entity');
						currentUserSettings.set('inboxPanelLastViewed', Date.now());
						currentUserSettings.save();
						KXL.vent.trigger('user:userInbox:unread:count:changed', 0);
					});

				});
				if (KXL.currentUser && KXL.currentUser.id) {
					KXL.execute('inbox:subscribe', self.addMessageToThread.bind(self));
				}
			} else {
				self.threadsRequest.then(function () {
					// only create the inbox threads view once
					if (!loadPanel && !self.threadsView) {
						self.threadsView = new InboxApp.Views.Threads({
							collection: self.threads
						});
					}

					// if inbox layout is there but doesn't yet have the threads view
					if (self.layout && !self.layout.threadList.currentView) {
						self.layout.threadList.show(self.threadsView);
					}

					// if header inbox panel not yet loaded
					if (self.panelLayout) {
						var panelThreadsView = new InboxApp.InboxPanel.Views.MessageItemsView({
							collection: self.threads
						});
						self.panelLayout.threadList.show(panelThreadsView);
					}

					if (!startingThread) {
						self.selectThread(selectedThreadId, loadPanelFlyout);
					}
				});
			}
		},

		showPreloader: function () {
			var loaderView = new KXL.Components.Loader.View();
			this.layout.preloader.show(loaderView);
		},

		hidePreloader: function () {
			var self = this;
			_.delay(function() {
				self.layout.preloader.close();
			}, 500);
		},

		showThread: function (threadId, isFocused, fromPanel) {
			var self = this;
			self.previousMessageScrollTop = 0;

			var focused = (isFocused) ? true : false;

			if (!this.threads || !this.threads.get(threadId) || fromPanel) {
				this.showInbox(threadId);
				return;
			}

			var thread = this.threads.get(threadId);
			if (!thread.get('messages')) {
				self.showPreloader();
				thread.getMessages();
			}
			var messages = thread.get('messages');

			messages.on('sync', function() {
				self.hidePreloader();
			});

			self.layout.recipient.close();
			thread.select();

			var lastReadAt = thread.get('lastReadAt');
			var lastMessageAt = thread.get('lastMessageAt');
			if (lastMessageAt > lastReadAt) {
				thread.set({ 'lastReadAt': lastMessageAt }, { suppressSort: true });
				thread.save();
			}

			var threadView = new InboxApp.Views.Thread({
				model: thread,
				collection: messages
			});
			self.layout.threadBody.show(threadView);

			var participantsView = new InboxApp.Views.Participants({
				model: thread
			});

			self.layout.participants.show(participantsView);

			self.layout.threadBody.$el.off('scroll');
			self.layout.threadBody.$el.scroll(function() {
				self.checkScrollPosition(self.layout.threadBody.$el, thread);
			});

			self.markThreadRead(threadId);

			self.scrollToBottom();

		},

		checkScrollPosition: function (element, thread) {

			var offset = thread.get('messages') && thread.get('messages').length;
			var currentScrollTop = element.scrollTop();

			// If scrollbar is at top and the user initiated the move
			if (currentScrollTop === 0 && this.previousMessageScrollTop !== 0)
			{
				//If we don't have the moreMessages attribute OR it has been set to true
				if (!thread.has('moreMessages') || thread.get('moreMessages')) {
					thread.getMoreMessages(offset, 10);
				}
			}
			this.previousMessageScrollTop = currentScrollTop;
		},

		startThread: function (recipient) {
			var self = this;

			if (!self.threads || !self.layout) {
				self.showInbox(null, true);
			}
			var focusRecipientField = true;
			var recipients = [];
			if (recipient) {
				focusRecipientField = false;
				recipients = [ recipient ];
			}

			KXL.asyncRequest('async:load:module', 'common/components/recipient_input').then(function () {

				var recipientField = KXL.request('new:recipient:input:view', {
					focus: focusRecipientField,
					recipients: recipients,
					customClass: 'inbox',
					placeholderText: 'Type Player Name'
				});
				var recipientList = recipientField.recipientList;

				KXL.asyncRequest('async:load:module', 'common/components/message_input').then(function () {
					var composeMessage = KXL.request('new:messageinput:view', {
						tabindex: 3,
						focus: !focusRecipientField,
						enterSubmit: false
					});
					composeMessage.on('submit', function (message) {
						if (recipientList.length > 0) {
							var deferred = KXL.request('inbox:send:message', message, {
								recipients: recipientList.map(function (recipient) {
									return recipient.id;
								})
							});
							deferred.then(function (messageModel) {
								var newThread = new KXL.Entities.Inbox.Thread(messageModel.get('thread'));
								self.addThread(newThread);
								self.showThread(messageModel.get('threadId'), true);
							});
						}
					});
					self.layout.messageCompose.show(composeMessage);
				});

				self.layout.recipient.show(recipientField);
				self.layout.threadBody.show(new InboxApp.Views.EmptyThread());
				self.layout.participants.close();

			});

		},

		addThread: function (newThread) {

			if (this.threads.get(newThread.get('id'))) {
				return;
			}

			this.threads.add(newThread);
		},

		addMessageToThread: function (message) {

			var thread = this.threads.get(message.get('threadId'));
			var updatedMessageThread = message.get('thread');

			if (thread && updatedMessageThread) {

				// If the thread already exists, set the lastMessageAt and add the message to it
				var threadUpdate = {
					lastMessageAt: message.get('createdAt'),
					snippet: message.get('body'),
					snippetAddress: updatedMessageThread.snippetAddress || KXL.request('inbox:add:suffix', KXL.currentUser.id)
				};
				var inboxOpen = KXL.appModel.get('pages').selected.id === 'inbox';
				if (thread.selected && inboxOpen) {
					threadUpdate.lastReadAt = message.get('createdAt');
				}
				thread.set(threadUpdate);

				var messages = thread.get('messages');
				if (messages) {
					messages.add(message);
				}
			}
			else {
				// If the thread does not exist, create it, we don't need to add the message to it as it is (by virtue of not existing)
				// not selected, and the message will be loaded when it is selected
				var newThread = new KXL.Entities.Inbox.Thread(message.get('thread'));
				this.addThread(newThread);
			}

			this.updateUnreadCount();
			this.scrollToBottom();
		},

		selectThread: function (threadId, loadPanelFlyout) {

			var loadPanelFlyout = loadPanelFlyout || false;

			var selectedThread;
			if (threadId) {
				selectedThread = this.threads.get(threadId);
			}
			if (!selectedThread && this.threads.length) {
				selectedThread = this.threads.first();
			}

			if (!loadPanelFlyout) {
				if (selectedThread) {
					this.showThread(selectedThread.id, false);
				} else {
					this.layout.threadBody.show(new InboxApp.Views.NoThreadSelected());
					this.layout.messageCompose.close();
				}
			} else {
				if(selectedThread) {
					selectedThread.select();
					if (!selectedThread.get('messages')) {
						selectedThread.getMessages();
					}
				}
			}

			this.updateUnreadCount();
		},

		markThreadRead: function (threadId) {

			var thread = this.threads.get(threadId);
			if (!thread) {
				return;
			}

			thread.set({ 'lastReadAt': thread.get('lastMessageAt') },{ suppressSort: true });
			thread.save();
			
			this.updateUnreadCount();
		},

		markThreadUnread: function (threadId) {

			var thread = this.threads.get(threadId);
			if (!thread) {
				return;
			}

			thread.set({ 'lastReadAt': thread.get('createdAt') - 1 }, { suppressSort: true });
			thread.save();

			this.updateUnreadCount();
		},

		deleteThread: function (threadId) {

			var thread = this.threads.get(threadId);
			if (!thread) {
				return;
			}

			thread.destroy();
			this.selectThread();
		}
	});
	InboxApp.Controller = new InboxController();
});

(function () {
	jQuery.ajaxSetup({
		beforeSend: function (jqXHR, settings) {
			// Append CSRF Token if required for this request.
			if (settings && ['GET', 'HEAD', 'OPTIONS', 'TRACE'].indexOf(settings.type) === -1) {
				jqXHR.setRequestHeader('X-CSRFToken', KXL.Common.Utils.CookieUtils.getCookie('csrftoken'));
			}
		},
		complete: function (jqXHR, textStatus) {
			// We only want to do this on relative URLs (same host)
			if (this.url && KXL.Common.Utils.Url.isRelative(this.url)) {
				if (KXL && KXL.currentUser && jqXHR.status === 401) {
					KXL.execute('logout');
				}
			}
		}
	});
})();
/* globals KXL */
KXL.module('Entities', function (Entities, KXL, Backbone, Marionette, $, _) {

	var comment = KXL.Entities.Model.extend({
		defaults: {
			content: '',
			userId: '',
			entityType: '',
			entityId: ''
		},
		url: function () {
			if (!this.options) {
				this.options = this.collection.options;
			}

			var url = KXL.config.API_URL + '/comments/';

			if (this.get('id')) {
				url += this.get('id');
			}

			return url;
		},
		update: function (attributes) {
			var deferred = $.Deferred();
			this.save(attributes, {
				patch: true,
				success: function(model, successCode, jqXHR) {
					deferred.resolve(model);
				}
			});
			return deferred.promise();
		},
		hide: function () {
			var deferred = $.Deferred();
			this.save({isHidden: true}, {
				patch: true,
				success: function(model, successCode, jqXHR) {
					deferred.resolve(model);
				}
			});
			return deferred.promise();
		}
	});

	var comments = KXL.Entities.Collection.extend({
		model: comment,
		comparator: 'createdAt',
		constructor: function (models, options) {
			this.options = options || {};
			return KXL.Entities.Collection.prototype.constructor.apply(this, arguments);
		},
		url: function () {
			return KXL.config.API_URL + '/comments/';
		},
		parse: function (response, options) {
			if (response.data) {
				this.meta = response.meta;
				if (options && options.direction === 'previous') {
					this.paging.previous = response.paging.previous;
				} else if (options && options.direction === 'next') {
					this.paging.next = response.paging.next;
				} else {
					this.paging = response.paging;
				}
				response = response.data;
			}
			return response;
		},
		post: function (attributes) {
			var self = this,
				deferred = $.Deferred(),
				defaults = {
					content: 'This is cool.',
					userId: KXL.currentUser.id,
					entityType: this.options.entityType,
					entityId: this.options.entityId
				},
				attributes = _.defaults({
					content: attributes.content
				} || {}, defaults),
				model = new comment(attributes);
			model.options = this.options;
			model.save(null, {
				success: function(model, successCode, jqXHR) {
					self.add(model);
					deferred.resolve(model);
				}
			});

			return deferred.promise();
		},
		sync: function (method, model, options) {
			var defaults = {
				url: this.url()
			};

			options = _.defaults(options || {}, defaults);

			if (options.direction && this.paging[options.direction]) {
				options.url += this.paging[options.direction];
			}

			options.data = {
				entityId: this.options.entityId,
				limit: 5
			};

			return Backbone.sync(method, model, options);
		}
	});

	var API = {
		getComments: function (data, options) {
			return new comments(data, options);
		}
	};

	KXL.reqres.setHandler('item:get:comments', API.getComments);

});

KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {
	Entities.GameInvite = Entities.Model.extend({
		initialize: function (options) {
			this.validation.inviteCode.msg = KXL.i18n.t('games.mustBeHex');
			this.on('save', function() {
				KXL.vent.trigger('beta:code:success');
			});
		},
		url: function () {
			return KXL.config.API_URL + '/users/' + KXL.request('get:current:user').id + '/game-invites/';
		},
		validation: {
			inviteCode: {
				required: true,
				length: 24,
				pattern: "^[a-zA-Z0-9]"
			}
		}
	});
});
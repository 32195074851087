KXL.module('GamesApp.Nav', function(Nav, KXL, Backbone, Marionette, $, _) {
	Nav.NavItem = Marionette.ItemView.extend({
		triggers: {
			'click': 'clicked'
		},
		modelEvents: {
			'selected': function () {
				this.$el.addClass('kx-active');
			},
			'deselected': function () {
				this.$el.removeClass('kx-active');
			}
		},
		onRender: function () {
			if (this.model.selected) {
				this.$el.addClass('kx-active');
			}
		},
		getTemplate: function () {
			if (this.model.id === 'logo') {
				return '#_game-nav-image-item-template';
			}
			return '#_game-nav-item-template';
		},
		className: function () {
			var classes = [
				'kx-game-nav-item',
				this.model.get('id')
			];
			var customClassName = this.model.get('subnavClassName') || '';
			if (customClassName) {
				classes.push(customClassName);
			}
			return classes.join(' ');
		}
	});
	Nav.View = Marionette.CompositeView.extend({
		itemViewEventPrefix: "nav:item",
		template: '#game-nav-template',
		itemViewContainer: '.kx-game-nav-inner',
		itemView: Nav.NavItem,
		className: function () {
			return this.model.get('commonName');
		},
		setActiveSection: function (section) {
			this.trigger('set:active:section', section);
		}
	});
});

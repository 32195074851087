KXL.module('Components.Video', function (Video, KXL, Backbone, Marionette, $, _) {
	Video.View = KXL.Components.ItemView.extend({
		template: '#video-template',
		className: 'kx-video',
		initialize: function (options) {
			this.options = _.defaults({}, options, {
				autoplay: 0,
				mute: 0,
				width: 554,  // Note these dimensions are also specified in video.scss
				height: 380,
				locale: KXL.config.locale
			});
		}
	});
});

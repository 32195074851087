/* globals Backbone, _, easyXDM */
(function () {

	var XFrameMessager = function (options, isParent)
	{
		this.options = options || {};
		if (!this.options.container) {
			this.options.container = null;
		}

		this.ee = new Backbone.Wreqr.EventAggregator();
		this.ee.emit = this.ee.trigger;
		this.ee.removeEventListener = this.ee.stopListening;

		this.socket = null;
		this.ready = false;

		if (typeof isParent === 'boolean') {
			this.isParent = isParent;
		}
		else this.isParent = !this.isIframe();
	};

	XFrameMessager.prototype.log = function ()
	{
		try
		{
			if (window.console && window.console.log)
			{
				var args = Array.prototype.slice.call(arguments);
				args.unshift('XFrameMessager ' + (this.isParent ? 'parent' : 'child'));

				if (window.console.log.apply) {
					window.console.log.apply(window.console, args);
				}
				else {
					window.console.log(args);
				}
			}
		}
		catch (e) {}
	};

	XFrameMessager.prototype.start = function (container, iframe_url)
	{
		var opts = {
			onMessage : this.onMessage.bind(this),
			onReady   : this.onReady.bind(this)
		};

		if (container) opts.container = container;
		if (iframe_url) opts.remote = iframe_url;

		try
		{
			if (!iframe_url && parent && parent.socket)
			{
				this.socket = parent.socket;
				this.onReady();

				return true;
			}
		}
		catch (e) {}

		this.socket = new easyXDM.Socket(opts);

		return true;
	};

	XFrameMessager.prototype.isIframe = function ()
	{
		if (window.top === window.self) return false;
		return true;
	};

	XFrameMessager.prototype.onReady = function ()
	{
		this.ready = true;
		this.log('onReady', arguments);
		this.emit('xfm_ready', true);
	};

	XFrameMessager.prototype.onEmit = function (message)
	{
		this.log('onEmit', message);
	};

	XFrameMessager.prototype.emit = function (event, data, cbid)
	{
		if (!this.ready)
		{
			this.on('xfm_ready', function () {
				this.emit(event, data, cbid);
			}.bind(this));
			return;
		}

		if (cbid) data.cbid = cbid;

		var message = [event, data];

		message = JSON.stringify(message);

		this.onEmit(message);
		this.socket.postMessage(message);
	};

	XFrameMessager.prototype.onMessage = function (data, origin)
	{

		var self = this;

		self.log('onMessage', data, origin);

		data = JSON.parse(data);

		if (!_.isArray(data))
		{
			self.log('onMessage', 'incorrect format');
			return;
		}

		var responseEmitter = function (responseObject) {

			self.emit(data[0], responseObject, (_.isObject(data[1]) ? data[1].cbid || null : null));
		};

		self.ee.emit(data[0], null, data[1], responseEmitter);
	};

	XFrameMessager.prototype.on = function (event, callback)
	{
		this.ee.on(event, callback);
	};

	XFrameMessager.prototype.once = function (event, callback, cbid, timeout)
	{
		var self = this;
		var timer;
		var cb;
		var fireCb;

		cb = function (error, data) {
			if (data && data.cbid != cbid) return;
			fireCb(error, data);
		};

		fireCb = function (error, data) {
			if (timer)
			{
				clearTimeout(timer);
			}
			self.ee.removeEvent(event, cb);
			callback(error, data);
		};

		if (timeout)
		{
			timer = setTimeout(fireCb.bind(fireCb, 'timeout'), timeout);
		}

		this.on(event, cb);
	};

	XFrameMessager.prototype.sendAndReceive = function (event, data, callback, timeout)
	{
		var cbid = Math.round(Date.now() + '' + Math.random() * 100000).toString(16);
		this.once(event, callback, cbid, timeout);
		this.emit(event, data, cbid);
	};

	XFrameMessager.prototype.listeners = function (event)
	{
		if (event) return this.ee.$events[event] || [];
		return this.ee.$events;
	};

	window.XFrameMessager = XFrameMessager;

})();

KXL.module('Common.Utils.StringUtils', function (StringUtils, KXL, Backbone, Marionette, $, _) {
	/*
	 * Replace first match of subStr in str if at the begining of str
	 * with <span [class="<className>"] [id="<elementId>"]><subStr match></span>
	 */
	StringUtils.prettySubString = function (str, subStr, elementId, className) {
		if (!str || !subStr) {
			return str;
		}

		var
			escaped = subStr.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1"),
			re = new RegExp('(' + escaped + ')', 'i')
		;

		return str.replace(re, '<span' + (elementId ? ' id="' + elementId + '"' : '') + (className ? ' class="' + className + '"' : '') + '>' + '$1' + '</span>');
	};

	/**
	 * New line to <br>
	 */
	StringUtils.nl2br = function (text) {
		return (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
	};

	StringUtils.htmlDecode = function (chars) {
		if (chars) {
			return $('<div />').html(chars).text();
		} else {
			return '';
		}
	};

	StringUtils.datePartPadZero = function (value) {
		var length = value.toString().length;
		if (length === 1) {
			value = '0' + value.toString();
		}
		return value;
	};

	/*
	 * Uppercase first letter of string.
	 */
	StringUtils.ucFirst = function (value) {
		return value.charAt(0).toUpperCase() + value.slice(1);
	};

	/*
	 * Lowercase first letter of string.
	 */
	StringUtils.lcFirst = function (value) {
		return value.charAt(0).toLowerCase() + value.slice(1);
	};

	/**
	 * Truncate and add ellipsis.
	 * @param  {String} value
	 * @param  {Number} character position to truncate.
	 * @return {String}
	 */
	StringUtils.truncateEllipsis = function (value, length) {
		$.trim(value);
		if (value.length > length) {
			value = value.substr(0, (length - 3));
			value = value.concat('...');
		}
		return value;
	};

	/**
	 * Run the given string through a bad word filter.
	 * @param input
	 * @returns {string} result
	 */
	StringUtils.filterMessage = function(input) {
		var result = input;
		var badwords = badwordList.all;

		_.each(_.union(badwords, badwordList.locales[KXL.config.locale.toLowerCase()]), function (badword) {
			var pat = new RegExp(badword, "gi");
			result = result.replace(pat, "****");
		});

		return result;
	}

	var badwordList = {
		all: [
			"\\bfuck\\b",
			"\\basshole\\b",
			"\\bshit\\b",
			"\\bcunt\\b"
		],
		locales: {
			en_us: [
				"\\b4r5e\\b",
				"\\b5h1t\\b",
				"\\b5hit\\b",
				"\\ba55\\b",
				"\\banal\\b",
				"\\banus\\b",
				"\\bass\\b",
				"\\bass-fucker\\b",
				"\\basses\\b",
				"\\bassfucker\\b",
				"\\bassfukka\\b",
				"\\bassholes\\b",
				"\\basswhole\\b",
				"\\ba_s_s\\b",
				"\\bb!tch\\b",
				"\\bb00bs\\b",
				"\\bb17ch\\b",
				"\\bb1tch\\b",
				"\\bballbag\\b",
				"\\bballsack\\b",
				"\\bbastard\\b",
				"\\bbeastial\\b",
				"\\bbeastiality\\b",
				"\\bbellend\\b",
				"\\bbestial\\b",
				"\\bbestiality\\b",
				"\\bbi+ch\\b",
				"\\bbiatch\\b",
				"\\bbitch\\b",
				"\\bbitches\\b",
				"\\bblow job\\b",
				"\\bblowjob\\b",
				"\\bblowjobs\\b",
				"\\bboiolas\\b",
				"\\bboner\\b",
				"\\bboob\\b",
				"\\bboobs\\b",
				"\\bbooobs\\b",
				"\\bboooobs\\b",
				"\\bbooooobs\\b",
				"\\bbooooooobs\\b",
				"\\bbuceta\\b",
				"\\bbugger\\b",
				"\\bbunny fucker\\b",
				"\\bbutthole\\b",
				"\\bbuttmuch\\b",
				"\\bbuttplug\\b",
				"\\bc0ck\\b",
				"\\bc0cksucker\\b",
				"\\bcarpet muncher\\b",
				"\\bcawk\\b",
				"\\bchink\\b",
				"\\bcipa\\b",
				"\\bcl1t\\b",
				"\\bclit\\b",
				"\\bclitoris\\b",
				"\\bclits\\b",
				"\\bcnut\\b",
				"\\bcock\\b",
				"\\bcock-sucker\\b",
				"\\bcockface\\b",
				"\\bcockhead\\b",
				"\\bcockmunch\\b",
				"\\bcockmuncher\\b",
				"\\bcocks\\b",
				"\\bcocksuck\\b",
				"\\bcocksucked \\b",
				"\\bcocksucker\\b",
				"\\bcocksucking\\b",
				"\\bcocksucks \\b",
				"\\bcocksuka\\b",
				"\\bcocksukka\\b",
				"\\bcok\\b",
				"\\bcokmuncher\\b",
				"\\bcoksucka\\b",
				"\\bcoon\\b",
				"\\bcox\\b",
				"\\bcum\\b",
				"\\bcummer\\b",
				"\\bcumming\\b",
				"\\bcums\\b",
				"\\bcumshot\\b",
				"\\bcunilingus\\b",
				"\\bcunillingus\\b",
				"\\bcunnilingus\\b",
				"\\bcuntlick\\b",
				"\\bcuntlicker\\b",
				"\\bcuntlicking\\b",
				"\\bcunts\\b",
				"\\bcyalis\\b",
				"\\bcyberfuc\\b",
				"\\bcyberfuck\\b",
				"\\bcyberfucked\\b",
				"\\bcyberfucker\\b",
				"\\bcyberfuckers\\b",
				"\\bcyberfucking\\b",
				"\\bdickhead\\b",
				"\\bdildo\\b",
				"\\bdildos\\b",
				"\\bdirsa\\b",
				"\\bdog-fucker\\b",
				"\\bdogging\\b",
				"\\bdonkeyribber\\b",
				"\\bdoosh\\b",
				"\\bduche\\b",
				"\\bdyke\\b",
				"\\bejaculate\\b",
				"\\bejaculated\\b",
				"\\bejaculates\\b",
				"\\bejaculating\\b",
				"\\bejaculatings\\b",
				"\\bejaculation\\b",
				"\\bejakulate\\b",
				"\\bf u c k\\b",
				"\\bf u c k e r\\b",
				"\\bfag\\b",
				"\\bfagging\\b",
				"\\bfaggitt\\b",
				"\\bfaggot\\b",
				"\\bfaggs\\b",
				"\\bfagot\\b",
				"\\bfagots\\b",
				"\\bfags\\b",
				"\\bfannyflaps\\b",
				"\\bfannyfucker\\b",
				"\\bfatass\\b",
				"\\bfcuk\\b",
				"\\bfcuker\\b",
				"\\bfcuking\\b",
				"\\bfeck\\b",
				"\\bfecker\\b",
				"\\bfelching\\b",
				"\\bfellate\\b",
				"\\bfellatio\\b",
				"\\bfingerfuck\\b",
				"\\bfingerfucked\\b",
				"\\bfingerfucker\\b",
				"\\bfingerfuckers\\b",
				"\\bfingerfucking\\b",
				"\\bfingerfucks\\b",
				"\\bfistfuck\\b",
				"\\bfistfucked\\b",
				"\\bfistfucker\\b",
				"\\bfistfuckers\\b",
				"\\bfistfucking\\b",
				"\\bfistfuckings\\b",
				"\\bfistfucks\\b",
				"\\bfucka\\b",
				"\\bfucked\\b",
				"\\bfucker\\b",
				"\\bfuckers\\b",
				"\\bfuckhead\\b",
				"\\bfuckheads\\b",
				"\\bfuckin\\b",
				"\\bfucking\\b",
				"\\bfuckings\\b",
				"\\bfuckingshitmotherfucker\\b",
				"\\bfuckme\\b",
				"\\bfucks\\b",
				"\\bfuckwhit\\b",
				"\\bfuckwit\\b",
				"\\bfudge packer\\b",
				"\\bfudgepacker\\b",
				"fuk",
				"\\bfuker\\b",
				"\\bfukker\\b",
				"\\bfukkin\\b",
				"\\bfuks\\b",
				"\\bfukwhit\\b",
				"\\bfukwit\\b",
				"\\bfux\\b",
				"\\bfux0r\\b",
				"\\bf_u_c_k\\b",
				"\\bgangbang\\b",
				"\\bgangbanged\\b",
				"\\bgangbangs\\b",
				"\\bgaylord\\b",
				"\\bgaysex\\b",
				"\\bgoatse\\b",
				"\\bgod-dam\\b",
				"\\bgod-damned\\b",
				"\\bgoddamn\\b",
				"\\bgoddamned\\b",
				"\\bhardcoresex\\b",
				"\\bheshe\\b",
				"\\bhomo\\b",
				"\\bhore\\b",
				"\\bhorniest\\b",
				"\\bhorny\\b",
				"\\bhotsex\\b",
				"\\bjack-off\\b",
				"\\bjackoff\\b",
				"\\bjap\\b",
				"\\bjerk-off\\b",
				"\\bjism\\b",
				"\\bjiz\\b",
				"\\bjizm\\b",
				"\\bjizz\\b",
				"\\bkawk\\b",
				"\\bknob\\b",
				"\\bknobead\\b",
				"\\bknobed\\b",
				"\\bknobend\\b",
				"\\bknobhead\\b",
				"\\bknobjocky\\b",
				"\\bknobjokey\\b",
				"\\bkock\\b",
				"\\bkondum\\b",
				"\\bkondums\\b",
				"\\bkum\\b",
				"\\bkummer\\b",
				"\\bkumming\\b",
				"\\bkums\\b",
				"\\bkunilingus\\b",
				"\\bl3i+ch\\b",
				"\\bl3itch\\b",
				"\\blabia\\b",
				"\\blust\\b",
				"\\blusting\\b",
				"\\bm0f0\\b",
				"\\bm0fo\\b",
				"\\bm45terbate\\b",
				"\\bma5terb8\\b",
				"\\bma5terbate\\b",
				"\\bmasochist\\b",
				"\\bmaster-bat\\b",
				"\\bmasterb8\\b",
				"\\bmasterbat\\b",
				"\\bmasterbat3\\b",
				"\\bmasterbate\\b",
				"\\bmasterbation\\b",
				"\\bmasterbations\\b",
				"\\bmasturbate\\b",
				"\\bmo-fo\\b",
				"\\bmof0\\b",
				"\\bmofo\\b",
				"\\bmothafuck\\b",
				"\\bmothafucka\\b",
				"\\bmothafuckas\\b",
				"\\bmothafuckaz\\b",
				"\\bmothafucked\\b",
				"\\bmothafucker\\b",
				"\\bmothafuckers\\b",
				"\\bmothafuckin\\b",
				"\\bmothafucking\\b",
				"\\bmothafuckings\\b",
				"\\bmothafucks\\b",
				"\\bmother fucker\\b",
				"\\bmotherfuck\\b",
				"\\bmotherfucked\\b",
				"\\bmotherfucker\\b",
				"\\bmotherfuckers\\b",
				"\\bmotherfuckin\\b",
				"\\bmotherfucking\\b",
				"\\bmotherfuckings\\b",
				"\\bmotherfuckka\\b",
				"\\bmotherfucks\\b",
				"\\bmutha\\b",
				"\\bmuthafecker\\b",
				"\\bmuthafuckker\\b",
				"\\bmuther\\b",
				"\\bmutherfucker\\b",
				"\\bn1gga\\b",
				"\\bn1gger\\b",
				"\\bnazi\\b",
				"\\bnigg3r\\b",
				"\\bnigg4h\\b",
				"\\bnigga\\b",
				"\\bniggah\\b",
				"\\bniggas\\b",
				"\\bniggaz\\b",
				"\\bnigger\\b",
				"\\bniggers\\b",
				"\\bnob jokey\\b",
				"\\bnobhead\\b",
				"\\bnobjocky\\b",
				"\\bnobjokey\\b",
				"\\borgasim\\b",
				"\\borgasims\\b",
				"\\borgasm\\b",
				"\\borgasms\\b",
				"\\bpenis\\b",
				"\\bpenisfucker\\b",
				"\\bphonesex\\b",
				"\\bphuck\\b",
				"\\bphuk\\b",
				"\\bphuked\\b",
				"\\bphuking\\b",
				"\\bphukked\\b",
				"\\bphukking\\b",
				"\\bphuks\\b",
				"\\bphuq\\b",
				"\\bpigfucker\\b",
				"\\bpimpis\\b",
				"\\bpiss\\b",
				"\\bpissed\\b",
				"\\bpisser\\b",
				"\\bpissers\\b",
				"\\bpisses\\b",
				"\\bpissflaps\\b",
				"\\bpissin\\b",
				"\\bpissing\\b",
				"\\bpissoff\\b",
				"\\bpoop\\b",
				"\\bporn\\b",
				"\\bporno\\b",
				"\\bpornography\\b",
				"\\bpornos\\b",
				"\\bprick\\b",
				"\\bpricks\\b",
				"\\bpron\\b",
				"\\bpube\\b",
				"\\bpusse\\b",
				"\\bpussi\\b",
				"\\bpussies\\b",
				"\\bpussy\\b",
				"\\bpussys\\b",
				"\\brectum\\b",
				"\\bretard\\b",
				"\\brimjaw\\b",
				"\\brimming\\b",
				"\\bsadist\\b",
				"\\bschlong\\b",
				"\\bscroat\\b",
				"\\bscrote\\b",
				"\\bscrotum\\b",
				"\\bsemen\\b",
				"\\bsh!+\\b",
				"\\bsh!t\\b",
				"\\bsh1t\\b",
				"\\bshagger\\b",
				"\\bshaggin\\b",
				"\\bshagging\\b",
				"\\bshemale\\b",
				"\\bshi+\\b",
				"\\bshitdick\\b",
				"\\bshite\\b",
				"\\bshited\\b",
				"\\bshitey\\b",
				"\\bshitfuck\\b",
				"\\bshitfull\\b",
				"\\bshithead\\b",
				"\\bshiting\\b",
				"\\bshitings\\b",
				"\\bshits\\b",
				"\\bshitted\\b",
				"\\bshitter\\b",
				"\\bshitters\\b",
				"\\bshitting\\b",
				"\\bshittings\\b",
				"\\bshitty\\b",
				"\\bskank\\b",
				"\\bslut\\b",
				"\\bsluts\\b",
				"\\bsmegma\\b",
				"\\bsmut\\b",
				"\\bsnatch\\b",
				"\\bson-of-a-bitch\\b",
				"\\bspunk\\b",
				"\\bt1tt1e5\\b",
				"\\bt1tties\\b",
				"\\btestical\\b",
				"\\btesticle\\b",
				"\\btit\\b",
				"\\btitfuck\\b",
				"\\btits\\b",
				"\\btitt\\b",
				"\\btittie5\\b",
				"\\btittiefucker\\b",
				"\\btitties\\b",
				"\\btittyfuck\\b",
				"\\btittywank\\b",
				"\\btitwank\\b",
				"\\bturd\\b",
				"\\btw4t\\b",
				"\\btwat\\b",
				"\\btwathead\\b",
				"\\btwatty\\b",
				"\\btwunt\\b",
				"\\btwunter\\b",
				"\\bv14gra\\b",
				"\\bv1gra\\b",
				"\\bvagina\\b",
				"\\bviagra\\b",
				"\\bvulva\\b",
				"\\bw00se,\\b",
				"\\bwhoar\\b",
				"\\bwhore\\b",
				"\\bxrated\\b"
			],
			fr_fr: [
				"\\banal(?=\\s|$)",
				"\\bandouille(?=\\s|$)",
				"\\banus(?=\\s|$)",
				"\\bappareilleuse(?=\\s|$)",
				"\\bastèque(?=\\s|$)",
				"\\bavorton(?=\\s|$)",
				"\\bbaise(?=\\s|$)",
				"\\bbâtard(?=\\s|$)",
				"\\bbic(?=\\s|$)",
				"\\bbicot(?=\\s|$)",
				"\\bbite(?=\\s|$)",
				"\\bbordel(?=\\s|$)",
				"\\bbordille(?=\\s|$)",
				"\\bboudin(?=\\s|$)",
				"\\bbouffon(?=\\s|$)",
				"\\bbougnoul(?=\\s|$)",
				"\\bbougnoulisation(?=\\s|$)",
				"\\bbougnouliser(?=\\s|$)",
				"\\bbougre(?=\\s|$)",
				"\\bboukak(?=\\s|$)",
				"\\bboulet(?=\\s|$)",
				"\\bbounioul(?=\\s|$)",
				"\\bbranlette(?=\\s|$)",
				"\\bbranleur(?=\\s|$)",
				"\\bbrigand(?=\\s|$)",
				"\\bburne(?=\\s|$)",
				"\\bchaudasse(?=\\s|$)",
				"\\bchiennasse(?=\\s|$)",
				"\\bchienne(?=\\s|$)",
				"\\bchier(?=\\s|$)",
				"\\bchinetoque(?=\\s|$)",
				"\\bchintok(?=\\s|$)",
				"\\bchleuh(?=\\s|$)",
				"\\bchnoque(?=\\s|$)",
				"\\bcoche(?=\\s|$)",
				"\\bcolon(?=\\s|$)",
				"\\bcon(?=\\s|$)",
				"\\bconchier(?=\\s|$)",
				"\\bconnard(?=\\s|$)",
				"\\bconnasse(?=\\s|$)",
				"\\bcouille(?=\\s|$)",
				"\\bcounifle(?=\\s|$)",
				"\\bcourtaud(?=\\s|$)",
				"\\bcrétin(?=\\s|$)",
				"\\bcrevure(?=\\s|$)",
				"\\bcul(?=\\s|$)",
				"\\bdébile(?=\\s|$)",
				"\\bdoryphore(?=\\s|$)",
				"\\bdu schnoc(?=\\s|$)",
				"\\bducon(?=\\s|$)",
				"\\bduconnot(?=\\s|$)",
				"\\bdugenoux(?=\\s|$)",
				"\\bdugland(?=\\s|$)",
				"\\bduschnock(?=\\s|$)",
				"\\benculé(?=\\s|$)",
				"\\benculer(?=\\s|$)",
				"\\benflure(?=\\s|$)",
				"\\benfoiré(?=\\s|$)",
				"\\bentuber(?=\\s|$)",
				"\\benvaselineur(?=\\s|$)",
				"\\bépais(?=\\s|$)",
				"\\bespingoin(?=\\s|$)",
				"\\bétron(?=\\s|$)",
				"\\bfion(?=\\s|$)",
				"\\bfouteur(?=\\s|$)",
				"\\bfoutre(?=\\s|$)",
				"\\bfripouille(?=\\s|$)",
				"\\bfrisé(?=\\s|$)",
				"\\bfritz(?=\\s|$)",
				"\\bfumier(?=\\s|$)",
				"\\bgland(?=\\s|$)",
				"\\bglandeur(?=\\s|$)",
				"\\bglandu(?=\\s|$)",
				"\\bgnoul(?=\\s|$)",
				"\\bgodon(?=\\s|$)",
				"\\bgogol(?=\\s|$)",
				"\\bgouilland(?=\\s|$)",
				"\\bgouine(?=\\s|$)",
				"\\bgourde(?=\\s|$)",
				"\\bgrognasse(?=\\s|$)",
				"\\bguide de merde(?=\\s|$)",
				"\\bguignol(?=\\s|$)",
				"\\bmakoumé(?=\\s|$)",
				"\\bmanche(?=\\s|$)",
				"\\bmange-merde(?=\\s|$)",
				"\\bmangeux de marde(?=\\s|$)",
				"\\bmauviette(?=\\s|$)",
				"\\bmerde(?=\\s|$)",
				"\\bmerdeux(?=\\s|$)",
				"\\bmerdouillard(?=\\s|$)",
				"\\bmongol(?=\\s|$)",
				"\\bmoule(?=\\s|$)",
				"\\bnègre(?=\\s|$)",
				"\\bnibard(?=\\s|$)",
				"\\bnibards(?=\\s|$)",
				"\\bnichons(?=\\s|$)",
				"\\bniquer(?=\\s|$)",
				"\\bpédale(?=\\s|$)",
				"\\bpédé(?=\\s|$)",
				"\\bpédophile(?=\\s|$)",
				"\\bpénis(?=\\s|$)",
				"\\bpétasse(?=\\s|$)",
				"\\bpignouf(?=\\s|$)",
				"\\bpleutre(?=\\s|$)",
				"\\bplouc(?=\\s|$)",
				"\\bporc(?=\\s|$)",
				"\\bporcas(?=\\s|$)",
				"\\bporcasse(?=\\s|$)",
				"\\bpouf(?=\\s|$)",
				"\\bpouffiasse(?=\\s|$)",
				"\\bputain(?=\\s|$)",
				"\\bpute(?=\\s|$)",
				"\\bsalaud(?=\\s|$)",
				"\\bsalop(?=\\s|$)",
				"\\bsalope(?=\\s|$)",
				"\\bsuceuse(?=\\s|$)",
				"\\btafiole(?=\\s|$)",
				"\\btantouze(?=\\s|$)",
				"\\btapette(?=\\s|$)",
				"\\btarlouze(?=\\s|$)",
				"\\btête de noeud(?=\\s|$)",
				"\\bteub(?=\\s|$)",
				"\\btrouduc(?=\\s|$)",
				"\\bvagin(?=\\s|$)",
				"\\bviol(?=\\s|$)",
				"\\bvioleur(?=\\s|$)",
				"\\byoupin(?=\\s|$)",
				"\\bzboub(?=\\s|$)",
				"\\bzguègue(?=\\s|$)"
			],
			de_de: [
				"\\bfick\\b",
				"\\bfickdes\\b",
				"\\bficke\\b",
				"\\bficken\\b",
				"\\bfickend\\b",
				"\\bfickende\\b",
				"\\bfickendem\\b",
				"\\bfickenden\\b",
				"\\bfickender\\b",
				"\\bfickendes\\b",
				"\\bficker\\b",
				"\\bfickest\\b",
				"\\bficket\\b",
				"\\bfickmuede\\b",
				"\\bfickmuff\\b",
				"\\bfickspan\\b",
				"\\bfickst\\b",
				"\\bfickt\\b",
				"\\bfickte\\b",
				"\\bfickten\\b",
				"\\bficktest\\b",
				"\\bficktet\\b",
				"\\bfickzitze\\b",
				"\\bgefickt\\b",
				"\\bgefickte\\b",
				"\\bgeficktem\\b",
				"\\bgefickten\\b",
				"\\bgefickter\\b",
				"\\bgeficktes\\b",
				"\\bhasenfick\\b",
				"\\bwandfick\\b",
				"\\bwandficken\\b",
				"\\bbescheisse\\b",
				"\\bscheiss\\b",
				"\\bscheissen\\b",
				"\\bscheissend\\b",
				"\\bscheisser\\b",
				"\\bscheissers\\b",
				"\\bscheissest\\b",
				"\\bscheisst\\b",
				"\\bnigger\\b",
				"\\bschlampe\\b",
				"\\bfotzen\\b",
				"\\bpimmel\\b",
				"\\bpimmeln\\b",
				"\\bpimmels\\b",
				"\\banpisse\\b",
				"\\banpissen\\b",
				"\\banpisst\\b",
				"\\banpisste\\b",
				"\\banpissten\\b",
				"\\bbepissen\\b",
				"\\bbepisst\\b",
				"\\bbepisste\\b",
				"\\bbettpiss\\b",
				"\\bbettpisse\\b",
				"\\bgepisst\\b",
				"\\bgepisste\\b",
				"\\bgepisstem\\b",
				"\\bhinpisser\\b",
				"\\bpiss\\b",
				"\\bpisse\\b",
				"\\bpissecke\\b",
				"\\bpissen\\b",
				"\\bpissend\\b",
				"\\bpissende\\b",
				"\\bpissendem\\b",
				"\\bpissenden\\b",
				"\\bpissender\\b",
				"\\bpissendes\\b",
				"\\bpissens\\b",
				"\\bpisser\\b",
				"\\bpissern\\b",
				"\\bpissers\\b",
				"\\bpissest\\b",
				"\\bpisset\\b",
				"\\bpissnelke\\b",
				"\\bpisst\\b",
				"\\bpisste\\b",
				"\\bpissten\\b",
				"\\bpisstest\\b",
				"\\bpisstet\\b",
				"\\bverpiss\\b",
				"\\bverpissen\\b",
				"\\bverpisst\\b",
				"\\bverpisste\\b",
				"\\babwichst\\b",
				"\\bgewichst\\b",
				"\\bgewichste\\b",
				"\\bgewichstem\\b",
				"\\bgewichsten\\b",
				"\\bgewichster\\b",
				"\\bgewichstes\\b",
				"\\bwichs\\b",
				"\\bwichse\\b",
				"\\bwichsen\\b",
				"\\bwichsend\\b",
				"\\bwichsende\\b",
				"\\bwichsendem\\b",
				"\\bwichsenden\\b",
				"\\bwichsender\\b",
				"\\bwichsendes\\b",
				"\\bwichser\\b",
				"\\bwichst\\b",
				"\\bwichste\\b",
				"\\bwichsten\\b",
				"\\bhuren\\b",
				"\\bhurend\\b",
				"\\bhurende\\b",
				"\\bhurendem\\b",
				"\\bhurenden\\b",
				"\\bhurender\\b",
				"\\bhurendes\\b",
				"\\bhurenkind\\b",
				"\\bhurenkinds\\b",
				"\\bhurensohn\\b",
				"\\bbimbo\\b",
				"\\bkanak\\b",
				"\\bkanake\\b",
				"\\bkanaken\\b",
				"\\babschaum\\b",
				"\\babschaumes\\b",
				"\\babschaums\\b",
				"\\bschissen\\b",
				"\\bschisser\\b",
				"\\bschissers\\b",
				"\\bschisses\\b",
				"\\bschissest\\b",
				"\\bschisset\\b",
				"\\bschisst\\b",
				"\\barsch\\b",
				"\\barsches\\b",
				"\\barschgeige\\b",
				"\\barschlochs\\b",
				"\\bfettarsch\\b",
				"\\bnacktarsch\\b",
				"\\boberarsch\\b",
				"\\bverarsche\\b",
				"\\bverarschen\\b",
				"\\bverarschst\\b",
				"\\bverarscht\\b",
				"\\bverarschte\\b",
				"\\bbums\\b",
				"\\bbumse\\b",
				"\\bbumsen\\b",
				"\\bbumsend\\b",
				"\\bbumsende\\b",
				"\\bbumsendem\\b",
				"\\bbumsenden\\b",
				"\\bbumsender\\b",
				"\\bbumsendes\\b",
				"\\bbumsens\\b",
				"\\bbumser\\b",
				"\\bbumsern\\b",
				"\\bbumsers\\b",
				"\\bbumses\\b",
				"\\bbumsest\\b",
				"\\bbumst\\b",
				"\\bbumste\\b",
				"\\bbumsten\\b",
				"\\bbumstest\\b",
				"\\bbumstet\\b",
				"\\bgebumst\\b",
				"\\bgebumste\\b",
				"\\bgebumstem\\b",
				"\\bgebumsten\\b",
				"\\bgebumster\\b",
				"\\bgebumstes\\b",
				"\\btucke\\b",
				"\\bhomo\\b",
				"\\bhomos\\b",
				"\\bblasen\\b",
				"\\bblasend\\b",
				"\\bblasende\\b",
				"\\bblasendem\\b",
				"\\bblasenden\\b",
				"\\bblasender\\b",
				"\\bblasendes\\b",
				"\\bgeblasen\\b",
				"\\bgepoppt\\b",
				"\\bpoppen\\b",
				"\\bpoppt\\b",
				"\\bhure\\b",
				"\\bkinderficker\\b",
				"\\bnazischlampe\\b",
				"\\bnutte\\b",
				"\\bschwanzlutscher\\b",
				"\\bwixer\\b",
				"\\bwixxer\\b",
				"\\bejakulieren\\b",
				"\\bgeil\\b",
				"\\bmasturbieren\\b",
				"\\bmöse\\b",
				"\\bonanieren\\b",
				"\\borgasmus\\b",
				"\\bporno\\b",
				"\\bpornografie\\b",
				"\\bsado-maso\\b",
				"\\bscheiße\\b",
				"\\bscheisse\\b",
				"\\bschwuchtel\\b",
				"\\btitte\\b",
				"\\btitten\\b"
			]
		}
	}

});

KXL.module('Components.BootstrapCarousel.Views', function (Views, KXL, Backbone, Marionette, $, _) {
	"use strict";

	var indicator = Marionette.ItemView.extend({
		template: function () {
			return '';
		},
		tagName: 'li',
		onRender: function () {
			this.$el.attr('data-target', this.options.carouselSelector);
			this.$el.attr('data-slide-to', this.options.slideNumber);
			if (this.options.slideNumber === 0) {
				this.$el.addClass('active');
			}
		}
	});
	Views.Indicators = Marionette.CollectionView.extend({
		itemView: indicator,
		tagName: 'ol',
		className: 'carousel-indicators',
		itemViewOptions: function (model, index) {
			return {
				slideNumber: index,
				carouselSelector: this.options.carouselSelector
			};
		}
	});
	var item = Marionette.ItemView.extend({
		template: '#bootstrap-carousel-item-template',
		className: function () {
			if (this.options && this.options.slideNumber === 0) {
				return 'item active';
			}
			return 'item';
		},
		triggers: {
			'click .kx-click-box': 'clicked'
		}
	});
	Views.Carousel = Marionette.CollectionView.extend({
		itemView: item,
		itemViewEventPrefix: 'carousel:slide',
		className: 'carousel-inner',
		itemViewOptions: function (model, index) {
			return {
				slideNumber: index
			};
		}
	});

	Views.CarouselLayout = Marionette.Layout.extend({
		template: '#bootstrap-carousel-template',
		className: 'carousel slide carousel-fade',
		regions: {
			indicatorsRegion: '.kx-indicators-region',
			imagesRegion: '.kx-slides-region'
		}
	});
});
KXL.module('Errors', function(Errors, KXL, Backbone, Marionette, $, _) {
	Errors.Router = Marionette.AppRouter.extend({
		appRoutes: {
			'*notFound': 'notFound',
			'500': 'internalError'
		}
	});
	KXL.addInitializer(function () {
		new Errors.Router({
			controller: API
		});
	});
	KXL.commands.setHandlers({
		'404:show': function () {
			KXL.navigate('/404');
			API.notFound();
		},
		'500:show': function () {
			KXL.navigate('/500');
			API.internalError();
		},
		'error:show': function (errorView) {
			API.showError(errorView);
		}
	});
	var API = {
		notFound: function () {
			this.showError(KXL.request('new:error:view', { error: 404 }));
		},
		internalError: function () {
			this.showError(KXL.request('new:error:view', { error: 500 }));
		},
		showError: function (errorView) {
			KXL.execute('page:show', 'error');
		    KXL.errorRegion.show(errorView);
		}
	};

	Errors.BaseError = function BaseError() {};
	Errors.BaseError.prototype = new Error();
	Errors.BaseError.prototype.toJSON = function () {
		var json = {
			'error': this.error,
			'reason': this.message
		};

		if (this.detail) {
			json.detail = this.detail;
		}
		return JSON.stringify(json);
	};

	// UsernameConflict
	Errors.UsernameConflictError = function UsernameConflictError(message, detail) {
		this.error = 'UsernameConflict';
		this.message = message || KXL.i18n.t('errors.UsernameConflict');
		this.detail = detail;
	}
	Errors.UsernameConflictError.prototype = new Errors.BaseError();
	Errors.UsernameConflictError.prototype.constructor = Errors.UsernameConflictError;

	// Registration Authorization Failure
	Errors.RegistrationAuthorizationError = function RegistrationAuthorizationFailure(message, detail) {
		this.error = 'RegistrationAuthorizationFailure';
		this.message = message || KXL.i18n.t('errors.RegistrationAuthorizationError');
		this.detail = detail;
	}

	// InvalidCredentials
	Errors.InvalidCredentialsError = function InvalidCredentialsError(message, detail) {
		this.error = 'InvalidCredentials';
		this.message = message || KXL.i18n.t('errors.InvalidCredentials');
		this.detail = detail;
	}
	Errors.InvalidCredentialsError.prototype = new Errors.BaseError();
	Errors.InvalidCredentialsError.prototype.constructor = Errors.InvalidCredentialsError;

	Errors.TooManyLoginAttemptsError = function RateLimitError(message, detail) {
		this.error = 'RateLimit';
		this.message = message || KXL.i18n.t('errors.TooManyLoginAttemptsError');
		this.detail = detail;
	}
	Errors.TooManyLoginAttemptsError.prototype = new Errors.BaseError();
	Errors.TooManyLoginAttemptsError.prototype.constructor = Errors.TooManyLoginAttemptsError;

	// GenericLoginError
	Errors.GenericLoginError = function GenericLoginError(message, detail) {
		this.error = 'GenericLoginError';
		this.message = message || KXL.i18n.t('errors.GenericLoginError');
		this.detail = detail;
	}
	Errors.GenericLoginError.prototype = new Errors.BaseError();
	Errors.GenericLoginError.prototype.constructor = Errors.GenericLoginError;

	// iOvation Blocked error
	Errors.IOvationBlockedError = function IOvationBlockedError(message, detail) {
		this.error = 'IOvationBlockedError';
		this.message = message || KXL.i18n.t('errors.IOvationBlockedError');
		this.detail = detail;
	}
	Errors.IOvationBlockedError.prototype = new Errors.BaseError();
	Errors.IOvationBlockedError.prototype.constructor = Errors.IOvationBlockedError;

	// Missing deviceFingerprint error
	Errors.DeviceFingerprintError = function DeviceFingerPrintError(message, detail) {
		this.error = 'DeviceFingerprintError';
		this.message = message || KXL.i18n.t('errors.DeviceFingerprintError');
		this.detail = detail;
	}

	Errors.DeviceFingerprintError.prototype = new Errors.BaseError();
	Errors.DeviceFingerprintError.prototype.constructor = Errors.DeviceFingerprintError;


	// Unexpected
	Errors.UnexpectedError = function UnexpectedError(message, detail) {
		this.error = 'Unexpected';
		this.message = message || KXL.i18n.t('errors.Unexpected');
		this.detail = detail;
	}
	Errors.UnexpectedError.prototype = new Errors.BaseError();
	Errors.UnexpectedError.prototype.constructor = Errors.UnexpectedError;

	// UnexpectedFacebookLoginError
	Errors.UnexpectedFacebookLoginError = function UnexpectedFacebookLoginError(message, detail) {
		this.error = 'UnexpectedFacebookLoginError';
		this.message = message || KXL.i18n.t('errors.UnexpectedFacebookLoginError');
		this.detail = detail;
	}
	Errors.UnexpectedFacebookLoginError.prototype = new Errors.BaseError();
	Errors.UnexpectedFacebookLoginError.prototype.constructor = Errors.UnexpectedFacebookLoginError;

	// UnexpectedGoogleLoginError
	Errors.UnexpectedGoogleLoginError = function UnexpectedGoogleLoginError(message, detail) {
		this.error = 'UnexpectedGoogleLoginError';
		this.message = message || KXL.i18n.t('errors.UnexpectedGoogleLoginError');
		this.detail = detail;
	}
	Errors.UnexpectedGoogleLoginError.prototype = new Errors.BaseError();
	Errors.UnexpectedGoogleLoginError.prototype.constructor = Errors.UnexpectedGoogleLoginError;

	// FacebookMergeConflictError
	Errors.FacebookMergeConflictError = function FacebookMergeConflictError(message, detail) {
		this.error = 'FacebookMergeConflictError';
		this.message = message || KXL.i18n.t('errors.FacebookMergeConflictError');
		this.detail = detail;
	}
	Errors.FacebookMergeConflictError.prototype = new Errors.BaseError();
	Errors.FacebookMergeConflictError.prototype.constructor = Errors.FacebookMergeConflictError;

	// EmailConflictError
	Errors.EmailConflictError = function EmailConflictError(message, detail) {
		this.error = 'EmailConflictError';
		this.message = message || KXL.i18n.t('errors.EmailConflictError');
		this.detail = detail;
	}
	Errors.EmailConflictError.prototype = new Errors.BaseError();
	Errors.EmailConflictError.prototype.constructor = Errors.EmailConflictError;

	// FacebookAPILoginError
	Errors.FacebookAPILoginError = function FacebookAPILoginError(message, detail) {
		this.error = 'FacebookAPILoginError';
		this.message = message || KXL.i18n.t('errors.FacebookAPILoginError');
		this.detail = detail;
	}
	Errors.FacebookAPILoginError.prototype = new Errors.BaseError();
	Errors.FacebookAPILoginError.prototype.constructor = Errors.FacebookAPILoginError;

	// InvalidEmailError
	Errors.InvalidEmailError = function InvalidEmailError(message, detail) {
		this.error = 'InvalidEmailError';
		this.message = message || KXL.i18n.t('errors.InvalidEmailError');
		this.detail = detail;
	}
	Errors.InvalidEmailError.prototype = new Errors.BaseError();
	Errors.InvalidEmailError.prototype.constructor = Errors.InvalidEmailError;

	// EmailInUseError
	Errors.EmailInUseError = function EmailInUseError(message, detail) {
		this.error = 'EmailInUseError';
		this.message = message || KXL.i18n.t('errors.EmailInUseError');
		this.detail = detail;
	}
	Errors.EmailInUseError.prototype = new Errors.BaseError();
	Errors.EmailInUseError.prototype.constructor = Errors.EmailInUseError;

	Errors.ValidationError = function ValidationError(message, detail) {
		this.error = 'ValidationError';
		this.message = message;
		this.detail = detail;
	};

	Errors.RequestError = function RequestError(message, detail) {
		this.error = 'RequestError';
		this.message = message;
		this.detail = detail;
	};

	Errors.SystemError = function SystemError(message, detail) {
		this.error = 'SystemError';
		this.message = message;
		this.detail = detail;
		this.toString = function () {
			return this.message + ': ' + this.detail;
		};
	};
});

KXL.module('Entities', function (Entities, KXL, Backbone, Marionette, $, _) {
	/**
	 * Used to mark a survey as taken for the current user
	 */
	var UserSurvey = Backbone.Model.extend({
		url: function () {
			return KXL.config.API_URL + '/users/' + KXL.request('get:current:user').id + '/surveys';
		}
	});

	/**
	 * Used for retrieving the surveys relevant for the game/locale
	 */
	var Surveys = Backbone.Collection.extend({
		url: function () {
			return KXL.config.API_URL + '/surveys';
		}
	});

	KXL.reqres.setHandlers({
		'get:surveys:entity': function () {
			return new Surveys();
		},
		'get:usersurvey:entity': function () {
			return new UserSurvey();
		}
	});
});
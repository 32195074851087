/* globals KXL */
KXL.module('Chat.ChatApp', function(ChatApp, KXL, Backbone, Marionette, $, _) {
	var chatSound;
	var Controller = Marionette.Controller.extend({
		layout: null,
		currentSelectedThread: null,
		sidebarBreadcrumb: false,
		sidebarViews: {
			topBarView: null,
			contentView: null,
			headerCTAView: null,
			headerCTABreadcrumbView: null
		},

		setNextThread: function (threadId) {
			var nextThread = KXL.request('get:chat:threads').get(threadId);
			if (nextThread) {
				this._selectThread(nextThread);
			} else {
				var currentUserSettings = KXL.request('current:user:settings:entity');
				currentUserSettings.set('lastChatId', threadId);
				currentUserSettings.save();
			}
		},

		_selectThread: function (thread, force) {
			if (this.currentSelectedThread === thread ||
				(this.creatingConversation && !force))
			{
				return;
			}
			if (this.creatingConversation && force) {
				this.creatingConversation = false;
			}
			var
				currentUserSettings = KXL.request('current:user:settings:entity'),
				lastChatId = currentUserSettings && currentUserSettings.get('lastChatId')
			;

			if (thread.id !== lastChatId) {
				currentUserSettings.set('lastChatId', thread.id);
				currentUserSettings.save();
			}

			thread.select();
		},
		startPrivateChat: function (userId, message) {
			var self = this;
			/**
			 * Bypassing old Start Chat right now to get Chat CTA's working.
			 */
			if (!Array.isArray(userId)) {
				var recips = [];
				recips.push(userId);
				this.startConversation(recips, true);
				return;
			}
			this.toggleChat(true);
			var chatInterface = KXL.request('get:chat:interface');
			var createThread = function () {
				chatInterface.createChat([ userId ]).then(function (newThread) {
					self.setNextThread(newThread.id);
					if (message) {
						chatInterface.sendMessageToRoom(newThread.id, message);
					}
				});
			};
			if (chatInterface && chatInterface.isConnected()) {
				createThread();
			} else {
				var onConnectionChanged = function (status) {
					if (status === 'ONLINE') {
						KXL.stopListening(chatInterface, 'connection', onConnectionChanged);
						createThread();
					}
				};
				self.listenTo(chatInterface, 'connection', onConnectionChanged);
			}
		},
		startConversation: function (recipients, isPrivate) {
			var self = this;
			isPrivate = isPrivate || false;
			self.creatingConversation = true;
			if (self.currentSelectedThread) {
				self.currentSelectedThread.deselect();
			}
			self.currentSelectedThread = null;
			self.sidebarViews.contentView.conversation.ensureEl();
			self.sidebarViews.contentView.conversation.reset();
			var chatConversationView = new ChatApp.Views.Conversation({
				// create an empty thread to give to the view
				model: new ChatApp.Entities.Thread(),
				recipients: recipients,
				isPrivate: isPrivate
			});

			self.listenTo(chatConversationView, 'start:thread', function (message, recipients) {
				self.sidebarViews.contentView.conversation.show(new ChatApp.Views.ConversationInit());
				KXL.request('get:chat:interface').createChat(recipients.pluck('id')).then(function (threadData) {
					/**
					 * This is because the creation of a room/thread is async and we have to wait for
					 * ejabberd to send the thread down to us before we can select it and send messages to it
					 */
					var
						newThread,
						threadCheckCount = 0,
						deferred = $.Deferred()
					;
					function getNewThread() {

						// If it has been 10 seconds... something is wrong :(
						if (threadCheckCount > 100) {
							deferred.reject();
						}

						newThread = KXL.request('get:chat:threads').get(threadData.id);

						if (!newThread) {
							threadCheckCount++;
							_.delay(getNewThread, 100);
						} else {
							KXL.request('get:chat:interface').sendMessageToRoom(threadData.id, message);
							self.creatingConversation = false;
							self._selectThread(newThread);
							deferred.resolve(newThread);
						}
					}
					getNewThread();
					return deferred;
				}).fail(function () {
					self.sidebarViews.contentView.conversation.show(new ChatApp.Views.ConversationInitFailed());
				});
			});
			self.sidebarViews.contentView.conversation.show(chatConversationView);
		},
		showChat: function () {
			var chatVent = new Backbone.Wreqr.EventAggregator();
			KXL.vent.trigger('chat:settings:hide');
			if (!this.sidebarViews.contentView) {
				var self = this;
				var defaultThreads = KXL.request('get:chat:default:threads');
				var userThreads = KXL.request('get:chat:threads');

				/**
				 * Comparator for default threads so groups show up first
				 * @param a Thread Model
				 * @param b Thread Model
				 * @returns {number}
				 */
				defaultThreads.comparator = function(a, b) {
					var retVal = 1;
					if (a.has('groupType')) {
						if (!b.has('groupType')) {
							retVal = -1;
						} else {
							retVal = 0;
						}
					}
					return retVal;
				};

				/**
				 * Sorts user threads by last message, so newest to the top.
				 * -1: move A to front
				 *  1: move A behind
				 *  0: A & B are same
				 * @param a	User Thread Model (private messages)
				 * @param b User Thread Model (private messages)
				 * @returns {number}
				 */
				userThreads.comparator = function(a, b) {
					var retVal = 0;
					if (a.timestamp > b.timestamp) {
						retVal = -1;
					} else if (a.timestamp < b.timestamp) {
						retVal = 1;
					}
					return retVal;
				};

				self.listenTo(defaultThreads, 'add remove', function(thread) {
					defaultThreads.sort();
				});
				self.listenTo(userThreads, 'add remove resort', function(thread) {
					userThreads.sort();
				});

				this.sidebarViews.contentView = new ChatApp.View({
					vent: chatVent
				});

				// Handle connection status changes, from a UI perspective...
				var connectionStatusHistory = [];
				function handleChatConnectionState(status) {
					if (status) {
						connectionStatusHistory.push(status);
					}
					if (status === 'ONLINE') {
						self.sidebarViews.contentView.ui.loading.fadeOut(1000);
						self.sidebarViews.contentView.ui.offline.fadeOut(1000);
					} else if (status === 'CONNECTING') {
						self.sidebarViews.contentView.ui.loading.fadeIn(1000);
						if (self.sidebarViews.contentView.ui.offline.is(':visible')) {
							self.sidebarViews.contentView.ui.offline.fadeOut(1000);
						}
					} else if (status === 'DISCONNECTED') {
						// Check the previous status
						// If the previous is DISCONNECTING, then the user intentionally disconnected
						if (connectionStatusHistory[connectionStatusHistory.length - 2] === 'DISCONNECTING') {
							self.sidebarViews.contentView.ui.loading.fadeOut(1000);
							self.sidebarViews.contentView.ui.offline.fadeIn(1000);
						} else {
							// lost connection
						}
					} else if ((!status && connectionStatusHistory.length > 0) || status === 'OFFLINE') {
						self.sidebarViews.contentView.ui.loading.fadeOut(1000);
						self.sidebarViews.contentView.ui.offline.fadeIn(1000);
					} else if (!self.sidebarViews.contentView.ui.loading.is(':visible')) {
						self.sidebarViews.contentView.ui.loading.fadeIn(1000);
					}
				}

				self.listenTo(self.sidebarViews.contentView, 'show', function () {
					handleChatConnectionState(KXL.request('get:chat:status'));
				});
				self.listenTo(KXL.request('get:chat:interface'), 'connection', handleChatConnectionState);

				self.sidebarViews.topBarView = new ChatApp.TopPanelView();
				self.sidebarViews.headerCTAView = ChatApp.TopPanelButtonsView;
				self.sidebarViews.headerCTABreadcrumbView = ChatApp.TopPanelBreadcrumbView;
				self.sidebarViews.footerCTAView = ChatApp.RecipientFooterCTAView;

				var chatDefaultThreadsView = new ChatApp.ThreadsView({ collection: defaultThreads }),
					chatThreadsView = new ChatApp.ThreadsView({ collection: userThreads });

				chatDefaultThreadsView.on('chat:thread:click', function (args) {
					_.each(chatDefaultThreadsView.collection.models, function (thread) {
						thread.deselect();
					});

					self._selectThread(args.model, true);
				});

				function autoPickNextThread() {
					if (defaultThreads.length) {
						self._selectThread(defaultThreads.first());
					} else if (userThreads.length) {
						self._selectThread(userThreads.first());
					} else {
						// Nothing to select so null out the lastChatId.
						var currentUserSettings = KXL.request('current:user:settings:entity');
						currentUserSettings.set('lastChatId', '');
						currentUserSettings.save();
					}
				}

				function checkForCurrentThreadRemoved (removedThread) {
					var currentUserSettings = KXL.request('current:user:settings:entity');
					if (currentUserSettings) {
						var lastThreadId = currentUserSettings.get('lastChatId');
						if (removedThread.id === lastThreadId) {
							KXL.Chat.ChatApp.trigger('removed:from:room');
						}
					}
				}

				self.listenTo(defaultThreads, 'remove', checkForCurrentThreadRemoved);
				self.listenTo(userThreads, 'remove', checkForCurrentThreadRemoved);

				self.listenTo(ChatApp, 'chat:start:thread', function () {
					self.startConversation();
				});
				self.listenTo(self.sidebarViews.contentView, 'chat:go:online', function () {
					var settings = KXL.request('current:user:settings:entity');
					settings.set('showOnlineStatusAs', 'online');
					settings.save();
				});

				/**
				 * Handler for events fired from the headerCTAView (including breadcrumb).
				 * If the event is 'back:clicked', we deselect both collections and pass the
				 * deselected thread to the toggleConversationRegion.
				 * If the event is 'removed:from:room', that means current user was removed from room
				 * by someone else.
				 *
				 * @param  {object}  view  the backbone view from where the event originated.
				 */
				self.listenTo(ChatApp, 'back:clicked sub:back:clicked leave:room:clicked removed:from:room', function(view) {
					returnToThreadList();
				});

				/**
				 * This listener checks for a show event on a conversation region.
				 * If currentSelected thread is not set, then we we know this ia new conversation view.
				 * So we toggle the region display appropriately with toggleConversationRegion
				 *
				 * @param  none
				 */
				self.listenTo(self.sidebarViews.contentView.conversation, 'show', function() {
					if (!self.currentSelectedThread) {
						toggleConversationRegion(null, true);
					}
				});

				KXL.atom.once('sidebar', function () {
					KXL.request(
						'sidebar:create:content',
						{
							contentId: 'chat',
							contentView: self.sidebarViews.contentView,
							topBarView: self.sidebarViews.topBarView,
							topBarBreadCrumb: self.sidebarBreadcrumb,
							headerCTAViewPointer: self.sidebarViews.headerCTAView,
							headerCTABreadcrumbViewPointer: self.sidebarViews.headerCTABreadcrumbView,
							footerCTAViewPointer: self.sidebarViews.footerCTAView,
							modulePointer: ChatApp,
							title: KXL.i18n.t('chat.title'),
							show: true
						}
					);
				});

				function showConversation(thread) {
					self.sidebarViews.contentView.conversation.ensureEl();
					self.sidebarViews.contentView.conversation.reset();
					var chatConversationView = new ChatApp.Views.Conversation({ model: thread });
					var chatInterface = KXL.request('get:chat:interface');
					chatConversationView.listenTo(
						chatInterface,
						'connected',
						function () {
							var room = chatInterface.getRoom(thread.id);
							var newThread;
							if (room) {
								newThread = KXL.request('get:thread:for:chat:room', room);
							}
							if (newThread) {
								selectConversation(newThread);
							} else {
								returnToThreadList();
							}
						}
					);
					self.listenTo(chatConversationView, 'send:message', function (message) {
						KXL.request('get:chat:interface').sendMessageToRoom(thread.id, message);
					});

					self.listenTo(chatConversationView, 'recipient:add', function (user) {
						KXL.request('get:chat:interface').addUsersToRoom(thread.id, [user.id]);
					});

					self.listenTo(chatConversationView, 'recipient:remove', function (user) {
						KXL.request('get:chat:interface').removeUserFromRoom(thread.id, user.id);
					});

					self.listenTo(chatConversationView, 'leave:room', function (thread) {
						chatThreadRemoveClick(thread);
					});


					self.sidebarViews.contentView.conversation.show(chatConversationView);
				}

				/**
				 * Toggles the conversation region based on three criteria:
				 * - If its a new Conversation and no current thread exists:
				 * 	Show the breadcrumb header with the default sidebar title,
				 * 	Hide the threadlist view,
				 * 	and Show the Conversation view.
				 * - If the current thread passed in is NOT selected:
				 * 	Hide the breadcrumb,
				 * 	Hide the conversation
				 * 	and show the threadlist.
				 * - If the current thread passed IS selected:
				 * 	Show the breadcrumb use currentThread for a possible custom title,
				 * 	Show the conversation view,
				 * 	and hide the threadlist.
				 * @param  {object}  currThread  the current thread object used in a thread select
				 * @param  {boolean}  newConvo  a flag that determins if this a new conversation (not thread)
				 */
				function toggleConversationRegion(currThread, newConvo) {
					if (newConvo && !currThread) {
						// if no currThread is passed, its a new convo, show breadcrumb
						self.sidebarViews.contentView.ui.conversation.addClass('visible');
						ChatApp.trigger('breadcrumb:show',
							{
								defaultTitle: KXL.i18n.t('chat.title')
							}
						);

						self.sidebarViews.contentView.ui.threadsContainer.removeClass('visible');
					} else if (!currThread || currThread.selected === false) {
						// if the thread is NOT selected, show the threadList.
						self.sidebarViews.contentView.ui.conversation.removeClass('visible');
						ChatApp.trigger('breadcrumb:hide',
							{
								thread: currThread,
								defaultTitle: KXL.i18n.t('chat.title')
							}
						);

						self.sidebarViews.contentView.ui.threadsContainer.addClass('visible');
					} else if (currThread.selected === true) {
						// Thread exists, we're loading an existing convo.
						self.sidebarViews.contentView.ui.conversation.addClass('visible');
						ChatApp.trigger('breadcrumb:show',
							{
								thread: currThread,
								defaultTitle: KXL.i18n.t('chat.title')
							}
						);

						self.sidebarViews.contentView.ui.threadsContainer.removeClass('visible');
					}
				}

				function returnToThreadList () {
					var currThread = null;
					_.each(defaultThreads.models, function (thread) {
						thread.deselect();
						if (self.currentSelectedThread === thread) {
							currThread = thread;
						}
					});
					_.each(userThreads.models, function (thread) {
						thread.deselect();
						if (self.currentSelectedThread === thread) {
							currThread = thread;
						}
					});
					self.currentSelectedThread = null;
					toggleConversationRegion(currThread);
				}

				function selectLastThread(thread) {
					var
						currentUserSettings = KXL.request('current:user:settings:entity'),
						lastChatId = currentUserSettings && currentUserSettings.get('lastChatId')
					;

					if (lastChatId) {
						if (thread.id === lastChatId) {
							self._selectThread(thread);
						}
					}
				}

				function selectConversation(thread) {
					self.currentSelectedThread = thread;
					showConversation(thread);
					toggleConversationRegion(thread);
				}

				function displayChatEmptyState() {
					self.sidebarViews.contentView.conversation.show(new ChatApp.EmptyStateView());
				}

				self.listenTo(defaultThreads, 'select:one', selectConversation);

				self.listenTo(userThreads, 'select:one', selectConversation);

				function getTotalNumberOfThreads() {
					return defaultThreads.length + userThreads.length;
				}
				self.listenTo(userThreads, 'remove', function (thread) {
					if (getTotalNumberOfThreads() < 1) {
						displayChatEmptyState();
					}
				});
				self.listenTo(defaultThreads, 'remove', function (thread) {
					if (getTotalNumberOfThreads() < 1) {
						displayChatEmptyState();
					}
				});
				self.sidebarViews.contentView.chatThreads.show(chatThreadsView);
				self.sidebarViews.contentView.defaultThreads.show(chatDefaultThreadsView);

				function chatThreadClick(deselectThreads, clickedThread) {
					_.each(deselectThreads, function (thread) {
						thread.deselect();
					});

					self._selectThread(clickedThread, true);
				}

				function chatThreadRemoveClick(thread) {
					if (thread.selected) {
						thread.deselect();
					}
					KXL.vent.trigger("chat:update:ui", this);
				}

				self.listenTo(chatDefaultThreadsView, 'chat:thread:remove:click', function (args) {
					var thread = args.model;
					var chatInterface = KXL.request('get:chat:interface');
					var jabberId = thread.get('jabberId');
					var chatRoom = chatInterface._defaultRooms[jabberId];
					if (chatRoom) {
						var currentUserSettings = KXL.request('current:user:settings:entity');
						currentUserSettings.set(chatRoom.settingsKey, false);
						currentUserSettings.save();
					}
					chatThreadRemoveClick(thread);
				});

				self.listenTo(chatThreadsView, 'chat:thread:click', function (args) {
					chatThreadClick(chatThreadsView.collection.models, args.model);
				});

				self.listenTo(chatThreadsView, 'chat:thread:remove:click', function (args) {
					var thread = args.model;
					KXL.request('get:chat:interface').leaveChat(thread.id);
					chatThreadRemoveClick(thread);
				});

				defaultThreads.sort();
				userThreads.sort();

			} else {
				KXL.atom.once('sidebar', function () {
					KXL.request('sidebar:show:content', 'chat');
				});
			}
			KXL.vent.trigger('chat:update:ui');
		},
		hideChat: function () {
			KXL.atom.once('sidebar', function () {
				KXL.request('sidebar:hide:content', 'chat');
			});
		},
		toggleChat: _.debounce(function (on) {
			var currentUserSettings = KXL.request('current:user:settings:entity');
			var sideBarState = KXL.appModel.get('sideBarState');
			var isOpen = sideBarState === 'open' ||
				sideBarState === 'chat';

			var self = this;
			function hide() {
				self.hideChat();
			}
			function show() {
				if (!KXL.request('auth:register:deferred:gate', { referrer: 'chat' })) {
					self.showChat();
				}
			}
			if (on === undefined) {
				if (isOpen) {
					hide();
				}
				else {
					show();
				}
			} else if (on) {
				show();
			} else {
				hide();
			}
		}, 200, true),
		/**
		 * Note that this actually removes the current user from the room, does not just set the user to inactive
		 */
		leaveRoom: function (thread) {
			KXL.request('get:chat:interface').removeUserFromRoom(thread.id, KXL.request('get:current:user').id);
		},

		debouncedMessageAudio: _.debounce(function () {
			if (!chatSound) {
				var $chatSound = $('#kx-chat-audio');
				if ($chatSound.length) {
					chatSound = $chatSound[0];
				}
			}
			if (chatSound) {
				chatSound.play();
			}
		}, 200, true),

		playMessageAudio: function () {
			var currentUserSettings = KXL.request('current:user:settings:entity');
			if (currentUserSettings.get('chatPlaySound')) {
				this.debouncedMessageAudio();
			}
		}
	});

	ChatApp.Controller = new Controller();

	function loadChatDependencies() {

		var deps = [
			KXL.asyncRequest('async:load:module', 'common/components/recipient_input'),
			KXL.asyncRequest('async:load:module', 'common/components/radio_button')
		];

		// Load the appropriate component based on chatMuzzled property
		if (KXL.currentUser &&
			KXL.currentUser.get('chatMuzzle') &&
			KXL.currentUser.get('chatMuzzle') !== false)
		{
			deps.push(KXL.asyncRequest('async:load:module', 'chat/muzzled'));
		} else {
			deps.push(KXL.asyncRequest('async:load:module', 'common/components/message_input'));
		}

		var defer = $.Deferred();

		$.when.apply($, deps).then(function () {
			defer.resolve();
		});

		return defer.promise();
	}

	KXL.reqres.setHandlers({
		'chat:show':  function (options) {
			var defer = $.Deferred();
			if (KXL.switches.chatEnabled) {
				loadChatDependencies().then(function () {
					ChatApp.Controller.showChat(options);
					defer.resolve();
				});
			} else {
				defer.reject();
			}
			return defer.promise();
		}
	});

	KXL.commands.setHandlers({
		'chat:show': function () {
			var defer = $.Deferred();
			if (KXL.switches.chatEnabled) {
				loadChatDependencies().then(function () {
					ChatApp.Controller.showChat();
					defer.resolve();
				});
			} else {
				defer.reject();
			}
			return defer.promise();
		},
		'chat:hide': function (options) {
			if (KXL.switches.chatEnabled) {
				ChatApp.Controller.hideChat(options);
			}
		},
		'chat:toggle': function (on) {
			if (KXL.switches.chatEnabled) {
				loadChatDependencies().then(function () {
					ChatApp.Controller.toggleChat(on);
				});
			}
		},
		'chat:leave:room': function (thread) {
			if (KXL.switches.chatEnabled) {
				ChatApp.Controller.leaveRoom(thread);
			}
		},
		'chat:start:conversation:private': function (userId, message) {
			if (KXL.switches.chatEnabled) {
				ChatApp.Controller.startPrivateChat(userId, message);
			}
		},
		'chat:start:conversation': function (recipients) {
			if (KXL.switches.chatEnabled) {
				ChatApp.Controller.startConversation(recipients);
			}
		},
		'chat:play:message:audio': function () {
			if (KXL.switches.chatEnabled) {
				ChatApp.Controller.playMessageAudio();
			}
		}
	});
});

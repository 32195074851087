/* globals _, Backbone, SockJS */
var JSONSocket = function (options) {

	_.extend(this, Backbone.Events);

	this.options = options || {};
	this.socket = null;
	this.connecting = false;
	this.connected = false;
	this.clientClosed = false;
	this.reconnectCount = 0;
	this.reconnectTimeout = null;
	this.reconnectLimit = null;
	this.subscriptions = {};
};

JSONSocket.prototype.connect = function (options) {

	var self = this;

	self.log('connect', {
		connected: self.connected,
		connecting: self.connecting,
		reconnectCount: self.reconnectCount
	});

	if (self.connected || self.connecting) {
		return;
	}
	self.connecting = true;
	self.clientClosed = false;

	if (options) {
		_.extend(self.options, options);
	}

	self.socket = new SockJS(self.options.url);
	// For server-client consistency
	self.emitLocal = self.trigger;

	self.socket.onopen = self.onOpen.bind(self);
	self.socket.onmessage = self.onMessage.bind(self);
	self.socket.onclose = self.onClose.bind(self);
	self.on('unsubscribe', function (path) {
		self.unsubscribePath(path, true);
	});
};

JSONSocket.prototype.disconnect = function () {

	if (!this.connected) {
		return;
	}

	this.clientClosed = true;
	// 1000 is a "normal" closure, ie, nothing went wrong, we just want to disconnect
	this.socket.close(1000);
};

JSONSocket.prototype.reconnect = function (limit) {

	var self = this;

	self.log('reconnect', { connected: self.connected, connecting: self.connecting, limit: limit });

	if (limit) {
		self.reconnectLimit = limit;
	}

	if (self.connected || self.connecting || self.reconnectTimeout) {
		return;
	}

	if (self.reconnectLimit && self.reconnectCount >= self.reconnectLimit) {
		self.log('reconnect limit reached', self.reconnectLimit, self.reconnectCount);
		return;
	}

	self.reconnectCount++;

	var delay = self.getReconnectDelay();

	self.reconnectTimeout = setTimeout(function (){
		self.reconnectTimeout = null;
		self.connect();
	}, delay);
	self.log('Reconnecting in', delay);
};

JSONSocket.prototype.log = function () {

	if (!this.options || !this.options.debug) {
		return;
	}

	var args = Array.prototype.slice.call(arguments);
	args.unshift('JSONSocket');

	console.log.apply(console, args);
};

JSONSocket.prototype.emit = function (eventName, payload) {

	var message = {
		eventName: eventName
	};

	if (payload) {
		message.payload = payload;
	}

	message = JSON.stringify(message);

	this.socket.send(message);
};

JSONSocket.prototype.onOpen = function () {

	this.authorize();
};

JSONSocket.prototype.onClose = function (event) {

	this.log('onClose', { clientClosed: this.clientClosed }, event);

	this.socket.onopen = null;
	this.socket.onmessage = null;
	this.socket.onclose = null;
	this.socket = null;

	this.connected = false;
	this.connecting = false;

	switch (event.code) {
		case 401:
			if (!this.clientClosed) {
				// In the case of an authorization failure, attempt reconnection, but only once
				// If auth fails it could be a server failure, but is more likely to be invalid auth data, if the
				// auth data is bad this client will enter a reconnect loop, so we limit to 1 reconnect
				this.reconnect(1);
			}
			break;
		case 1000:
			// This is a "normal" closure, nothing went wrong
			// and the socket was intentionally closed, do not reconnect
			break;
		default:
			if (!this.clientClosed) {
				this.reconnect();
			}
	}
};

JSONSocket.prototype.authorize = function () {

	var self = this;

	self.once('authorization', self.onAuthorization.bind(self));

	self.emit('authorize', self.options.authData);
};

JSONSocket.prototype.onAuthorization = function (authorized) {

	var self = this;

	if (authorized !== true) {
		return self.close(401, 'Authorization failed');
	}

	self.log('onAuthorization', self.subscriptions);

	_.forEach(self.subscriptions, function (count, path) {
		self.emit('subscribe', path);
	});

	self.connected = true;
	self.connecting = false;
	self.reconnectCount = 0;
	self.reconnectLimit = null;
};

JSONSocket.prototype.onMessage = function (message) {

	try {
		message = JSON.parse(message.data);
	} catch (error) {
		this.log('onMessage error', error);
	}

	this.log('onMessage', message.eventName, message.payload);

	this.emitLocal(message.eventName, message.payload);
};

JSONSocket.prototype.getReconnectDelay = function () {

	if (this.reconnectCount < 5) {
		return this.reconnectCount * 75;
	}
	if (this.reconnectCount < 10) {
		return this.reconnectCount * 300;
	}
	if (this.reconnectCount < 20) {
		return this.reconnectCount * 500;
	}

	return 10000;
};

JSONSocket.prototype.subscribe = function (path, listener) {

	this.log('subscribe', this.connected, path, listener);

	if (!this.subscriptions[path]) {
		this.subscriptions[path] = 1;
		if (this.connected) {
			this.emit('subscribe', path);
		}
	} else {
		this.subscriptions[path]++;
	}

	if (listener) {
		this.on(path, listener);
	}

	return true;
};

JSONSocket.prototype.unsubscribe = function (path, listener) {

	this.log('unsubscribe', path, listener);

	if (!this.subscriptions[path]) {
		return false;
	}

	this.subscriptions[path]--;
	this.off(path, listener);

	if (this.subscriptions[path] < 1) {
		delete this.subscriptions[path];
		if (this.connected) {
			this.emit('unsubscribe', path);
		}
	}

	return true;
};

JSONSocket.prototype.unsubscribePath = function (path, noEmit) {

	this.log('unsubscribePath', path);

	if (!this.subscriptions[path]) {
		return false;
	}

	delete this.subscriptions[path];
	this.off(path);

	if (this.connected && !noEmit) {
		this.emit('unsubscribe', path);
	}
};

JSONSocket.prototype.unsubscribeAll = function () {

	var self = this;

	_.forEach(self.subscriptions, function (count, path) {
		self.off(path);
		self.emit('unsubscribe', path);
		delete self.subscriptions[path];
	});
};

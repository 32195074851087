KXL.module('Services.BattleLog', function (BattleLog, KXL, Backbone, Marionette, $, _) {

	var _battleLogCache = {};

	KXL.reqres.setHandlers({

		'get:services:battlelog': function (gameId) {

			if (_battleLogCache[gameId]) {
				return _battleLogCache[gameId];
			}
			return null;
		},

		'set:services:battlelog': function (battleLogCollection, gameId) {
			_battleLogCache[gameId] = battleLogCollection;
		}
	});

});

KXL.module('FooterApp', function(FooterApp, KXL, Backbone, Marionette, $, _) {
	this.startWithParent = false;
	var API = {
		loadFooter: function () {
			FooterApp.Footer.Controller.loadFooter();
		},
		showFooter: function () {
			FooterApp.Footer.Controller.showFooter();
		},
		hideFooter: function () {
			FooterApp.Footer.Controller.hideFooter();
		}
	};
	KXL.on('start', function(id){
		API.loadFooter();
	});
	KXL.commands.setHandlers({
		'footer:show': function () {
			API.showFooter();
		},
		'footer:hide': function () {
			API.hideFooter();
		}
	});
});

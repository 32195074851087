KXL.module('ProfileApp.ActivityFeed', function(ActivityFeed, KXL, Backbone, Marionette, $, _) {
	ActivityFeed.Controller = {
		feedLayout: null,
		feedView: null,
		messageInputView: null,
		feedUserId: null,
		currentUserFeed: null,
		emptyFeed: null,
		websocket: null,
		systemNotifications: null,
		/*
		 * Initialize the activity feed view
		 */
		getActivityFeedView: function (userId){
			var self = this;
			this.feedUserId = userId;
			this.feedLayout = new ActivityFeed.mainLayout({
				previousPostsAction: 'click' // scroll , click
			});

			// Check if the user is viewing their own activity feed.
			if (KXL.currentUser && KXL.currentUser.id === self.feedUserId) {
				this.currentUserFeed = true;
			}else {
				this.currentUserFeed = false;
			}

			KXL.asyncRequest('async:load:module', 'common/components/message_input').then(function () {
				// Initialize the message input widget
				self.messageInputView = KXL.request(
					'new:messageinput:view',
					{
						startMinimized: true,
						submitBtnLabel: KXL.i18n.t('messageInput.postBtnLabel')
					}
				)
	
				// On message input submit, insert a new note into the API
				self.messageInputView.on('submit', function(message){
					// Display the loading state
					this.displayLoadingState();
					self.postNote(message);
				});
			});

			// This event gets fired when the user reaches the bottom of the activity feed
			// or when see more posts is clicked.
			this.feedLayout.on('load:previous:page', function () {
				self.loadPreviousPosts();
			});

			// This event gets fired when the feed is closed (page changed)
			this.feedLayout.on('activityfeed:closed', function () {
				self.activityFeedUnSubscribe();
			});

			this.feedLayout.on('show', function () {
				// Show the privacy select region if the user is viewing their own feed.
				if (KXL.currentUser.id === self.feedUserId) {
					var editPrivacyOptionsView = new KXL.Components.EditOptions.Controller.getEditOptions({
						name: 'editactivityfeedprivacy',
						optionsTitle: KXL.i18n.t('activityFeed.editPrivacyOptionsTitle'),
						model: KXL.request('current:user:settings:entity'),
						modelAttr: 'activityStreamPrivacy',
						modelSaveOnChange: true,
						optionItems: [
							{
								label: KXL.i18n.t('activityFeed.editPrivacyOptionsLabelEveryone'),
								value: 'public'
							},
							{
								label: KXL.i18n.t('activityFeed.editPrivacyOptionsLabelFriends'),
								value: 'friends'
							},
							{
								label: KXL.i18n.t('activityFeed.editPrivacyOptionsLabelPrivate'),
								value: 'private'
							}
						]
					});

					this.editPrivacy.show(editPrivacyOptionsView);
				}

				if (self.currentUserFeed) {
					var systemNoties = KXL.request('get:userNotifications');
					$.when(systemNoties).done(
						function (noties) {
							self.systemNotifications = noties.filter(function (item) {
								return item.attributes.type === 'system';
							});
							self.loadPosts();
						}
					).fail(function (response) {
						// Problem getting noties, load without them.
						self.loadPosts();
					});
				}else {
					// Its another user's feed, load the posts without noties.
					self.loadPosts();
				}
			});

			return this.feedLayout;
		},
		/*
		 * Post a note to the current users wall
		 */
		postNote: function (message) {
			var self = this;
			var saveNote = KXL.request(
				'save:note',
				KXL.currentUser.id,
				this.feedUserId,
				message
			);
			$.when(saveNote).done(
				function () {
					// Reset the message input view.
					self.messageInputView.reset();
					if (self.emptyFeed) {
						self.loadPosts();
					}else {
						self.loadLatestPosts();
					}
				}
			).fail(function (response) {
					//TODO @cmartin display something for the error states.
					switch (response.status) {
						case 400:
							//bad request
							break
						case 401:
							//unauthorized
							break;
						default:
							//unknown
							break;
					}
				});
		},
		/*
		 * Load posts
		 */
		loadPosts: function () {
			var self = this;
			// Show a loading view until the data is loaded.
			var activityFeedLoadingView = new ActivityFeed.LoadingView();
			this.feedLayout.feed.show(activityFeedLoadingView);
			this.feedLayout.showPreloader();
			var fetchPosts = KXL.request(
				'get:posts:by:userid',
				this.feedUserId
			);
			$.when(fetchPosts).done(function (posts) {
				if (self.currentUserFeed) {
					// Combine posts with system noties
					posts = self.combinePostsWithSystemNotifications(posts);
				}
				if (posts.length) {
					self.emptyFeed = false;
				} else {
					self.emptyFeed = true;
				}
				// Initialize the feed composite view
				self.feedView = new ActivityFeed.CompositeView({
					userId: self.feedUserId,
					currentUserFeed: self.currentUserFeed,
					collection: posts
				});

				self.feedView.on('feed:item:hide:post', function (view) {
					// Remove the element from the model.
					self.feedView.collection.remove(view.model);

					if(view.model.get('action') === 'notification') {
						// If its a notification, destroy the model which will delete it from the server.
						view.model.destroy();
					}else {
						// Its a note type and they are the owner. Hide the note.
						if (view.model.get('object').entityType === 'note' &&
							KXL.currentUser.id === view.model.get('object').hydrated.from) {
							KXL.request(
								'hide:note',
								view.model.get('object').entityId
							);
						}else if (self.currentUserFeed) {
							// If its there wall hide any post they want.
							KXL.request(
								'hide:post:by:userid',
								self.feedUserId,
								view.model.get('id')
							);
						}
					}
				});

				// Show the activity feed region
				self.feedLayout.feed.show(self.feedView);
				self.feedLayout.hidePreloader({
					paging: KXL.request('get:posts:paging')
				});
				self.feedLayout.isLoading = false;

				KXL.asyncRequest('async:load:module', 'common/components/message_input').then(function () {
					// Show the message input region
					self.feedLayout.messageInput.show(
						self.messageInputView
					);
				});

				// get a web socket and subscribe if the user is logged in
				if (KXL.currentUser) {
					// Initialize the web socket.
					self.websocket = KXL.module('Websocket');

					// Subscribe to activity stream updates
					KXL.execute('websocket:subscribe',
						'/users/' + self.feedUserId + '/activity-stream-posts',
						self.activityFeedSubListener.bind(self)
					);
				}

			}).fail(function (response) {
					switch (response.status) {
						case 403:
							// Forbidden
							// Hide preloader
							self.feedLayout.hidePreloader({
								paging: KXL.request('get:posts:paging')
							});
							// Show a permission denied message.
							//You do not have permission to view this user's activity
							var activityFeedPermissionDeniedView = new ActivityFeed.PermissionsView();
							self.feedLayout.feed.show(activityFeedPermissionDeniedView);
							break;
						default:
							// Unknown
							console.log(response);
							break;
					}
				});
		},
		/*
		 * Listener for when the websocket sends the latest feed items and add them to the correct collection.
		 */
		activityFeedSubListener: function (object) {
			if (object.type === 'comment') {
				this.feedView.children.each(function(view){
					if (object.content.entityId && view.entityId === object.content.entityId) {
						view.addComment(object.content);
					}
				});
			} else {
				this.feedView.itemAppend = false;
				this.feedView.collection.add(
					KXL.Entities.PostsCollection.prototype.parse({data: [object.content]}, null)
				);
			}
		},
		/*
		 * Unsubscribe when the activity feed region is closed.
		 */
		activityFeedUnSubscribe: function () {
			if (this.websocket) {
				KXL.execute('websocket:unsubscribe',
					'/users/' + this.feedUserId + '/activity-stream-posts',
					this.activityFeedSubListener
				);
			}
		},
		/*
		 * Load latest posts
		 */
		loadLatestPosts: function () {
			var self = this;
			var fetchLatestPosts = KXL.request(
				'get:latest:posts:by:userid',
				this.feedUserId
			);
			$.when(fetchLatestPosts).done(function (posts) {
				if (posts) {
					self.feedView.itemAppend = false;
					self.feedView.collection.add(posts.models);
				}
			}).fail(function (response) {
					console.log(response);
				});
		},
		/*
		 * Load older posts
		 */
		loadPreviousPosts: function () {
			var self = this;
			var fetchPreviousPosts = KXL.request(
				'get:previous:posts:by:userid',
				this.feedUserId
			);
			this.feedLayout.showPreloader();
			$.when(fetchPreviousPosts).done(function (posts) {
				if (posts) {
					var postsNotiesCombined = self.combinePostsWithSystemNotifications(posts);
					self.feedView.collection.add(
						postsNotiesCombined.models
					);
					self.feedLayout.hidePreloader({
							paging: KXL.request('get:posts:paging')
						}
					);
					self.feedLayout.isLoading = false;
				}else {
					// End of the feed.
					// Add any remaining system notifications
					var remainingSystemNotifications = self.getRemainingSytemNotifications();
					self.feedView.collection.add(
						remainingSystemNotifications.models
					);
					self.feedLayout.hidePreloader({
						paging: KXL.request('get:posts:paging')
					});
				}
			}).fail(function (response) {
				console.log(response);
			});
		},
		/*
		 * Combine posts with system notifications.
		 */
		combinePostsWithSystemNotifications: function (posts) {
			var self = this;
			var combinedCollection = new Backbone.Collection();
			var systemNotieKeysToRemove = [];

			// For every post check if we can add a system notification before it.
			posts.each(function(post) {
				if (self.systemNotifications) {
					_.each(self.systemNotifications, function (notie, notiekey) {
						// Is the notie publish at date before the post createdAt date?
						if (notie && post && notie.get('publishAt') && post.get('createdAt') &&
							notie.get('publishAt') > post.get('createdAt')){
							notie.set('action', 'notification');
							combinedCollection.add(notie);
							systemNotieKeysToRemove.push(notiekey);
							delete self.systemNotifications[notiekey]
						}
					});
				}
				combinedCollection.add(post);
			});
			// Remove any system noties displayed in the feed.
			this.systenNotificationsRemaining = _.difference(this.systemNotifications, systemNotieKeysToRemove);
			this.systemNotifications = this.systenNotificationsRemaining;

			return combinedCollection;
		},
		/*
		 * Get remaining system notifications.
		 */
		getRemainingSytemNotifications: function () {
			var remainingSystemNotifications = new Backbone.Collection();
			if (this.systemNotifications) {
				_.each(this.systemNotifications, function (notie) {
					if (notie) {
						notie.set('action', 'notification');
						remainingSystemNotifications.add(notie);
					}
				});
				this.systemNotifications = null;
			}
			return remainingSystemNotifications;
		}
	};
});

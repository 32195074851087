KXL.module('HomeApp.Home', function(Home, KXL, Backbone, Marionette, $, _) {
	Home.LoggedOutLayout = KXL.Components.Layout.extend({
		template: Templates['home/home-logged-out'],
		className: 'kx-home-logged-out',
		regions: {
			carousel: '.kx-home-masthead',
			pager: '.kx-home-pager',
			signup: '.kx-home-game-signup',
			staticGames: '.kx-home-static-games'
		}
	});
});

/* globals KXL */
KXL.module('Components.Comments', function (Comments, KXL, Backbone, Marionette, $, _) {
	Comments.Controller = {
		getComments: function (options) {
			var defaults = {
				inputCloseOnOtherInputOpen: true,
				inputStartExpanded: false,
				canComment: true,
				showNoCommentingCTALink: false
			};

			this.options = _.defaults(options || {}, defaults);

			var commentsView = new Comments.CommentsLayout(this.options),
				commentsListView = new Comments.ListView({
					canManage: options.canManage,
					commentOwnerCanManage: options.commentOwnerCanManage,
					collection: options.collection
				}),
				commentInputView
			;

			if (this.options.canComment) {
				commentInputView = new KXL.Components.CommentInput.Controller.getCommentInput({
					userId: KXL.currentUser.id,
					startExpanded: this.options.inputStartExpanded,
					closeOnOtherInputOpen: this.options.inputCloseOnOtherInputOpen,
					canComment: this.options.canComment
				});
				commentsView.on('show', function () {
					commentInputView.on('submit', function (value) {
						options.collection.post({
							content: value
						}).then(function (model) {
							commentInputView.ui.commentInputField.val('');
							commentInputView.options.closeOnOtherInputOpen = false;
							commentsView.trigger('new:comment');
						});
					});
	
					commentsView.on('show:comment:input', function (commentData) {
						commentsView.expand();
						commentInputView.expandCommentInput();
					});
	
					commentsView.listenTo(KXL.vent, 'comment:input:opened', function (view) {
						if (view.cid !== commentInputView.cid &&
							commentInputView.options.closeOnOtherInputOpen &&
							!commentInputView.options.startExpanded) {
							// Close all other comment inputs open.
							commentInputView.reset();
							commentsView.contract();
							commentsView.trigger('comments:contracted');
						}
					});
					commentsView.commentInputRegion.show(commentInputView);
				});
			} else if (this.options.showNoCommentingCTALink) {
				commentsView.on('comments:no:commenting:cta:clicked', function () {
					KXL.request(
						'show:delayed:register:form:banner',
						{
							referrer: 'comment-input',
							registrationHeaderText: 'Register to comment'
						}
					);
				});
			}

			commentsView.on('show', function () {
				// If there are more pages, show the view more comments ui
				if (options.collection.paging && options.collection.paging.previous) {

					var self = this,
						pagingView = new Comments.PagingView();

					pagingView.on('previous:page', function () {
						options.collection.fetch({
							remove: false,
							direction: 'previous',
							success: function (collection) {
								// last page? hide the previous page button
								if (!collection.paging.previous) {
									self.commentPagingRegion.close();
								}
							}
						});
					});

					this.commentPagingRegion.show(pagingView);

				}
				commentsListView.on('feed:item:hide:comment', function (view) {
					view.model.hide().then(function () {
						options.collection.remove(view.model);
						commentsView.trigger('delete:comment');
					});
				});

				this.commentsRegion.show(commentsListView);
			});

			commentsView.on('append:comment', function (commentData) {
				options.collection.add(commentData);
			});

			return commentsView;
		}
	};
});

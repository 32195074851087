KXL.module('Common.Utils.ImageUtils', function (ImageUtils, KXL, Backbone, Marionette, $, _) {
	/**
	 *
	 function:
	 loadImage

	 Description:
	 Promisified image loading util

	 @params
	 src: Image source

	 */
	ImageUtils.loadImage = function (src) {
		return $.Deferred(function (task) {
			var image = new Image();
			image.onload = function () { task.resolve(image); }
			image.onerror = function () { task.reject(); }
			image.src = src;
		}).promise();
	}

	/**
	 *
	 function:
	 imageToDataURL

	 Description:
	 Return base64 encoded data of the image passed in

	 @params
	 image: Image to process base64 encoded data for

	 */
	ImageUtils.imageToDataURL = function (image) {
		var canvas = document.createElement("canvas");
		canvas.width = image.width;
		canvas.height = image.height;
		var ctx = canvas.getContext("2d");
		ctx.drawImage(image, 0, 0);
		return canvas.toDataURL("image/png");
	}

	/**
	 *
	 function:
	 setImgUsingFile

	 Description:
	 Set up an image for display using a file object

	 @params
	 file: file to display
	 imgEl: imgEl to set. If not passed in one will be created

	 */
	ImageUtils.setImgUsingFile = function (file, imgEl) {
		var imgEl = imgEl || document.createElement('img');
		imgEl.file = file;
		var reader = new FileReader();
		reader.onload = (function (aImg) {
			return function(e){
				aImg.src = e.target.result;
			};
		} (imgEl));
		reader.readAsDataURL(file);
		return imgEl;
	}


	/**
	 *
	 function:
	 calculateAspectRatioFit

	 Description:
	 Conserve aspect ratio of the orignal region. Useful when shrinking/enlarging
	 images to fit into a certain area.

	 @params
	 {Number} srcWidth Source area width
	 {Number} srcHeight Source area height
	 {Number} maxWidth Fittable area maximum available width
	 {Number} maxHeight Fittable area maximum available height
	 @return {Object} { width, height }

	 */

	ImageUtils.calculateAspectRatioFit = function (srcWidth, srcHeight, maxWidth, maxHeight) {

		var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

		return { width: srcWidth*ratio, height: srcHeight*ratio };

	}

	/**
	 *
	 function:
	 preload

	 Description:
	 Preload images for provided array of image file paths

	 @params
	 imagesToPreload: array of image file paths

	 */
	ImageUtils.preload = function (imagesToPreload) {
		var loader = new PxLoader();
		_.each(imagesToPreload, function (image) {
			loader.addImage(image);
		});
		loader.start();
		return loader;
	}
});
// Battle Pirates Battle Logs
KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {

	Entities.Battlelog = Entities.Model.extend({});

	Entities.BattlelogCollection = Entities.Collection.extend({
		model: Entities.Battlelog,
		initialize: function(models, options) {
			this.userId = options.userId;
			this.gameId = options.gameId;
		},
		url: function () {
			return KXL.config.API_URL + '/users/' + this.userId + '/games/' + this.gameId + '/battle-log';
		}
	});

	var API = {
		getBattlelog: function (userId, gameId) {
			var battlelogCollection = new Entities.BattlelogCollection(null, {
				userId: userId,
				gameId: gameId
			});
			var deferred = battlelogCollection.fetch({
				error: (function() {
					battlelogCollection.reset();
				})
			});
			deferred._collection = battlelogCollection;
			return deferred;
		}
	};

	KXL.reqres.setHandler('battlelog:entities', function (userId, gameId) {
		return API.getBattlelog(userId, gameId);
	});

});
KXL.module('Entities', function (Entities, KXL, Backbone, Marionette, $, _) {
	Entities.Avatar = Entities.Model.extend({
		defaults: {
			userId: '',
			url: ''
		},
		url: function () {
			return KXL.config.API_URL + '/user-avatar-updates';
		}
	});

	Entities.AvatarPreset = Entities.Model.extend();
	Entities.AvatarPresetsCollection = Entities.Collection.extend({
		model: Entities.AvatarPreset
	});

	var API = {
		getAvatarPresets: function () {
			return new Entities.AvatarPresetsCollection(
				KXL.config.avatarPresets
			);
		}
	};

	KXL.reqres.setHandler('get:avatar:presets', function () {
		return API.getAvatarPresets();
	});
});

KXL.module('FriendsPanelApp', function(FriendsPanelApp, KXL, Backbone, Marionette, $, _) {
	FriendsPanelApp.Controller = {

		layout: null,
		sidebarViews: {
			topBarView: null,
			contentView: null,
			headerCTAView: null
		},

		getFriendsPanelView: function (options) {
			var self = this,
				friendsPerPage = 50,
				friendsDisplayed = friendsPerPage;

			if (!this.sidebarViews.contentView) {
				var friendPanelVent = new Backbone.Wreqr.EventAggregator();
				var searchModel = new Backbone.Model({
					mode: 'simple', // simple | advanced
					context: 'kxl',
					scope: [],
					term: '',
					ignoreUserIds: [],
					limit: 20,
					offset: 0,
					source: 'primary',
					ignoreUserLimit: 99,
					resultsTxt: KXL.i18n.t('friends.panel.displayingAllFriends'),
					allResultsReturned: false,
					numResultsReturned: 0,
					advancedSearchParams: new Backbone.Collection([
						{
							id: 'wc',
							params: new Backbone.Collection([
								{
									id: 'sector',
									label: KXL.i18n.t('friends.panel.inSector'),
									checked: false,
									value: null
								},
								{
									id: 'minLevel',
									label: KXL.i18n.t('friends.panel.minLevel'),
									checked: false,
									value: null
								},
								{
									id: 'maxLevel',
									label: KXL.i18n.t('friends.panel.maxLevel'),
									checked: false,
									value: null
								}
							])
						},
						{
							id: 'bp',
							params: new Backbone.Collection([
								{
									id: 'sector',
									label: KXL.i18n.t('friends.panel.inSector'),
									checked: false,
									value: null
								},
								{
									id: 'minLevel',
									label: KXL.i18n.t('friends.panel.minLevel'),
									checked: false,
									value: null
								},
								{
									id: 'maxLevel',
									label: KXL.i18n.t('friends.panel.maxLevel'),
									checked: false,
									value: null
								}
							])
						},
						{
							id: 'vc',
							params: new Backbone.Collection([
								{
									id: 'planet',
									label: KXL.i18n.t('friends.panel.inPlanet'),
									checked: false,
									value: null
								},
								{
									id: 'minLevel',
									label: KXL.i18n.t('friends.panel.minLevel'),
									checked: false,
									value: null
								},
								{
									id: 'maxLevel',
									label: KXL.i18n.t('friends.panel.maxLevel'),
									checked: false,
									value: null
								}
							])
						}
					])
				});
				searchModel.contextGetAdvancedParams = function () {
					var context = this.get('context');
					var advancedParams = this.get('advancedSearchParams').get(context);
					return advancedParams;
				};
				searchModel.contextGetAdvancedParam = function (id) {
					var advancedParams = this.contextGetAdvancedParams();
					// Only return the value if there is one for the context
					// and the checkbox is checked.
					if (advancedParams && advancedParams.get('params').get(id) &&
						advancedParams.get('params').get(id).get('checked') &&
						this.get('mode') === 'advanced') {
						return advancedParams.get('params').get(id).get('value');
					} else {
						return null;
					}
				};
				searchModel.hasAdvancedParams = function () {
					var advancedParams = this.contextGetAdvancedParams();
					var hasAdvancedParams = false;
					if (advancedParams) {
						advancedParams.get('params').each(function (model) {
							if (model.has('value') && model.get('checked')) {
								hasAdvancedParams = true;
							}
						});
						return hasAdvancedParams;
					} else {
						return false;
					}
				};
				searchModel.clearCurrentContextAdvancedParams = function () {
					var advancedParams = this.contextGetAdvancedParams();
					if (advancedParams) {
						advancedParams.get('params').each(function (model) {
							model.set('checked', false);
							model.set('value', '');
						});
					}
				};
				if (KXL.context) {
					searchModel.set('scope', [KXL.games.findWhere({urlName: KXL.context.get('urlName')}).id]);
				}

				var requestsCollection = new Backbone.FilteredCollection(null, { collection: KXL.request('current:user:received:friend:request:entities')._collection });
				requestsCollection.collection.on("add remove destroy", function (requestsCollection) {
					KXL.vent.trigger("user:userFriendRequests:count:changed", requestsCollection.collection.length);
				});
				var websocket = KXL.module('Websocket');
				websocket.on('friend-request', function (friendRequest) {
					requestsCollection.collection.unshift(friendRequest);
				});
				var friendsCollection = KXL.request('current:user:friend:entities')._collection;

				var filteredFriendsCollection = new Backbone.FilteredCollection(null, {
					collection: friendsCollection,
					comparator: function (a, b) {

						var userA = a.get('user');
						var userB = b.get('user');

						var usernameA = userA.username.toLowerCase();
						var usernameB = userB.username.toLowerCase();

						var presenceA = a.get('presence') || 'unavailable';
						var presenceB = b.get('presence') || 'unavailable';

						if (presenceA === presenceB) {
							if (presenceA === 'available') {
								return usernameA > usernameB ? 1 : usernameA < usernameB ? -1 : 0;
							}
							else {
								var lastSeenA = userA.lastSeen || 0;
								var lastSeenB = userB.lastSeen || 0;

								if (lastSeenA < lastSeenB) {
									return 1;
								}
								else if(lastSeenA > lastSeenB) {
									return -1;
								}
								return usernameA > usernameB ? 1 : usernameA < usernameB ? -1 : 0;
							}
						}
						else if (presenceA > presenceB) {
							return 1;
						}
						return -1;
					}
				});

				filteredFriendsCollection.on('filter-complete', _.debounce(function () {
					filteredFriendsCollection.sort();
				}, 30000));

				friendsCollection.on('reset', function () {
					if (friendsCollection.fetchComplete && friendsCollection.sortOnFetchComplete) {
						friendsCollection.sortOnFetchComplete = false;
						filteredFriendsCollection.sort();
					}
				});

				var proxyCollection = new Backbone.Obscura(filteredFriendsCollection);
				proxyCollection.setPerPage(friendsPerPage);
				var friendOptions = {
					friendsCollection: proxyCollection,
					requestsCollection: requestsCollection,
					searchModel: searchModel,
					vent: friendPanelVent
				};

				self.sidebarViews.contentView = new FriendsPanelApp.SearchView(friendOptions);
				self.sidebarViews.contentView.on('friends:panel:get:more', _.debounce(function () {
					if (filteredFriendsCollection.length !== proxyCollection.length) {
						friendsDisplayed += friendsPerPage;
						proxyCollection.setPerPage(friendsDisplayed);
					}
				}, 250));

				self.sidebarViews.headerCTAView = FriendsPanelApp.ButtonsView;
			}
			return this.sidebarViews;
		},

		destroyFriendsPanelView: function () {
			if (this.sidebarViews.contentView) {
				this.sidebarViews.contentView.close();
				this.sidebarViews.contentView = null;
			}
		}

	};

});

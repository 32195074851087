/* globals KXL, TweenLite */
KXL.module('HeaderApp.Header.Banner', function (Banner, KXL, Backbone, Marionette, $, _) {

	Banner.Controller = Marionette.Controller.extend({
		bannersView: undefined,
		initialize: function (options) {
			this.bannersView = new Banner.Banners();
		},
		getBannersView: function () {
			return this.bannersView;
		},
		getBanner: function (bannerId) {
			return this.bannersView.regions[bannerId];
		},
		showBanner: function (bannerId, options) {
			var bannerRegion = this.bannersView.regions[bannerId];
			if (!bannerRegion) {
				return;
			}

			var
				self = this,
				opts = _.defaults(
					{},
					options,
					{
						hideOthers: true
					}
				),
				$bannerRegion = bannerRegion.$el
			;

			if (opts.hideOthers) {
				var bannerRegionKeys = _.keys(this.bannersView.regions);
				_.each(bannerRegionKeys, function (key) {
					if (key !== bannerId) {
						self.hideBanner(key);
					}
				});
			}

			bannerRegion.ensureEl();
			$bannerRegion.show();
			KXL.vent.trigger('request:header:height:change:check');
		},
		createBanner: function (options) {
			// Create a new banner. If a banner with the
			// same bannerId already exists the view is replaced.
			var
				self = this,
				opts = _.defaults(
					{},
					options,
					{
						animate: true,
						show: true,
						removeOnClose: true
					}
				),
				contentView = opts.view
			;

			this.bannersView.$el.show();

			var bannerRegion = this.bannersView.regions[opts.bannerId];
			var $bannerRegion;
			if (!bannerRegion) {
				// Create a DOM node to hold the new banner.
				$bannerRegion = $(
					'<div class="kx-banner-region ' +
					opts.bannerId +
					( opts.className ? ' ' + opts.className : '' ) +
					'" style="display:none;"></div>'
				);
				this.bannersView.$el.append($bannerRegion);

				// Create a new region.
				bannerRegion = new Backbone.Marionette.Region({
					el: $bannerRegion
				});
				

				var region = {};
				region[opts.bannerId] = bannerRegion;
				this.bannersView.addRegions(region);
			}

			// Wrap the content in a banner view.
			var bannerView = new Banner.Banner(
				{
					contentView: contentView,
					closable: opts.closable
				}
			);

			bannerView.listenTo(bannerView, 'close:clicked', function () {
				self.hideBanner(opts.bannerId, opts.removeOnClose, {
					manual: true
				});
			});

			bannerRegion.toggleClosable = function (closable) {
				bannerView.toggleClosable(closable);
			};

			if (opts.show) {
				bannerRegion.on('show', function () {
					self.showBanner(opts.bannerId, opts);
				});
			}
			bannerRegion.show(bannerView);
			return bannerRegion;
		},
		removeBanner: function (bannerId) {
			var region = this.bannersView.regions[bannerId];
			if (!region) {
				return;
			}
			region.close();
			this.bannersView.removeRegion(bannerId);
			region.$el.remove();
			region.trigger('removed');
			KXL.vent.trigger('request:header:height:change:check');
		},
		hideBanner: function (bannerId, remove, options) {
			var self = this;
			if (!bannerId) {
				// If no banner id is passed in, hide all banners.
				var bannerRegionKeys = _.keys(this.bannersView.regions);
				_.each(bannerRegionKeys, function (key) {
					self.hideBanner(key, remove, options);
				});
				return;
			}

			var region = this.bannersView.regions[bannerId];
			if (!region) {
				return;
			}

			var $bannerRegion = region.$el;
			if ($bannerRegion) {
				$bannerRegion.hide();
				KXL.vent.trigger('request:header:height:change:check');
				if (remove) {
					self.removeBanner(bannerId);
				} else {
					region.trigger('hidden');
				}
			}

			KXL.vent.trigger(
				'banner:hidden',
				_.extend(
					{ bannerId: bannerId },
					options || {}
				)
			);
		}
	});
});

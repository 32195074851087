/*...*/
KXL.module('InboxApp.InboxPanel.Views', function(Views, KXL, Backbone, Marionette, $, _) {

	Views.MainLayout = Marionette.Layout.extend({
		template: Templates['inbox/inbox-panel-layout'],
		className: 'kx-megaflyout-panel',
		regions: {
			threadList: '.kx-items-list-region'
		},
		events: {
			'click .kx-footing': 'seeAll',
			'click .kx-start-new-message': 'startNewMessage',
			'click .kx-inbox-deprecated-notice a': 'deprecatedMoreInfo'
		},
		deprecatedMoreInfo: function (e) {
			e.preventDefault();
			KXL.navigate($(e.target).attr('href'), { trigger: true });
		},
		seeAll: function () {
			KXL.execute('inbox:show');
		},
		startNewMessage: function () {
			KXL.execute('chat:start:group');
		}
	});

	Views.EmptyView = Marionette.Layout.extend({
		template: Templates['inbox/inbox-panel-empty']
	});

	Views.MessageItemView = Marionette.Layout.extend({
		template: Templates['inbox/inbox-panel-message-item'],
		tagName: 'li',
		className: 'kx-megaflyout-panel-item',
		snippetLength: 100,
		regions: {
			avatar: '.kx-icon',
			body: '.kx-message-body',
			cta: '.kx-cta'
		},
		ui: {
			title: '.kx-title',
			snippet: '.kx-message-body',
			avatar: '.kx-icon'
		},
		triggers: {
			'click .kx-cta-button' : 'ctaClicked',
			'click' : 'clicked'
		},
		initialize: function () {
			var self = this;
			self.model.on('change', this.render);
		},
		onCtaClicked: function() {
			// Temporary start chats instead of replying via the inbox
			var participants = [];
			_.each(this.model.get('participants'), function (participant) {
				if (participant.id !== KXL.currentUser.id) {
					participants.push(participant.id);
				}
			});
			if (participants.length === 1) {
				KXL.execute('chat:start:private', participants[0]);
			} else if (participants.length > 1) {
				KXL.execute('chat:start:group', participants);
			}
			//KXL.execute('inbox:show:thread:from:panel', this.model.id, true);
		},
		onClicked: function() {
			KXL.execute('inbox:show:thread:from:panel', this.model.id, false);
		},
		onRender: function () {
			var self = this;

			// Get the Users avatar
			var participants = self.model.get('participants');
			var participant;

			// 1on1 conversation...
			if (participants.length < 3) {
				participant = _.find(participants, function (participantObj) {
					if (participantObj && participantObj.id && participantObj.id !== KXL.currentUser.id) {
						return true;
					}
					return false;
				});
				if (participant) {
					KXL.request(
						'new:avatar:view',
						participant.id,
						{
							size: 'medium',
							color: KXL.Components.Avatar.borderColors.NONE,
							el: self.ui.avatar,
							avatarMenu: KXL.Components.Avatar.avatarMenu.OFF
						}
					);
				}
			} else {

				if (self.model.get('origin') === 'user') {

					var snippetUserId = self.model.has('snippetAddress') ?
						KXL.request('inbox:strip:suffix', self.model.get('snippetAddress')) :
						KXL.currentUser.id;

					// Last message was from an ignored user so we default back to current User avatar for thread
					if (snippetUserId !== KXL.currentUser.id &&
						KXL.request('is:user:ignored', snippetUserId)) {
						snippetUserId = KXL.currentUser.id;
					}

					KXL.request(
						'new:avatar:view',
						snippetUserId,
						{
							size: 'medium',
							color: KXL.Components.Avatar.borderColors.NONE,
							el: self.ui.avatar,
							avatarMenu: KXL.Components.Avatar.avatarMenu.OFF
						}
					);
				}
			}

			// Flag read/unread
			var lastMessageAt = self.model.get('lastMessageAt') || 0;
			var lastReadAt = self.model.get('lastReadAt') || 0;
			if (lastReadAt < lastMessageAt) {
				self.$el.addClass('kx-unread');
			}
			else {
				self.$el.removeClass('kx-unread');
			}
		},
		templateHelpers: function () {
			var data = this.model.toJSON();
			var participants = [];

			_.each(data.participants, function (participant, i) {
				if (participant.id !== KXL.request('get:current:user').id) {
					participants.push(participant.name);
				}
			});

			var snippetUserId = _.has(data, 'snippetAddress') ?
				KXL.request('inbox:strip:suffix', data.snippetAddress) :
				KXL.currentUser.id;

			// We blank out snippet on thread if last message was sent by ignored user
			if (snippetUserId !== KXL.currentUser.id &&
				KXL.request('is:user:ignored', snippetUserId)) {
				data.snippet = '';
			} else {
				$.trim(data.snippet);
				if (data.snippet.length > this.snippetLength) {
					data.snippet = data.snippet.substr(0, (this.snippetLength - 3));
					data.snippet = data.snippet.concat('...');
				}
			}
			data.title = participants.join(', ');

			return data;
		}
	});

	Views.MessageItemsView = Marionette.CollectionView.extend({
		className: 'kx-megaflyout-panel-item-list',
		tagName: 'ul',
		itemView: Views.MessageItemView,
		emptyView: Views.EmptyView,
		itemViewEventPrefix: 'inbox:panel:message:item',
		fetchMore: _.throttle(function (offset) {
			this.collection.fetch({
				remove: false,
				data: {
					offset: offset,
					limit: 10
				}
			});
		}, 500, { trailing: false }),
		initialize: function () {
			this.collection.on('sort', this.render);
		},
		checkScrollPosition: function () {
			var offset = this.collection && this.collection.length;
			if (offset >= 10 && !(offset % 10) &&
				(this.$el.scrollTop() + this.$el.innerHeight() >= this.$el.prop('scrollHeight')))
			{
				this.fetchMore(offset);
			}
		},
		onRender: function () {
			var self = this;
			this.$el.scroll(function () {
				self.checkScrollPosition();
			});
		}
	});
});
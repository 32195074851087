KXL.module('HomeApp.Home', function(Home, KXL, Backbone, Marionette, $, _) {
	var Controller = Marionette.Controller.extend({
		layout: null,
		/**
		 * Show the logged out Home view
		 *
		 * @param  {Boolean} reload Whether to recreate and display the view
		 */
		showHome: function (reload) {
			var self = this;

			if (!KXL.currentUser && (!this.layout || reload)) {
				this.layout = new Home.LoggedOutLayout();
				this.layout.on('show', function () {
					self.showStaticGames();
					self.showCarousel();
					KXL.animate.pageIn('home');
					self.showSignUp();
				});
				KXL.homeRegion.show(this.layout);

				this.layout.listenTo(KXL.vent, 'auth:changed', function () {
					if (KXL.currentUser) {
						KXL.homeRegion.close();
						self.layout = null;
					}
				});
			} else if (!KXL.homeRegion.currentView) {
				KXL.homeRegion.show(this.layout);
			}
		},
		showCarousel: function () {
			var vcPlayButtonTheme = KXL.config.locale === 'fr_FR' ? KXL.Components.Button.Themes.alt7 : KXL.Components.Button.Themes.alt14;
			var carouselItems = new KXL.HomeApp.Carousel.CarouselConfigCollection([
				{
					commonName: 'bp',
					background: KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/bp/background.jpg',
					images: [
						KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/bp/background.jpg',
						KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/bp/logo.png',
						KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/bp/button.png'
					],
					summary: KXL.i18n.t('home.game.bp.summary'),
					href: 'game/battlepirates',
					urlName: 'battlepirates'
				},
				{
					commonName: 'wc',
					background: KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/wc/background.jpg',
					images: [
						KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/wc/background.jpg',
						KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/wc/logo.png',
						KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/wc/button.png'
					],
					summary: KXL.i18n.t('home.game.wc.summary'),
					href: 'game/warcommander',
					urlName: 'warcommander'
				},
				{
					commonName: 'io',
					background: KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/io/background.jpg',
					images: [
						KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/io/background.jpg',
						KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/io/logo.png',
						KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/io/button.png'
					],
					summary: KXL.i18n.t('home.game.io.summary'),
					href: 'game/imperiaonline',
					urlName: 'imperiaonline'
				},
				{
					commonName: 'wcra',
					background: KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/wcra/background.jpg',
					images: [
						KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/wcra/background.jpg',
						KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/wcra/logo.png',
						KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/wcra/button.png'
					],
					href: 'game/warcommanderrogueassault',
					urlName: 'warcommanderrogueassault'
				},
				{
					commonName: 'vc',
					background: KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/vc/background.jpg',
					images: [
						KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/vc/background.jpg',
						KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/vc/logo.png'
					],
					href: 'game/vegaconflict',
					urlName: 'vegaconflict',
					playButtonLabel: KXL.i18n.t('home.game.vc.playBtnLabel'),
					playButtonTheme: vcPlayButtonTheme,
					showAppStoreButtons: true
				},
				{
                    commonName: 'rfb',
                    background: KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/rfb/background.jpg',
                    images: [
                        KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/rfb/background.jpg',
                        KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/rfb/logo.png',
                        KXL.config.STATIC_BASE_URL + '/modules/home/carousel/images/rfb/button.png'
                    ],
                    href: 'game/riseoffirstborn',
                    urlName: 'riseoffirstborn',
                    showAppStoreButtons: true
                },
			]);
			var pager = KXL.request(
				'new:pager:view',
				carouselItems
			);
			this.layout.pager.show(pager);

			var carousel =
				KXL.HomeApp.Carousel.Controller.getCarousel(
					carouselItems,
					pager
				);
			carousel.listenTo(
				KXL.vent,
				[
					'dialog:opened',
					'logged:out:chat:room:changed',
					'logged:out:chat:interaction:started'
				].join(' '),
				function ()
			{
				carousel.stop();
			});
			carousel.listenTo(
				KXL.vent,
				[
					'dialog:closed',
					'logged:out:chat:interaction:completed'
				].join(' '),
				function () {
					carousel.start();
				}
			);
			carousel.listenTo(carousel, 'item:changed', function (item) {
				KXL.appModel.set('homePageCurrentGameCommonName', item.get('commonName'));
			});
			carousel.listenTo(
				KXL.appModel,
				'change:homePageCurrentGameCommonName',
				function (model, value, options)
			{
				carousel.setSelectedItemByGameCommonName(value);
			});
			this.layout.carousel.show(carousel);
		},
		showStaticGames: function () {
			this.layout.staticGames.show(KXL.request('new:games:view', KXL.staticGames));
		},
		showSignUp: function () {
			var trackingData = {
				trackControl: 'widget',
				trackSource: 'home'
			};
			var signupForm = KXL.request(
				'new:inline:signup:form:view',
				{
					data: trackingData,
					tooltipPos: 'left'
				}
			);
			this.listenTo(signupForm, 'register:submit', function (registration) {
				KXL.execute(
					'show:register:dialog',
					{
						registration: registration,
						step: KXL.AuthApp.RegisterSteps.CREATE_ACCOUNT,
						data: trackingData
					}
				);
			});
			this.listenTo(signupForm, 'fb:api:authenticated', function (fbAccessToken) {
				KXL.request('fblogin:submit', fbAccessToken, trackingData).done(function (user, isNew) {
					if (isNew) {
						KXL.execute(
							'show:register:dialog',
							{
								step: KXL.AuthApp.RegisterSteps.COMPLETE_FACEBOOK_REGISTER,
								fbAccessToken: fbAccessToken,
								isNew: isNew
							}
						);
					}
				}).fail(function (error) {
						if (error instanceof KXL.Errors.EmailConflictError) {
							signupForm.setErrorMsg(
								KXL.i18n.t(
									'auth.loginEmailConflictError',
									{ email: error.detail.email }
								)
							)
						} else if (error instanceof KXL.Errors.IOvationBlockedError ||
							error instanceof KXL.Errors.DeviceFingerprintError) {
							signupForm.setErrorMsg(error.message);
						} else {
							signupForm.setErrorMsg(KXL.i18n.t('auth.registrationFailed'));
						}
					});
			});
			signupForm.listenTo(KXL, 'google:api:authenticated', function (googleAccessToken) {
				KXL.request('googlelogin:submit', googleAccessToken, trackingData).done(function (user, isNew, hasAvatar) {
					if (isNew) {
						if (hasAvatar) {
							KXL.execute(
								'show:register:dialog',
								{
									step: KXL.AuthApp.RegisterSteps.IMPORT_FB_GAMES_PROMPT,
									googleAccessToken: googleAccessToken,
									isNew: isNew
								}
							);
						} else {
							KXL.execute(
								'show:register:dialog',
								{
									step: KXL.AuthApp.RegisterSteps.IMPORT_FB_GAMES_PROMPT,
									googleAccessToken: googleAccessToken,
									isNew: isNew
								}
							);
						}
					}
				}).fail(function (error) {
						if (error instanceof KXL.Errors.EmailConflictError) {
							signupForm.setErrorMsg(
								KXL.i18n.t(
									'auth.loginEmailConflictError',
									{ email: error.detail.email }
								)
							);
						} else if (error instanceof KXL.Errors.IOvationBlockedError ||
							error instanceof KXL.Errors.DeviceFingerprintError) {
							signupForm.setErrorMsg(error.message);
						} else {
							signupForm.setErrorMsg(KXL.i18n.t('auth.registrationFailed'));
						}
					});
			});
			signupForm.listenTo(KXL, 'google:api:error', function (errorMessage) {
				signupForm.setErrorMsg(KXL.i18n.t('auth.registrationFailed'));
			});
			this.listenTo(signupForm, 'steam:api:authenticated', function (authPromise) {
				authPromise.fail(function (error) {
					var errorMessage;
					switch (error) {
						case 'BANNED':
							KXL.execute('show:banned:dialog', error.data);
							break;
						case 'FAILED_FRAUD_CHECK':
							errorMessage = new KXL.Errors.IOvationBlockedError().message;
							break;
						case 'NO_DEVICE_FINGERPRINT':
							errorMessage = new KXL.Errors.DeviceFingerprintError().message;
							break;
						default:
							errorMessage = KXL.i18n.t('auth.registrationFailed');
					}
					signupForm.setErrorMsg(errorMessage);
				});
			});

			this.layout.signup.show(signupForm);
		}
	});
	Home.Controller = new Controller();
});
KXL.navigate = function (route, options) {
	var opts = _.extend({}, options);
	// Check to see if this is an external link...
	var urlParse = KXL.Common.Utils.Url.parse(route),
		currentHost = KXL.Common.Utils.Url.parse('//' + window.location.host).host;
	if (urlParse.host && urlParse.host !== currentHost) {
		if (!opts.target) {
			window.location.href = route;
		} else {
			window.open(route, opts.target);
		}
	} else {
		var currentRoute = KXL.getCurrentRoute().toLowerCase();
		var lowRoute = route.toLowerCase();
		if (opts.trigger && lowRoute === currentRoute) {
			Backbone.history.loadUrl(route);
		} else if (lowRoute !== currentRoute) {
			Backbone.history.navigate(route, opts);
		}

		KXL.vent.trigger('document:update:title');

		var re = /\/$/;
		if (lowRoute.replace(re, '') !== currentRoute.replace(re, '')) {
			if (opts.auth) {
				KXL.vent.trigger('info:page:nav', route.concat('?', $.param(_.pick(opts, 'auth'))));
			} else {
				KXL.vent.trigger('info:page:nav');
			}
		}

		KXL.vent.trigger('page:nav', route);
	}
};

KXL.getCurrentRoute = function () {
	return Backbone.history.fragment;
};

KXL.appModel.on('change:context', function (appModel, context) {
	KXL.context = context;
});

KXL.reqres.setHandlers({
	'get:current:user': function () {
		return KXL.currentUser;
	},
	'new:leaderboard:view': function (options) {
		return new KXL.Components.Leaderboard.Controller.getLeaderboard(options);
	},
	'new:avatar:view': function (userId, options) {
		return KXL.Components.Avatar.Controller.getAvatar(userId, options);
	}
});
KXL.commands.setHandlers({
	'context:set': function (context) {
		KXL.appModel.set('context', context);
	}
});

KXL.configBaseInitializeBefore = function (options) {
	// Config options for the environment.
	KXL.config = {};
	KXL.i18n = new KXL.Language.i18n({ localizations: options.localizations, locale: options.locale });
	KXL.config.useCompressed = options.useCompressed;
	KXL.config.asyncModules = options.asyncModules;
	// For all requests requiring authentication, the webserver proxy
	// should be used.
	KXL.config.API_URL = '/api/v2';
	KXL.config.LEGACY_API_URL = '/api/v1';
	KXL.config.APP_ID = options.kxAppId;
	KXL.config.siteName = options.siteName;
	// Capture the set, and available locale(s)
	KXL.config.locale = options.locale;
	KXL.config.supportedLocales = options.supportedLocales;
	KXL.config.DEFAULT_LOCALE = 'en_US';
	// For any public calls such as user avatars, the public route
	// directly to the node api can be used.
	KXL.config.PUBLIC_API_URL = options.apiUrl;
	// The websocket URL
	KXL.config.WEBSOCKET_URL = options.websocketUrl;
	// Static host, either from the public_html directory or from the CDN.
	KXL.config.STATIC_BASE_URL = options.staticBaseUrl;
	KXL.config.BASE_URL = options.baseUrl;
	KXL.games = KXL.request('games:entities', options.games);
	KXL.mobileGames = KXL.request('mobilegames:entities', KXL.games);
	KXL.config.browser = options.browser;
	if (options.browser.name === 'Internet Explorer' && parseFloat(options.browser.version) < 10) {
		KXL.config.animationCapabilities = false;
	}
};

KXL.module('Components.FriendCard', function (FriendCard, KXL, Backbone, Marionette, $, _) {

	FriendCard.FriendCardGameAvatarView = KXL.Components.ItemView.extend({
		template: '#friendcard-game-avatar-template',
		className: 'kx-usergame-avatar',
		events: {
			'click .kx-avatar-wrapper': 'clicked'
		},
		triggers: {
			'click': 'clicked'
		},
		clicked: function (args) {
			KXL.execute('profile:show', this.model.get('userId'), { gameId: this.model.get('gameId') });
		}
	});

	FriendCard.UserGame = Marionette.ItemView.extend({
		initialize: function (options) {
			var defaults = {
				searchTerm: null,
				alignment: 'vertical',
				displayGameName: true
			};

			this.options = _.defaults(options || {}, defaults);

			this.searchTerm = this.options.searchTerm;
			this.alignment = this.options.alignment;
			this.displayGameName = this.options.displayGameName;
			this.clickable = this.options.clickable;
		},
		template: function () {
			return '';
		},
		className: 'kx-friend-card-user-game kx-ellipsis',
		events: {
			'click': 'gameAliasClicked'
		},
		gameAliasClicked: function () {
			if (this.clickable) {
				KXL.execute('profile:show', this.model.get('userId'), {
						gameId: this.model.get('gameId'),
						overrideGameContext: true
					}
				);
			}
		},
		prettyAlias: function () {
			return KXL.Common.Utils.StringUtils.prettySubString(
				this.model.get('alias') || this.model.get('playerId'),
				this.searchTerm,
				null,
				'kx-friend-search-highlight'
			);
		},
		_renderIcon: function () {
			var gameId = this.model.get('gameId'),
				game = gameId && KXL.games.get(gameId);
			if (game) {
				var iconUrl = KXL.config.STATIC_BASE_URL +
					'/modules/game/images/favicon/' + game.get('commonName') + '.png';
				this.$el.css('background-image', 'url(' + iconUrl + ')');
			}
		},
		_renderUsername: function () {
			this.$el.html(this.prettyAlias());
		},
		onRender: function () {
			this._renderIcon();
			if (this.displayGameName) {
				this._renderUsername();
			}
			if (this.clickable) {
				this.$el.addClass('kx-clickable');
			}
			switch (this.alignment) {
				case 'grid':
					this.$el.addClass('kx-quad-grid');
					break;
				case 'vertical':
					this.$el.addClass('kx-vertical');
					break;
				default:
					//Horizontal
					break;
			}
		}
	});

	FriendCard.UserGames = Marionette.CollectionView.extend({
		template: function () {
			return '';
		},
		className: 'kx-friend-card-user-games',
		itemView: FriendCard.UserGame,
		itemViewOptions: function () {
			return this.options;
		},
		initialize: function () {
			// Filter any games that are not included within KXL static games
			this.collection = new Backbone.FilteredCollection(null, { collection: this.collection });
			this.collection.setFilter(function (model) {
				return KXL.request('is:static:game', model.get('gameId'));
			});
		},
		onRender: function () {
			if (this.options && this.options.alignment === 'horizontal') {
				this.$el.addClass('kx-align-right');
			}
		}
	});

	FriendCard.FriendCardAttributeView = Marionette.CollectionView.extend({
		template: function () {
			return '';
		},
		tagName: 'li',
		onRender: function () {
			this.$el.html(this.model.get('label') + ': <span class="kx-highlight">' + this.model.get('value') + '</span>');
		}
	});

	FriendCard.FriendCardAttributesView = Marionette.CollectionView.extend({
		tagName: 'ul',
		className: 'kx-user-attributes',
		itemView: FriendCard.FriendCardAttributeView
	});

	FriendCard.HorizontalDividerView = KXL.Components.Layout.extend({
		template: function () {
			return '';
		},
		className: 'kx-friendcard-horizontal-divider'
	});

	FriendCard.FriendCardSubTitleView = KXL.Components.ItemView.extend({
		template: function () {
			return '';
		},
		tagName: 'span',
		className: 'kx-sub-title',
		onRender: function () {
			this.$el.html(this.options.text);
		}
	});

	FriendCard.ExpandBtnView = KXL.Components.Layout.extend({
		template: function () {
			return '';
		},
		className: 'kx-expand-arrow-btn',
		initialize: function (options) {
			var defaults = {
				expanded: false,
				highlighted: false
			};
			this.options = _.defaults({}, options, defaults);
			this.expanded = this.options.expanded;
		},
		onRender: function () {
			// If it's expanded, show the button to close it.
			if (this.expanded) {
				this.$el.addClass('kx-close');
			} else {
				this.$el.addClass('kx-open');
			}
			this.$el.addClass('kx-clickable');

			if (this.options.highlighted) {
				this.$el.addClass('kx-highlight');
			}
		},
		events: {
			'mousedown': 'onMouseDown'
		},
		onMouseDown: function () {
			this.$el.toggleClass('kx-open');
			this.$el.toggleClass('kx-close');
			this.expanded = !this.expanded;
			this.trigger('expandBtn:toggle', this.expanded);
		}
	});

	FriendCard.View = KXL.Components.Layout.extend({
		template: '#friendcard-template',
		className: 'kx-friend-card kx-player-details kx-clickable',
		tagName: 'li',
		triggers: {
			'click' : 'clicked'
		},
		regions: {
			avatarRegion: '.kx-avatar',
			gamesPlayedIconsRegion: '.kx-games-played-icons-region',
			gamesPlayedRegion: '.kx-games-played-region',
			dividerRegion: '.kx-divider-region',
			featuredGameNameRegion: '.kx-featured-game-name-region',
			subTitleRegion: '.kx-sub-title-region',
			attributesRegion: '.kx-attributes-region',
			ctaRegion: '.kx-cta-region',
			expandBtnRegion: '.kx-expand-arrow-btn-region'
		},
		ui: {
			friendCard: '.kx-friend-card-inner',
			username: '.kx-user-name',
			avatar: '.kx-avatar',
			presence: '.kx-online-presence',
			lastSeen: '.kx-user-last-seen',
			friendIcon: '.kx-user-is-friend-icon',
			title: '.kx-title',
			content: '.kx-card-content',
			chatBtn: '.kx-chat-btn'
		},
		events: {
			'click .kx-user-name': function () {
				this.trigger('username:clicked', this.model);
				this.trigger('cta:clicked');
			},
			'mouseover' : 'onMouseOver',
			'mouseout' : 'onMouseOut',
			'mousedown' : 'onMouseDown',
			'mouseover .kx-clickable' : 'onClickableOver',
			'mouseout .kx-clickable' : 'onClickableOut',
			'click @ui.chatBtn' : '_initiateChat'
		},
		initialize: function (options) {
			var self = this,
			defaults = {
				searchTerm: '',
				context: 'kxl',
				userType: '',
				hoverCard: 'full',
				cardType: 'search',
				displayChatBtn: false,
				selectable: false,
				expandable: false,
				namespace: null,
				displayScopedGameName: true
			};

			this.options = _.defaults({}, options, defaults);

			this.searchTerm = this.options.searchTerm;
			this.context = this.options.context;
			this.userType = this.options.userType;
			this.hoverCard = this.options.hoverCard;
			this.cardType = this.options.cardType;
			this.displayChatBtn = this.options.displayChatBtn;
			this.selectable = this.options.selectable;
			this.expandable = this.options.expandable;
			this.namespace = this.options.namespace;
			this.displayScopedGameName = this.options.displayScopedGameName;
			this.entireCardIsClickable = true;

			if (this.namespace) {
				this.listenTo(this.model, 'selected:' + this.namespace, function () {
					self.selectedState();
				});
				this.listenTo(this.model, 'deselected:' + this.namespace, function () {
					self.deselectedState();
				});
			}

			if (this.model.has('user')) {
				var games = null,
					requestId = null;
				if (this.model.has('games')) {
					games = this.model.get('games');
				}
				if (this.cardType === 'request') {
					requestId = this.model.get('id');
				}
				this.model = KXL.request('new:user:entity', this.model.get('user'));
				if (games) {
					this.model.set('games', games);
				}
				if (requestId) {
					this.model.set('requestId', requestId);
				}
			}

			if (options.appScope && this.options.appScope.length) {
				this.appScope = options.appScope;
			}

			this.friendsCollection = KXL.request('current:user:friend:entities')._collection;
			this.friend = this.friendsCollection.get(this.model.id);
			if (this.friend) {
				this.listenTo(this.friend, 'change', function () {
					self.setPresence(self.friend.get('presence'));
				});
			}
		},
		onMouseOver: function () {
			this.$el.addClass('hover');
			if (this.displayChatBtn) {
				this.ui.chatBtn.show();
			}
			if (!this.expandBtnRendered && !this.autoExpand) {
				this._renderExpandBtn();
			} else if (this.expandBtnRegion.$el) {
				this.expandBtnRegion.$el.show();
			}
			if (this.ctaView && this.ctaRegion.currentView) {
				this.ctaRegion.$el.show();
			} else if (this.ctaView && !this.ctaRegion.currentView) {
				this.ctaRegion.show(this.ctaView);
			}
			if (this.ctaView && this.subTitle) {
				this.subTitleRegion.$el.hide();
			}
		},
		onMouseOut: function () {
			this.$el.removeClass('hover');
			this.ui.chatBtn.hide();
			if (!this.expandBtnHighlighted && this.expandBtnRegion.$el) {
				this.expandBtnRegion.$el.hide();
			}
			if (this.ctaView && this.ctaRegion.currentView) {
				this.ctaRegion.$el.hide();
			}
			if (this.ctaView && this.subTitle) {
				this.subTitleRegion.$el.show();
			}
		},
		onMouseDown: function () {
			// The whole friend card is clickable unless you are clicking
			// another button on the friend card.
			if (this.entireCardIsClickable) {
				this.onCardClicked();
			}
		},
		onClickableOver: function () {
			this.entireCardIsClickable = false;
		},
		onClickableOut: function () {
			this.entireCardIsClickable = true;
		},
		onCardClicked: function () {
			this.trigger('username:clicked', this.model);
		},
		_initiateChat: function (ev) {
			if (ev) {
				ev.stopPropagation();
				ev.preventDefault();
			}
			if (!KXL.request('is:user:ignored', this.model.get('id'))) {
				KXL.execute('chat:start:private', this.model.get('id'));
			}
		},
		_minimizeFriendCard: function () {
			if (KXL.config.animationCapabilities && this.expandTimeline) {
				this.expandTimeline.reverse();
			} else {
				this.ui.content.height(this.contentMinimizedHeight);
			}
		},
		_expandFriendCard: function () {
			if (KXL.config.animationCapabilities && this.expandTimeline) {
				this.expandTimeline.play();
			} else {
				this.ui.content.height(this.contentMaximizedHeight - 10); // 10 is the top and bottom padding
			}
		},
		_renderExpandBtn: function (highlighted) {
			var self = this,
				showBtn = true;

			var setContentMaxMinHeight = function () {
				self.contentMaximizedHeight = self.ui.content[0].scrollHeight;
				self.contentMinimizedHeight = self.ui.content.height();
			};

			if (this.ui.content && !highlighted) {
				setContentMaxMinHeight();
				if (this.contentMaximizedHeight < this.contentMinimizedHeight + 20) {
					showBtn = false;
				}
			}

			if (showBtn) {
				var expandBtn = new FriendCard.ExpandBtnView({
					highlighted: highlighted || false
				});

				expandBtn.on('expandBtn:toggle', function (expand) {

					if (!self.contentMaximizedHeight && !self.contentMinimizedHeight) {
						setContentMaxMinHeight();
					}

					if (KXL.config.animationCapabilities && !self.expandTimeline) {
						// Create expand animation timeline
						self.expandTimeline = new TimelineLite();
						self.expandTimeline.add(
							TweenLite.to(
								self.ui.content,
								0.25,
								{
									height: self.contentMaximizedHeight,
									ease: 'Power2.easeInOut'
								}
							)
						);
						self.expandTimeline.pause();
					}

					if (expand) {
						self._expandFriendCard();
					} else {
						self._minimizeFriendCard();
					}

				});

				this.expandBtnRegion.show(expandBtn);
				this.expandBtnRendered = true;
			}
		},
		_renderAttributes: function (attributes) {
			if (attributes.length) {
				var attributeOrder = ['level', 'sector', 'playerId', 'totalXP'];
				// Filter out any attributes that don't exist in the collection
				attributeOrder = _.filter(
					attributeOrder,
					function (id) {
						return attributes.get(id);
					}
				);
				// Reset in the correct order.
				attributes.reset(
					_.map(
						attributeOrder,
						function (id) {
							return attributes.get(id);
						}
					)
				);
			}

			var attributesView = new FriendCard.FriendCardAttributesView({
				collection: attributes
			});
			this.attributesRegion.show(attributesView);
			if (attributes.length) {
				return true;
			}
		},
		_renderSubTitle: function (options) {
			this.subTitle = new FriendCard.FriendCardSubTitleView({
				text: options.text
			});

			this.subTitleRegion.show(this.subTitle);
		},
		_renderFeaturedGameName: function (app) {
			var featuredUsername = new FriendCard.UserGame({
				searchTerm: this.searchTerm,
				model: app,
				displayGameName: true
			});
			this.featuredGameNameRegion.show(featuredUsername);
		},
		_renderHorizontalDivider: function () {
			var horizontalDivider = new FriendCard.HorizontalDividerView();
			this.dividerRegion.show(horizontalDivider);
		},
		_renderGamesPlayed: function (options) {

			var defaults = {
				games: [],
				exclude: [],
				alignment: 'grid',
				displayGameName: true,
				region: this.gamesPlayedRegion
			};

			var opts = _.defaults(options || {}, defaults);

			var games = opts.games,
				excludeGames = opts.exclude,
				alignment = opts.alignment,
				self = this;

			games = new Backbone.FilteredCollection(null, { collection: games });

			if (excludeGames && excludeGames.length) {
				games.setFilter(function (model) {
					return !_.contains(excludeGames, model.get('gameId'));
				});
			}

			if (games.length) {
				var gamesPlayed = new FriendCard.UserGames({
					collection: games,
					searchTerm: this.searchTerm,
					alignment: alignment,
					displayGameName: opts.displayGameName,
					clickable: !this.selectable
				});

				opts.region.show(gamesPlayed);

				// Check if one of the games played substring matches the search term
				var aliasSubStringSearchTermMatch = false;
				if (this.searchTerm) {
					games.each(function (game) {
						if (game.get('alias') &&
							game.get('alias').indexOf(self.searchTerm) > -1 &&
							self.searchTerm !== '') {
							aliasSubStringSearchTermMatch = true;
						}
					});
				}

				return {
					displayed: true,
					highlighted: aliasSubStringSearchTermMatch
				};
			}
		},
		onRender: function () {
			var self = this;
			var games = null;
			var avatar;

			if (this.model.has('games')) {
				games = KXL.request('usersearch:game:entities', this.model.get('games'));
			}
			if (this.model.has('username')) {
				var avatarOpts = {
					color: KXL.Components.Avatar.borderColors.BLACK,
					size: KXL.Components.Avatar.size.MEDIUM_LARGE,
					avatarMenuFriend: this.userType && this.userType === 'friends',
					model: this.model,
					friendCard: true,
					suppressDefaultClickAction: this.selectable
				};

				if (this.hoverCard !== 'none') {

					var delayedMenuOpen = null;

					function mouseEnter() {
						delayedMenuOpen = setTimeout(function () {
							avatar.showMenu({ menuPositionTop: 0, menuPositionLeft: avatar.$el.width() });
						}, 1000);
					}

					function mouseLeave() {
						clearTimeout(delayedMenuOpen);
						var delayedMenuClose = setTimeout(function () {
							avatar.hideMenu();
						}, 500);
					}

					if (this.hoverCard === 'full') {
						this.ui.friendCard.on('mouseenter', mouseEnter);
						this.ui.friendCard.on('mouseleave', mouseLeave);
					} else if (this.hoverCard === 'avatar') {
						this.ui.avatar.on('mouseenter', mouseEnter);
						this.ui.avatar.on('mouseleave', mouseLeave);
					}

					avatarOpts.avatarMenu = KXL.Components.Avatar.avatarMenu.ON;
				}

				avatar = KXL.request('new:avatar:view',
					this.model.id,
					_.extend(avatarOpts, {
						color: KXL.Components.Avatar.borderColors.GREY,
						radiusStyle: 'circular',
						bgMode: true,
						presence: KXL.Components.Avatar.presence.NONE,
						avatarMenuFriend: this.userType && this.userType === 'friends',
						model: this.model
					})
				);

				this.listenTo(
					avatar,
					[
						'clicked',
						'friend-chat:clicked'
					].join(' '),
					function () {
						self.trigger('cta:clicked');
					}
				);

			} else {
				if (games && games.length > 0) {
					var game = games.at(0);
					this.model.set('alias', game.get('alias'));
					var gameModel = KXL.games.get(game.get('gameId'));
					avatar = new FriendCard.FriendCardGameAvatarView({
						model: new KXL.Entities.Model({
							commonName: gameModel.get('commonName'),
							userId: this.model.id,
							gameId: gameModel.id
						})
					});
					avatar.listenTo(avatar, 'clicked', function () {
						self.trigger('cta:clicked');
					});
				}
				this.displayChatBtn = false;
			}

			if (avatar) {
				this.avatarRegion.show(avatar);
			}

			if (this.friend) {
				this.setPresence(this.friend.get('presence'));
				this.ui.friendIcon.show();
			}

			if (this.appScope && games) {
				var scopedGame = null,
				displayGame = null,
				renderAttributes = null,
				renderGamesPlayed = null,
				renderGameIconsPlayed = null,
				excludedGamesPlayed = [],
				gamesPlayedAlignment = 'vertical',
				displayGamesPlayedGameName = true;

				// If there is only one scoped game define it.
				if (this.appScope.length === 1) {
					scopedGame = games.findWhere({gameId: this.appScope[0]});
					displayGame = scopedGame;
				}

				if (!displayGame && games.length) {
					// User doesn't play the scoped game display another game.
					if (games.length === 1) {
						// Only one game display it.
						displayGame = games.at(0);
					} else {
						// More then one game, get the most recently played
						displayGame = games.max(function (game) {
							return game.get('seen') || false;
						});
					}
				}

				if (displayGame && this.displayScopedGameName &&
					KXL.request('is:static:game', displayGame.get('gameId'))) {
					this._renderFeaturedGameName(displayGame);
					excludedGamesPlayed.push(displayGame.get('gameId'));
				}

				if (scopedGame && scopedGame.get('attributes') && this.cardType === 'search') {
					renderAttributes = this._renderAttributes(scopedGame.get('attributes'));

				} else if (this.cardType === 'search')  {
					this.autoExpand = true;

				} else if (this.cardType === 'friend' || this.cardType === 'request') {
					// Render game icons without usernames if its a friend or a friend request.
					renderGameIconsPlayed = this._renderGamesPlayed({
						games: games,
						exclude: excludedGamesPlayed,
						alignment: 'horizontal',
						displayGameName: false,
						region: this.gamesPlayedIconsRegion
					});

				}

				renderGamesPlayed = this._renderGamesPlayed({
					games: games,
					exclude: excludedGamesPlayed,
					alignment: gamesPlayedAlignment,
					displayGameName: displayGamesPlayedGameName,
					region: this.gamesPlayedRegion
				});

				// Add a horizontal rule if there is expanded data.
				var gamesPlayedDisplayed = renderGamesPlayed && renderGamesPlayed.displayed || false;
				if (renderAttributes && gamesPlayedDisplayed ||
					gamesPlayedDisplayed && renderGameIconsPlayed) {
					this._renderHorizontalDivider();
				}

				this.expandBtnHighlighted = renderGamesPlayed && renderGamesPlayed.highlighted || false;

				if (!this.autoExpand) {
					this.ui.content.addClass('kx-expandable');
				}

				if (this.expandable && this.expandBtnHighlighted && !this.autoExpand) {
					this._renderExpandBtn(this.expandBtnHighlighted);
				}
			}
		},
		onClicked: function () {
			if (this.namespace) {
				this.model.toggleSelected();
			}
		},
		selectedState: function () {
			this.$el.addClass('kx-selected');
		},
		deselectedState: function () {
			this.$el.removeClass('kx-selected');
		},
		setPresence: function (presence) {
			if (presence === 'available') {
				this.ui.presence.addClass('kx-online');
				this.ui.lastSeen.hide();
			} else {
				this.ui.presence.removeClass('kx-online');
				this.ui.lastSeen.show();
			}
		},
		templateHelpers: {
			prettyTitle: function () {
				return KXL.Common.Utils.StringUtils.prettySubString(
					this.user.username || this.user.alias,
					this.searchTerm,
					null,
					'kx-friend-search-highlight'
				);
			},
			lastSeen: function () {
				return this.user.lastSeen ? KXL.Common.Utils.DateUtils.fuzzytime2(this.user.lastSeen * 1000) : null;
			}
		},
		serializeData: function () {
			return {
				user: this.model.toJSON(),
				searchTerm: this.searchTerm || '',
				templateHelpers: this.templateHelpers
			};
		}
	});

	FriendCard.FriendRequestCTAView = KXL.Components.ItemView.extend({
		template: '#friendcard-friend-request-cta-template',
		className: 'kx-friend-request-buttons',
		events: {
			'click .kx-friend-request-accept-button': 'acceptClicked',
			'click .kx-friend-request-reject-button': 'rejectClicked',
			'click .kx-view-profile-button': 'profileClicked'
		},
		acceptClicked: function () {
			var self = this;
			self.$el.find('cta-button').prop('disabled', true);
			self.options.vent.trigger('friend:request:remove', self.model);
			KXL.request('user:friend:request:accept', this.model.get('requestId'), this.model.get('id')).then(function () {
				self.options.vent.trigger('friend:request:remove', self.model);
			}).always(function () {
				self.$el.find('cta-button').prop('disabled', false);
			});
		},
		rejectClicked: function () {
			var self = this;
			self.$el.find('cta-button').prop('disabled', true);
			KXL.request('user:friend:request:reject', this.model.get('requestId'), this.model.get('id')).then(function () {
				self.options.vent.trigger('friend:request:remove', self.model);
			}).always(function () {
				self.$el.find('cta-button').prop('disabled', false);
			});
		},
		profileClicked: function () {
			KXL.execute('profile:show', this.model.get('userId'));
		}
	});

	FriendCard.FriendRequestView = FriendCard.View.extend({
		className: 'kx-friend-card kx-friend-request',
		onRender: function () {
			this.ctaView = new FriendCard.FriendRequestCTAView({
				model: this.model,
				vent: this.options.vent
			});

			this._renderSubTitle({
				text: KXL.i18n.t('friends.panel.friendRequest')
			});

			FriendCard.View.prototype.onRender.call(this);
		}
	});

	FriendCard.SimpleView = FriendCard.View.extend({
		className: 'kx-friend-card kx-friend-card-simple',
		template: '#friendcard-simple-template',
		regions: {
			avatarRegion: '.kx-avatar'
		},
		triggers: {
			'click': 'selected:user'
		},
		events: {},
		onRender: function () {
			var self = this;
			var avatar = KXL.request('new:avatar:view',
				this.model.id,
				{
					size: 'small',
					avatarMenu: KXL.Components.Avatar.avatarMenu.ON,
					color: 'grey',
					avatarMenuFriend: this.userType && this.userType === 'friends',
					model: this.model,
					suppressDefaultClickAction: true
				}
			);

			this.listenTo(
				avatar,
				[
					'clicked',
					'friend-chat:clicked'
				].join(' '),
				function () {
					self.trigger('cta:clicked');
				}
			);

			this.avatarRegion.show(avatar);

			if (this.friend) {
				this.setPresence(this.friend.get('presence'));
				this.ui.friendIcon.show();
			}
		}
	});
});
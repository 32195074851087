KXL.module('HeaderApp', function(HeaderApp, KXL, Backbone, Marionette, $, _) {
	this.startWithParent = false;

	var headerView;

	var API = {
		createHeader: function () {
			return HeaderApp.Header.Controller.createHeader();
		},
		showPlayersOnline: function () {
			HeaderApp.Header.Controller.showPlayersOnline();
		},
		hidePlayersOnline: function () {
			HeaderApp.Header.Controller.hidePlayersOnline();
		},
		showHeader: function () {
			HeaderApp.Header.Controller.showHeader();
		},
		hideHeader: function () {
			HeaderApp.Header.Controller.hideHeader();
		},
		showBanner: function (bannerId, options) {
			return HeaderApp.Header.Controller.bannersController.showBanner(bannerId, options);
		},
		createBanner: function (options) {
			return HeaderApp.Header.Controller.bannersController.createBanner(options);
		},
		hideBanner: function (bannerId, remove, options) {
			KXL.atom.once('header', function () {
				HeaderApp.Header.Controller.bannersController
					.hideBanner(bannerId, remove, options);
			});
		},
		removeBanner: function (bannerId) {
			HeaderApp.Header.Controller.bannersController.removeBanner(bannerId);
		},
		getBanner: function (bannerId) {
			return HeaderApp.Header.Controller.bannersController.getBanner(bannerId);
		},
		showContextChangeDialog: function (currentContext, newContext) {
			return HeaderApp.Header.Controller.showContextChangeDialog(currentContext, newContext);
		}
	};
	KXL.on('start', function(id) {
		headerView = API.createHeader();
		headerView.on('show', function () {
			HeaderApp.Header.Controller.adjustAppContainers();
			KXL.atom.set('header', headerView);
		});
		
		KXL.vent.on('request:header:height:change:check', function (overrides) {
			HeaderApp.Header.Controller.adjustAppContainers(overrides);
		});
		KXL.appModel.on('change:sideBarState', HeaderApp.Header.Controller.adjustAppContainers);

		if (navigator.platform === "Win32") {

			// Listen for overlay (menu & dialog) overlay open closed events and keep track of open count
			KXL.vent.on('overlay:toggle', function(toggle) {
				var currentOverlayOpenCnt = KXL.atom.get('overlay:open:count');
				currentOverlayOpenCnt = currentOverlayOpenCnt || 0;
				// Add or Substract from our open overlay count
				currentOverlayOpenCnt = toggle ? (currentOverlayOpenCnt + 1) : (currentOverlayOpenCnt - 1);
				currentOverlayOpenCnt = Math.max(0, currentOverlayOpenCnt);
				KXL.atom.set('overlay:open:count', currentOverlayOpenCnt);
			});

			// Listen for overlay open count changes - toggle property that indicates that a overlay is open or closed
			KXL.atom.on('overlay:open:count', function (openCount) {
				if (openCount >= 1) {
					KXL.atom.set('is:overlay:open', true);
				} else {
					KXL.atom.set('is:overlay:open', false);
				}
			});

			//Listen for overlay open change events - trigger appropriate event to be communicated to game if
			// game is open once the xframe is ready.
			KXL.atom.on('is:overlay:open', function (isOverlayOpen) {
				if (KXL.atom.get('game:isopen')) {
					KXL.atom.once('game:xframe:ready', function () {
						if (isOverlayOpen) {
							KXL.vent.trigger('nav:overlay:open');
						} else {
							KXL.vent.trigger('nav:overlay:closed');
						}
					});
				}
			});
		}
		KXL.headerRegion.show(headerView);
	});
	KXL.addInitializer(function () {
		KXL.reqres.setHandlers({
			'banner:create': function(options) {
				return API.createBanner(options);
			},
			'banner:show': function(options) {
				return API.showBanner(options);
			},
			'banner:hide': function(bannerId, remove, options) {
				API.hideBanner(bannerId, remove, options);
			},
			'banner:remove': function(bannerId) {
				API.removeBanner(bannerId);
			},
			'banner:get': function(bannerId) {
				return API.getBanner(bannerId);
			},
			'header:context:change:dialog': function (currentContext, newContext) {
				return API.showContextChangeDialog(currentContext, newContext);
			}
		});
		KXL.commands.setHandlers({
			'playersonline:show': function () {
				API.showPlayersOnline();
			},
			'playersonline:hide': function () {
				API.hidePlayersOnline();
			},
			'header:show': function() {
				API.showHeader();
			},
			'header:hide': function() {
				API.hideHeader();
			},
		});
	});
});

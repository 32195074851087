KXL.module('CookieNoticeApp', function(CookieNoticeApp, KXL, Backbone, Marionette, $, _) {
	this.startWithParent = false;
	var API = {
		loadCookieNotice: function () {
			CookieNoticeApp.CookieNotice.Controller.loadCookieNotice();
		},
		hideCookieNotice: function () {
			CookieNoticeApp.CookieNotice.Controller.hideCookieNotice();
		}
	};
	KXL.on('start', function(id){
		API.loadCookieNotice();
	});
	KXL.commands.setHandlers({
		'cookie_notice:hide': function () {
			API.hideCookieNotice();
		}
	});
});

KXL.module('Chat.ChatApp.Views', function(Views, KXL, Backbone, Marionette, $, _) {
	Views.Conversation = Marionette.Layout.extend({
		messageInput: null,
		messageInputOpened: false,
		muzzledView: null,
		template: '#chat-conversation-template',
		regions: {
			editOptions: '.options-region',
			recipients: '.recipient-region',
			conversation: '.conversation-region',
			composeMessage: '.compose-message-region'
		},
		ui: {
			conversation: '.conversation-region',
			recipientInput: '.recipient-input',
			recipientRegion: '.recipient-region'
		},
		triggers: {
			'mousedown .recipient-input': 'chat:recipient:focus',
			'blur input': 'chat:recipient:blur'
		},
		prevHeight: 76,
		initialize: function (options) {
			if (!options.model) {
				throw "No conversation has been selected.";
			}
			if (options.recipients) {
				this.prePopulateRecipients = options.recipients;
			}
			this.isPrivate = options.isPrivate || false;
		},
		createTitle: function (title) {
			var self = this;
			if (this.recipients) {
				var chatTitle = new Views.ChatTitle({
					model: this.model,
					title: title
				});
				if (this.model.get('type') !== 'private') {
					chatTitle.on('clicked', function () {
						var recipientDeferreds = [];
						if (this.model.get('members').length) {
							this.model.get('members').each(function (user) {
								recipientDeferreds.push(KXL.request('user:entity', user.get('userId')));
							});
						}
						if (recipientDeferreds.length) {
							$.when.apply($, recipientDeferreds).then(function () {
								self.createRecipientInput(arguments);
							});
						}
					});
				}
				this.recipients.show(chatTitle);
				if (this.recipients.$el) {
					this.adjustConversationTopPosition(this.recipients.$el.outerHeight(true));
				}
			}
		},
		/**
		 * Create a list of user thumbnails show in the conversation header.
		 * @param  none
		 * @returns  none
		 */
		createThumbnails: function() {
			var self = this;
			var limitedMembers = new Backbone.Collection();
			limitedMembers.reset(this.model.get('members').first(10));
			if (this.recipients &&
				limitedMembers &&
				limitedMembers.length) {
				var recipientsListView = new Views.ChatRecipients({
					collection: limitedMembers
				});
				this.recipients.show(recipientsListView);
			}
		},
		editTitle: function () {
			var self = this;
			var chatEditTitle = new Views.EditChatTitle();
			chatEditTitle.on('submit', function(title) {
				// Show room title.
				self.createThumbnails();
				// Save room title
				KXL.request('get:chat:interface').setRoomTitle(self.model.get('id'), title);

				if (this.recipients.$el) {
					self.adjustConversationTopPosition(this.recipients.$el.outerHeight(true));
				}

			});
			this.recipients.show(chatEditTitle);
			if (this.recipients.$el) {
				this.adjustConversationTopPosition(this.recipients.$el.outerHeight(true));
			}
		},
		createRecipientInput: function (recipients) {
			var self = this;

			if (recipients && recipients.length) {
				// Make sure the currentUser is not in the list
				recipients = _.filter(recipients, function (user) {
					return user.id !== KXL.request('get:current:user').id;
				});
			}
			if (self.model.get('type') !== 'private') {
				var dismissOnOutClick = false;
				if (!self.model.isNew() && recipients && recipients.length) {
					dismissOnOutClick = true;
				}

				self.recipientInput = KXL.request(
					'new:recipient:input:view',
					{
						focus: !self.isPrivate,
						recipients: recipients,
						// Dismiss on out click only when there are recipients otherwise stay open
						dismissOnOutClick: dismissOnOutClick,
						placeholderText: KXL.i18n.t('chat.conversation.recipient.placeholder'),
						prePlaceholderText: KXL.i18n.t('chat.conversation.recipient.to.placeholder')
					}
				);

				self.ui.recipientInput = self.recipientInput.$el;

				self.listenTo(self.recipients, 'show', function(view) {
					var adjustedTop = (self.recipients.$el) ? self.recipients.$el.outerHeight(true) : 0;
					self.adjustConversationTopPosition(adjustedTop);
				});

				function updateMessageInputEnabledState() {
					if (self.recipientInput.recipientList.length) {
						self.messageInput.enable();
						if (self.isPrivate) {
							self.messageInput.focus();
						}
					} else {
						self.messageInput.disable();
					}
				}

				self.recipientInput.on('recipient:add', function (user) {
					self.trigger('recipient:add', user);
					updateMessageInputEnabledState();
					var adjustedTop = (self.recipients.$el) ? self.recipients.$el.outerHeight(true) : 0;
					self.adjustConversationTopPosition(adjustedTop + 10);
				});
				self.recipientInput.on('recipient:remove', function (user) {
					self.trigger('recipient:remove', user);
					updateMessageInputEnabledState();
				});
				self.recipientInput.on('recipient:input:update', function() {
					var adjustedTop = (self.recipients.$el) ? self.recipients.$el.outerHeight(true) : 0;
					self.adjustConversationTopPosition(adjustedTop + 10);
				});

				self.recipientInput.on('dismissed', function (user) {
					var adjustedTop = 0;

					// If this is a group chat
					if (self.model.get('members').length > 2) {
						adjustedTop = (self.ui.recipientRegion && self.editOptions.$el) ? self.editOptions.$el.outerHeight(true) : 0;
						self.createThumbnails();
					} else {
						adjustedTop = (self.recipients.$el) ? self.recipients.$el.outerHeight(true) : 0;
					}

					adjustedTop = (adjustedTop < 41) ? 41 : adjustedTop;

					if (self.editOptions.$el && self.model.get('members').length > 10) {
						self.editOptions.$el.show();
						self.adjustConversationTopPosition(adjustedTop);
					} else if (self.editOptions.$el) {
						self.adjustConversationTopPosition(adjustedTop);
					}
					KXL.Chat.ChatApp.trigger('footer:hide');
				});
				// If the user clicks around quickly, the recipients region will be gone, sanity it.
				if (self.recipients) {
					self.recipients.show(self.recipientInput);
					updateMessageInputEnabledState();
				}
			}

		},
		adjustConversationTopPosition: function (coord) {
			if (this.ui.conversation && this.ui.conversation.css) {
				this.ui.conversation.css('top', coord + 'px');
			}
		},
		adjustConversationBotPosition: function(coord) {
			if (this.ui.conversation && this.ui.conversation.css) {
				this.ui.conversation.css('bottom', coord + 'px');
			}
		},

		_renderMuzzledView: function (muzzledOptions) {
			var self = this;
			self.muzzledView = KXL.request('new:chat:muzzled:view', muzzledOptions);
			self.composeMessage.show(self.muzzledView);
		},

		_renderMessageInput: function () {
			var self = this;

			var focusMessageInput = self.isPrivate ||
				(self.model.get('type') === 'default' || self.model.get('members').length);

			var messageInput = self.messageInput = KXL.request(
				'new:messageinput:view',
				{
					focus: focusMessageInput,
					// Disable chat box until recipients picked (except for default groups
					// where it's always enabled).
					disabled: self.model.get('type') !== 'default' && !self.model.get('members').length,
					startMinimized: true,
					showLoader: false,
					submitBtnStyle: 'kx-alt-chat-ctas',
					inputHeight: 90,
					disableAutosize: true

				}
			);

			messageInput.on('submit', function (message) {
				if (_.isEmpty(message)) {
					return;
				}
				if (self.model.id) {
					self.trigger('send:message', message);
				} else {
					if (self.recipientInput && self.recipientInput.recipientList.length) {
						self.trigger('start:thread', message, self.recipientInput.recipientList);
					}
				}
				// Can only reset and focus if the view exists
				// In the case that a new thread was just started, the messageInput is replaced entirely so it won't
				// exist when it gets to this point
				if (!this.isClosed) {
					this.reset();
					this.focus();
				}
			});

			/**
			 * messageInput handler that fires 'after' the input is expanded or contracted.
			 */
			self.listenTo(messageInput, 'all', function (ev) {
				if (ev === 'expanded:changed' || ev === 'resized' || ev === 'click') {
					setTimeout(function () {
						if (self.composeMessage && self.composeMessage.$el.height()) {
							self.composeMessage.ensureEl();
							if (ev === 'click') {
								self.composeMessage.currentView.ui.messageInputTextarea.css('height', 90 + 'px');
							}
							self.adjustConversationBotPosition(self.composeMessage.$el.height());

							var diffHeight = _.max([(self.composeMessage.$el.height() - self.prevHeight), 0]);

							// Check if we are expanded, then update scroll position to a positive number, which
							// pushes shit up so its not covered.
							if (self.composeMessage.currentView.expanded &&
								self.messageInputOpened === false &&
								ev === 'click') {
								self.conversation.currentView.updateThreadScrollPosition({diffUserHeight: diffHeight});
								self.messageInputOpened = true;
							}
						}
					}, 0);
				}
			});


			/**
			 * messageInput handler that fires 'before' the input is contracted.
			 * This will return to the scroll to it's previous position.
			 * self.prevHeight: Hard coded number that represents height of message input when contracted.
			 */
			self.listenTo(messageInput, 'minimized', function() {
				var diffHeight = 0 - _.max([(self.composeMessage.$el.height() - self.prevHeight), 0]);
				self.composeMessage.currentView.ui.messageInputTextarea.css('height', '');
				self.conversation.currentView.updateThreadScrollPosition({diffUserHeight: diffHeight});
				self.messageInputOpened = false;
			});

			self.composeMessage.show(messageInput);

		},
		_renderMessageList: function () {
			var self = this;
			var messageList = new Views.MessageList({
				collection: this.model.get('messages'),
				type: this.model.get('type')
			});
			this.conversation.show(messageList);
		},
		_renderConversationOptions: function () {
			var
				self = this,
				conversationType = this.model.get('type'),
				editOptionButtonStyle = 'kx-alt15'
			;

			if (conversationType === 'group' || conversationType === 'private') {
				var optionItems = [
					{
						label: KXL.i18n.t(conversationType === 'group' ? 'chat.conversation.option.add.people' : 'chat.conversation.option.start.group'),
						itemEvent: 'conversation:add:people'
					}
				];

				if (conversationType === 'group') {
					optionItems.push({
						label: KXL.i18n.t('chat.conversation.option.rename'),
						itemEvent: 'conversation:rename:room'
					},
					{
						label: KXL.i18n.t('chat.conversation.option.leave'),
							itemEvent: 'conversation:leave:room'
					});
					editOptionButtonStyle = 'kx-alt15-group-chat';
				}

				this.conversationOptions = new Views.ViewAllRecipientsView();

				/**
				 * Module event listener to listen for conversation settings click, then trigger the event to open
				 * the edit recipients view.
				 */
				this.listenTo(
					KXL.Chat.ChatApp,
					'chat:conversation:settings:clicked chat:conversation:view:all:recipients:click',
					function(view) {
						self.conversationOptions.trigger('conversation:add:people');
				});

				/**
				 * Module event listener for leaving a chat room click.
				 */
				this.listenTo(
					KXL.Chat.ChatApp,
					'chat:conversation:leave:room:clicked',
					function(view) {
						self.conversationOptions.trigger('conversation:leave:room');
					}
				)

				this.conversationOptions.on('conversation:add:people', function () {
					var recipientDeferreds = [];
					if (self.model.get('members').length) {
						self.model.get('members').each(function (user) {
							recipientDeferreds.push(KXL.request('user:entity', user.get('userId')));
						});
					}
					if (recipientDeferreds.length) {
						$.when.apply($, recipientDeferreds).then(function () {
							if (conversationType === 'private') {
								KXL.execute('chat:start:conversation', arguments);
							} else {
								self.createRecipientInput(arguments);
								if (self.editOptions.$el) {
									self.editOptions.$el.hide();
								}
							}
						});
					}
					KXL.Chat.ChatApp.trigger('footer:show');
				});

				this.conversationOptions.on('conversation:leave:room', function () {
					KXL.execute('chat:leave:room', self.model);
					self.trigger('leave:room', self.model);
					KXL.Chat.ChatApp.trigger('leave:room:clicked');
				});

				this.conversationOptions.on('conversation:rename:room', function () {
					self.editTitle();
				});

				this.listenTo(this.editOptions, 'show', function() {
					if (this.model.get('members').length > 10) {
						this.editOptions.$el.show();
					} else {
						this.editOptions.$el.hide();
					}
				});

				this.editOptions.show(this.conversationOptions);
			}
		},
		onRender: function () {
			var self = this;

			self.listenTo(self, 'chat:recipient:focus', function(ev, view) {
				if (!self.ui.recipientInput.hasClass('focused')) {
					self.ui.recipientInput.addClass('focused');
				}
			});
			self.listenTo(self, 'chat:recipient:blur', function(ev, view) {
				if (self.ui.recipientInput.hasClass('focused')) {
					self.ui.recipientInput.removeClass('focused');
				}
			});
			self.listenTo(KXL.Chat.ChatApp, 'chat:conversation:footer:recipient:cancel', function(view) {
				if (self.model.has('members') && self.model.get('members').length > 0) {
					self.recipientInput.trigger('dismissed');
				} else {
					KXL.Chat.ChatApp.trigger('footer:hide');
					KXL.Chat.ChatApp.trigger('breadcrumb:hide');
				}
			});

			self.listenTo(KXL.Chat.ChatApp, 'chat:conversation:footer:recipient:done', function(view) {
				self.recipientInput.trigger('dismissed');
			});

			self.listenTo(KXL.Chat.ChatApp, 'chat:recipient:hide', function() {
				self.ui.recipientRegion.hide();
				var adjustedTop = (self.ui.recipientRegion) ? self.ui.recipientRegion.outerHeight(true) : 0;
				self.adjustConversationTopPosition(0);
			});

			self.listenTo(KXL.Chat.ChatApp, 'chat:recipient:show', function() {
				self.ui.recipientRegion.show();
				var adjustedTop = (self.ui.recipientRegion) ? self.ui.recipientRegion.outerHeight(true) : 0;
				adjustedTop = (adjustedTop < 41) ? 41 : adjustedTop;
				self.adjustConversationTopPosition(adjustedTop);
			});

			this._renderConversationOptions();
			this._renderMessageList();

			if (KXL.currentUser &&
				KXL.currentUser.get('chatMuzzle') &&
				KXL.currentUser.get('chatMuzzle') !== false)
			{
				this._renderMuzzledView(KXL.currentUser.get('chatMuzzle'));
			} else {
				this._renderMessageInput();
			}

			if (this.model.get('type') !== 'default') {
				var isNewThread = this.model.isNew();
				var recipients = [];
				var recipientDeferreds = [];
				if (this.model.get('members').length) {
					this.model.get('members').each(function (user) {
						recipientDeferreds.push(KXL.request('user:entity', user.get('userId')));
					});
				} else if (this.prePopulateRecipients) {
					// This is the case where somebody passed in a list of recipient(s) to start a new thread with
					// This list can be user objects or userId's
					_.each(this.prePopulateRecipients, function (user) {
						if (_.isObject(user)) {
							recipients.push(user);
						} else if (_.isString(user)) {
							recipientDeferreds.push(KXL.request('user:entity', user));
						} else {
							throw "Unrecognized user type to add to recipient list"
						}
					});
				}
				if (recipientDeferreds.length) {
					$.when.apply($, recipientDeferreds).then(function () {
						if (isNewThread) {

							self.createRecipientInput(arguments);
						} else {
							self.createThumbnails();
						}
					});
				} else if (recipients.length) {
					if (isNewThread) {
						this.createRecipientInput(recipients);
					} else {
						self.createThumbnails();
					}
				} else {
					this.createRecipientInput(null);
				}
			}
		}
	});

	Views.MessageBody = Marionette.ItemView.extend({
		template: '#chat-message-body-template',
		className: 'kx-message-body-inner',
		settingsModel: null,
		type: null,

		initialize: function (options) {
			this.settingsModel = KXL.request('current:user:settings:entity');
			this.type = options.type;
		},

		serializeData: function () {
			var data = this.model.toJSON();
			data.showUsernames = true;
			if (KXL.currentUser && this.settingsModel) {
				// Enable the profanity filter if configured to do so
				if (this.settingsModel.get('filterProfanity') === true) {
					data.body = KXL.Common.Utils.StringUtils.filterMessage(data.body);
				}
				data.messageDate = getChatTimestamp(data.timestamp);
				data.showTimestamps = true;
			}
			else {
				data.showTimestamps = false;
			}
			// Create clickable links.
			data.body = new Handlebars.SafeString($.fn.urlize(Handlebars.Utils.escapeExpression(data.body)));
			function getChatTimestamp(timestamp) {
				return KXL.Common.Utils.DateUtils.fuzzytime2(timestamp);
			}

			return data;
		}
	});

	Views.Message = Marionette.Layout.extend({
		template: '#chat-message-template',
		className: 'kx-message-body-wrapper',
		regions: {
			avatar: '.avatar-region',
			body: '.message-body'
		},
		avatarView: null,
		messageBodyView: null,

		initialize: function (options) {
			var opts = _.extend({}, options);
			var userModel = new KXL.Entities.User({
				id: this.model.get(opts.userIdModelAttr),
				username: this.model.get('username')
			});

			var avatarOpts =
				_.defaults(
					{},
					opts.avatarOptions,
					{
						model: userModel,
						size: 'small',
						color: 'grey'
					}
				);

			if (KXL.currentUser) {
				avatarOpts = _.extend(
					avatarOpts,
					{
						avatarMenu: KXL.Components.Avatar.avatarMenu.ON,
						menuPosition: 'lock-bottom-right-avatar'
					}
				);
			}

			this.avatarView = KXL.request(
				'new:avatar:view',
				this.model.get(this.options.userIdModelAttr),
				avatarOpts
			);
			this.messageBodyView = new Views.MessageBody({ model: this.model, type: opts.type });
		},
		serializeData: function () {
			return {
				'title': KXL.Common.Utils.DateUtils.fuzzytime2(this.model.get('timestamp'))
			};
		},
		/**
		 * Checks to see if the previous message in our collection has the same id as our current model,
		 * and if that the new message's timestamp is greater than 1 minute from the last message sent,
		 * if the previous sender is the same as the current sender.
		 * @param msgModel model of current message
		 * @returns {boolean}
		 */
		isNewMessageGroup: function(msgModel) {
			if (msgModel.collection.length <= 1) {
				return false;
			}

			var prevModel = msgModel.collection.at(msgModel.collection.indexOf(msgModel) - 1);
			if (!prevModel) {
				return false;
			}

			var currModelRecipId = msgModel.get('userId');
			var prevModelRecipId = prevModel.get('userId');
			if (currModelRecipId !== prevModelRecipId) {
				return false;
			}

			var currentModelTimestamp = msgModel.get('timestamp');
			var prevModelTimestamp = prevModel.get('timestamp');
			if ((currentModelTimestamp - prevModelTimestamp) > (60 * 1000)) {
				return false;
			}

			return true;
		},
		onRender: function () {
			this.avatar.show(this.avatarView);
			this.body.show(this.messageBodyView);
			if (KXL.currentUser &&
				KXL.currentUser.id == this.model.get(this.options.userIdModelAttr)) {
				this.$el.addClass('kx-current-user');
			}

			if (this.isNewMessageGroup(this.model)) {
				this.$el.addClass('kx-message-group');
			}
		}
	});

	Views.MessageList = Marionette.CollectionView.extend({
		SCROLL_TO_BOTTOM_THRESHOLD: 5,
		threadAtBottomScroll: true,
		userScrollPos: null,
		className: 'kx-chat-message-list',
		itemView: Views.Message,
		debouncedUpdateThreadScrollPosition: undefined,
		itemViewOptions: function (model, index) {
			return {
				type: this.options.type,
				userIdModelAttr: this.options.userIdModelAttr,
				avatarOptions: this.options.avatarOptions
			}
		},
		settingsModel: null,

		initialize: function (options) {
			var
				self = this,
				defaults = {
					userIdModelAttr: 'userId',
					type: null
				}
			;
			this.debouncedUpdateThreadScrollPosition =
				_.debounce(this.updateThreadScrollPosition.bind(this), 100);
			this.options = _.defaults({}, options, defaults);
		},
		saveThreadScrollState: function () {
			if (!this.$el.is(':visible')) {
				// Dom node scroll state values are erroneous when
				// the node isn't visible so ignore.
				return;
			}
			var
				scrollHeight = this.$el.prop('scrollHeight'),
				scrollTop = this.$el.prop('scrollTop'),
				outerHeight = this.$el.outerHeight(true),
				diff = (scrollHeight - scrollTop)
			;
			this.threadAtBottomScroll = diff <= (outerHeight + this.SCROLL_TO_BOTTOM_THRESHOLD);
			if (this.threadAtBottomScroll) {
				this.userScrollPos = null;
			} else {
				this.userScrollPos = scrollTop;
			}
		},
		scrollThreadToBottom: function () {
			try {
				this.$el.prop('scrollTop', (this.$el.prop('scrollHeight')));
			} catch(err) {
				//Nothing to do here, just handling IE9 Unspecific error gracefully.
			}
			this.threadAtBottomScroll = true;
			this.userScrollPos = null;
		},
		updateThreadScrollPosition: function (options) {
			if (this.threadAtBottomScroll) {
				this.scrollThreadToBottom();
			} else if (this.userScrollPos) {
				var adjustedScrollPos = this.userScrollPos;
				if (options && options.diffUserHeight && $.isNumeric(options.diffUserHeight)) {
					adjustedScrollPos = adjustedScrollPos + options.diffUserHeight;
				}
				this.$el.prop('scrollTop', adjustedScrollPos);
			}
		},
		onBeforeItemAdded: function () {
			this.saveThreadScrollState();
		},
		onAfterItemAdded: function (view) {
			var currentUser = KXL.request('get:current:user');
			if (currentUser && view.model.get(this.options.userIdModelAttr) === currentUser.id) {
				// Always scroll to the bottom for a message by the
				// the current user.
				this.scrollThreadToBottom();
			} else {
				this.updateThreadScrollPosition();
			}
		},
		onClose: function () {
			$(window).off('resize', this.debouncedUpdateThreadScrollPosition);
			this.$el.off('scroll');
		},
		onRender: function () {
			var self = this;
			_.delay(function () {
				self.scrollThreadToBottom();
			}, 0);
			self.$el.on('scroll', self.saveThreadScrollState.bind(this));
			$(window).on('resize', self.debouncedUpdateThreadScrollPosition);
		}
	});

	Views.ConversationInit = Marionette.ItemView.extend({
		template: '#chat-conversation-init-template',
		className: 'chat-thread-init'
	});

	Views.ConversationInitFailed = Marionette.ItemView.extend({
		template: '#chat-conversation-init-failed-template',
		className: 'chat-thread-init chat-thread-init-failed'
	});

	Views.ChatTitle = Marionette.ItemView.extend({
		template: '#chat-title-template',
		className: function () {
			var className = 'chat-title';
			if (this.model.get('type') === 'private') {
				className += ' private-chat-title';
			}
			return className;
		},
		triggers: {
			'click': 'clicked'
		},
		serializeData: function () {
			var threadTitle = '';
			var membersCol = this.model.get('members');
			var members;
			var currentUser = KXL.request('get:current:user');
			if (this.options.title) {
				threadTitle = this.options.title;
			} else if(this.model.get('title')) {
				threadTitle = this.model.get('title');
			} else {
				if (this.model.get('type') !== 'private') {
					members = membersCol.models;
				} else {
					members = membersCol.filter(function (member) {
						return member.id != currentUser.id;
					});
				}
				_.each(members, function (member) {
					if (!threadTitle.length) {
						threadTitle = member.get('username');
					} else {
						threadTitle += (', ' + member.get('username'));
					}
				});
			}
			return {
				title: threadTitle
			}
		}
	});

	Views.ChatRecipient = Marionette.Layout.extend({
		template: '#chat-recipient-thumbnail-template',
		tagName: 'li',
		className: 'kx-chat-recipient-mini-avatar',
		regions: {
			avatarRegion: '.recipient-avatar-region'
		},
		initialize: function(options) {
			var opts = _.extend({}, options);
			var userModel = new KXL.Entities.User({
				id: this.model.get('userId'),
				username: this.model.get('username')
			});

			var avatarOpts =
				_.defaults(
					{},
					opts.avatarOptions,
					{
						model: userModel,
						size: 'xsmall',
						color: 'grey',
						bgMode: true,
						borderColors: 'none',
						avatarMenu: KXL.Components.Avatar.avatarMenu.ON,
						menuPosition: 'lock-bottom-right-avatar'
					}
				);

			this.avatarView = KXL.request(
				'new:avatar:view',
				this.model.get('userId'),
				avatarOpts
			);
		},
		onRender: function() {
			this.avatarRegion.show(this.avatarView);
		}
	});

	Views.ChatRecipients = Marionette.CollectionView.extend({
		tagName: 'ul',
		className: 'chat-recipients-thumbnails',
		itemView: Views.ChatRecipient
	});

	Views.ViewAllRecipientsView = Marionette.ItemView.extend({
		template: '#chat-conversation-recipient-view-all-template',
		tagName: 'span',
		className: 'kx-recipient-all-link',
		triggers: {
			'click': 'clicked'
		},
		onClicked: function(view) {
			KXL.Chat.ChatApp.trigger('chat:conversation:view:all:recipients:click');
		}
	});

	Views.EditChatTitle = Marionette.ItemView.extend({
		template: '#chat-edit-title-template',
		className: 'chat-edit-title',
		events: {
			"keydown .room-name-input": "inputKeyDown"
		},
		ui: {
			input: '.room-name-input'
		},
		inputKeyDown: function (e) {
			if (e.keyCode === 13) {
				// If enter key is pressed, trigger submit event.
				if (this.ui.input.val() !== '') {
					var roomTitle = this.ui.input.val();
					this.ui.input.val('');
					this.trigger('submit',roomTitle);
				}
			}
		}
	});
});

KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {
	var Role = Entities.Model.extend({
		idAttribute: 'roleId'
	});
	Entities.UserRoles = Entities.Collection.extend({
		idAttribute: 'roleId',
		url: function () {
			return KXL.config.API_URL + '/users/' + this.userId + '/roles';
		},
		initialize: function (models, options) {
			this.userId = options.userId;
		},
		model: Role
	});
});

KXL.module('LandingPageV2App', function (LandingPageV2App, KXL, Backbone, Marionette, $, _) {
    LandingPageV2App.Router = Marionette.AppRouter.extend({
		appRoutes: {
			'game/battlepiratesbrawl(/)': 'showLandingPageV2',
            'game/battlepiratesbrawl/home(/)': 'showLandingPageV2',
            'game/battlepiratesbrawl/play(/)': 'showLandingPageV2'
		}
    });

	var API = {
        /**
         * Initialize the landing page
         * 
         * @param {Object} gameUrlName The url name of the selected game
         */
        showLandingPageV2: function(gameUrlName) {
            var self = this;
            
            gameUrlName = typeof gameUrlName === 'undefined' ? 'battlepiratesbrawl' : gameUrlName;

            if (gameUrlName == 'warcommanderrogueassault') {
				window.location = 'https://warcommander.com';
                return;
            }

            if (!KXL.staticGames.findWhere({ urlName: gameUrlName })) {
                KXL.execute('home:show');
                return;
            }

            // Check if the landing page is already displayed for the selected game
            var pages = KXL.appModel.get('pages');
            var LandingPageV2 = pages.findWhere({id: 'landing'});
            var sameGame = this.game && this.game.get('urlName') === gameUrlName;
            if (LandingPageV2.selected && sameGame) {
                return;
            }

            this.game = KXL.staticGames.findWhere({ urlName: gameUrlName });

            // This is the landing page for the game that is currently being played
            if (KXL.context && KXL.context.get('urlName') === gameUrlName) {
                this.loadLandingPageV2();
                return;
            }

            // This is the landing page for a different game, so confirm that user 
            // wants to leave their current game session
            KXL.asyncRequest('context:change', this.game).then(function () {
                self.loadLandingPageV2();
            });
        },

        /**
         * Load the landing page. This will set the navigation URL, display the 
         * landing page region, update the navigation bar, and load the landing
         * page view
         */
        loadLandingPageV2: function() {
            var self = this;
            KXL.navigate('/game/' + this.game.get('urlName') + '/home');
            KXL.execute('page:show', 'landingv2');

            // Display the game specific nav items
            KXL.GamesApp.Nav.Controller.getNavView(this.game, 'home');

            KXL.asyncRequest('async:load:module', 'landing_page_v2').then(function() {
                self.controller = new LandingPageV2App.LandingPageV2.Controller(self.game);
                self.controller.showLandingPageV2(self.game);
            });
        },

        /**
         * A player clicked play now, either display an authentication modal or load the game
         */
        onPlayNowClick: function(game) {
            if (!KXL.currentUser) {
                this.showAuthModal(game.get('urlName'));
                return;
            }

            KXL.execute('game:play:load', game.get('urlName'));
        }
    };

	KXL.addInitializer(function () {
		new LandingPageV2App.Router({
			controller: API
        });
    });

    KXL.commands.setHandlers({
		'landing_page_v2:show': function(gameUrlName) {
            API.showLandingPageV2(gameUrlName);
        },
        'landing_page_v2:play_now:clicked': function(game) {
            gameUrlName = game.get('urlName');
            KXL.asyncRequest('async:load:module', 'common/components/sms_modal').then(function() {
				KXL.request('show:dialog', KXL.request('new:sms_modal:view', gameUrlName));
			});
        }
    });

    KXL.reqres.setHandlers({
		'get:landing_page_v2:game_url': function() {
			return API.game.get('urlName')
        }
    });
});

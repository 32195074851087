KXL.module('GamesApp.Alliance', function(Alliance, KXL, Backbone, Marionette, $, _) {

	Alliance.View = Marionette.Layout.extend({
		template: '#kx-alliance-layout-template',
		className: 'kx-alliance',
		regions: {
			topRegion: ".kx-alliance-region-top",
			leaderRegion:".kx-alliance-region-leader",
			membersRegion:".kx-alliance-region-members"
		}
	});

	Alliance.EmptyView = Marionette.Layout.extend({
		template: '#kx-alliance-empty-template',
		className: 'kx-alliance-empty-template'
	});

	Alliance.AllianceTopView = Marionette.ItemView.extend({
		template: '#kx-alliance-top-template',
		className: 'kx-top',
		tagName: 'div',
		modelEvents: {
			change: 'render'
		}
	});

	Alliance.AllianceLeaderView = Marionette.Layout.extend({
		template: '#kx-alliance-leader-template',
		regions: {
			leaderAvatar: '.kx-leader-avatar'
		},
		modelEvents: {
			'change': 'render'
		},
		onRender: function () {
			var leader = this.model.get('leader');
			var leaderId = leader ? leader.userId : '';
			var profile = leader ? leader.profile : '';
			var alias = profile ? profile.alias : '';

			this.leaderAvatar.show(
				KXL.request(
					'new:avatar:view',
					leaderId,
					{
						model: new KXL.Entities.User({ id: leaderId, username: alias }),
						size: 'medium',
						color: 'grey',
						avatarMenu: KXL.Components.Avatar.avatarMenu.ON
					}
				)
			);
		}
	});

	Alliance.MemberItemView = Marionette.Layout.extend({
		template: '#kx-member-template',
		tagName: 'li',
		className: 'kx-member',
		regions: {
			memberAvatar: '.kx-member-avatar'
		},
		onRender: function () {
			var userId = this.model.get('userId');
			var profile = this.model.get('profile');
			var alias = profile ? profile.alias : '';

			this.memberAvatar.show(
				KXL.request(
					'new:avatar:view',
					this.model.get('userId'),
					{
						model: new KXL.Entities.User({ id: userId, username: alias }),
						size: 'medium',
						color: 'grey',
						avatarMenu: KXL.Components.Avatar.avatarMenu.ON
					}
				)
			);
		}
	});

	Alliance.MembersListView = Marionette.CompositeView.extend({
		template: '#kx-alliance-members-layout-template',
		itemViewContainer: 'ul',
		itemView: Alliance.MemberItemView,
		collectionEvents: {
			'add remove reset': 'updateCount'
		},
		ui: {
			memberCount: '.kx-member-count'
		},
		updateCount: function () {
			this.ui.memberCount.html(this.collection.length);
		}
	});
});
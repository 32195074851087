/* globals KXL */
KXL.module('InboxApp', function (InboxApp, KXL, Backbone, Marionette, $, _) {
	InboxApp.Router = Marionette.AppRouter.extend({
		appRoutes: {
			'inbox(/)': 'showInbox',
			'inbox(/:threadId)(/)': 'showThread'
		}
	});

	var API = {
		showInbox: function () {
			KXL.execute('page:show', 'inbox');
			KXL.navigate('/inbox');
			InboxApp.Controller.showInbox();
		},
		showThread: function (threadId, isFocused, fromPanel) {
			KXL.execute('page:show', 'inbox');
			var url = '/inbox';
			var isFromPanel = fromPanel || false;
			if (threadId) {
				url += '/' + threadId;
			}
			KXL.navigate(url);
			InboxApp.Controller.showThread(threadId, isFocused, isFromPanel);
		},
		startThread: function (recipient) {
			KXL.execute('page:show', 'inbox');
			KXL.navigate('/inbox/compose');
			InboxApp.Controller.startThread(recipient);
		},
		markThreadRead: function (threadId) {
			InboxApp.Controller.markThreadRead(threadId);
		},
		markThreadUnread: function (threadId) {
			InboxApp.Controller.markThreadUnread(threadId);
		},
		deleteThread: function (threadId) {
			InboxApp.Controller.deleteThread(threadId);
		},
		getPanelView: function() {
			return InboxApp.Controller.getInboxPanelView();
		}
	};

	KXL.addInitializer(function () {
		new InboxApp.Router({
			controller: API
		});
		KXL.reqres.setHandler('inbox:panel:get:view',
			function (options) {
				return API.getPanelView();
			}
		);
	});

	KXL.commands.setHandlers({
		'inbox:show': function () {
			API.showInbox();
		},
		'inbox:show:thread': function (threadId, isFocused) {
			API.showThread(threadId, isFocused);
		},
		'inbox:show:thread:from:panel': function (threadId, isFocused) {
			API.showThread(threadId, isFocused, true);
		},
		'inbox:thread:markread': function (view) {
			API.markThreadRead(view);
		},
		'inbox:thread:markunread': function (view) {
			API.markThreadUnread(view);
		},
		'inbox:thread:delete': function (view) {
			API.deleteThread(view);
		}
	});
});

KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {
	Entities.Note = Entities.Model.extend({
		defaults: {
			from: '',
			to: '',
			text: '',
			category: 'post'
		},
		url: function () {
			return KXL.config.API_URL + '/notes/';
		}
	});

	var API = {
		saveNote: function(from, to, text) {
			var note = new KXL.Entities.Note({
				from: from,
				to: to,
				text: _.escape(text),
				appId: KXL.config.APP_ID.toString()
			});
			var deferred = note.save();
			deferred._model = note;
			return deferred;
		},
		hideNote: function (noteId) {
			var deferred = $.Deferred();
			$.ajax({
				type: 'PATCH',
				url: KXL.config.API_URL + '/notes/' + noteId,
				dataType: 'json',
				contentType: 'application/json',
				data: JSON.stringify({isHidden: true})
			}).done(function(data) {
				deferred.resolve(data);
			}).fail(function(error) {
				deferred.resolve(error);
			});
			return deferred.promise();
		}
	};

	KXL.reqres.setHandler('save:note', function (from, to, text) {
		return API.saveNote(from, to, text);
	});

	KXL.reqres.setHandler('hide:note', function (noteId) {
		return API.hideNote(noteId);
	});

});

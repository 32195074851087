KXL.module('AuthApp.Login', function(Login, KXL, Backbone, Marionette, $, _) {
	Login.LoginModel = KXL.Entities.Model.extend({
		defaults: function () {
			return {
				username: null,
				password: null,
				state: Login.LoginModel.NOT_AUTH,
				stateDetails: ''
			};
		},
		validation: function () {
			return {
				username: {
					required: true,
					msg: KXL.i18n.t('auth.login.validation.required.username')
				},
				password: {
					required: true,
					msg: KXL.i18n.t('auth.login.validation.required.password')
				}
			};
		}
	});

	Login.LoginModel.NOT_AUTH = 'Not Authenticated';
	Login.LoginModel.PENDING = 'Pending Authentication';
	Login.LoginModel.SUCCESS = 'Authentication Success';
	Login.LoginModel.FAIL = 'Authentication Failure';
	Login.LoginModel.UNKNOWN = 'Authentication Unknown';
	Login.LoginModel.VALIDATION_ERRORS = 'Validation Errors';

	var Controller = Marionette.Controller.extend({
		getLogin: function (options) {
			var
				self = this,
				loginModel = new Login.LoginModel(),
				opts = _.extend({}, options)
			;

			opts = _.extend(opts, { model: loginModel });
			var loginView = new Login.View(opts);

			this.listenTo(loginView, 'fb:api:authenticated', function (accessToken) {
				KXL.request('fblogin:submit', accessToken, { trackControl: 'dialog', trackSource: 'login' }).done(function (user, isNew) {
					loginView.trigger('fb:authenticated', accessToken, user, isNew);
				}).fail(function (error) {
					if (error instanceof KXL.Errors.EmailConflictError) {
						loginView.setSocialErrorMsg(
							KXL.i18n.t(
								'auth.loginEmailConflictError',
								{ email: error.detail.email }
							)
						);
					} else {
						loginView.setSocialErrorMsg(error.message);
					}
				});
			});

			loginView.listenTo(KXL, 'google:api:authenticated', function (accessToken) {
				KXL.request('googlelogin:submit', accessToken, { trackControl: 'dialog', trackSource: 'login' }).done(function (user, isNew) {
					loginView.trigger('google:authenticated', accessToken, user, isNew);
				}).fail(function (error) {
					loginView.setSocialErrorMsg(error.message);
				});
			});
				
			loginView.listenTo(KXL, 'google:api:error', function (errorMessage) {
				loginView.setSocialErrorMsg(errorMessage);
			});

			this.listenTo(loginView, 'steam:api:authenticated', function (authPromise) {
				authPromise.done(function () {
					loginView.trigger('steam:authenticated');
				}).fail(function (error) {
					var errorMessage;
					switch (error.error) {
						case 'BANNED':
							KXL.execute('show:banned:dialog', error.data);
							break;
						case 'FAILED_FRAUD_CHECK':
							errorMessage = new KXL.Errors.IOvationBlockedError().message;
							break;
						case 'NO_DEVICE_FINGERPRINT':
							errorMessage = new KXL.Errors.DeviceFingerprintError().message;
							break;
						default:
							errorMessage = new KXL.Errors.UnexpectedError().message;
					}
					loginView.setSocialErrorMsg(errorMessage);
				});
			});

			this.listenTo(loginView, 'login:submit', function(loginModel) {

				if (loginModel.get('state') === Login.LoginModel.PENDING ||
					loginModel.get('state') === Login.LoginModel.SUCCESS)
				{
					return;
				}

				loginModel.set('state', Login.LoginModel.PENDING);
				var errors = loginModel.validate();
				var isValid = loginModel.isValid();
				if (isValid) {
					var promise = KXL.request(
						'login:submit',
						loginModel.get('username'),
						loginModel.get('password')
					);
					$.when(promise).done(
						function(model) {
							loginModel.set('state', Login.LoginModel.SUCCESS);
							loginView.trigger('authenticated');
						}
					).fail(function (error) {
						loginView.setErrorMsg(error.message);
						loginModel.set('state', Login.LoginModel.FAIL);
					});
				} else {
					loginModel.set('validationErrors', errors);
					loginModel.set('state', Login.LoginModel.VALIDATION_ERRORS);
				}
			});
			return loginView;
		}
	});
	Login.Controller = new Controller();
});

KXL.module('Facades', function(Facades, KXL, Backbone, Marionette, $, _) {
	Facades.Facebook = {
		login: function () {
			var deferred = $.Deferred();
			FB.login(
				function (rsp) {
					if (rsp.status === 'connected') {
						deferred.resolve(rsp);
					} else {
						deferred.reject(new KXL.Errors.FacebookAPILoginError(null, rsp));
					}
				},
				{ scope: 'email' }
			);
			return deferred;
		},
		logout: function () {
			var deferred = $.Deferred();
			function handleSessionResponse(response) {
				if (response.status !== 'connected') {
					deferred.resolve(response);
				} else {
					FB.logout(handleSessionResponse);
				}
			}
			FB && FB.getLoginStatus && FB.getLoginStatus(handleSessionResponse);
			return deferred;
		}
	}
});
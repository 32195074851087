/* globals KXL */
KXL.module('Components.Avatar', function (Avatar, KXL, Backbone, Marionette, $, _) {

	Avatar.size = {
		XSMALL: 'xsmall',
		SMALL: 'small',
		MEDIUM: 'medium',
		MEDIUM_LARGE: 'medium-large',
		LARGE: 'large'
	};

	Avatar.borderColors = {
		RED: 'red',
		GREY: 'grey',
		BLACK: 'black',
		NONE: 'none'
	};

	Avatar.avatarMenu = {
		ON: 'on',
		OFF: 'off',
		SELF_ON: 'self'
	};

	Avatar.presence = {
		WEBSITE: 'website',
		CHAT: 'chat',
		NONE: 'none'
	};

	Avatar.View = KXL.Components.Layout.extend({
		className: function () {
			var classes = ['kx-avatar'];
			if (this.options.clickable) {
				classes.push('kx-clickable');
			}
			return classes.join(' ');
		},
		tagName: 'div',
		triggers: function() {
			return {
				'click .kx-avatar-wrapper': { event: 'clicked', stopPropagation: this.options.stopClickPropagation },
				'click .kx-avatar-menu-ignore': 'ignore:clicked',
				'click .kx-avatar-menu-add': 'friend-request:clicked',
				'click .kx-avatar-menu-chat':'friend-chat:clicked'
			};
		},
		modelEvents: {
			'change': 'render',
			'change:avatar' : 'setAvatarUrl'
		},
		ui: {
			img: 'img',
			avatar: '.kx-avatar-wrapper',
			avatarMenu: '.kx-avatar-menu',
			avatarName: '.kx-avatar-name',
			ignore: '.kx-avatar-menu-ignore',
			mutualFriendsHeader: '.kx-avatar-mutual-friends-header'
		},
		regions: {
			avatarMenuAvatar: '.kx-avatar-menu-avatar',
			mutualFriendsList: '.kx-avatar-mutual-friends-list'
		},
		maintainMenuOpen: false,
		delayedMenuOpen: null,
		delayedMenuClose: null,
		friendsCollection: null,
		friend: null,

		initialize: function (options) {
			var self = this;

			// If this is a UserWrapped object, bind to the user object within the UserWrapped object
			var user = this.model.get('user');
			if (user) {
				this.userModel = user;
			} else {
				this.userModel = this.model;
			}

			if (KXL.currentUser) {

				if (KXL.currentUser.id === this.userModel.id
					&& options.avatarMenu === Avatar.avatarMenu.ON) {
					this.options.avatarMenu = Avatar.avatarMenu.OFF;
				}

				this.friendsCollection = KXL.request('current:user:friend:entities')._collection;

				this.listenTo(this.friendsCollection, 'add remove reset', function (args) {
					self.checkFriend();
				});

				this.friend = this.friendsCollection.get(this.userModel.id);

				if (this.options.presence === Avatar.presence.WEBSITE) {
					if (this.friend) {
						this.listenTo(this.friend, 'change', function () {
							self.setPresence(self.friend.get('presence'));
						});
					}
				} else if (this.options.presence === Avatar.presence.CHAT) {
					if (this.friend) {
						var chatInterface = KXL.request('get:chat:interface');
						this.listenTo(chatInterface, 'presence', function (chatUser) {
							if (chatUser.userId === self.userModel.id) {
								self.setPresence(chatUser.status);
							}
						});
					}
				}
			}
		},

		setPresence: function (presence) {
			if (presence === 'available') {
				this.$el.find('.kx-online-presence').addClass('kx-online');
			} else {
				this.$el.find('.kx-online-presence').removeClass('kx-online');
			}
		},

		checkFriend: function () {
			var friend = this.friendsCollection.get(this.userModel.id);
			if (friend !== this.friend) {
				if (this.friend) {
					this.stopListening(this.friend, 'change');
				}
				this.friend = friend;
				this.render();
			}
		},

		getTemplate: function () {
			if (KXL.currentUser && KXL.currentUser.id === this.userModel.id && this.options.avatarMenu === Avatar.avatarMenu.SELF_ON) {
				return '#avatar-template-self-with-menu';
			} else if (!KXL.currentUser || (KXL.currentUser.id === this.userModel.id || this.options.avatarMenu === Avatar.avatarMenu.OFF) ) {
				return '#avatar-template-no-menu';
			}

			return this.friend ? '#avatar-template-friend' : '#avatar-template-non-friend';
		},

		onRender: function () {
			var self = this;
			if (this.options.size) {
				this.$el.addClass('kx-avatar-' + this.options.size);
			}
			if (this.options.color) {
				this.$el.addClass('kx-border-' + this.options.color);
			}

			switch (this.options.radiusStyle) {
				case 'circular':
					this.$el.addClass('kx-circular');
					break;
				default:
					this.$el.addClass('kx-square');
					break;
			}

			if (this.options.avatarMenu !== Avatar.avatarMenu.OFF && !this.options.friendCard) {
				this.ui.avatar.on('mouseenter', function (e) {
					self.trigger('friend:request:check:sent', self.model.id);
					self.delayedMenuOpen = setTimeout(function () {
						self.showMenu();
					}, 500);
				});
				this.ui.avatar.on('mouseleave', function () {
					clearTimeout(self.delayedMenuOpen);
					self.delayedMenuClose = setTimeout(function () {
						self.hideMenu();
					}, 500);
				});
				this.ui.avatarMenu.on('mouseover', function () {
					self.maintainMenuOpen = true;
				});
				this.ui.avatarMenu.on('mouseout', function () {
					self.maintainMenuOpen = false;
					self.delayedMenuClose = setTimeout(function () {
						self.hideMenu();
					}, 500);
				});
			}
			if (this.options.presenceOn) {
				this.setPresence('available');
			}
			else if (this.friend) {
				if (this.options.presence === Avatar.presence.WEBSITE) {
					this.setPresence(this.friend.get('presence'));
				} else if (this.options.presence === Avatar.presence.CHAT) {
					var chatInterface = KXL.request('get:chat:interface');
					this.setPresence(chatInterface.getUserPresence(this.friend.id));
				}
			}
		},
		showMenu: function (options) {
			// the delay on firing showMenu can create a race condition
			// that results in the ui elements being non-existent
			// which will throw an exception.  Check for this condition upfront.
			if (!(this.ui.avatarMenu instanceof jQuery)) {
				return;
			}

			if (!this.userModel.get('username')) {
				return;
			}

			if (!KXL.currentUser || KXL.request('auth:is:temp:user')) {
				return;
			}

			clearTimeout(this.delayedMenuOpen);
			this.$el.addClass('kx-avatar-menu-active');

			var menuOffset = this.$el.offset();

			var menuPositionTop = menuOffset.top - $(document).scrollTop();
			var menuPositionLeft = menuOffset.left;

			if (this.options.friendCard) {
				this.ui.avatarMenu.addClass('kx-avatar-friend-card');
				menuPositionTop = options.menuPositionTop;
				menuPositionLeft = options.menuPositionLeft;
			}
			else {
				// TODO, adjust this for different avatar sizes.
				switch(this.options.menuPosition) {
					case 'top-left':
						menuPositionTop -= 50;
						menuPositionLeft -= 202;
						break;
					case 'top-right':
						menuPositionTop -= 50;
						menuPositionLeft += 202;
						break;
					case 'bottom-right':
						menuPositionTop += 50;
						break;
					case 'bottom-left':
						menuPositionTop += 50;
						menuPositionLeft -= 202;
						break;
					case 'lock-bottom-right-avatar':
						menuPositionTop += (this.ui.avatar.outerHeight(true)) ? this.ui.avatar.outerHeight(true) : 0;
						menuPositionLeft += (this.ui.avatar.outerWidth(true)) ? this.ui.avatar.outerWidth(true) : 0;
						break;
				}
			}

			if (this.avatarMenuAvatar && !this.avatarMenuAvatar.currentView) {
				if (!this.menuAvatar) {
					this.menuAvatar = KXL.request('new:avatar:view', this.userModel.id, {
						size: KXL.Components.Avatar.size.LARGE,
						color: KXL.Components.Avatar.borderColors.BLACK
					});
				}
				this.avatarMenuAvatar.show(this.menuAvatar);
			}

			var self = this;

			if (this.mutualFriendsList && !this.mutualFriendsView) {
				this.mutualFriendsView = {};
				KXL.request('user:entities', {
					friendsOf: [
						KXL.currentUser.id,
						this.userModel.id
					].join(',')
				}).then(function (collection) {
					if (collection && collection.length) {
						self.mutualFriendsView = new Avatar.MutualFriendsListView({
							collection: collection
						});
						self.mutualFriendsList.show(self.mutualFriendsView);
						self.ui.mutualFriendsHeader.html(KXL.i18n.t('avatar.menu.mutualfriends.header'));
					}
				});
			}

			this.ui.avatarMenu.css('top', menuPositionTop);
			this.ui.avatarMenu.css('left', menuPositionLeft);

			if (KXL.request('is:user:ignored', this.userModel.id)) {
				this.$el.addClass('kx-avatar-menu-ignored');
				this.ui.ignore.attr('title', KXL.i18n.t('avatar.menu.unignore.title'));
				this.ui.ignore.html(KXL.i18n.t('avatar.menu.unignore'));
			} else {
				this.$el.removeClass('kx-avatar-menu-ignored');
				this.ui.ignore.attr('title', KXL.i18n.t('avatar.menu.ignore.title'));
				this.ui.ignore.html(KXL.i18n.t('avatar.menu.ignore'));
			}
			// Setup a scrolling listener so when the user is mouse is over a avatar and then scrolls with the
			// mouse wheel the avatar menu will close.
			this.setupScrollListener();
		},
		hideMenu: function () {
			clearTimeout(this.delayedMenuClose);
			if (this.maintainMenuOpen) {
				return;
			}
			this.unbindScrollListener();
			this.$el.removeClass('kx-avatar-menu-active');
		},
		setupScrollListener: function () {
			var self = this;
			$(document).scroll(function () {
				self.unbindScrollListener();
				self.delayedMenuClose = setTimeout(function () {
					self.maintainMenuOpen = false;
					self.hideMenu();
				}, 100);
			});
		},
		unbindScrollListener: function () {
			$(document).unbind('scroll');
		},
		serializeData: function () {
			//TODO @chrismartin. Seperate the avatar from the info to stop the avatar re-rendering everytime
			// the data gets set within the view. Below is a hack to get it working.
			if (this.userModel.get('avatarUrl')) {
				this.options.avatarUrl = this.userModel.get('avatarUrl');
			}

			var created = this.userModel.get('created');

			return {
				id: this.userModel.id,
				size: this.options.size,
				color: this.options.color,
				username: this.userModel.get('username'),
				avatarUrl: this.options.avatarUrl,
				avatarUsernameOverlay: this.options.avatarUsernameOverlay,
				bgMode: this.options.bgMode,
				memberSinceYear: created && (new Date(created * 1000)).getFullYear()
			};
		},
		setAvatarUrl: function (url) {
			this.userModel.set('avatarUrl', url + '?' + KXL.Common.Utils.DateUtils.now());
			this.ui.img.attr('src', this.userModel.get('avatarUrl'));
			this.render();
		},
		onClose: function () {
			this.ui.avatar.unbind('mouseenter');
			this.ui.avatar.unbind('mouseleave');
			this.ui.avatarMenu.unbind('mouseover');
			this.ui.avatarMenu.unbind('mouseout');
		}
	});

	Avatar.MutualFriendsListView = Marionette.CollectionView.extend({
		template: function () {
			return '';
		},
		className: 'kx-mutual-friends-list',
		collectionEvents: {
			'sort': 'sortEvent',
			'add remove reset change': 'collectionModified'
		},

		collectionModified: function () {
			var self = this;
			_.debounce(function() {
				self.collection.sort();
			}, 1000);
		},

		sortEvent: function () {
			this.render();
		},

		initialize: function () {
			if (this.collection) {
				this.collection.comparator = function (a, b) {
					return a.username > b.username ? 1 : a.username < b.username ? -1 : 0;
				};

				this.collection.sort();
			}
		},

		itemView: Marionette.Layout.extend({
			template: '#mutual-friends-list-item-template',
			className: 'kx-mutual-friends-list-item',
			regions: {
				avatar: ".avatar-region"
			},
			ui: {
				avatar: ".avatar-region"
			},

			onRender: function () {
				var avatar =
					KXL.request(
						'new:avatar:view',
						this.model.id,
						{
							model: this.model,
							size: KXL.Components.Avatar.size.SMALL,
							color: KXL.Components.Avatar.borderColors.BLACK,
							stopClickPropagation: true
						}
					);

				this.avatar.show(avatar);

				var username = this.model.get('username');

				if (username) {
					this.ui.avatar.attr('title', username);
				}
			}
		})
	});
});

/* globals KXL */
KXL.module('GamesApp', function (GamesApp, KXL, Backbone, Marionette, $, _) {
	GamesApp.Router = Marionette.AppRouter.extend({
		appRoutes: {
			'game/warcommanderrogueassault/home(/)': 'wcraRedirect',
			'game/:gameUrl/register(/)(:referrer)(/)(:experiment)': 'registerGame',
			'game/:gameUrl/autoregister(/)(:experiment)': 'autoRegister',
			'game/:gameUrl/invite(/*path)': 'processInvite',
			'game/:gameUrl/steamconnect(/)': 'showTomeSteamConnect',
			'game/:gameUrl(/:section)(/*path)': 'showGame',
			'warcommander(/:canvasPathAddOn)(/)': 'gameRedirect',
			'battlepirates(/:canvasPathAddOn)(/)': 'gameRedirect',
			'vegaconflict(/:canvasPathAddOn)(/)': 'gameRedirect'
		}
	});

	var API = {
		wcraRedirect: function () {
				window.location = 'https://warcommander.com';
				return;
		},
		gameRedirect: function (canvasPathAddOn, queryParams) {
			var gameUrlName = Backbone.history.fragment.replace(/[\/\?].*/g, '');

			var mobileGame = KXL.mobileGames.findWhere({'urlName': gameUrlName});

			// Route to /game/{gameUrl}  if logged in and it isn't a mobile game
			if (KXL.currentUser && !mobileGame) {
				KXL.navigate('game/' + gameUrlName);
				API.playGame(gameUrlName, canvasPathAddOn, queryParams);
			} else {
				KXL.navigate('game/' + gameUrlName + '/home');
				API.showGame(gameUrlName, 'home');
			}

		},
		processInvite: function (gameUrl, path) {
			var self = this;
			this.showGame(gameUrl, 'home');

			function _processFailedInvite (gameUrl, path) {
				self.showGame(
					gameUrl,
					'home',
					null,
					{
						errorObject: {
							code: path,
							text: KXL.i18n.t('games.invalidCode')
						}
					}
				);
			}

			if (KXL.currentUser) {
				if (KXL.request('auth:is:temp:user')) {
					KXL.execute('show:delayed:register:dialog', { referrer: 'invitelink'});
				} else {
					var saveGameInvites = new KXL.Entities.GameInvite({ inviteCode: path });
					// We have 2 failed states here depending on the type of error
					saveGameInvites.save().done(function (result) {
						if (result) {
							KXL.execute('game:play:load', gameUrl);
						} else {
							_processFailedInvite(gameUrl, path);
						}
					}).fail(function (error) {
						_processFailedInvite(gameUrl, path);
					});
				}
			} else {
				KXL.execute('show:register:dialog',
					{
						contextGameUrlName: gameUrl,
						suppressSuccessStep: true
					}
				);
			}
		},
		registerGame: function(gameUrl, referrer, experiment) {

			if (!KXL.currentUser) {
				var showRegisterDialogOptions = {
					suppressSuccessStep: true,
					contextGameUrlName: gameUrl,
					captchaRequired: true
				};
				KXL.execute('show:register:dialog', showRegisterDialogOptions);
			} else {
				this.showGame(gameUrl, 'play');
			}
		},
		autoRegister: function (gameUrl, experiment) {
			var self = this,
			currGame = KXL.request('get:game:by:gameurl', KXL.games, gameUrl),
			staticGame = KXL.staticGames.get(gameUrl),
			gameAllowsAutoRegistration = staticGame && staticGame.get('autoRegistration');

			if (!gameAllowsAutoRegistration) {
				KXL.navigate('game/' + gameUrl + '/home');
				return;
			}

			function byPassBetaCheck(currGame) {
				var defer = $.Deferred();
				$.ajax({
					url: '/api/game-bypassed',
					success: function (data) {
						if (currGame.get('commonName') === data) {
							return defer.resolve(true);
						} else {
							return defer.resolve(false);
						}
					}
				});
				return defer;
			}
			if (currGame.get('inviteOnly') && !KXL.currentUser) {
				byPassBetaCheck(currGame).then(function (doAutoReg) {
					if (doAutoReg) {
						KXL.execute('auto:register', gameUrl);
					} else {
						self.showGame(gameUrl, 'play');
					}
					// LIVE-7957: before stripping /autoregister out of actual url, make GA call
					var path = 'game/' + gameUrl;
					KXL.vent.trigger('info:page:nav', "/" + path + "/autoregister");
					KXL.navigate(path);
				});
			} else if (!currGame.get('inviteOnly') && !KXL.currentUser) {
				KXL.execute('auto:register', gameUrl);
			} else {
				self.showGame(gameUrl, 'play');
			}
		},
		playGame: function (gameUrl, canvasPathAddOn, queryParams) {
			var self = this;
			var currGame = KXL.request('get:game:by:gameurl', KXL.games, gameUrl);

			// Load/initialize special offers
			KXL.request('async:load:module', 'special_offer');

			if (KXL.currentUser &&
				((!KXL.request('auth:is:temp:user')) ||
					(KXL.request('auth:is:temp:user') &&
						_.has(KXL.config.autoRegCommonNames, currGame.get('commonName')))
					)
				) {

				GamesApp.Game.Controller.playGame(currGame, canvasPathAddOn, queryParams);
			} else {
				this.showGame(gameUrl, 'home');
			}
		},
		showTomeSteamConnect: function (gameUrl) {
			var self = this,
				currGame = KXL.games.findWhere({ urlName: gameUrl });
			KXL.asyncRequest('context:change', currGame).then(function () {
				function showSteamConnect() {
					KXL.asyncRequest('async:load:module', 'game/steam_connect').then(function () {
						var steamLayout = new KXL.Common.Views.ContentWithNav(),
							steamController = new GamesApp.SteamConnect.Controller({
								showContent: function (view) {
									steamLayout.contentRegion.show(view);
								}
							});
						steamLayout.on('show', function () {
							KXL.asyncRequest('new:nav:view', KXL.context).then(function (view) {
								steamLayout.navRegion.show(view);
							});
						});
						KXL.gameRegion.show(steamLayout);
						KXL.execute('page:show', 'game');
						steamController.showSteamConnect();
					});
				}

				if (!KXL.currentUser) {
					KXL.execute('show:login:dialog');
					KXL.vent.on('dialog:closed', function () {
						if (!KXL.currentUser) {
							self.showGame(gameUrl, 'home');
						}
						KXL.vent.off('dialog:closed', arguments.callee);
					});
				} else {
					showSteamConnect();
				}
			});
		},
		showGame: function (gameUrl, section, path, options) {
			var currGame = KXL.games.findWhere({ urlName: gameUrl });

			if (!currGame) {
				KXL.execute('home:show');
				return;
			}
			
			var redirectUrl = currGame.get('redirectUrl');
			if (redirectUrl != null)	{
				window.location = redirectUrl;
				return;
			}

			// Game is not WCRA and player is not authenticated, or game is VC, player is authenticated and section is "home", redirect to landing page
			if (
				(!KXL.currentUser && !/^(wcra)$/.test(currGame.get('commonName'))) ||
				(KXL.currentUser && /^(vc)$/.test(currGame.get('commonName')) && section === 'home')
			) {

				// Load the landing page module
				KXL.asyncRequest('async:load:module', 'landing_page').then(function () {
					KXL.execute('landing_page:show', gameUrl);
				});

				return;
			}			

			KXL.asyncRequest('context:change', currGame).then(function () {
				if (!section) {
					API.playGame(gameUrl, path, options);
				} else {
					// Mobile Games always get directed to /game/{gameUrl}/home
					var mobileGame = KXL.mobileGames.findWhere({'urlName': gameUrl});
					if (mobileGame) {
						if (!section) section = 'home';
					}

					// TODO @jaustin: Remove this conditional once we have user-game-reports and want to see landing pages
					// For IO, skip the logged in landing pages and go directly to the game
					if (KXL.currentUser && currGame.get('commonName') === 'io') {
						KXL.navigate('game/' + gameUrl + '/play');
						API.playGame(gameUrl, path, options);
						return;
					}

					if (section === 'play' || (!section && KXL.currentUser)) {
						API.playGame(gameUrl, path, options);
						return;
					}

					KXL.vent.trigger('info:game:page', gameUrl, section);

					var navHref = 'game/' + gameUrl + (section ? '/' + section + (path ? '/' + path : '') : '');

					KXL.navigate(navHref);
					options = _.extend({}, options, { path: path });
					GamesApp.Game.Controller.showGame(currGame, section, options);
				}
			});
		},
		isUserAllowedToPlayGame: function () {
			var
				deferred = $.Deferred(),
				gameInviteModel = new KXL.Entities.GameInvite()
			;

			if (!KXL.context.get('inviteOnly')) {
				deferred.resolve(true, gameInviteModel);
				return deferred;
			}
			var gameInvitesPromise = KXL.request('get:game:invites', gameInviteModel);
			gameInvitesPromise.then(function (invites) {
				if (invites) {
					deferred.resolve(_.contains(invites.get('gameInvites'), KXL.context.id), gameInviteModel);
				} else {
					deferred.resolve(false, gameInviteModel);
				}
			}).fail(function () {
				deferred.resolve(false, gameInviteModel);
			});
			return deferred;
		},
		getNavView: function (game, section) {
			return GamesApp.Nav.Controller.getNavView(game, section);
		},
		getStoryView: function (options) {
			return GamesApp.Story.Controller.getStory(options);
		},
		getGalleryView: function (collection, game, options) {
			return KXL.Components.Slider.Controller.getSlider(collection, game, options);
		},
		getMediaPreview: function (options) {
			return GamesApp.MediaPreview.Controller.getMediaPreview(options);
		},
		getBattlelogView: function (userId, gameId, gameCommonName) {
			return GamesApp.Battlelog.Controller.getBattlelog(userId, gameId, gameCommonName);
		},
		getMastheadView: function (options) {
			return GamesApp.Masthead.Controller.getMasthead(options);
		},
		getPlayMessageUnsupportedView: function (options) {
			return GamesApp.PlayMessage.Controller.getPlayMessageUnsupported(options);
		},
		getBackgroundView: function (options) {
			return GamesApp.Background.Controller.getBackground(options);
		},
		getBackgroundCMSView: function (options) {
			return GamesApp.Background.Controller.getBackgroundCMS(options);
		},
		getAppStoreView: function (options) {
			return GamesApp.AppStore.Controller.getAppStore(options);
		},
		getAllianceView: function (gameId, allianceId) {
			return GamesApp.Alliance.Controller.getAlliance(gameId, allianceId);
		},
		getLoadingRibbonView: function (options) {
			return GamesApp.LoadingRibbon.Controller.getLoadingRibbon(options);
		},
		getGameInvites: function (gameInviteModel) {
			var deferred = $.Deferred();
			if (KXL.currentUser) {
				gameInviteModel.fetch({
					success: function (data) {
						deferred.resolve(data);
					}
				});
			} else {
				deferred.resolve(new Backbone.Collection());
			}
			return deferred.promise();
		},
		showGetNotifedBanner: function (options) {
			var deferred = $.Deferred();
			KXL.asyncRequest('async:load:module', 'game/get_notified').then(function () {
				KXL.request(
					'get:notified:new:view',
					_.extend(
						options || {},
						{
							email: KXL.currentUser ? KXL.currentUser.get('email') : '',
							country: KXL.currentUser ? KXL.currentUser.get('location') : '',
							gameUrlName: KXL.context && KXL.context.get('urlName'),
							showNotifyBySMS: false
						}
					)
				).done(function (getNotifed) {
					getNotifed.on('layout:changed show', function () {
						KXL.vent.trigger('request:header:height:change:check');
					});
					var banner = KXL.request(
						'banner:create',
						{
							bannerId: 'getNotified',
							view: getNotifed,
							closable: true
						}
					);
					deferred.resolve(banner);
				});
			});
			return deferred;
		}
	};

	KXL.commands.setHandlers({
		'game:show': function (gameUrlName, section, href, options) {
			API.showGame(gameUrlName, section, href, options);
		},
		'game:play:load': function (gameUrlName, path, parameters) {
			var game = KXL.games.findWhere({ urlName: gameUrlName });

			KXL.asyncRequest('context:change', game).then(function () {
				KXL.navigate('game/' + gameUrlName);
				API.playGame(gameUrlName, path, parameters);
			});
		},
		'game:play:unload': function () {
			KXL.execute('page:close', 'play');
		},
		'game:play:reload': function () {
			var urlName = KXL.context && KXL.context.get('urlName');
			KXL.execute('game:play:unload');
			if (urlName) {
				KXL.execute('game:play:load', urlName);
			}
		},
		'game:navigate:steam': function (gameUrlName) {
			var staticGame = KXL.staticGames.get(gameUrlName),
				steamUrl = staticGame && staticGame.get('steamUrl');
			if (steamUrl) {
				KXL.navigate(steamUrl, { target: '_blank' });
			}
		},
		'game:register': function (gameUrl, referrer, experiment) {
			API.registerGame(gameUrl, referrer, experiment);
		},
		'game:autoregister': function (gameUrl, experiment) {
			API.autoRegister(gameUrl, experiment)
		}
	});

	KXL.addInitializer(function () {
		new GamesApp.Router({
			controller: API
		});
		KXL.appModel.on('change:currentUser', function (app, currentUser) {
			GamesApp.Game.Controller.reset();
		});

		KXL.appModel.on('change:context', function () {
			GamesApp.Game.Controller.reset();
		});
	});

	// Handlers for view requests.
	KXL.reqres.setHandlers({
		'game:is:user:allowed': function () {
			return API.isUserAllowedToPlayGame();
		},
		'new:nav:view': function (game, section) {
			return API.getNavView(game, section);
		},
		'new:story:view': function (options) {
			return API.getStoryView(options);
		},
		'new:gallery:view': function (collection, game, options) {
			return API.getGalleryView(collection, game, options);
		},
		'new:gamemediapreview:view': function (options) {
			return API.getMediaPreview(options);
		},
		'new:battlelog:view': function (userId, gameId, gameCommonName) {
			return API.getBattlelogView(userId, gameId, gameCommonName);
		},
		'new:masthead:view': function (options) {
			return API.getMastheadView(options);
		},
		'new:play:message:unsupported:view': function (options) {
			return API.getPlayMessageUnsupportedView(options);
		},
		'new:background:view': function (options) {
			return API.getBackgroundView(options);
		},
		'new:backgroundcms:view': function (options) {
			return API.getBackgroundCMSView(options);
		},
		'new:appstore:view': function (options) {
			return API.getAppStoreView(options);
		},
		'new:alliance:view': function (gameId, allianceId) {
			return API.getAllianceView(gameId, allianceId);
		},
		'new:game:loading:ribbon:view': function (options) {
			return API.getLoadingRibbonView(options);
		},
		'get:game:invites': function (gameInviteModel) {
			return API.getGameInvites(gameInviteModel);
		},
		'show:get:notified:banner': function (options) {
			return API.showGetNotifedBanner(options);
		}
	});
});

/*---------------- Button Widget ------------------
Usage:
Step 1 - Create a region in your view

	regions: {
		buttonRegion: '.kx-button-region'
	},

Step 2 - In the onRender function of the view write this code

	var buttonView = new KXL.Components.Button.View({
		label: 'Submit',
		theme: KXL.Components.Button.Themes.alt7,
		singleClick: true // Button will be disable after one click
	});

	this.buttonRegion.show(buttonView);

Step 3 - To handle click event
 	buttonView.listenTo(buttonView, 'click', function(view){
		//..Some code
	});
--------------------------------------------------------*/
KXL.module('Components.Button', function (Button, KXL, Backbone, Marionette, $, _) {
	Button.View = KXL.Components.Layout.extend({
		template: '#kx-button-template',
		className: 'kx-button',
		regions: {
			loaderRegion: '.kx-loader-region'
		},
		initialize: function (options) {
			var defaults = {
				label: '',
				customClass: '',
				theme: '',
				singleClick: false,
				enabled: true,
				loading: false,
				tooltip: undefined,
				preventDefault: undefined,
				stopPropagation: undefined
			};
			this.options = _.defaults(options || {}, defaults);
		},
		serializeData: function () {
			var ttip = this.options.tooltip;
			if (ttip === undefined &&
				// If tooltip option was not set make it the same as the
				// label but if set as null tooltip is effectively suppressed.
				this.options.label)
			{
				ttip = this.options.label;
			}
			return {
				label: this.options.label,
				tooltip: ttip
			};
		},
		events: {
			'click': 'clickHandler'
		},
		ui: {
			buttonLabel: '.kx-button-label'
		},
		onRender: function () {
			//Set the custom class of this el
			if (this.options.customClass) {
				this.$el.addClass(this.options.customClass);
			}
			//Add the theme to the button
			if (this.options.theme) {
				this.$el.addClass(this.options.theme);
			}
			// Enabled?
			if (!this.options.enabled) {
				this.disable();
			}
		},
		// Only accept the first click on a button
		clickHandler: _.debounce(function(e) {
			if (e) {
				var prevent = e.preventDefault;
				var stop = e.stopPropagation;

				if (this.options.preventDefault && prevent) {
					prevent.apply(e);
				}
				if (this.options.stopPropagation && stop) {
					stop.apply(e);
				}
			}
			if (this.options.enabled) {
				// Trigger click for parent
				this.trigger('click');
				// Now check if it is a single click button
				if (this.options.singleClick) {
					this.disable();
				}
				// Now see if we need to show a loading state
				if (this.options.loading) {
					this.startLoading();
				}
			}
		}, 1000, { leading: true, trailing: false }),
		// Enable the button
		enable: function () {
			this.options.enabled = true;
			this.$el.removeClass('kx-disabled');
		},
		// Disable the button
		disable: function () {
			this.options.enabled = false;
			this.$el.addClass('kx-disabled');
		},
		// Show loading state
		startLoading: function () {
			if (!this.loaderRegion.currentView) {
				this.loaderRegion.show(new KXL.Components.Loader.View({
					size: KXL.Components.Loader.sizes.small
				}));
			}
			this.$el.addClass('kx-loading');
		},
		// Hide loading state
		stopLoading: function () {
			this.$el.removeClass('kx-loading');
		},
		// Set button label
		label: function (value) {
			if (!this.isClosed) {
				this.ui.buttonLabel.text(value);
			}
		}
	});

	//Button Themes
	Button.Themes = {
		alt1: 'kx-alt1',
		alt2: 'kx-alt2',
		alt3: 'kx-alt3',
		alt4: 'kx-alt4',
		alt4o: 'kx-alt4o',
		alt5: 'kx-alt5',
		alt6: 'kx-alt6',
		alt7: 'kx-alt7',
		alt8: 'kx-alt8',
		alt9: 'kx-alt9',
		alt10: 'kx-alt10',
		alt11: 'kx-alt11',
		alt11b: 'kx-alt11b',
		alt11c: 'kx-alt11c',
		alt12: 'kx-alt12',
		alt14: 'kx-alt14',
		alt15: 'kx-alt15',
		alt16: 'kx-alt16',
		alt17: 'kx-alt17',
		alt18: 'kx-alt18',
		alt19: 'kx-alt19',
		alt20: 'kx-alt20',
		alt21: 'kx-alt21',
		alt22: 'kx-alt22',
		fbalt1: 'kx-fb-alt1',
		gplusalt1: 'kx-gplus-alt1',
		link: 'kx-link',
		alt23: 'kx-alt23',
		alt24: 'kx-alt24',
		alt25: 'kx-alt25',
		alt26: 'kx-alt26'
	};
});

KXL.module('Components.KXLButton', function (KXLButton, KXL, Backbone, Marionette, $, _) {
	KXLButton.View = KXL.Components.Button.View.extend({
		template: '#kx-kxl-button-template',
		className: 'kxl-button'
	});
});

(function () {
	"use strict";

	Backbone.FilteredCollection.prototype.setFilter = function (newFilter, options) {
      options || (options = {});
      if (newFilter === false) { newFilter = this.defaultFilter } // false = clear out filter
      this.collectionFilter = newFilter || this.collectionFilter || this.defaultFilter;

      // this assumes that the original collection was unmodified
      // without the use of add/remove/reset events. If it was, a
      // reset event must be thrown, or this object's .resetCollection
      // method must be invoked, or this will most likely fall out-of-sync

      // why HashMap lookup when you can get it off the stack
      var filter = this.collectionFilter;
      var mapping = this._mapping;
      var passed = [];
		
      // this is the option object to pass, it will be mutated on each
      // iteration
      var passthroughOption = _.extend({}, options);
      this.collection.each(function(model, index) {
        var foundIndex = mapping.indexOf(index);

        if (filter(model, index, passed)) {
        	passed.push(model);
          // if already added, no touchy
          if (foundIndex == -1) {
            passthroughOption.index = index
            this._forceAddModel(model, passthroughOption);
          }
        }
        else {
          if (foundIndex > -1) {
            passthroughOption.index = foundIndex == -1 ? this.length : foundIndex;
            this._forceRemoveModel(model, passthroughOption);
          }
        }
      }, this);
      if (! options.silent) {
        this._filterComplete();
      }
    }
 
     Backbone.FilteredCollection.prototype._modelChanged = function(model, collection, options){
      options || (options = {});

      var ownIndexOfModel = this.indexOf(model);
      if (this.collectionFilter(model, ownIndexOfModel, this.models)){
        // Model passed filter
        if (ownIndexOfModel < 0){
          // Model not found, add it
          var index = this.collection.indexOf(model);
          this._forceAddModel(model, {index: index});
        }
      } else {
        // Model did not pass filter
        if (ownIndexOfModel > -1){
          this._forceRemoveModel(model, {index: ownIndexOfModel});
        }
      }
      if (! options.silent) {
        this._filterComplete();
      }
    }
 
     Backbone.FilteredCollection.prototype.addModel = function(model, collection, options) {
      if (this.collectionFilter(model, undefined, this.models)) {
        this._forceAddModel(model, _.extend(options || {}, {index: (options && options.at) || collection.indexOf(model)}));
      }
      this.renumberMappings();
    }
})();
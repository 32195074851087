KXL.module('GamesApp.Alliance', function(Alliance, KXL, Backbone, Marionette, $, _) {
	Alliance.Controller = {
		allianceLayout: null,
		getAlliance: function (gameId, allianceId) {

			var self = this;
			var allianceModel = KXL.request(
				'alliance:entity',
				gameId,
				allianceId
			);

			allianceModel.fail(function(alliance, response, options){
					self.allianceLayout.topRegion.reset();
					self.allianceLayout.topRegion.show(new Alliance.EmptyView());
					self.allianceLayout.leaderRegion.reset();
					self.allianceLayout.membersRegion.reset();
			});

			this.allianceLayout = new Alliance.View({
				model: allianceModel._model
			});

			this.allianceLayout.on('render', function() {

				var self = this;

				var allianceTopView = new Alliance.AllianceTopView({
					model: allianceModel._model
				});
				self.topRegion.show(allianceTopView);

				var allianceLeaderView = new Alliance.AllianceLeaderView({
					model: allianceModel._model
				});
				self.leaderRegion.show(allianceLeaderView);

				var membersListView = new Alliance.MembersListView({
					collection: allianceModel._model.membersList
				});
				self.membersRegion.show(membersListView);
			})

			return this.allianceLayout;
		}
	};
});

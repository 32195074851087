KXL.module('Components', function(Components, KXL, Backbone, Marionette, $, _) {

	Components.Dialog = Components.Layout.extend({
		template: Templates['common/components/dialog/dialog'],
		className: 'kx-dialog',
		dialogId: null,
		regions: {
			content: '.kx-dialog-pane-content'
		},
		ui: {
			closeBtn: '.kx-dialog-close-button',
			veil: '.kx-dialog-veil'
		},
		initialize: function (options) {
			_.extend(this, Backbone.Events);
			var defaults = {
				size: 'medium',
				// When set to false will prevent the dialog from being closed
				// by the user.
				allowClose: true,
				// Close the dialog when the ESC key is pressed
				closeOnEsc: true,
				// Show a close button
				showCloseButton: true,
				// Show the veil around the dialog
				showVeil: true,
				// Boolean to set the veil as transparent or not
				transparentVeil: true,
				// Center the dialog on the page
				center: false,
				// css class to be added to the kx-dialog element
				customClassName: '',
				// click veil to close
				clickVeilToClose: true,
				dialogId: null
			};
			this.options = _.defaults(options || {}, defaults);
			this.dialogId = this.options.dialogId;
			KXL.vent.trigger('overlay:toggle', true);
		},
		bindWindowEvents: function () {
			var self = this;
			if (this.options.allowClose && this.options.closeOnEsc) {
				// Escape key closes the dialog.
				this.onKeyUpHandler = function (e) {
					if (e.keyCode === 27) {
						self.$el.fadeOut(200, function() {
							self.trigger('dialog:close', true);
						});
					}
				};
				$(window).on('keyup.dialog', this.onKeyUpHandler);
			}
		},
		setContent: function (view) {
			this.content.show(view);
		},
		onClose: function () {
			$('body').removeClass('kx-dialog-disable-scroll');

			$(window).off('resize.dialog');
			$(window).off('keyup.dialog');

			KXL.vent.trigger('overlay:toggle', false);

			$('body').css('top', '');
			$(window).scrollTop(this.scrollDistance);  
		},
		onRender: function () {
			this.$el.fadeIn(200);

			this.$pane = this.$el.find('.kx-dialog-pane');
			this.bindWindowEvents();

			if (this.options.size) {
				this.$el.addClass(
					Components.Dialog.SIZE_CLASS_NAMES[this.options.size]
				);
			}

			if (this.options.center) {
				this.$el.addClass('kx-dialog-center')
			}

			if (this.options.customClassName) {
				this.$el.addClass(this.options.customClassName);
			}

			if (!this.options.showVeil) {
				this.ui.veil.hide();
			}

			if (this.options.transparentVeil) {
				this.$el.find('.kx-dialog-veil').addClass('transparent');
			}

			this.toggleClosable(this.options.allowClose);
		},
		onShow: function () {
			if (this.options.showVeil) {
				this.scrollDistance = $(window).scrollTop();
				$('body').addClass('kx-dialog-disable-scroll');
				$('body').css('top', this.scrollDistance * -1);
			}
		},
		toggleClosable: function (isClosable) {
			var self = this;

			// Remove any current handlers.
			this.ui.veil.off('click');
			this.ui.closeBtn.off('click');

			function close() {
				self.trigger('dialog:close', true);
			}

			if (isClosable) {
				if (this.options.showVeil) {
					// Close when user clicks on veil.
					if (this.options.clickVeilToClose) {
						this.ui.veil.on('click', function() {
							self.$el.fadeOut(200, close);
						});
					}
				}
				if (this.options.showCloseButton) {
					this.ui.closeBtn.show();
					// Close when user clicks on close button.
					this.ui.closeBtn.on('click', function() {
						self.$el.fadeOut(200, close);
					});
				} else {
					this.ui.closeBtn.hide();
				}
			} else {
				this.ui.closeBtn.hide();
			}
		}
	});


	// Size constants
	Components.Dialog.SIZES = {
		tiny: 'tiny',
		small: 'small',
		medium: 'medium',
		large: 'large'
	};

	// Size to class name mapping.
	Components.Dialog.SIZE_CLASS_NAMES = {};
	Components.Dialog.SIZE_CLASS_NAMES[Components.Dialog.SIZES.tiny] =
		'kx-dialog-tiny';
	Components.Dialog.SIZE_CLASS_NAMES[Components.Dialog.SIZES.small] =
		'kx-dialog-small';
	Components.Dialog.SIZE_CLASS_NAMES[Components.Dialog.SIZES.medium] =
		'kx-dialog-medium';
	Components.Dialog.SIZE_CLASS_NAMES[Components.Dialog.SIZES.large] =
		'kx-dialog-large';

	Components.DialogRegion = Marionette.Region.extend({
		onShow: function(view) {
			this.listenTo(view, 'dialog:close', this.closeDialog);
			this.listenTo(view, 'dialog:resize', this.resizeDialog);
			KXL.vent.trigger('dialog:opened');
		},
		closeDialog: function (manual) {
			this.stopListening();
			KXL.vent.trigger('dialog:closed', manual, this.currentView);
			this.close();
		},
		resizeDialog: function () {
			console.log('resizing dialog');
		}
	});
});
KXL.module('Components.Tooltip', function (Tooltip, KXL, Backbone, Marionette, $, _) {

	Tooltip.View = Marionette.ItemView.extend({
		template: '#tooltip-template',
		className: 'kx-tooltip',
		tagName: 'div',
		initialize: function(options) {
			var defaults = {
				// Parent el
				$elParent: null,
				text: '',
				position: Tooltip.Positions.right, // left or right
				noWrap: true,
				width: 'auto',
				fadeOut: false,
				fadeOutAfter: 5000
			};

			this.options = _.defaults(options || {}, defaults);
		},
		ui: {
			text: '.kx-tooltip-text',
			arrow: '.kx-tooltip-arrow'
		},
		serializeData: function () {
			return {
				options: this.options
			};
		},
		showError: function (text, options) {
			this._show(text, options);
			this.$el.addClass('kx-error');
		},
		showText: function (text, options) {
			this._show(text, options);
			this.$el.removeClass('kx-error');
		},
		_show: function (text, options) {
			var self = this;
			var opts = _.defaults(
				{},
				options,
				this.options
			);

			if (opts.$elParent) {
				if(text) {
					self.ui.text.html(text);
				}

				self.$el.addClass('kx-active');

				//Set the position of arrow
				self.$el.toggleClass(
					'kx-tooltip-left',
					opts.position === Tooltip.Positions.left
				);

				self.$el.toggleClass(
					'kx-tooltip-right',
					opts.position === Tooltip.Positions.right
				);

				self.$el.toggleClass(
					'kx-tooltip-no-wrap',
					opts.noWrap
				);

				self.$el.css({ width: opts.width });

				self.$el.show();

				setTimeout(function () {
					var bubblePosition = opts.$elParent.position();
					// Calculate Top and Left position for this bubble
					var vPos = 0;
					var hPos =  opts.$elParent.width() + 12;
					if (opts.position === Tooltip.Positions.right) {
						self.$el.css({ top: vPos, left: hPos });
					} else {
						self.$el.css({ top: vPos, right: hPos });
					}
				}, 0);
				if (opts.fadeOut) {
					setTimeout(
						function () {
							self.$el.fadeOut(500);
						},
						opts.fadeOutAfter
					);
				}
			}
		},
		hide: function () {
			this.$el.removeClass('kx-active kx-error');
			this.$el.hide();
		}
	});

	Tooltip.Positions = {
		left: 'left',
		right: 'right'
	};
});
KXL.module('AuthApp.Login', function(Login, KXL, Backbone, Marionette, $, _) {
	Login.View = KXL.Components.Layout.extend({
		template: '#login-template',
		className: 'kx-login',
		events: {
			'click .kx-login-forgot': 'triggerForgotPass'
		},
		triggers: {
			'click .kx-register-link': 'register:link:clicked'
		},
		// Shortcut references to components within the UI.
		ui: {
			loginBtn: '.kx-login-btn',
			authErrorMessage: '.kx-login-auth-error-msg',
			socialAuthErrorMessage: '.kx-social-login-auth-error-msg',
			header: '.kx-header'
		},
		// Specify the model properties that we should rerender the view on.
		modelEvents: {
			'change:state': 'handleStateChange'
		},
		regions: {
			usernameRegion: '.kx-txt-login-username-region',
			passwordRegion: '.kx-login-password-region',
			loginBtnRegion: '.kx-login-btn-region',
			fbConnnectBtnRegion: '.kx-btn-fb-login-region',
			googleBtnRegion: '.kx-btn-google-login-region',
			steamBtnRegion: '.kx-btn-steam-login-region'
		},
		initialize: function (options) {
			this.fields = {};
			this.options = _.defaults(options, {
				steamLoginEnabled: KXL.switches.steamLoginEnabled
			});
		},
		triggerForgotPass: function () {
			KXL.execute('show:forgot:password:dialog');
		},
		onRender: function () {
			var self = this;
			function onLoginFieldChanged () {
				self.setErrorMsg('');
			}

			function triggerLogin () {
				self.model.set('username', self.fields.username.val());
				self.model.set('password', self.fields.password.val());
				self.trigger(
					'login:submit',
					self.model
				);
			}

			// Username inputbox
			this.fields.username = new KXL.Components.TextBox.View({
				model: this.model,
				modelAttr: 'username',
				placeHolder: KXL.i18n.t('auth.usernameEmail'),
				showSuccess: false
			});
			this.listenTo(this.fields.username, 'change', onLoginFieldChanged);
			this.listenTo(this.fields.username, 'submit', triggerLogin);
			this.usernameRegion.show(this.fields.username);

			// Password inputbox
			this.fields.password = new KXL.Components.TextBox.View({
				model: this.model,
				modelAttr: 'password',
				customClass: "kx-txt-login-pwd",
				placeHolder: KXL.i18n.t('auth.password'),
				showSuccess: false,
				type: 'password'
			});
			this.listenTo(this.fields.password, 'change', onLoginFieldChanged);
			this.listenTo(this.fields.password, 'submit', triggerLogin);
			this.passwordRegion.show(this.fields.password);

			// Log In button
			var loginBtn = new KXL.Components.Button.View({
				label: KXL.i18n.t('auth.logIn'),
				theme: KXL.Components.Button.Themes.alt7,
				customClass: 'kx-login-btn'
			});
			loginBtn.on('click', triggerLogin);
			this.loginBtnRegion.show(loginBtn);

			// Fb connect
			var fbconnectBtn = new KXL.Components.Button.View({
				theme: KXL.Components.Button.Themes.alt8,
				customClass: 'kx-btn-fb-login'
			});

			fbconnectBtn.on('click', function () {
				self.setSocialErrorMsg('');
				self.setErrorMsg('');
				KXL.vent.trigger('info:fblogin:start', { trackControl: 'dialog', trackSource: 'login' });
				FB.login(
					function (rsp) {
						if (rsp.status === 'connected') {
							self.trigger('fb:api:authenticated', rsp.authResponse.accessToken);
						}
					},
					{ scope: 'email' }
				);
			});
			this.fbConnnectBtnRegion.show(fbconnectBtn);

			// Google auth
			var googleBtn = new KXL.Components.Button.View({
				theme: KXL.Components.Button.Themes.alt17,
				customClass: 'kx-btn-google-login'
			});
			this.listenTo(googleBtn, 'click', function () {
				self.setSocialErrorMsg('');
				self.setErrorMsg('');
				KXL.vent.trigger('info:googlelogin:start', { trackControl: 'dialog', trackSource: 'login' });
				gapi.requestAccessToken();
			});
			this.googleBtnRegion.show(googleBtn);

			if (this.options.steamLoginEnabled) {
				var steamBtn = new KXL.Components.Button.View({
					theme: KXL.Components.Button.Themes.alt26,
					customClass: 'kx-btn-steam-login'
				});
				this.listenTo(steamBtn, 'click', function () {
					self.setSocialErrorMsg('');
					self.setErrorMsg('');
					KXL.execute('steamlogin:start', self, { trackControl: 'dialog', trackSource: 'login' });
				});
				this.steamBtnRegion.show(steamBtn);
			}

			this.handleStateChange();
			this.$el.find('input').placeholder(); // Placeholder fallback for IE9
		},
		setErrorMsg: function (msg) {
			if (msg) {
				this.ui.authErrorMessage.html(msg).show();
			} else {
				this.ui.authErrorMessage.html('').hide();
			}
		},
		setSocialErrorMsg: function (msg) {
			if (msg) {
				this.ui.socialAuthErrorMessage.html(msg).show();
			} else {
				this.ui.socialAuthErrorMessage.html('').hide();
			}
		},
		handleStateChange: function () {
			switch (this.model.get('state')) {
			case Login.LoginModel.NOT_AUTH_STATE:
				this.fields.username.focus();
				break;
			case Login.LoginModel.VALIDATION_ERRORS:
				// Display validation errors.
				this.$el.find('input').prop('disabled', false);
				this.$el.find('.kx-login-btn').removeClass('kx-login-disabled');
				var errors = this.model.get('validationErrors');
				var self = this;
				_.each(errors, function (err, key) {
					if (self.fields[key]) {
						self.fields[key].showError(err);
					}
				});
				break;
			case Login.LoginModel.PENDING:
				this.$el.find('input').prop('disabled', true);
				this.$el.find('.kx-login-btn').addClass('kx-login-disabled');
				break;
			case Login.LoginModel.FAIL:
				this.$el.find('input').prop('disabled', false);
				this.$el.find('.kx-login-btn').removeClass('kx-login-disabled');
				this.fields.password.focus();
				break;
			case Login.LoginModel.SUCCESS:
				break;
			}
		},
		onShow: function () {
			var self = this;
			setTimeout(function () {
				if(self.fields.username) {
					self.fields.username.focus();
				}
			}, 0);
		},
		serializeData: function () {
			var data = this.options;
			data.logInFacebookConnect = KXL.i18n.t('auth.logInFacebookConnect' + (KXL.switches.steamLoginEnabled ? '' : 'NoSteam'));
			return data;
		}
	});
});

KXL.module('HomeApp.Games', function(Games, KXL, Backbone, Marionette, $, _) {
	Games.Game = Marionette.ItemView.extend({
		template: '#_game-pile-item-template',
		tagName: 'li',
		className: function () {
			var classNames = [
				'kx-game-pile-item',
				'kx-game-pile-' + this.model.get('commonName')
			];
			if (this.model.get('isMobile') ||
				this.model.get('supportsMobile'))
			{
				classNames.push('kx-mobile');
			}
			return classNames.join(' ');
		},
		triggers: {
			'click': 'game:clicked'
		}
	});
	Games.View = Marionette.CollectionView.extend({
		itemView: Games.Game,
		tagName: 'ul',
		itemViewEventPrefix: 'game:pile:item',
		className: 'kx-game-pile kx-clearfix'
	});

	Games.IconView = Marionette.ItemView.extend({
		template: '#game-icon-template',
		tagName: 'div',
		className: 'kx-game-icon-avatar-wrapper',
		events: {
			'click': 'onClicked'
		},
		ui: {
			img: 'img'
		},
		templateHelpers: function () {
			var self = this;

			return {
				size: function () {
					return self.options.size;
				}
			};
		},
		onClicked: function () {
			KXL.execute('game:show', this.model.get('urlName'));
		},
		initialize: function (options) {
			var defaults = {
				size: 'small'
			};
			this.options = _.defaults(options.options || {}, defaults);
		}
	});
});

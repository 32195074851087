/**
 * This override changes the default functionality in Backbone.Model.save to do PATCH
 * requests instead of PUT requests on the update/save of Models.
 *
 * If you want to force a PUT, you can pass the option as follows save({ put: true })
 */
(function () {
	"use strict";

	if (Backbone && Backbone.Model) {
		var oldModel = Backbone.Model;
		Backbone.Model = Backbone.Model.extend({
			constructor: function () {
				this._originalAttrs = {};
				this._unsavedChanges = {};

				var ret =  oldModel.apply(this, arguments);

				this._resetTracking();

				return ret;
			}
		});
		_.extend(Backbone.Model.prototype, {
			// Trigger an `unsavedChanges` event on this model,
			// supplying the result of whether there are unsaved
			// changes and a changed attributes hash.
			_triggerUnsavedChanges: function () {
				this.trigger('unsavedChanges', !_.isEmpty(this._unsavedChanges), _.clone(this._unsavedChanges), this);
			},
			// Symmetric to Backbone's `model.changedAttributes()`,
			// except that this returns a hash of the model's attributes that
			// have changed since the last save, or `false` if there are none.
			// Like `changedAttributes`, an external attributes hash can be
			// passed in, returning the attributes in that hash which differ
			// from the model.
			unsavedAttributes: function (attrs) {
				if (!attrs) return _.isEmpty(this._unsavedChanges) ? false : _.clone(this._unsavedChanges);
				var val, changed = false, old = this._unsavedChanges;
				for (var attr in attrs) {
					if (_.isEqual(old[attr], (val = attrs[attr]))) continue;
					(changed || (changed = {}))[attr] = val;
				}
				return changed;
			},
			_resetTracking: function () {
				this._originalAttrs = _.clone(this.attributes);
				this._unsavedChanges = {};
			},
			set: _.wrap(Backbone.Model.prototype.set, function(oldSet, key, val, options) {
				var attrs, ret, self = this;
				if (key == null) return this;
				// Handle both `"key", value` and `{key: value}` -style arguments.
				if (typeof key === 'object') {
					attrs = key;
					options = val;
				} else {
					(attrs = {})[key] = val;
				}
				options || (options = {});

				// Delegate to Backbone's set.
				ret = oldSet.call(this, attrs, options);

				if (!options.silent) {
					_.each(attrs, function(val, key) {
						if (_.isEqual(self._originalAttrs[key], val)) {
							delete self._unsavedChanges[key];
						} else {
							self._unsavedChanges[key] = val;
						}
					});
					this._triggerUnsavedChanges();
				}
				return ret;
			}),
			save: _.wrap(Backbone.Model.prototype.save, function (oldSave, key, val, options) {
				var optionsIsVal = (key == null || typeof key === 'object'),
					self = this;
				if (optionsIsVal) {
					options = val;
					// If this model is being patched, only patch the changed attributes
					// The typeof check on key here is to allow people to explicitly push
					// changes without this overriding it with the changed attributes.
					if (!this.isNew() && !_.size(key)) {
						if (options && options.unsaved) {
							key = this.unsavedAttributes();
						} else {
							key = this.changedAttributes();
						}

						// Don't continue if nothing has changed...
						if (!key) {
							// If there is a success, run it!
							if (options && options.success) {
								options.success.call(this, this);
							}
							return false;
						}
					}
				}

				options = options || {};
				options.success = _.wrap(options.success, function (oldSuccess, data, textStatus, jqXHR) {
					var ret;
					if (oldSuccess) {
						ret = oldSuccess.call(this, data, textStatus, jqXHR);
					}
					self._resetTracking();
					self._triggerUnsavedChanges();
					self.trigger('save');
					return ret;
				});
				// To force a PUT, add put: true to the options object
				options.patch = (!options.put);
				if (optionsIsVal) {
					return oldSave.call(this, key, options);
				} else {
					return oldSave.call(this, key, val, options);
				}
			}),
			fetch: _.wrap(Backbone.Model.prototype.fetch, function (oldFetch, options) {
				var self = this;
				options = options || {};
				options.success = _.wrap(options.success, function (oldSuccess, data, textStatus, jqXHR) {
					var ret;
					if (oldSuccess) {
						ret = oldSuccess.call(this, data, textStatus, jqXHR);
					}
					self._resetTracking();
					self._triggerUnsavedChanges();
					return ret;
				});
				return oldFetch.call(this, options);
			}),
			toJSON: function () {
				if (!this.blacklist) {
					return _.clone(this.attributes);
				} else {
					return _.omit(this.attributes, this.blacklist);
				}
			}
		});
	}
})();

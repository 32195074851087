KXL.module('GamesApp.LoadingRibbon', function(LoadingRibbon, KXL, Backbone, Marionette, $, _) {
	LoadingRibbon.Controller = {
		getLoadingRibbon: function (options) {
			var
				loadingRibbonView,
				currGame = KXL.request('get:game:by:gameurl', KXL.games, options.gameId);

			loadingRibbonView = new LoadingRibbon.View({
				model: currGame
			});

			loadingRibbonView.$el.addClass(currGame.get('commonName'));

			return loadingRibbonView;
		}
	};
});

KXL.module('Components.CommentInput', function (CommentInput, KXL, Backbone, Marionette, $, _) {
	CommentInput.View = KXL.Components.Layout.extend({
		initialize: function (options) {
			var defaults = {
				startMinimized: false,
				maxlength: 500,
				clickToExpand: false,
				expandHeight: 50,
				canComment: true
			};
			this.options = _.defaults(options || {}, defaults);
		},
		regions: {
			avatar: '.kx-comment-thumbnail-container'
		},
		template: '#kx-comment-input',
		tagName: 'div',
		className: 'kx-comment-input',
		ui: {
			commentInput: '.kx-comment-input-small',
			commentInputField: '.kx-comment-input-field',
			commentSubmitBtn: '.kx-comment-submit-btn'
		},
		events: {
			'keyup @ui.commentInput': 'inputKeyUp',
			'click @ui.commentSubmitBtn': 'commentInputSubmit'
		},
		commentInputSubmit: function () {
			if (this.ui.commentInputField.val() !== '') {
				this.trigger('submit', this.ui.commentInputField.val());
			}
		},
		inputKeyUp: function (e) {
			// If enter key
			if (e.keyCode === 13) {
				if (this.ui.commentInputField.val() !== '') {
					this.trigger('submit', this.ui.commentInputField.val());
				}
			}
		},
		onRender: function () {
			if (this.options.canComment) {
				if (this.options.startExpanded) {
					this.expandCommentInput();
				}
			}
		},
		expandCommentInput: function () {
			if (!this.expanded && this.options.canComment) {

				this.$el.parent().css('overflow', 'hidden');
				this.ui.commentInput.css('display', 'table-row');
				this.ui.commentInputField.focus();

				this.avatar.show(
					KXL.request(
						'new:avatar:view',
						this.options.userId, {
						avatarMenu: KXL.Components.Avatar.avatarMenu.ON,
						size: 'small',
						color: KXL.Components.Avatar.borderColors.NONE
					})
				);

				if (KXL.config.animationCapabilities) {
					TweenLite.fromTo(
						this.$el.parent(),
						0.3,
						{
							height: 0
						},
						{
							height: this.options.expandHeight
						}
					);
					TweenLite.fromTo(
						this.ui.commentInput,
						0.3,
						{
							opacity: 0
						},
						{
							opacity: 1,
							delay: 0.3
						}
					);
				}
				KXL.vent.trigger('comment:input:opened', this);
				this.expanded = true;
			}
		},
		hideCommentInput: function () {
			if (KXL.config.animationCapabilities) {
				TweenLite.to(
					this.ui.commentInput,
					0.3,
					{
						opacity: 0
					}
				);
			} else {
				this.ui.hide();
			}
		},
		reset: function () {
			var self = this;
			if (KXL.config.animationCapabilities) {
				TweenLite.to(
					this.ui.commentInput,
					0.3,
					{
						opacity: 0
					}
				);
				TweenLite.to(
					this.$el.parent(),
					0.3,
					{
						height: 0,
						delay: 0.3,
						onComplete: function () {
							self.ui.commentInput.hide();
						}
					}
				);
			} else {
				this.ui.commentInput.hide();
			}
			this.expanded = false;
		},
		serializeData: function () {
			var data = {};
			if (this.model) {
				data = this.model.toJSON();
			}
			_.extend(data, { options: this.options });
			return data;
		}
	});
});

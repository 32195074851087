KXL.module('Entities', function (Entities, KXL, Backbone, Marionette, $, _) {

	Entities.Leaderboard = Entities.Model.extend();
		
	Entities.Leaderboards = Entities.Collection.extend({
		model: Entities.Leaderboard,
		initialize: function (models, options) {
			this.url = function () {
				return KXL.config.API_URL + '/leaderboards?gameId=' + options.gameId;
			};
		}
	});

	Entities.LeaderboardEntry = Entities.Model.extend();

	Entities.LeaderboardEntryItem = Entities.Collection.extend({
		model: Entities.LeaderboardEntry,
		initialize: function (models, options) {
			this.url = function () {
				var url = KXL.config.API_URL + '/leaderboards/' + options.leaderboardId + '/entries'

				if (options.entryId) {
					url += '/' + options.entryId;
				}

				return url;
			};
		}
	});

	Entities.LeaderboardEntries = Entities.Collection.extend({
		model: Entities.LeaderboardEntry,
		defaults: {
			limit: 100
		},
		initialize: function (models, options) {
			this.options = _.defaults({}, options, this.defaults);
		},
		url: function () {
			return KXL.config.API_URL +
				'/leaderboards/' +
				this.options.leaderboardId +
				'/entries?' +
				$.param(this.options);
		}
	});

	Entities.LeaderboardEntryMulti = Entities.Collection.extend({
		model: Entities.LeaderboardEntry,
		initialize: function (models, options) {
			this.url = function () {
				var url = KXL.config.API_URL + '/leaderboard-entries/?leaderboardIds=' + options.leaderboardIds;

				// entryId MUST be given.
				if (options.entryId) {
					url += '&entryId=' + options.entryId;
				}

				if (options.friendOf) {
					url += "&friendOf=" + options.friendOf;
				}

				return url;
			};
		}
	});

	var API = {
		// TODO: Reduce boilerplate in here
		getLeaderboardEntries: function(options) {
			var leaderboardEntries = new Entities.LeaderboardEntries([], options);

			// Want the promise to resolve with NotificationsCollection, not the Ajax response.
			var deferred = $.Deferred();

			var fetchParams = {
				success: function (collection) {
					deferred.resolve(collection);
				},
				error: function (error) {
					deferred.reject(error);
				}
			};

			leaderboardEntries.fetch(fetchParams);

			return deferred;
		},
		getLeaderboardEntryItem: function(options) {
			var leaderboardEntries = new Entities.LeaderboardEntryItem([], options);

			// Want the promise to resolve with NotificationsCollection, not the Ajax response.
			var deferred = $.Deferred();

			var fetchParams = {
				success: function (collection) {
					deferred.resolve(collection);
				},
				error: function (error) {
					deferred.reject(error);
				}
			};

			leaderboardEntries.fetch(fetchParams);

			return deferred;
		},
		getLeaderboardEntriesFor: function(options) {
			var leaderboardEntries = new Entities.LeaderboardEntryMulti([], options);

			// Want the promise to resolve with NotificationsCollection, not the Ajax response.
			var deferred = $.Deferred();

			var fetchParams = {
				success: function (collection) {
					deferred.resolve(collection);
				},
				error: function (error) {
					deferred.reject(error);
				}
			};

			leaderboardEntries.fetch(fetchParams);
			return deferred;
		},
		getLeaderboards: function(options) {
			var leaderboards = new Entities.Leaderboards([], options);

			// Want the promise to resolve with NotificationsCollection, not the Ajax response.
			var deferred = $.Deferred();

			var fetchParams = {
				success: function (collection) {
					deferred.resolve(collection);
				},
				error: function (error) {
					deferred.reject(error);
				}
			};

			leaderboards.fetch(fetchParams);

			return deferred;
		}
	};

	KXL.reqres.setHandler('leaderboard:entities', function (options) {
		return API.getLeaderboards(options);
	});
	KXL.reqres.setHandler('leaderboard:entry:entities', function (options) {
		return API.getLeaderboardEntryItem(options);
	});
	KXL.reqres.setHandler('leaderboard:entries:entities', function (options) {
		return API.getLeaderboardEntries(options);
	});
	KXL.reqres.setHandler('leaderboard:entry:entities:multi', function (options) {
		return API.getLeaderboardEntriesFor(options);
	});
});

KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {
	Entities.Locale = Entities.Model.extend({
		idAttribute: 'locale'
	});
	Entities.Locales = Entities.Collection.extend({
		model: Entities.Locale
	});
	var API = {
		setLocales: function(locales) {
			return new Entities.Locales(locales);
		}
	};
	KXL.reqres.setHandler('new:locales:entity', function (locales) {
		var localesObj = _.map(locales, function(locale){ return _.object(['locale'], [locale])});
		return API.setLocales(localesObj);
	});
});
KXL.module('Components.Ticker', function(Ticker, KXL, Backbone, Marionette, $, _) {
	Ticker.View = Marionette.ItemView.extend({
		template: '#ticker-template',
		tickerTimeline: null,
		events: {
			'mouseover .kx-ticker': 'tickerOver',
			'mouseout .kx-ticker': 'tickerOut'
		},
		tickerOver: function () {
			this.tickerTimeline.pause();
		},
		tickerOut: function () {
			this.tickerTimeline.play();
		},
		onShow: function () {
			var self = this;
			var tickerText = this.$el.find('.kx-ticker-text');
			var tickerTextContainer = this.$el.find('.kx-ticker');
			var tickerSpeed = (tickerText.outerWidth() / 75);
			var minSpeed = (tickerText.width() / 75);
			var tickerStartPosition = tickerTextContainer.width();

			this.tickerTimeline = new TimelineLite({onComplete: resetTicker});

			function resetTicker () {
				self.tickerTimeline.restart();
			}

			tickerText.ready(function () {
				tickerSpeed = (tickerText.outerWidth() / 75);
				minSpeed = (tickerText.width() / 75);
				if (tickerSpeed < minSpeed) {
					tickerSpeed = minSpeed;
				}
				tickerStartPosition = tickerTextContainer.width();
				tickerText.css('left', tickerStartPosition);
				self.tickerTimeline.add(TweenLite.to(tickerText, tickerSpeed ,
					{
						x: -(tickerText.outerWidth()),
						ease: 'linear'
					}));
				// Start ticker
				resetTicker();
			});
		}
	});
});

/* globals KXL */
KXL.module('ProfileApp.UserInfo', function (UserInfo, KXL, Backbone, Marionette, $, _) {
	UserInfo.View = Marionette.Layout.extend({
		template: '#home-user-info-template',
		className: 'kx-profile-user-info',
		modelEvents: {
			'change': 'render'
		},
		events: {
			'click .kx-avatar-edit' : 'editUserSettings'
		},
		initialize: function () {
			var self = this;
			var user = this.options.user;

			this.listenTo(user, 'change', this.render);

			if (KXL.currentUser) {

				this.friendsCollection = KXL.request('current:user:friend:entities')._collection;

				this.listenTo(this.friendsCollection, 'add remove reset', function () {
					self.checkFriend(user.id);
				});

				this.friend = this.friendsCollection.get(user.id);
			}
		},
		regions: {
			avatar: '.kx-profile-user-info-avatar',
			avatartBtnEditRegion: '.kx-edit-btn-region',
			msgBtnRegion: '.kx-msg-btn-region',
			chatBtnRegion: '.kx-chat-btn-region',
			removeFriendBtnRegion: '.kx-remove-friend-btn-region'
		},
		checkFriend: function (userId) {
			var friend = this.friendsCollection.get(userId);
			if (friend !== this.friend) {
				this.friend = friend;
				this.render();
			}
		},
		onRender: function () {
			var self = this;
			var avatar = KXL.request(
				'new:avatar:view',
				this.options.user.id,
				{
					size: 'large',
					model: this.options.user,
					color: KXL.Components.Avatar.borderColors.NONE
				}
			);

			this.avatar.show(avatar);

			// If it's the current user's profile show the edit button.
			if (KXL.currentUser.id === this.options.user.id) {
				// Create Avatar edit button
				var avatarBtnEdit =  new KXL.Components.Button.View({
					label: KXL.i18n.t('home.user.edit'),
					theme: KXL.Components.Button.Themes.alt3,
					customClass: 'kx-avatar-edit'
				});
				this.avatartBtnEditRegion.show(avatarBtnEdit);
			}

			if (this.options.user.get('username')) {
				var chatBtn =  new KXL.Components.Button.View({
					label: KXL.i18n.t('home.user.chat'),
					theme: KXL.Components.Button.Themes.alt11
				});
				this.listenTo(chatBtn, 'click', function () {
					if (!KXL.request('auth:register:deferred:gate', { referrer: 'profile-user-info-chat' })) {
						KXL.execute('chat:start:private', self.options.user.id);
					}
				});
				this.chatBtnRegion.show(chatBtn);
			}

			if (this.friend) {
				var removeFriendBtn = new KXL.Components.Button.View({
					label: KXL.i18n.t('home.user.removefriend'),
					theme: KXL.Components.Button.Themes.alt11
				});
				this.listenTo(removeFriendBtn, 'click', function () {
					// Display a confirmation dialog
					var removeDialogView = KXL.request(
						'show:confirm:dialog',
						{
							title: KXL.i18n.t('home.user.removefriend.dialog.label', { friendUserName: self.options.user.get('username') }),
							confirmButtonOptions: {
								label: KXL.i18n.t('home.user.removefriend.dialog.defriend')
							}
						}
					);

					self.listenToOnce(removeDialogView, 'confirm:clicked', function () {
						removeDialogView.trigger('start:loading');

						if (!KXL.request('is:user:ignored', this.options.user.id)) {
							KXL.request('current:user:remove:friend', this.options.user.id);
						}

						KXL.dialogRegion.close();
					});
				});
				this.removeFriendBtnRegion.ensureEl();
				this.removeFriendBtnRegion.$el.removeClass('kx-hidden');
				this.removeFriendBtnRegion.show(removeFriendBtn);
			}
		},
		serializeData: function () {
			var isOther = this.options.user.id !== KXL.currentUser.id;
			var buttonsEnabled =
				!!this.options.user.get('username') &&
				// Only enable buttons for self.
				this.options.user.id !== KXL.currentUser.id;

			var lastSeen = this.options.user.has('lastSeen') && KXL.Common.Utils.DateUtils.fuzzytime(this.options.user.get('lastSeen') * 1000);
			var createdAt = this.options.user.has('created') && KXL.Common.Utils.DateUtils.fuzzytime(this.options.user.get('created') * 1000);

			return {
				buttonsEnabled: buttonsEnabled,
				username: this.options.user.get('username'),
				haveTimestamps: !!(lastSeen && createdAt),
				createdAt: createdAt,
				lastSeen: lastSeen
			};
		},
		editUserSettings: function (e) {
			if (!KXL.request('auth:register:deferred:gate', { referrer: 'profile-avatar-edit' })) {
				KXL.execute('settings:show');
			}
		}
	});
});

KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {

	Entities.Alliance = Entities.Model.extend({
		initialize: function(options) {
			this.allianceId = options.allianceId;
			this.gameId = options.gameId;
			this.membersList = new Backbone.Collection();
		},
		url: function () {
			return KXL.config.API_URL +
				"/games/" + this.gameId + "/alliances/" + this.allianceId;
		},
		parse: function (response, options) {
			this.membersList.add(
				new Backbone.Collection(response.members).models,
				{ parse: true, remove: false, merge: true }
			);
			delete response.members;
			return response;
		}
	});

	var API = {
		getAlliance: function (gameId, allianceId) {

			var alliance = new Entities.Alliance({
				allianceId: allianceId,
				gameId: gameId
			});

			var deferred = $.Deferred();

			deferred._model = alliance;

			alliance.fetch({
				success: function(entity, response, options) {
					deferred.resolve(entity, response, options);
				},
				error: function (entity, response, options) {
					deferred.reject(entity, response, options);
				}
			});
			deferred._model = alliance;
			return deferred;
		}
	};

	KXL.reqres.setHandler('alliance:entity', function (gameId, allianceId) {
		return API.getAlliance(gameId, allianceId);
	});

});

KXL.module('Entities', function (Entities, KXL, Backbone, Marionette, $, _) {
	var postPaging = null;
	var latestPost = null;
	Entities.PostItem = Entities.Model.extend();
	Entities.PostsCollection = Entities.Collection.extend({
		model: Entities.PostItem,
		parse: function (response, options) {

			if (response.paging) {
				postPaging = response.paging;
				if (this.method === 'initial' || this.method === 'latest') {
					latestPost = postPaging.next;
				}
			}

			if (response.data) {

				var
					avatarCacheUpdates = {},
					actor
				;

				_.each(response.data, function (entry) {
					var actor = entry.actor || {};

					if (actor && actor.hydrated && actor.hydrated.avatars) {
						avatarCacheUpdates[actor.hydrated.id] = actor.hydrated.avatars;
					}

					var object = entry.object || {};
					if (object.comments && object.comments.data) {
						_.each(object.comments.data, function (comment) {
							var author = comment.author || {};
							if (author.avatars) {
								avatarCacheUpdates[author.id] = author.avatars;
							}
						});
					} else {
						object.comments = {
							data: [],
							paging: []
						};
					}

					var comments = object.comments;
					object.comments = KXL.request(
						'item:get:comments',
						comments.data,
						{
							entityType: object.entityType,
							entityId: object.entityId,
							postId: entry.id
						}
					);
					object.comments.paging = comments.paging;
				});

				if (!_.isEmpty(avatarCacheUpdates)) {
					// Send in batch to cache service for less event noise.
					KXL.request('set:services:avatars', avatarCacheUpdates);
				}

			}

			// Filter out any posts that don't have hydrated data.
			return _.reject(response.data, function (post) {
				return _.isEmpty(post.object.hydrated);
			});
		}
	});

	/*
	 * Fetch posts based on criteria set below.
	 */
	var getPosts = function(url, method) {
		Entities.Posts = new Entities.PostsCollection();
		Entities.Posts.method = method;
		var deferred = $.Deferred();
		var fetchUrl = url;
		Entities.Posts.url = fetchUrl;
		Entities.Posts.fetch({
			success: function (entity, response, options) {
				deferred.resolve(entity);
			},
			error: function (entity, response, options) {
				deferred.reject(response);
			}
		});
		return deferred.promise();
	};

	var API = {
		/*
		 * Initially get posts
		 */
		getPosts: function (userId) {
			return getPosts(KXL.config.API_URL + '/users/' + userId + '/activity-stream-posts', 'initial');
		},
		/*
		 * Get the users latest posts
		 */
		getLatestPosts: function (userId) {
			var url = KXL.config.API_URL + '/users/' + userId + '/activity-stream-posts';
			if (latestPost) {
				url += latestPost;
			} else {
				return null;
			}
			return getPosts(url, 'latest');
		},
		/*
		 * Get user's older posts (next page)
		 */
		getPreviousPosts: function (userId) {
			var url = KXL.config.API_URL + '/users/' + userId + '/activity-stream-posts';
			if (postPaging && postPaging.previous) {
				url += postPaging.previous;
			} else {
				return null;
			}
			return getPosts(url, 'previous');
		},
		/*
		 * Hide post
		 */
		hidePost: function (userId, postId) {
			var deferred = $.Deferred();
			$.ajax({
				type: 'PATCH',
				url: KXL.config.API_URL + '/users/' + userId + '/activity-stream-posts/' + postId,
				dataType: 'json',
				contentType: 'application/json',
				data: JSON.stringify({isHidden: true})
			}).done(function(data) {
				deferred.resolve(data);
			}).fail(function(error) {
				deferred.resolve(error);
			});
			return deferred.promise();
		},
		/*
		 * Get post paging
		 */
		getPostPaging: function () {
			return postPaging;
		}
	};
	KXL.reqres.setHandler('get:posts:by:userid', function (userId) {
		return API.getPosts(userId);
	});
	KXL.reqres.setHandler('get:latest:posts:by:userid', function (userId) {
		return API.getLatestPosts(userId);
	});
	KXL.reqres.setHandler('get:previous:posts:by:userid', function (userId) {
		return API.getPreviousPosts(userId);
	});
	KXL.reqres.setHandler('get:posts:paging', function () {
		return API.getPostPaging();
	});
	KXL.reqres.setHandler('hide:post:by:userid', function (userId, postId) {
		return API.hidePost(userId, postId);
	});
});

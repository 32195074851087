KXL.module('GamesApp.Nav', function(Nav, KXL, Backbone, Marionette, $, _) {
	var NavBarItems = KXL.Entities.SingleSelectCollection.extend({
		model: KXL.Entities.Model
	});

	Nav.Controller = {
		getNavView: function(game, section) {
			var
				commonName = game.get('commonName'),
				staticGame,
				sections,
				logoSection = {
					id: 'logo',
					subnavText: '',
					subnavClassName: 'kx-game-nav-item-logo'
				}
			;

			if (!commonName) {
				return;
			}

			staticGame = KXL.staticGames.findWhere({ commonName: game.get('commonName') });

			if (!staticGame || staticGame.get('hideSubnav')) {
				return;
			}

			sections = staticGame.get('subMenuItems');
			logoSection.backgroundImage = KXL.config.STATIC_BASE_URL + staticGame.get('subnavLogo') || '';
			logoSection.url = '/game/'.concat(staticGame.get('urlName'), '/home');
			sections.splice(Math.floor(sections.length / 2), 0, logoSection);

			var navBarItems = new NavBarItems(sections);

			function setActiveSection(section) {
				if (section === 'logo') {
					section = 'home';
				}
				var targetNavBarItem = navBarItems.findWhere({ 'id': section });
				if (targetNavBarItem) {
					targetNavBarItem.select();
				}
			}

			// Set the default section
			if (section) {
				setActiveSection(section);
			}

			// if coming from fb remove play button, or if you are not logged in.
			if (!KXL.config.SHOW_GAMES || !KXL.currentUser) {
				navBarItems.remove(navBarItems.get('play'));
			}

			function buildView() {
				var nav = new Nav.View({
					collection: navBarItems,
					model: staticGame
				});

				nav.on('set:active:section', function (section) {
					setActiveSection(section);
				});

				nav.on('nav:item:clicked', function (navItemView) {
					var
						section = navItemView.model.id,
						url = navItemView.model.get('url'),
						gameName = this.model.id
						;

					switch (section) {
						case 'home':
							KXL.execute('game:show', gameName, section);
							break;
						case 'guide':
							KXL.asyncRequest('async:load:module', 'guide').then(function () {
								KXL.execute('guide:show', gameName);
							});
							break;
						case 'strategy':
						case 'forum':
						case 'support':
							window.open(url);
							break;
						case 'play':
							var currentGame = KXL.context && KXL.context.get('commonName');
							if (KXL.request('auth:is:temp:user') &&
								!_.has(KXL.config.autoRegCommonNames, currentGame)) {
								KXL.execute('auth:register:deferred:show', { referrer: gameName + '-play' });
							} else {
								if(KXL.currentUser) {
									KXL.execute('game:play:load', gameName);
								} else {
									KXL.execute('game:show', gameName, 'home');
								}
							}
							break;
						case 'logo':
							section = 'home';
							KXL.execute('game:show', gameName, section);
							break;
						default:
							break;
					}

					setActiveSection(section);
				});

				// If a user successfully enters a beta code, update the nav to remove any items that have hideWithBetaAccess
				nav.listenTo(KXL.vent, 'beta:code:success', function () {
					// To be safe, check if the game they currently have selected was the game they were granted access to
					KXL.asyncRequest('game:is:user:allowed').then(function (isAllowed) {
						if (isAllowed) {
							navBarItems.remove(navBarItems.filter(function (item) {
								return item.get('hideWithBetaAccess');
							}));
						}
					});
				});

				return nav;
			}

			if (KXL.context.get('inviteOnly')) {
				var defer = $.Deferred();
				KXL.asyncRequest('game:is:user:allowed').then(function (isAllowed) {
					if (isAllowed) {
						navBarItems.remove(navBarItems.filter(function (item) {
							return item.get('hideWithBetaAccess');
						}));
					}
					defer.resolve(buildView());
				});
				return defer.promise();
			} else {
				navBarItems.remove(navBarItems.filter(function (item) {
					return item.get('requireBetaMode');
				}));
				return $.Deferred().resolve(buildView()).promise();
			}
		}
	};
});

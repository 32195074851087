KXL.module('Components.BootstrapCarousel', function (Carousel, KXL, Backbone, Marionette, $, _) {
	"use strict";

	var API = Marionette.Controller.extend({
		getCarousel: function (tags) {
			var slides = new Carousel.Entities.Carousel();
			var defer = $.Deferred();
			slides.fetch({
				data: {
					tags: (tags || []).join(',')
				},
				success: function (collection) {
					var indicators;
					var domId = 'kx-carousel-' + (tags || []).join(',');
					var layout = new Carousel.Views.CarouselLayout({
							id: domId
						}),
						carousel = new Carousel.Views.Carousel({
							collection: collection
						});

					if (collection && collection.length > 1) {
						// only display indicators if there is more than one slide
						indicators = new Carousel.Views.Indicators({
							collection: collection,
							carouselSelector: '#' + domId
						});
					}

					carousel.on('carousel:slide:clicked', function (slideView) {
						if (slideView && slideView.model) {
							var url = slideView.model.get('url');
							KXL.navigate(url, { trigger: true, target: '_blank' });
						}
					});
					layout.on('render', function () {
						if (indicators) {
							layout.indicatorsRegion.show(indicators);
						}
						layout.imagesRegion.show(carousel);
						layout.$el.carousel();
					});
					layout.on('before:close', function () {
						// Without this there is a js error because the carousel
						// continues to rotate...
						// TODO: figure out how to completely blow away this
						// reference to the carousel.
						this.$el.carousel('pause');
					});
					defer.resolve(layout);
				},
				error: function () {
					KXL.execute('500:show');
				}
			});
			return defer.promise();
		}
	});

	Carousel.Controller = new API();
});
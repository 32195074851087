KXL.module('GamesApp.Battlelog', function(Battlelog, KXL, Backbone, Marionette, $, _) {
	Battlelog.Controller = {

		battleLogLayout: null,
		getBattlelog: function (userId, gameId, gameCommonName) {

			var itemView;

			switch (gameCommonName)
			{
				case 'bp':
					itemView = Battlelog.BattlelogBattlePiratesItem;
					break;
				case 'wc':
					itemView = Battlelog.BattlelogWarCommanderItem;
					break;
				case 'vc':
					itemView = Battlelog.BattlelogVegaConflictItem;
					break;
				default:
					itemView = Battlelog.EmptyView;
					break;
			}

			var battlelogCollection = KXL.request('get:services:battlelog', gameId);

			if (!battlelogCollection) {

				battlelogCollection = KXL.request(
					'battlelog:entities',
					userId,
					gameId
				)._collection;

				// We only cache battlelogs for current user
				if (KXL.currentUser.id === userId) {
					KXL.request('set:services:battlelog', battlelogCollection, gameId);
				}
			}

			this.battleLogLayout = new Battlelog.View();

			this.battleLogLayout.on('show', function() {

				var self = this;

				var collectionView = new Battlelog.CollectionView({
					collection: battlelogCollection,
					itemView: itemView
				});

				self.battlelogListRegion.show(collectionView);

			});

			return this.battleLogLayout;
		}
	};
});

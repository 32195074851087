KXL.module('Chat.ChatApp', function(ChatApp, KXL, Backbone, Marionette, $, _) {

	ChatApp.View = Marionette.Layout.extend({
		template: '#chat-template',
		className: 'kx-chat-app',
		horizontalSplitterDragging: false,
		verticalSplitterDragging: false,
		topPanelButtonsEl: null,
		topPanelSettingsEl: null,
		triggers: {
			'click .start-chat': 'chat:start:thread',
			'click .chat-go-online': 'chat:go:online'
		},
		ui: {
			loading: '.chat-loading',
			offline: '.chat-offline',
			topPanelWrapper: '.chat-top-wrapper',
			topPanel: '.chat-top-panel',
			threadsContainer: '.chat-threads-container',
			defaultThreads: '.chat-default-threads',
			chatThreads: '.chat-user-threads',
			conversation: '.chat-conversation',
			horizGrippy: '.chat-divider',
			vertGrippy: '.vert-chat-divider',
			startChatBtn: '.start-chat'
		},
		regions: {
			topPanel: '.chat-top-panel',
			settingsPanel: '.chat-top-panel-settings',
			defaultThreads: '.chat-default-threads',
			chatThreads: '.chat-user-threads',
			conversation: '.chat-conversation',
			horizGrippy: '.chat-divider'
		},
		initialize: function () {
			var userSettings = KXL.request('current:user:settings:entity');
		},
		onRender: function () {
			var self = this;
			this.ui.sidebarButton = $(document).find('.kx-user-chats-wrapper');
			KXL.vent.on('chat:update:ui', function () {
				self.ui.conversationMessageBoxHeight = self.ui.conversation.find('.compose-message-region');
				self.ui.conversationHeader = self.ui.conversation.find('.recipient-region');
				if (self.options.vent) {
					self.options.vent.trigger('content:size:adjust');
				}
			});
			this.on('container:size:adjust', function (containerHeight) {
				self.sizeAdjust(containerHeight);
			});
		},
		onShow: function () {
			this.ui.threadsContainer.addClass('visible');
		},
		sizeAdjust: function (containerHeight) {
			this.$el.css('height', containerHeight + 'px');
			if (this.options.vent) {
				if (this.chatThreads.currentView) {
					this.options.vent.trigger('content:isolate:scrolling', this.ui.threadsContainer);
				}
				if (this.conversation.currentView) {
					this.options.vent.trigger('content:isolate:scrolling',
						this.conversation.currentView.$el.find('.kx-chat-message-list'));
				}
			}
		}
	});

	/**
	 * A Footer CTA view that is used for recipient input only.
	 *
	 * @type {*|void|Object}
	 */
	ChatApp.RecipientFooterCTAView = Marionette.ItemView.extend({
		template: '#chat-footer-cta-template',
		className: 'kx-chat-footer-cta',
		triggers: {
			'click .kx-cancel-button': 'cancel:clicked',
			'click .kx-done-button': 'done:clicked'
		},
		onCancelClicked: function(view) {
			ChatApp.trigger('chat:conversation:footer:recipient:cancel');
		},
		onDoneClicked: function(view) {
			ChatApp.trigger('chat:conversation:footer:recipient:done');
		}

	});

	ChatApp.TopPanelButtonsView = Marionette.ItemView.extend({
		template: '#chat-top-panel-buttons',
		triggers: {
			'click .kx-start-chat-button': 'chat:start:thread',
			'click .kx-settings-button': 'settings:clicked'
		},
		onSettingsClicked: function(view) {
			ChatApp.trigger('settings:clicked');
		},
		onChatStartThread: function(view) {
			ChatApp.trigger('chat:start:thread');
		},
		templateHelpers: function() {
			var data = {};
			if (this.options.title) {
				data.headingText = this.options.title;
			}
			return data;
		}
	});

	ChatApp.TopPanelBreadcrumbView = Marionette.ItemView.extend({
		template: '#chat-top-panel-breadcrumb',
		triggers: {
			'click .kx-settings-button': 'settings:clicked',
			'click .kx-sidebar-close-button': 'close:clicked',
			'click .kx-breadcrumb-back-button': 'back:clicked'
		},
		ui: {
			title: '.kx-heading-title > span',
			settingsBtn: '.kx-settings-button'
		},
		enableSettings: true,
		initialize: function(options) {
			if (!options.thread ||
				options.thread.get('type') === 'default') {
				this.enableSettings = false;
			}
		},
		onSettingsClicked: function(view) {
			ChatApp.trigger('sub:settings:clicked', this.options.thread);
		},
		onCloseClicked: function(view) {
			ChatApp.trigger('close:clicked');
		},
		onBackClicked: function(view) {
			ChatApp.trigger('back:clicked');
		},
		templateHelpers: function() {
			var data = {};
			if (this.options.title) {
				data.headingText = this.options.title;
			}
			data.enableSettings = this.enableSettings;
			return data;
		}
	});

	ChatApp.ConvoSettingsView = Marionette.Layout.extend({
		getTemplate: function () {
			if (this.options.thread && this.options.thread.get('type') === 'private') {
				return '#chat-conversation-private-settings-template';
			}
			return '#chat-conversation-group-settings-template';
		},
		className: 'kx-chat-conversation-settings',
		tagName: 'div',
		triggers: {
			'click .kx-convo-settings-button': 'convo:clicked',
			'click .kx-convo-leave-room-button': 'leave:clicked'
		},
		onConvoClicked: function() {
			ChatApp.trigger("chat:conversation:settings:clicked");
		},
		onLeaveClicked: function() {
			ChatApp.trigger("chat:conversation:leave:room:clicked");
		}
	});

	ChatApp.SettingsView = Marionette.Layout.extend({
		template: '#chat-settings-template',
		className: 'kx-chat-settings-template',
		regions: {
			showOnlineStatusAsRegion: '.kx-show-online-status-as-region',
			showKIXEYEChatRegion: '.kx-show-kixeye-chat-region',
			showBattlePiratesChatRegion: '.kx-show-battle-pirate-chat-region',
			showVEGAConflictChatRegion: '.kx-show-vega-conflict-chat-region',
			showWarCommanderChatRegion: '.kx-show-war-commander-chat-region',
			showProfanityFilterRegion: '.kx-show-profanity-filter-region',
			playSoundRegion: '.kx-play-sound-region',

		},
		initialize: function() {
			var self = this;
			this.settingsModel = KXL.request('current:user:settings:entity');

			// Listen to all chat setting changes
			this.listenTo(this.settingsModel, [
				'change:showOnlineStatusAs'
			].join(' '), function () {
				self.render();
			});

			var chatInterface = KXL.request('get:chat:interface');
			this.listenTo(chatInterface, 'connection', function (status) {
				if (status === 'ONLINE') {
					self.adjustDefaultRoomCheckboxes();
				}
			});
		},

		adjustDefaultRoomCheckboxes: function () {
			var self = this;
			var chatInterface = KXL.request('get:chat:interface');
			_.each([
				'showKIXEYEChat',
				'showBattlePiratesChat',
				'showVEGAConflictChat',
				'showWarCommanderChat'
			], function (settingsKey) {

				if (chatInterface.getDefaultRoomBySettingsKey(settingsKey)) {
					self.showCheckbox(settingsKey);
				}
				else {
					self.hideCheckbox(settingsKey);
				}

			});
			KXL.vent.trigger('chat:update:ui');
		},

		showCheckbox: function (settingsKey) {
			var itemName = settingsKey + 'Region';
			var checkbox = this[itemName];
			if (checkbox) {
				checkbox.$el.show();
			}
		},

		hideCheckbox: function (settingsKey) {
			var itemName = settingsKey + 'Region';
			var checkbox = this[itemName];
			if (checkbox) {
				checkbox.$el.hide();
			}
		},

		onRender: function () {

			var self = this;

			// Show Online Status
				self.showOnlineStatusAsRegionRadioGroup = new KXL.Components.RadioButton.Controller.getRadioButtonGroup(
					[
						{
							value: 'online',
							label: KXL.i18n.t('chat.settings.online'),
							checked: self.settingsModel.get('showOnlineStatusAs') === 'online' ? true : false
						},
						{
							value: 'offline',
							label: KXL.i18n.t('chat.settings.offline'),
							checked: self.settingsModel.get('showOnlineStatusAs') === 'offline' ? true : false
						}
					]
				);
				self.showOnlineStatusAsRegionRadioGroup.on('change:selected', function (params) {
					self.settingsModel.set('showOnlineStatusAs', params.value);
					self.settingsModel.save();
				});
				self.showOnlineStatusAsRegion.show(self.showOnlineStatusAsRegionRadioGroup);


			// Show KIXEYE
			var showKIXEYEChatCheckbox = new KXL.Components.CheckBox.View({
				label: KXL.i18n.t('chat.settings.showKIXEYEChat'),
				model: this.settingsModel,
				modelAttr: 'showKIXEYEChat',
				customClass: 'kx-setting-checkbox'
			});
			this.showKIXEYEChatRegion.show(showKIXEYEChatCheckbox);
			showKIXEYEChatCheckbox.on('change', function () {
				self.settingsModel.save();
			});

			// Show Battle Pirates
			var showBattlePiratesChatCheckbox = new KXL.Components.CheckBox.View({
				label: KXL.i18n.t('chat.settings.showBattlePiratesChat'),
				model: this.settingsModel,
				modelAttr: 'showBattlePiratesChat',
				customClass: 'kx-setting-checkbox'
			});
			this.showBattlePiratesChatRegion.show(showBattlePiratesChatCheckbox);
			showBattlePiratesChatCheckbox.on('change', function (){
				self.settingsModel.save();
			});

			// Show VEGA Conflict
			var showVEGAConflictCheckBox = new KXL.Components.CheckBox.View({
				label: KXL.i18n.t('chat.settings.showVEGAConflictChat'),
				model: this.settingsModel,
				modelAttr: 'showVEGAConflictChat',
				customClass: 'kx-setting-checkbox'
			});
			this.showVEGAConflictChatRegion.show(showVEGAConflictCheckBox);
			showVEGAConflictCheckBox.on('change', function (){
				self.settingsModel.save();
			});

			// Show War Commander
			var showWarCommanderCheckBox = new KXL.Components.CheckBox.View({
				label: KXL.i18n.t('chat.settings.showWarCommanderChat'),
				model: this.settingsModel,
				modelAttr: 'showWarCommanderChat',
				customClass: 'kx-setting-checkbox'
			});
			this.showWarCommanderChatRegion.show(showWarCommanderCheckBox);
			showWarCommanderCheckBox.on('change', function () {
				self.settingsModel.save();
			});

			// Show Profanity Filter
			var showProfanityCheckBox = new KXL.Components.CheckBox.View({
				label: KXL.i18n.t('chat.settings.filterProfanity'),
				model: this.settingsModel,
				modelAttr: 'filterProfanity',
				customClass: 'kx-setting-checkbox'
			});
			this.showProfanityFilterRegion.show(showProfanityCheckBox);
			showProfanityCheckBox.on('change', function () {
				self.settingsModel.save();
			});
			// Play sound
			var playSoundCheckBox = new KXL.Components.CheckBox.View({
				label: KXL.i18n.t('chat.settings.playSound'),
				model: this.settingsModel,
				modelAttr: 'chatPlaySound',
				customClass: 'kx-setting-checkbox'
			});
			this.playSoundRegion.show(playSoundCheckBox);
			playSoundCheckBox.on('change', function () {
				self.settingsModel.save();
			});

			self.adjustDefaultRoomCheckboxes();

		}
	});

	ChatApp.settingsVisible = false;

	ChatApp.TopPanelView = Marionette.Layout.extend({
		template: '#chat-top-panel-template',
		className: 'kx-chat-top-panel',
		regions: {
			buttons: '.chat-top-panel-buttons',
			settings: '.chat-top-panel-settings'
		},
		ui: {
			settingsPanel: '.chat-top-panel-settings'
		},

		topPanelButtonView: null,

		initialize: function () {
			var self = this;
			this.listenTo(ChatApp, 'settings:clicked', function (args) {
				ChatApp.settingsVisible ? self.ui.settingsPanel.removeClass('visible') : self.ui.settingsPanel.addClass('visible');
				ChatApp.settingsVisible = !ChatApp.settingsVisible;
				self.settings.show(new ChatApp.SettingsView());
				KXL.vent.trigger('chat:update:ui', this);
			});

			self.listenTo(self.settings, 'show', function () {
				KXL.vent.trigger('chat:update:ui', this);
				if (ChatApp.settingsVisible) {
					if (self.settings.currentView.adjustDefaultRoomCheckboxes) {
						self.settings.currentView.adjustDefaultRoomCheckboxes();
					}
				}
			});

			function hideChatSettings() {
				ChatApp.settingsVisible = false;
				self.ui.settingsPanel.removeClass && self.ui.settingsPanel.removeClass('visible');
				self.settings.reset();
				KXL.vent.trigger('chat:update:ui', this);
			}

			/**
			 * Event Handler: If the conversation top header cog is fired, lets show the convo settings.
			 *
			 * @param  {object}  args  object representing the arguments payload for this event
			 */
			this.listenTo(ChatApp, 'sub:settings:clicked', function (thread) {
				ChatApp.settingsVisible ? self.ui.settingsPanel.removeClass('visible') : self.ui.settingsPanel.addClass('visible');
				ChatApp.settingsVisible = !ChatApp.settingsVisible;

				self.settings.show(new ChatApp.ConvoSettingsView({ thread: thread }));
			});

			this.listenTo(KXL.vent, 'chat:settings:hide', hideChatSettings);

			this.listenTo(ChatApp, 'back:clicked breadcrumb:show leave:room:clicked', hideChatSettings);
		},

		onRender: function () {
			this.settings.show(new ChatApp.SettingsView());
			if (!ChatApp.settingsVisible) {
				this.ui.settingsPanel.removeClass('visible');
			}
		}
	});

	ChatApp.ThreadAvatarView = Marionette.ItemView.extend({
		template: '#chat-thread-avatar-view-template',
		className: 'kx-group-avatar-wrapper',
		tagName: 'div',
		templateHelpers: function() {
			var data = {};

			if (this.options.groupLogo) {
				data.logoImage = this.options.groupLogo;
			}

			return data;
		}
	});

	ChatApp.ThreadView = Marionette.Layout.extend({
		template: '#chat-thread-template',

		className: 'kx-chat-thread',

		triggers: {
			'click': 'click',
			'click .kx-chat-thread-remove': 'remove:click'
		},

		ui: {
			title: '.kx-chat-thread-title',
			snippet: '.kx-chat-thread-snippet',
			timestamp: '.kx-chat-thread-ts'
		},
		regions: {
			avatar: '.kx-chat-thread-avatar-region'
		},
		modelEvents: {
			'change': 'render',
			'blink': 'startBlink',
			'blink:twice': 'blinkForTwoSeconds',
			'blink:stop': 'stopBlink'
		},

		blinkInterval: null,
		lastMessageModel: null,

		initialize: function () {
			var self = this;
			self.settingsModel = KXL.request('current:user:settings:entity');
			var chatInterface = KXL.request('get:chat:interface');
			var type = this.model.get('type');
			var members = this.model.get('members');
			var messages = this.model.get('messages');

			if (KXL.currentUser) {
				self.listenTo(self.settingsModel, 'change:filterProfanity', function () {
					self.render();
				});
			}

		},
		getGameIdForRoom: function(roomId) {
			var gameId,
				gameRoomModel,
				gameModel,
				commonName = null;
			var defaultRooms = KXL.request('get:chat:interface')._defaultRooms;
			if (roomId) {
				gameRoomModel = _.findWhere(defaultRooms, {id : roomId});
				if (gameRoomModel) {
					commonName = gameRoomModel.commonName;
					gameModel = KXL.games.findWhere({ commonName: commonName });

					if (gameModel) {
						gameId = gameModel.get('id');
					}
				}
			}
			return gameId;
		},
		updatePublicAvatar: function(gameId) {
			if (gameId) {
				var avatarView = KXL.request(
					'new:game:logo:view',
					gameId,
					{ size: 'xsmall' }
				);
				this.avatar.show(avatarView);
			}
		},
		updateAvatar: function(messageModel) {
			try {
				var userId = null;
				var type = this.model.get('type');
				if (type === 'private') {
					var members = this.model.get('members');
					var peer = members.find(function (member) {
						return member.get('userId') !== KXL.request('get:current:user').id;
					});
					if (peer) {
						userId = peer.id;
					}
				} else {
					var lastMessage = messageModel;
					if (lastMessage && lastMessage.has('userId')) {
						userId = lastMessage.get('userId');
					}
				}
				if (userId) {
					var avatarView = KXL.request('new:avatar:view', userId, {
						avatarMenu: KXL.Components.Avatar.avatarMenu.OFF,
						presence: KXL.Components.Avatar.presence.CHAT,
						size: 'small',
						color: 'black'
					});
					if (this.avatar) {
						this.avatar.show(avatarView);
					}
				}
			} catch (ex) {
				/*	Do nothing with this error for now, basically we're having an issue where the above region
					is not available because a sort is happening at the same time.
				 */
			}
		},
		updateSnippet: function(messageModel) {
			try {
				var lastMessageSnippet = (messageModel && messageModel.has('body')) ? messageModel.get('body') : null,
					snippetLength = 100;

				if (lastMessageSnippet) {
					$.trim(lastMessageSnippet);
					if (lastMessageSnippet.length > snippetLength) {
						lastMessageSnippet = lastMessageSnippet.substr(0, (snippetLength - 3));
						lastMessageSnippet = lastMessageSnippet.concat('...');
					}

					if (this.settingsModel.get('filterProfanity') === true) {
						lastMessageSnippet = KXL.Common.Utils.StringUtils.filterMessage(lastMessageSnippet);
					}

					if (this.ui.snippet instanceof jQuery) {
						this.ui.snippet.text(lastMessageSnippet);
					}
				}
			} catch (ex) {
				/*	Do nothing with this error for now, basically we're having an issue where the above ui element
					is not available because a sort is happening at the same time.
				 */
			}
		},
		startBlink: function () {
			var self = this;

			if (!this.blinkInterval) {

				self.$el.addClass('kx-blink');
				this.blinkInterval = setInterval(function () {
					self.$el.toggleClass('kx-blink');
				}, KXL.config.chatFlashInterval);
				return true;
			}
			return false;
		},

		stopBlink: function () {
			if (this.blinkInterval) {
				clearInterval(this.blinkInterval);
				this.blinkInterval = null;
				this.$el.removeClass('kx-blink');
			}
		},

		blinkForTwoSeconds: function () {
			var self = this;
			if (self.startBlink()) {
				_.delay(function () {
					self.stopBlink();
				}, KXL.config.chatFlashInterval * 4);
			}
		},

		setTimestamp: function() {
			if (this.ui.timestamp instanceof jQuery) {
				var timestamp = _.max(this.model.get('messages').pluck('timestamp'));
				if (timestamp === -Infinity) {
					this.ui.timestamp.addClass('infinity');
				} else {
					this.ui.timestamp.html(KXL.Common.Utils.DateUtils.fuzzytime2(timestamp));
				}
			}
		},

		onRender: function () {
			var
				threadType = this.model.get('type'),
				threadTitle = this.model.get('title') || '',
				self = this,
				type = this.model.get('type'),
				members = this.model.get('members'),
				messages = this.model.get('messages')
			;

			self.setTimestamp();

			if (type === 'private') {
				if (messages) {
					this.listenToOnce(messages, 'all', function(ev, messageModel) {
						if (ev === 'add') {
							self.updateAvatar(messageModel);
							self.updateSnippet(messageModel);
						}
					});
					if (messages.length > 0) {
						self.updateSnippet(messages.last());
						self.updateAvatar(messages.last());
					}
				}
			} else if (type === 'default') {
				self.updatePublicAvatar(self.getGameIdForRoom(self.model.get('id')));
			} else if (type === 'group') {
				if (messages) {
					this.listenToOnce(messages, 'all', function(ev, messageModel) {
						if (ev === 'add') {
							self.updateAvatar(messageModel);
							self.updateSnippet(messageModel);
						}
					});
					if (messages.length > 0) {
						self.updateSnippet(messages.last());
						self.updateAvatar(messages.last());
					}
				}
			}

			function setSelectedState(selected) {
				var thisEl = self.$el;
				if (thisEl) {
					if (selected) {
						thisEl.addClass('selected');
					} else {
						thisEl.removeClass('selected');
					}
				}
			}

			this.model.on('selected', function () {
				setSelectedState(true);
				self.stopBlink();
				KXL.execute('chat:update:icon:blink:status');
			});

			this.model.on('deselected', function () {
				setSelectedState(false);
			});

			if (this.model.selected) {
				setSelectedState(true);
			} else {
				setSelectedState(false);
			}

			if (!threadTitle.length) {
				members = this.model.get('members');

				if (threadType === 'group') {
					_.each(members.models, function (member) {
						if (!threadTitle.length) {
							threadTitle = member.get('username');
						} else {
							threadTitle += (', ' + member.get('username'));
						}
					});
				} else if (threadType === 'private') {
					var peer = members.find(function (member) {
						return member.get('userId') !== KXL.request('get:current:user').id;
					});
					if (peer) {
						threadTitle = peer.get('username');
					}
				}
				this.ui.title.text(threadTitle);
			}
		},

		onShow: function() {
			KXL.vent.trigger('chat:update:ui', this);
		}
	});

	ChatApp.ThreadsView = Marionette.CollectionView.extend({
		template: '#chat-threads-template',
		itemView: ChatApp.ThreadView,
		itemViewEventPrefix: 'chat:thread',
		onItemRemoved: function(itemView) {
			KXL.vent.trigger('chat:update:ui', this);
		},
		initialize: function() {
			this.listenTo(this.collection, 'sort', this.render);
		}
	});

	ChatApp.EmptyStateView = Marionette.ItemView.extend({
		template: function () {
			return '';
		}
	});
});

KXL.module('ProfileApp.Profile', function(Profile, KXL, Backbone, Marionette, $, _) {
	Profile.Controller = {
		currentUserId: null,
		currentProfileUser: null,
		showProfile: function (userId, options) {
			var
				self = this,
				isSelf = KXL.currentUser && KXL.currentUser.id === userId
			;
			if (!KXL.profileRegion.currentView ||
				this.currentUserId !== userId ||
				(
					self.currentProfileUser &&
					self.currentProfileUser.hasChanged()
				))
			{
				var user = self.currentProfileUser =
					isSelf ?
					KXL.currentUser :
					KXL.request('user:entity', userId)._model;
				KXL.appModel.set('currentProfilePageUser', user);
				KXL.appModel.set('currentProfilePageUserId', userId);
				this.currentUserId = userId;
				if (user) {
					var layout = new Profile.LoggedInLayout();
					layout.on('show', function () {

						// User info
						layout.userInfo.show(
							KXL.request(
								'new:contentpane:view',
								{
									contentView: KXL.request(
										'new:userinfo:view',
										user.id
									),
									type: KXL.Components.ContentPane.Types.NO_BOTTOM
								}
							)
						);

						// Friends
						layout.friends.show(
							KXL.request(
								'new:friends:view',
								user.id,
								{
									isSelf: userId === KXL.currentUser.id
								}
							)
						);

						// Game Stats
						if (KXL.config.SHOW_GAMES) {

							if (KXL.currentUser.id !== userId) {
								showUserGameStats();
							} else {
								showCurrentUserGameStats();
							}

						}

						// Check if the user viewing the profile is a friend.
						// If not show a add friend button.
						if (!isSelf) {

							var friendsCollection = KXL.request('current:user:friend:entities')._collection;

							var checkFriend = function (userId, friendsCollection, layout) {
								var friend = friendsCollection.get(userId);
								if (!friend && friendsCollection.fetchComplete) {

									// Not a friend, and not ignored - show the add friend button.
									var user = KXL.request('user:entity', userId).promise();

									$.when(user).done(function (usermodel) {

										var username = usermodel.get('username');
										var ignored = usermodel.get('ignored');

										if (_.isBoolean(ignored) && ignored === false && username) {

											KXL.asyncRequest('async:load:module', 'profile/add_friend').then(function () {

												var addFriendLayout = KXL.request(
													'new:addfriend:view',
													userId,
													username
												);
												layout.addFriend.show(addFriendLayout);
												addFriendLayout.checkRequestSent();

											});
										}

									});
								} else if (layout.addFriend.currentView) {
									layout.addFriend.close();
								}
							};

							this.listenTo(friendsCollection, 'add remove reset', function (args) {
								checkFriend(userId, friendsCollection, layout);
							});

							checkFriend(userId, friendsCollection, layout);
						}

						// Activity Feed
						layout.activity.show(
							KXL.request(
								'new:contentpane:view',
								{
									headingText: KXL.i18n.t('profile.gameActivity'),
									contentView: KXL.request(
										'new:activityfeed:view',
										user.id
									)
								}
							)
						);

					});

					function showUserGameStats() {
						var userGamesFetching = KXL.request('usergame:entities', userId);

						userGamesFetching.then(function(userGames) {

							var gameStatsViewDeferred = KXL.request(
								'new:gamestats:view',
								userGames,
								KXL.request('user:entity', userId)._model,
								options && options.gameId,
								{
									overrideGameContext: options && options.overrideGameContext
								}
							);

							gameStatsViewDeferred.then(function (view) {
								layout.gameStats.show(view);
							});

						});

					}

					function showCurrentUserGameStats() {
						var userGames = KXL.request('current:user:game:entities');

						//Binding to CACHED current user game collection. The one that websocket
						//events will change. Dependant on CACHE having data as a result of login
						// or page render. If cache is not bootstrapped this widget will flicker
						// on login.
						var gameStatsViewDeferred = KXL.request(
							'new:gamestats:view',
							userGames,
							KXL.currentUser,
							options && options.gameId
						);

						gameStatsViewDeferred.then(function (view) {
							layout.gameStats.show(view);
						});

						//Re-render when a usergame is added (user plays a game and we receive a WS event)
						userGames.on('add', function(model, collection) {
							var gameStatsViewDeferred = KXL.request(
								'new:gamestats:view',
								collection,
								KXL.currentUser,
								options && options.gameId
							);

							gameStatsViewDeferred.then(function (view) {
								layout.gameStats.show(view);
							});
						});
					}

					KXL.profileRegion.show(layout);
				}
			}
		}
	};
});

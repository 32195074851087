KXL.module('CookieNoticeApp.CookieNotice', function(CookieNotice, KXL, Backbone, Marionette, $, _) {
	CookieNotice.Controller = {
		loadCookieNotice: function () {
			var cookie = Cookies.get('kixeye-com-cookie-notice');
			if(typeof cookie === 'undefined') {
				var view = new CookieNotice.View();
				KXL.cookieNoticeRegion.show(view);
				Cookies.set('kixeye-com-cookie-notice', 1, {expires: 3652, secure: true, sameSite: 'None'});
				view.on('cookie_notice_close:clicked', function () {
					KXL.execute('cookie_notice:hide');
				});
			}
		},
		hideCookieNotice: function () {
			KXL.cookieNoticeRegion.ensureEl();
			if (KXL.cookieNoticeRegion.$el.is(':visible')) {
				KXL.cookieNoticeRegion.$el.hide();
			}
		}
	};
});

KXL.module('Components.LocaleSelector', function (LocaleSelector, KXL, Backbone, Marionette, $, _) {
	LocaleSelector.ItemView = Marionette.ItemView.extend({
		tagName: 'span',
		template: '#_locale-selector-item-template',
		modelEvents: function () {
			var
				self = this;
				events = {},
				namespace = this.collection.namespace
			;
			
			events['selected:' + namespace] = function () {
				self.$el.addClass('kx-active');
			};
			events['deselected:' + namespace] = function () {
				self.$el.removeClass('kx-active');
			};
			return events;
		},
		triggers: {
			'click' : 'clicked'
		},
		onRender: function () {
			if (this.collection.selected === this.model) {
				this.$el.addClass('kx-active');
			}
		},
		serializeData: function () {
			return {
				locale: this.model.get('locale'),
				name: KXL.i18n.t('localeSelector.' + this.model.get('locale') + '_name')
			}
		}
	});

	LocaleSelector.View = Marionette.CompositeView.extend({
		template: '#locale-selector-template',
		itemViewContainer: '.kx-locales',
		itemViewEventPrefix: 'locales:locale',
		itemViewOptions: function (model, index) {
			return {
				collection: this.collection,
				model: model
			}
		},
		itemView: LocaleSelector.ItemView,
		className: 'kx-locale-selector kx-clearfix kx-no-text-selection',
		modelEvents: {
			'change': 'render'
		}
	});
});

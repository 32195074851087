/**
 * A bastardization of Backbone.Picky by Derick Bailey
 *
 * Having too many issues where models were shared by collections and the selected
 * state being on the model was very problematic (and models calling select on the
 * this.collection would always call the very first collection that model was added
 * to, which wasn't always the collection that you wanted it selected in (or was a
 * native Backbone.Collection, which would throw an error).
 *
 *
 */

Backbone.PickThis = (function (Backbone, _) {
	var PickThis = {};

	// PickThis.SingleSelect
	// ------------------
	// A single-select mixin for Backbone.Collection, allowing a single
	// model to be selected within a collection. Selection of another
	// model within the collection causes the previous model to be
	// deselected.

	PickThis.SingleSelect = function(collection, namespace){
		this.namespace = namespace;
		this.collection = collection;
		this.collection.on('remove', function (model) {
			if (model === this.selected) {
				delete this.selected;
			}
		});
		this.collection.on('reset', function (collection) {
			if (!collection.get(this.selected)) {
				delete this.selected;
			}
		});
	};

	_.extend(PickThis.SingleSelect.prototype, {

		// Select a model, deselecting any previously
		// selected model
		select: function(model){
			if (model && this.selected === model) { return; }

			this.deselect();

			this.selected = model;
			this.selected.trigger("selected:" + this.namespace);
			this.trigger("select:one", model);
		},

		// Deselect a model, resulting in no model
		// being selected
		deselect: function(model){
			if (!this.selected){ return; }

			model = model || this.selected;
			if (this.selected !== model){ return; }

			var selected = this.selected;
			delete this.selected;
			selected.trigger("deselected:" + this.namespace);
			this.trigger("deselect:one", selected);
		}

	});

	// PickThis.MultiSelect
	// -----------------
	// A multi-select mixin for Backbone.Collection, allowing a collection to
	// have multiple items selected, including `selectAll` and `selectNone`
	// capabilities.

	PickThis.MultiSelect = function (collection, namespace) {
		this.selected = {};
		this.namespace = namespace;
		this.collection = collection;
	};

	_.extend(PickThis.MultiSelect.prototype, {

		// Select a specified model, make sure the
		// model knows it's selected, and hold on to
		// the selected model.
		select: function (model) {
			if (this.selected[model.cid]) { return; }

			this.selected[model.cid] = model;
			model.trigger('selected:' + this.namespace);
			calculateSelectedLength(this);
		},

		// Deselect a specified model, make sure the
		// model knows it has been deselected, and remove
		// the model from the selected list.
		deselect: function (model) {
			if (!this.selected[model.cid]) { return; }

			delete this.selected[model.cid];
			model.trigger('deselected:' + this.namespace);
			calculateSelectedLength(this);
		},

		// Select all models in this collection
		selectAll: function () {
			var self = this;
			this.each(function (model) {
				self.select(model);
			});
			calculateSelectedLength(this);
		},

		// Deselect all models in this collection
		selectNone: function () {
			var self = this;
			if (this.selectedLength === 0) { return; }
			this.each(function (model) {
				self.deselect(model);
			});
			calculateSelectedLength(this);
		},

		// Toggle select all / none. If some are selected, it
		// will select all. If all are selected, it will select 
		// none. If none are selected, it will select all.
		toggleSelectAll: function () {
			if (this.selectedLength === this.length) {
				this.selectNone();
			} else {
				this.selectAll();
			}
		}
	});

	// Helper Methods
	// --------------

	// Calculate the number of selected items in a collection
	// and update the collection with that length. Trigger events
	// from the collection based on the number of selected items.
	var calculateSelectedLength = function (collection) {
		collection.selectedLength = _.size(collection.selected);

		var selectedLength = collection.selectedLength;
		var length = collection.length;

		if (selectedLength === length) {
			collection.trigger("select:all", collection);
			return;
		}

		if (selectedLength === 0) {
			collection.trigger("select:none", collection);
			return;
		}

		if (selectedLength > 0 && selectedLength < length) {
			collection.trigger("select:some", collection);
			return;
		}
	};

	return PickThis;
})(Backbone, _);

/* globals KXL */
KXL.module('HeaderApp.Header', function(Header, KXL, Backbone, Marionette, $, _) {
	var navRibbonView;
	Header.Controller = new (Marionette.Controller.extend({
		bannersController: undefined,
		userControlsRegion: null,
		userMenuRegion: null,
		navToggle: null,
		navRibbon: null,
		searchView: null,
		friendsRegion: null,
		notificationsRegion: null,
		chatsRegion: null,
		vent: null,
		options: {},
		headerView: null,
		atom: null,
		initialize: function () {
			this.atom = atom.create();
		},

		createHeader: function () {
			var self = this;
			var header = this.headerView = new Header.View();
			header.bannersRegion.ensureEl();
			var bannersController =
				this.bannersController = new Header.Banner.Controller();
			header.on('show', function () {
				self.updateUserControls();
				$('body').addClass('header-showing');
				header.bannersRegion.show(bannersController.getBannersView());
				self.atom.set('header', header);
			});
			header.on('home:click', function () {
				KXL.execute('home:show');
			});

			this.flyoutOpenHeight = 162;
			this.navToggleView = new Header.NavToggleView(),
			this.navRibbonView = new Header.NavRibbonView();
			this.listenTo(KXL.appModel, 'change:navRibbonShowing', function() {
				var navRibbonShowing = KXL.appModel.get('navRibbonShowing');
				self.toggleFlyout(navRibbonShowing);
			});
			this.navToggleView.on('clicked', function () {
				KXL.appModel.set('navRibbonShowing', !KXL.appModel.get('navRibbonShowing'));
			});
			this.navRibbonView.on('cta:clicked', function () {
				KXL.appModel.set('navRibbonShowing', false);
			});

			/* Friend Panel */
			this.friendsRegion = new Backbone.Marionette.Region({
				el: '.kx-user-friends-wrapper'
			});

			this.navToggle = new Backbone.Marionette.Region({
				el: '.kx-header-nav-toggle-region'
			});

			this.navRibbon = new Backbone.Marionette.Region({
				el: '.kx-header-nav-ribbon-region'
			});

			this.userControlsRegion = new Backbone.Marionette.Region({
				el: '.kx-header-user-controls-region'
			});
			this.notificationsRegion = new Backbone.Marionette.Region({
				el: '.kx-user-notifications-wrapper'
			});
			this.chatsRegion = new Backbone.Marionette.Region({
				el: '.kx-user-chats-wrapper'
			});
			this.searchRegion = new Backbone.Marionette.Region({
				el: '.kx-searchbar-region'
			});

			KXL.vent.on('game:clicked', function (gameMenuItemView) {
				var redirectUrl = gameMenuItemView.model.get('redirectUrl');
				if (redirectUrl != null)
				{
					window.location = redirectUrl;
					return;
				}

				KXL.execute('game:show', gameMenuItemView.model.get('id'), 'home');
			});

			KXL.vent.on('game:section:clicked', function (gameMenuItemView, gameSubMenuItemView) {
				switch (gameSubMenuItemView.model.id) {
					case 'home':
					case 'about':
						KXL.execute('game:show', gameMenuItemView.model.id, gameSubMenuItemView.model.id);
						break;
					case 'guide':
						if (gameMenuItemView.model.id === 'battlepirates') {
							KXL.asyncRequest('async:load:module', 'guide').then(function () {
								KXL.execute('guide:show', gameMenuItemView.model.id);
							});
						} else {
							KXL.asyncRequest('async:load:module', 'game/guide').then(function () {
								KXL.execute('oldGuide:show', gameMenuItemView.model.id, gameSubMenuItemView.model.id);
							});
						}
						break;
					case 'forum':
						window.open(gameSubMenuItemView.model.get('url'));
						break;
					case 'groups':
						KXL.execute('group:show', null, gameMenuItemView);
						break;
					case 'support':
						window.open(gameSubMenuItemView.model.get('href'));
						break;
					case 'play':
						if(KXL.currentUser) {
							KXL.execute('game:play:load', gameMenuItemView.model.id);
						} else {
							if (!gameSubMenuItemView.model.get('startNowUrlName')) {
								KXL.execute('game:show', gameMenuItemView.model.id, 'home');
							}
						}
						break;
					default:
						break;
				}
			});

			KXL.vent.on('auth:changed', function () {
				self.updateUserControls();
			});
			return header;
		},
		showContextChangeDialog: function (currentContext, newContext) {
			var self = this;
			var defer = $.Deferred();
			KXL.appModel.set('navRibbonShowing', true);
			KXL.appModel.lock('navRibbonShowing');
			this.navRibbonView.overlay.show(new Header.ContextChangeOverlay({
				defer: defer,
				currentContext: currentContext,
				newContext: newContext
			}));
			defer.always(function(changeContext) {
				KXL.appModel.unlock('navRibbonShowing');
				if (changeContext) {
					KXL.appModel.set('navRibbonShowing', false);
				}
				self.navRibbonView.overlay.close();
			});
			return defer.promise();
		},

		toggleFlyout: function(showing) {
			this.navRibbon.ensureEl();
			if (showing) {
				$('.kx-menu-wrapper').removeClass('kx-active');
				KXL.request('banner:remove', 'accountInfoRibbon');
				this.navToggleView.toggleActive(true);
				this.navRibbon.$el.css('height', this.flyoutOpenHeight);
				KXL.vent.trigger('request:header:height:change:check');
			} else {
				this.navToggleView.toggleActive(false);
				this.navRibbon.$el.css('height', 0);
				KXL.vent.trigger('request:header:height:change:check');
			}
		},

		updateUserControls: function () {
			var self = this;

			this.navToggle.show(this.navToggleView);
			this.navRibbon.show(this.navRibbonView);

			if (KXL.currentUser) {
				// Logged in.
				var menuItemClicked = function (menuItem) {

					if (menuItem.options.buttonClassName &&
						menuItem.options.buttonClassName === 'kx-chat-btn') {
						KXL.execute('chat:toggle');
					}

					if (menuItem.options.sidebarViewId) {
						KXL.atom.once('sidebar:states:ready', function () {
							var sidebarItemId = menuItem.options.sidebarViewId;
							var sidebarView = KXL.request('sidebar:get:content', sidebarItemId);
							if (sidebarView) {
								if (!sidebarView.$el.is(':visible')) {
									KXL.request('sidebar:show:content', sidebarItemId);
								} else {
									KXL.request('sidebar:hide:content', sidebarItemId);
								}
							}
						});
					}

				};

				this.headerView.ui.userMenu.show();

				var userFriendsView = new Header.UserMenuItem({
					buttonClassName: 'kx-friends-btn',
					text: KXL.i18n.t('header.userFriends'),
					sidebarViewId: 'friends'
				});
				this.headerView.listenTo(userFriendsView, 'user:menu:clicked', function (item) {
					menuItemClicked(item);
				});
				this.friendsRegion.show(userFriendsView);
				KXL.vent.on("user:userFriendRequests:count:changed", function(count) {
					userFriendsView.updateFriendRequestsCount(count);
				});

				var notificationsView = new Header.UserMenuItem({
					buttonClassName: 'kx-notifications-btn',
					text: KXL.i18n.t('header.userNotifications'),
					sidebarViewId: 'notifications'
				});

				this.headerView.listenTo(notificationsView, 'user:menu:clicked', function (item) {
					menuItemClicked(item);
				});
				this.notificationsRegion.show(notificationsView);
				KXL.vent.on('user:userNotifications:count:changed', function (count) {
					notificationsView.updateNotificationCount(count);
				});

				if (KXL.switches.chatEnabled) {
					var userChatsView = new Header.UserMenuItem({
						buttonClassName: 'kx-chat-btn',
						text: KXL.i18n.t('header.userChat')
					});
					this.headerView.listenTo(userChatsView, 'user:menu:clicked', function (item) {
						menuItemClicked(item);
					});
					this.chatsRegion.show(userChatsView);
					KXL.vent.on('user:userChats:count:changed', function (count) {
						userChatsView.updateNotificationCount(count);
					});
				}

				// Show account info.
				var accountInfoView = new Header.AccountInfoView({
					model: KXL.currentUser,
					className: 'kx-header-user-controls-wrapper',
					API_URL: KXL.config.API_URL
				});
				accountInfoView.on('account:click', function () {
					KXL.execute('home:show');
				});

				KXL.atom.once('sidebar', function () {

					var sideBarStates = KXL.request('sidebar:get:states');

					sideBarStates.on('select:one', function (model) {
						var $navButton = $('.kx-' + model.id + '-btn');
						$navButton = $navButton.parent();
						$navButton.addClass('active');
					});

					sideBarStates.on('deselect:one', function (model) {
						if (model.id === 'chat') {
							KXL.execute('chat:toggle', false);
						}
						var $navButton = $('.kx-' + model.id + '-btn');
						$navButton = $navButton.parent();
						$navButton.removeClass('active');
					});

				});

				this.userControlsRegion.show(accountInfoView);

				this.userControlsRegion.show(accountInfoView);
			} else {
				// Logged out
				// Show login and join button.
				this.headerView.ui.userMenu.hide();

				var loginJoinView = new Header.LoginJoinView({
					className: 'kx-header-user-controls-wrapper'
				});
				loginJoinView.on('join:click', function () {
					KXL.execute('show:register:dialog');
				});
				loginJoinView.on('login:click', function () {
					KXL.execute('show:login:dialog');
				});
				this.notificationsRegion.close();
				this.friendsRegion.close();
				this.chatsRegion.close();
				this.userControlsRegion.show(loginJoinView);
			}
		},
		showPlayersOnline: function () {
			var self = this;
			this.atom.once('header', function(header) {
				var region = header.playersOnlineRegion;
				region.ensureEl();
				if (region.$el.is(':hidden')) {
					region.$el.show();
				}

				self.adjustAppContainers();
			});
		},
		hidePlayersOnline: function () {
			this.atom.once('header', function (header) {
				var region = header.playersOnlineRegion;
				region.ensureEl();
				if (region.$el.is(':visible')) {
					region.$el.hide();
				}
			});
		},
		showHeader: function() {
			var self = this;
			this.atom.once('header', function(header) {
				$('body').addClass('header-showing');
				var region = KXL.headerRegion;
				region.ensureEl();
				if (region.$el.is(':hidden')) {
					region.$el.show();
				}

				self.adjustAppContainers();
			});
		},
		hideHeader: function() {
			this.atom.once('header', function (header) {
				$('body').removeClass('header-showing');
				var region = KXL.headerRegion;
				region.ensureEl();
				if (region.$el.is(':visible')) {
					region.$el.hide();
				}
			});
		},
		adjustAppContainers: function(overrides) {
			var self = this;
			if (!this.headerView) {
				return;
			}

			var $headerBottom = this.headerView.$el.find('.kx-header-bottom');
			var headerBottomHeight = 0;
			if (overrides && overrides.headerBottomHeight) {
				headerBottomHeight = overrides.headerBottomHeight;
				$headerBottom.height(overrides.headerBottomHeight);
			} else {
				// Because header-bottom is absolute positioned we have to
				// control its height. Which means we have to loop through
				// its top level child nodes to calculate the height and then
				// set it.
				$headerBottom.children().each(function () {
					headerBottomHeight += this.offsetHeight;
				});
				$headerBottom.height(headerBottomHeight);
			}

			KXL.headerRegion.ensureEl();
			var headerHeight = KXL.headerRegion.$el.get(0).offsetHeight + headerBottomHeight;

			$('#play-region').css('top', headerHeight);
			$('#sidebar-region').css('top', headerHeight);
			KXL.atom.once('sidebar', function () {
				KXL.execute('sidebar:adjust:children:to:container:height');
			});
			KXL.atom.once('header', function() {
				// Players online needs to be repositioned when the header
				// height changes.
				self.headerView.setPlayersOnlineTop(headerHeight);
			});

			KXL.vent.trigger('header:height:changed', headerHeight);
		}
	}))();
});

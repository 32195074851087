KXL.module('ProfileApp.GameStats', function(GameStats, KXL, Backbone, Marionette, $, _) {

	var GamePagerItemView = KXL.Components.ItemView.extend({
		tagName: 'li',
		template: '#_home-game-stats-pager-item-template',
		triggers: {
			'click': 'clicked'
		},
		modelEvents: {
			'selected': function () {
				this.$el.addClass('kx-pager-item-active');
				KXL.vent.trigger('gamestats:game:selected', this.model.get('gameId'));
			},
			'deselected': function () {
				this.$el.removeClass('kx-pager-item-active');
			}
		},
		onRender: function () {
			if (this.model.selected) {
				this.$el.addClass('kx-pager-item-active');
				KXL.vent.trigger('gamestats:game:selected', this.model.get('gameId'));
			}
		},
		templateHelpers: {
			getGameIconImagePath: function () {
				var gameId = this.model.get('gameId');
				if (gameId) {
					var game = KXL.games.get(this.model.get('gameId'));
					if (game) {
						return KXL.config.STATIC_BASE_URL +
							'/modules/profile/game_stats/images/' + game.get('commonName') + '/pager_game_icon.png';
					}
				}
				return '';
			}
		},
		serializeData: function () {
			return {
				templateHelpers: this.templateHelpers,
				model: this.model
			};
		}
	});

	// The pager that allows the user to control which game's stats
	// are in view. It shows next and previous buttons once a
	// configurable threshold is met.
	var GamePagerView = Marionette.CompositeView.extend({
		defaultOptions: {
			showPagerThreshold: 5
		},
		template: '#home-game-stats-pager-template',
		className: 'kx-gamestats-pager kx-no-text-selection',
		itemViewContainer: '.kx-gamestats-pager-list',
		itemViewEventPrefix: 'game:pager:item',
		itemView: GamePagerItemView,
		collectionEvents: {
			'add remove': 'onItemsCountChanged'
		},
		initialize: function (options) {
			this.options = _.defaults(options, this.defaultOptions);
		},
		events: {
			'click .kx-gamestats-pager-left-arrow': function () {
				var prev = this.collection.prev(this.collection.selected, true);
				if (prev) {
					prev.select();
				}
			},
			'click .kx-gamestats-pager-right-arrow': function () {
				var next = this.collection.next(this.collection.selected, true);
				if (next) {
					next.select();
				}
			}
		},
		onItemsCountChanged: function () {
			// Don't bother showing this widget until we have one or more games
			if (this.collection.length > 1) {
				this.$el.show();
			} else {
				this.$el.hide();
			}
			if (this.collection.length > this.options.showPagerThreshold) {
				// Show the scroller buttons once there more than SHOW_PAGER_THRESHOLD
				// games to show.
				this.$el.addClass('kx-gamestats-pager-showing-scroller-btns');
			} else {
				this.$el.removeClass('kx-gamestats-pager-showing-scroller-btns');
			}
		},
		onGamePagerItemClicked: function (view) {
			// If the user is on their own profile, change context.
			if (view.model.get('userId') === KXL.currentUser.id) {
				KXL.asyncRequest('context:change', KXL.games.get(view.model.id)).then(function () {
					KXL.execute('home:show');
				});
			} else {
				view.model.select();
			}
		}
	});

	// View that displays stats values. It tries to see if there is a custom
	// template available for the game otherwise uses a default one.
	var GameStatsValuesView = KXL.Components.Layout.extend({
		tagName: 'ul',
		modelEvents: {
			'change': 'render'
		},
		className: function () {
			var className = 'kx-gamestats-values kx-clearfix' +
				' kx-' + this.options.game.get('commonName');
			return className;
		},
		serializeData: function () {
			var alliance = this.model.get('alliance');
			if (alliance && alliance.name) {
				this.$el.addClass('kx-show-alliance');
				var allianceExists = true;
			}

			var userGame = this.model.toJSON();
			userGame.medals = typeof userGame.medals !== 'undefined' ?  userGame.medals : '-';
			userGame.infamy = typeof userGame.infamy !== 'undefined' ?  userGame.infamy : '-';
			if (userGame.sector && userGame.sector < 0) {
				// A negative sector means the user was culled from the game.
				userGame.sector = '';
			}

			return {
				game: this.options.game ? this.options.game.toJSON(): {},
				userGame: userGame,
				alliance: allianceExists
			};
		},
		getTemplate: function () {
			if (this.options.game) {
				var customTemplateId = '#home-game-stats-' + this.options.game.get('commonName') + '-template';
				var customTemplate = $(customTemplateId);
				if (customTemplate.length) {
					return customTemplateId;
				}
			}
			return '#home-game-stats-default-template';
		}
	});

	var GameStatsItemView = KXL.Components.Layout.extend({
		userId: null,
		className: 'kx-gamestats-item',
		template: '#_home-game-stats-item-template',
		templateHelpers: {
			getGameLogoImagePath: function () {
				if (this.game) {
					return KXL.config.STATIC_BASE_URL + '/modules/profile/game_stats/images/' +
						this.game.commonName + '/logo.png';
				}
				return '';
			}
		},
		regions: {
			statsRegion: '.kx-gamestats-basic-stats',
			playBtnRegion: '.kx-gamestats-playnow',
			editSectorRegion: '.kx-edit-options-sector-region'
		},
		modelEvents: {
			'selected': function () {
				this.$el.show();
			},
			'deselected': function () {
				this.$el.hide();
			},
			'change': 'render'
		},
		initialize: function (options) {
			if (this.model.selected) {
				this.$el.show();
			} else {
				this.$el.hide();
			}
			this.gameModel = KXL.games.get(this.model.id);
			this.userId = options.user.get('id');
			this.listenTo(options.user, 'change', function() {
				this.render();
			});
		},
		onRender: function () {
			var self = this;

			if (KXL.config.SHOW_GAMES) {
				var staticGame = KXL.staticGames.get(this.gameModel.get('urlName')),
				playOnSteam = staticGame && staticGame.has('steamUrl'),
				playBtnLabel = (playOnSteam ? 'profile.gamestats.play.steam' : 'profile.gamestats.playNow');

				var playBtn = new KXL.Components.Button.View({
					label: KXL.i18n.t(playBtnLabel),
					theme: KXL.Components.Button.Themes.alt7
				});
				playBtn.on('click', function () {
					if (playOnSteam) {
						KXL.execute('game:navigate:steam', self.gameModel.get('urlName'));
					} else {
						KXL.execute('game:play:load', self.gameModel.get('urlName'));
					}
				});

				this.playBtnRegion.show(playBtn);
			}

			this.statsRegion.show(
				new GameStatsValuesView({
					model: self.model,
					game: self.gameModel
				})
			);

			if (KXL.currentUser && KXL.currentUser.id === this.userId) {
				var options = {
					name: 'sectoroptions',
					optionsTitle: KXL.i18n.t('profile.gamestats.sector.edit'),
					model: KXL.request('current:user:settings:entity'),
					modelAttr: 'sectorPrivacy',
					modelSaveOnChange: true,
					closeOnItemEvent: true,
					optionItems: [
						{
							label: KXL.i18n.t('profile.gamestats.sector.public'),
							value: 'public'
						},
						{
							label: KXL.i18n.t('profile.gamestats.sector.friends'),
							value: 'friends'
						},
						{
							label: KXL.i18n.t('profile.gamestats.sector.private'),
							value: 'private'
						}
					]
				};
				var editOptionsView = new KXL.Components.EditOptions.Controller.getEditOptions(options);
				this.editSectorRegion.show(editOptionsView);
			}


		},
		serializeData: function () {
			var renderMoreStats = KXL.currentUser.id === this.model.get('userId');

			var gameName =
				(this.model.get('alias') ?
					this.model.get('alias') :
					this.options.user.get('username')
				);

			return {
				renderMoreStats: renderMoreStats,
				templateHelpers: this.templateHelpers,
				game: this.gameModel ? this.gameModel.toJSON(): {},
				user: this.options.user.toJSON(),
				userGame: this.model.toJSON(),
				gameName: gameName
			};
		},
		ui: {
			moreStatsSection: '.kx-gamestats-more-stats'
		},
		events: {
			'click .kx-gamestats-more-stats-lnk': 'onMoreStatsToggleClicked',
			'click .kx-gamestats-logo': 'onMoreStatsToggleClicked'
		},
		onMoreStatsToggleClicked: function (e) {
			// Navigate to the game page.
			if (this.gameModel) {
				KXL.execute('game:show', this.gameModel.get('urlName'), 'home');
			}
		}
	});

	var GameStatsCollectionView = Marionette.CollectionView.extend({
		className: 'kx-gamestats-items',
		itemView: GameStatsItemView,
		itemViewOptions: function () {
			return {
				user: this.options.user
			};
		}
	});

	GameStats.View = Marionette.Layout.extend({
		template: '#profile-game-stats-template',
		className: 'kx-gamestats',
		regions: {
			pager: '.kx-gamestats-pager-wrapper',
			stats: '.kx-gamestats-stats-region'
		},
		onRender: function () {
			this.pager.show(
				new GamePagerView({
					collection: this.collection
				})
			);
			this.stats.show(
				new GameStatsCollectionView({
					collection: this.collection,
					user: this.options.user
				})
			);
		}
	});

	GameStats.EmptyView = Marionette.Layout.extend({
		template: '#home-game-stats-empty-view-template',
		className: 'kx-gamestats-empty-view',
		events: {
			'click .kx-bp .kx-play-now': 'playBP',
			'click .kx-wc .kx-play-now': 'playWC',
			'click .kx-vc .kx-play-now': 'playVC',
			'click .kx-io .kx-play-now': 'playIO',
		},
		playBP: function() {
			KXL.execute('game:play:load', 'battlepirates');
		},
		playWC: function() {
			KXL.execute('game:play:load', 'warcommander');
		},
		playVC: function() {
			KXL.execute('game:play:load', 'vegaconflict');
		},
		playIO: function() {
			KXL.execute('game:play:load', 'imperiaonline');
		}
	});

});

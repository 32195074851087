KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {
	Entities.Game = Entities.Model.extend({});

	Entities.GameSiteContents = Entities.Model.extend({
		defaults: {
			id: null,
			gameBannerClickUrl: null,
			gameBannerImageUrl: null,
			gameScreenshotThumbUrl: null,
			gameScreenshotUrl: null,
			gameVideoThumbUrl: null,
			gameVideoYoutubeUrl: null
		},
		url: function () {
			return '/api/games/site-contents/' + this.id + '/' + KXL.config.locale;
		}
	});

	Entities.GamesCollection = Entities.Collection.extend({
		model: Entities.Game,
		url: function () {
			return KXL.config.API_URL +'/games';
		}
	});

	Entities.StaticGame = Entities.Model.extend({
		defaults: {
			id: '',
			name: '',
			url: '',
			className: '',
			subMenuItems: []
		}
	});

	Entities.StaticGames = KXL.Entities.Collection.extend({
		model: Entities.StaticGame
	});

	function getStaticGamesCollection() {
		var staticGames = new Entities.StaticGames(
			KXL.config.staticGames
		);

		return staticGames;
	}

	function getFilteredGamesCol(games, gameUrls, comparator) {
		var filtered = new Backbone.FilteredCollection(null, { collection: games });
		filtered.setFilter(function(game) {
			return gameUrls.indexOf(game.get('urlName')) !== -1;
		});
		if (comparator) {
			filtered.comparator = comparator;
			filtered.sort();
		}
		return filtered;
	}

	// This also determines the sort order
	var webGameUrls = ['warcommander', 'battlepirates', 'imperiaonline', 'vegaconflict'];
	var mobileGameUrls = ['warcommanderrogueassault', 'vcm'];
	var API = {
		getGameByGameUrl: function (games, gameUrl) {
			return games.findWhere({ 'urlName':  gameUrl });
		},
		getMobileGames: function (games) {
			return getFilteredGamesCol(games, mobileGameUrls);
		},
		getWebGames: function (games) {
			return getFilteredGamesCol(
				games,
				webGameUrls,
				function comparator(game1, game2) {
					var game1SortScore = webGameUrls.indexOf(game1.get('urlName'));
					var game2SortScore = webGameUrls.indexOf(game2.get('urlName'));
					// a is less than b by some ordering criterion
					if (game1SortScore < game2SortScore) {
						return -1;
					} else if (game2SortScore < game1SortScore) {
						return 1;
					} else {
						// a must be equal to b
						return 0;
					}
				}
			);
		},
		getGames: function(games) {
			return new Entities.GamesCollection(games);
		},
		getGameIFrameUrl: function (urlName, options) {
			var opts = _.extend({}, options),
				defer = $.Deferred();

			var section = (opts.section ? '/' +opts.section : '');
			var path = (opts.path ? '/' + opts.path : '');
			var queryString = (opts.queryParams ? '?' + $.param(opts.queryParams) : '');
			$.ajax({
				dataType: "json",
				url: '/api/games/get/' + urlName + section + path + queryString ,
				success: function (data) {
					if (data.error || data.iframeSrc === null) {
						defer.resolve('');
					} else if (data.iframeSrc) {
						defer.resolve(data.iframeSrc);
					}
				}
			});
			return defer.promise();
		},

		getGameSiteContents: function (id) {
			var gameSiteContents = new Entities.GameSiteContents({ id: id });
			var deferred = gameSiteContents.fetch();
			deferred._model = gameSiteContents;
			return deferred;
		},

		getStaticWebGames: function () {
			var desktopGamesCollection = new Backbone.FilteredCollection(
				null,
				{
					collection: getStaticGamesCollection(),
					collectionFilter: function (game) {
						return !game.get('isMobile');
					}
				}
			);
			return desktopGamesCollection;
		},

		getStaticGames: function () {
			return getStaticGamesCollection();
		}
	};

	KXL.reqres.setHandlers({
		'get:game:by:gameurl': function (games, gameUrl) {
			return API.getGameByGameUrl(games, gameUrl);
		},
		'get:game:site-contents': function (id) {
			return API.getGameSiteContents(id);
		},
		'get:game:iframe:url': function (urlName, options) {
			return API.getGameIFrameUrl(urlName, options);
		},
		'games:entities': function (games) {
			games = games || [];
			return API.getGames(games);
		},
		'mobilegames:entities': function (games) {
			return API.getMobileGames(games);
		},
		'webgames:entities': function (games) {
			return API.getWebGames(games);
		},
		'staticgames:entities': function () {
			return API.getStaticGames();
		},
		'static:webgames:entities': function () {
			return API.getStaticWebGames();
		}
	});
});

KXL.module('Components.GameSelector', function (GameSelector, KXL, Backbone, Marionette, $, _) {
		GameSelector.Controller = {
			getView: function (options) {
				var
					opts = _.extend({}, options),
					namespace = _.uniqueId('game:selector:games:'),
					games
				;

				if (opts.collection) {
					games = opts.collection
				} else {
					games = _.extend(
						KXL.staticGames,
						new Backbone.PickThis.SingleSelect(
							KXL.staticGames,
							namespace 
						)
					);
				}

				var view = new GameSelector.View({
					collection: games
				});

				function selectGameByGameId(gameId) {
					var game = KXL.games.get(gameId);
					var gameModel = games.findWhere({ urlName: game.get('urlName') });
					if (gameModel) {
						games.select(gameModel);
					}
				}

				if (opts.defaultGameId) {
					selectGameByGameId(opts.defaultGameId);
				} else if (games.length && !games.selected) {
					games.select(games.first());
				}
				return view;
			}
		}
});
KXL.module('HomeApp.Carousel', function(Carousel, KXL, Backbone, Marionette, $, _) {
	Carousel.CarouselConfig = KXL.Entities.Model.extend({
		defaults: {
			commonName: null,
			images: [],
			summary: null,
			href: null,
			playButtonLabel: null,
			playButtonTheme: null,
			showAppStoreButtons: null
		}
	});

	Carousel.CarouselConfigCollection = KXL.Entities.Collection.extend({
		model: Carousel.CarouselConfig
	});

	Carousel.Controller = {
		getCarousel: function (items, pager) {
			_.extend(items, new Backbone.Picky.SingleSelect(items));

			var timeoutId;
			var PAUSE_FOR_VIEWING_INTERVAL = 6 * 1000;

			function preload (itemModel, onComplete) {
				// Look up the config data for the current item and preload any images
				// listed therein
				var config = itemModel;
				if (config) {
					var loader = new PxLoader();
					var imagesToPreload = config.get('images');
					_.each(imagesToPreload, function (image) {
						loader.addImage(image);
					});
					// Callback that will be run once images are loaded.
					loader.addCompletionListener(function () {
						if (onComplete) {
							onComplete();
						}
					});
					// Begin downloading images
					loader.start();
				} else {
					// No preloading required.
					if (onComplete) {
						onComplete();
					}
				}
			}

			function stop () {
				// Stop carousel looping.
				if (timeoutId) {
					clearTimeout(timeoutId);
					timeoutId = null;
				}
			}

			function showNext () {
				// Do not advance the carousel if the website isn't visible
				if (document.hidden) {
					_.delay(showNext, PAUSE_FOR_VIEWING_INTERVAL);
					return;
				}

				// Cue the next item.
				var currIndex = items.indexOf(items.selected);
				var nextItem = items.at(currIndex + 1);
				if (!nextItem) {
					// Loop back to the beginning.
					nextItem = items.at(0);
				}
				if (nextItem) {
					preload(nextItem, function () {
						nextItem.select();
						stop();
						timeoutId = _.delay(showNext, PAUSE_FOR_VIEWING_INTERVAL);
					});
				}
			}

			function start () {
				// (Re)start carousel looping.
				if (!timeoutId) {
					timeoutId = _.delay(showNext, PAUSE_FOR_VIEWING_INTERVAL);
				}
			}

			// Listen for clicks on the pager and stop the carousel if
			// we get one.
			if (pager) {
				pager.on('pager:item:clicked', function () {
					stop();
				});
			}

			var carousel = new Carousel.View({
				collection: items
			});

			carousel.on('start:requested', function () {
				start();
			});

			carousel.on('stop:requested', function () {
				stop();
			});

			carousel.on('before:close', function () {
				stop();
			});
			carousel.on('render', function () {
				// Select the first one as the default.
				var defaultSelectedModel = items.first();
				if (defaultSelectedModel) {
					defaultSelectedModel.select();
				}

				if (items.length > 1) {
					// Only start the carousel if we have at least one item.
					start();
				}
			});
			carousel.on('carousel:item:moused:over:content', function () {
				// User has moused over the content area. Stop the carousel.
				stop();
			});
			carousel.on('carousel:item:moused:out:content', function () {
				// User has moused out from content area. Restart the carousel.
				start();
			});
			carousel.on('carousel:item:play:clicked', function (view) {
				var gameName = view.model.get('urlName');
				KXL.execute('game:show', gameName, 'home');
			});
			items.on('selected', function (selected) {
				function loadSelected() {
					preload(selected, function () {
						selectedItemView.animateIn();
						carousel.trigger('item:changed', selected);
					});
				}
				var currSelectedModel = items.selected;
				var selectedItemView = carousel.children.findByModel(selected);
				if (currSelectedModel) {
					// Animate out the current item before animating in the newly selected one.
					var currSelectedItemView = carousel.children.findByModel(currSelectedModel);
					if (currSelectedItemView) {
						currSelectedItemView.animateOut(function () {
							loadSelected();
						});
					}
				} else {
					// No current item to animate out so animate in the newly selected one.
					loadSelected();
				}
			});
			return carousel;
		}
	};
});

KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {
	Entities.UserGame = Entities.Model.extend({
		idAttribute: 'gameId',
		url: function () {
			return KXL.config.API_URL + '/users/' + this.get('userId') + '/games/' + this.get('gameId');
		}
	});

	Entities.UserGamesCollection = Entities.Collection.extend({
		model: Entities.UserGame,
		url: function () {
			return KXL.config.API_URL + '/users/' + this.userId + '/games';
		},
		initialize: function (models, options) {
			this.userId = options.userId;
		},
		comparator: function (a, b) {
			var aSeen = a.get('seen');
			var bSeen = b.get('seen');
			if (aSeen > bSeen) {
				return -1;
			} else if (aSeen < bSeen) {
				return 1;
			}
			return 0;
		}
	});

	var API = {
		getUserGames: function (userId) {
			var userGames = new Entities.UserGamesCollection(
				[],
				{
					userId: userId
				}
			);
			var deferred = $.Deferred();
			userGames.fetch({
				success: function (entity, response, options) {
					deferred.resolve(entity, response, options);
				},
				error: function (entity, response, options) {
					deferred.reject(entity, response, options);
				}
			});
			deferred._collection = userGames;
			return deferred;
		},
		getUserGame: function(userId, gameId) {
			var userGame = new Entities.UserGame({
				userId: userId,
				gameId: gameId
			});
			var deferred = userGame.fetch();
			deferred._model = userGame;
			return deferred;
		}
	};

	KXL.reqres.setHandler('usergame:entities', function (userId) {
		return API.getUserGames(userId);
	});
	KXL.reqres.setHandler('usergame:entity', function (userId, gameId) {
		return API.getUserGame(userId, gameId);
	});
});

KXL.module('FraudApp', function(FraudApp, KXL, Backbone, Marionette, $, _) {
    var self = this;

    // The sources of events and the names expected by the API
    FraudApp.OriginEvents = {
        LOGIN: 'login',
        PLAY: 'play',
        PAYMENT: 'payment'
    };

    // Default options that get overridden by app configs
    self.defaultOptions = {
        installActiveX:  false,       // Don't prompt to install ActiveX
        installFlash:    false,       // Don't prompt to install Flash
        activeXExcludesBitmask: 12,   // Bitmask; do not run Active X on Vista or Windows XP
        realIPs:         true,        // Collect real IP info
        javascriptUrl:   null,        // URL of the iovation javascript generating fingerprints
        javascriptLoadTimeout: 10000, // Timeout after this many seconds if the script 404s
    };

    // Stores device fingerprint and state
    self.atom = atom();
    FraudApp.atom = self.atom;

    // App start
    KXL.on('start', function(config) {
        // Store configuration in self.options
        self.options = _.extend(self.defaultOptions, config.fraud);
        
      	KXL.execute('fraud:fingerprint:get');
    });

    // Provider for the device fingerprint.
    //
    // Gets everything started by rendering the JS (if it hasn't
    // already been rendered) and generating the device fingerprint.
    self.atom.provide('deviceFingerprint', function(done) {
        KXL.execute('fraud:javascript:render');
        self.atom.once('complete', function() {
            done(self.atom.get('deviceFingerprint'));
        }).once('failed', function() {
            done(undefined);
        });

    });

    // Kick-off fingerprint generation when the game canvas loads.
    // This ensures that the fingerprint will be available on payment
    // session start.
    KXL.vent.on('info:game:load', function() {
        KXL.execute('fraud:fingerprint:get', $.noop);
    });

    self.API = {
        // Render script tags into the head element to download the iovation
        // JavaScript.  iovation automatically starts the fingerprint generation
        // and calls back to our callback function.
        //
        // If the JS has already been rendered, it will not be rendered again,
        // so this is safe to call multiple times.
        renderJavascript: function() {
            if (self.atom.get('javascriptRendered')) {
                return;
            }

            var scriptTag = _.template(
                '\
                var io_install_stm = <%= installActiveX %>;\
                var io_install_flash = <%= installFlash %>;\
                var io_exclude_stm = <%= activeXExcludesBitmask %>;\
                var io_enable_rip = <%= realIPs %>;\
                \
                /**\
                 * Callback that is called by the ioVation JS when the device fingerprint is updated.\
                 *\
                 * @param deviceFingerprint - the device fingerprint.\
                 * @param complete - boolean, whether all the collection methods have completed.\
                 */\
                var io_bb_callback = function (deviceFingerprint, complete) {\
                    KXL.execute(\
                        "fraud:fingerprint:update",\
                        deviceFingerprint,\
                        complete\
                    );\
                };',
                self.options
            );
            $.globalEval(scriptTag);

            // Load the iovation snare javascript.  Because this is loaded cross-domain
            // jQuery's error handler does not get called if the script 404s.  To get
            // around this, we set a timeout which will trigger the error handler in that
            // case.  Since the JS file is ~50KB I don't want to load it statically on
            // every page load.  The script is loaded when a game loads, so it should
            // not impact payments.
            $.ajax({
                url: self.options.javascriptUrl,
                dataType: "script",
                crossDomain: true,
                timeout: self.options.javascriptLoadTimeout,
                success: function() {
                    self.atom.set('javascriptLoaded', true);
                },
                error: function(jqxhr, settings, exception) {
                    // The script failed to load.  Device fingerprint generation
                    // will never complete, so mark a failure status.
                    self.atom.set('failed', true);
                }
            });

            self.atom.set('javascriptRendered', true);
        },

        // Store the updated fingerprint and mark when generation is complete.
        // This is called by the iovation callback as the fingerprint gets generated.
        //
        // @param deviceFingerprint the device fingerprint (partial or complete).
        // @param complete boolean indicating whether fingerprint generation is complete.
        updateFingerprint: function (deviceFingerprint, complete) {
            self.atom.set('partialDeviceFingerprint', deviceFingerprint);
            if (complete) {
                self.atom.set({
                    'deviceFingerprint': deviceFingerprint,
                    'complete': true
                });
            }
        },

        // Send a fingerprint to the device-fingerprints endpoint
        //
        // @param userId a user id.
        // @param deviceFingerprint the device fingerprint.
        // @param originEvent the action that triggered the event.  One of FraudApp.OriginEvents.
        sendFingerprint: function (userId, deviceFingerprint, originEvent) {
            KXL.Facades.Kixeye.deviceFingerprints(
                userId,
                deviceFingerprint,
                originEvent
            );
        },

        // Calls the callback function passing in the device fingerprint.
        // If the fingerprint has already been generated, the callback will
        // be called almost immediately.  If it has not, it can take a second
        // or two to generate.  If the device fingerprint could not be generated
        // the callback will be called with undefined.
        //
        // An optional optimization we can make is to provide a timeout for this
        // operation.
        //
        // @param callback function that will be called with the device fingerprint.
        getFingerprint: function (callback) {
            self.atom.need('deviceFingerprint', function(deviceFingerprint) {
                callback(deviceFingerprint);
            });
        },

        getFingerprintPromise: function() {
            var deferred = $.Deferred();
            self.API.getFingerprint(function(deviceFingerprint) {
               deferred.resolve(deviceFingerprint);
            });
            return deferred.promise();
        }
    };

    // Commands
    KXL.commands.setHandlers({
        'fraud:javascript:render':  self.API.renderJavascript,
        'fraud:fingerprint:update': self.API.updateFingerprint,
        'fraud:fingerprint:send':   self.API.sendFingerprint,
        'fraud:fingerprint:get':    self.API.getFingerprint

    });
    KXL.reqres.setHandlers({
        'fraud:fingerprint:promise:get': self.API.getFingerprintPromise
    });

});
KXL.module('Components.Leaderboard', function (Leaderboard, KXL, Backbone, Marionette, $, _) {
	Leaderboard.EntryView = KXL.Components.Layout.extend({
		template: '#_leaderboard-entry-template',
		tagName: 'li',
		regions: {
			avatar: '.kx-leaderboard-avatar',
		},
		onRender: function () {
			//TODO: this is assuming entryId is a user.  If other types (alliances, etc) this needs to change
			//TODO: change this to use the kxl username for the avatar when it's available.  Using game alias
			// is not right.
			var
				userId = this.model.get('entryId'),
				username = this.model.get('alias')
			;

			this.avatar.show(
				KXL.request(
					'new:avatar:view',
					this.model.get('entryId'),
					{
						model: new KXL.Entities.User({ id: this.model.get('entryId'), username: this.model.get('alias') }),
						size: 'medium',
						avatarMenu: KXL.Components.Avatar.avatarMenu.ON,
						color: 'grey',
						gameId: this.options.gameId
					}
				)
			);
		}
	});

	Leaderboard.EntriesView = Marionette.CollectionView.extend({
		template: function () {
			return '';
		},
		itemView: Leaderboard.EntryView,
		tagName: 'ul',
		itemViewOptions: function () {
			return { gameId: this.options.gameId };
		}
	});

	Leaderboard.Layout = KXL.Components.Layout.extend({
		template: '#leaderboard-template',
		regions: {
			entries: '.kx-leaderboard .kx-top',
			currentUser: '.kx-leaderboard .kx-self'
		},
		ui: {
			sectors: '.kx-sectors'
		},
		events: {
			'click .kx-leaderboard-header .kx-sector-carat': 'toggleList',
			'click .kx-leaderboard-header .kx-sectors .selectable': 'selectSector'
		},
		toggleList: function (e) {
			this.ui.sectors.toggleClass('open');
		},
		selectSector: function (e) {
			this.toggleList();
		}
	});
});

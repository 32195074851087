
KXL.module('Chat.ChatApp', function(ChatApp, KXL, Backbone, Marionette, $, _) {

	var chatInterface = new KXL.Chat.KXLInterface();
	var currentChatStatus = 'OFFLINE';

	// Latency needed to account for real-time message timestamps being
	// client generated and so earlier than other related timestamps
	// (for example initial value for lastMessageAt) generated server side.
	var MESSAGE_LATENCY = 200;

	function initializeChat() {


		var chatDefaultThreads = new ChatApp.Entities.DefaultThreadsCollection();
		var chatThreads = new ChatApp.Entities.ThreadsCollection();
		var defaultRoomSettingEvents = [];
		var currentUserSettings = KXL.request('current:user:settings:entity');

		var heartBeatInterval = null;
		var chatIconBlinkInterval = null;
		var documentTitleFlash = false;
		var documentTitleFlashInterval = null;

		function getMostRecentMessageInLatestUnreadThread(thread) {
			var messagesArray = thread.get('messages').models;
			return messagesArray[messagesArray.length - 1];
		}

		function startDocumentTitleFlash(thread) {
			if (!documentTitleFlashInterval) {
				documentTitleFlashInterval = setInterval(function () {
					documentTitleFlash = !documentTitleFlash;
					var title;
					if (documentTitleFlash) {
						var mostRecentMessageInThread = getMostRecentMessageInLatestUnreadThread(thread);
						title = mostRecentMessageInThread.get('username') + ' ' + KXL.i18n.t('chat.says');
					}
					else {
						title = KXL.config.siteName;
						var unreadThreads = getUnreadThreadCount();
						if (unreadThreads > 0) {
							title += ' (' + unreadThreads + ')';
						}
					}
					document.title = title;
				}, KXL.config.chatFlashInterval);
			}
		}

		function stopDocumentTitleFlash() {
			if (documentTitleFlashInterval) {
				clearInterval(documentTitleFlashInterval);
				documentTitleFlashInterval = null;
				documentTitleFlash = false;
				document.title = KXL.config.siteName;
			}
		}

		function startChatIconBlink() {
			var $chatParentButton = $('.kx-chat-btn');
			if (!chatIconBlinkInterval) {
				$chatParentButton.addClass('kx-blink');
				chatIconBlinkInterval = setInterval(function () {
					$chatParentButton.toggleClass('kx-blink');
				}, KXL.config.chatFlashInterval);
				return true;
			}
			return false;
		}

		function stopChatIconBlink() {
			var $chatParentButton = $('.kx-chat-btn');
			if (chatIconBlinkInterval) {
				clearInterval(chatIconBlinkInterval);
				chatIconBlinkInterval = null;
				$chatParentButton.removeClass('kx-blink');
			}
		}

		function blinkChatIconForTwoSeconds() {
			if (startChatIconBlink()) {
				_.delay(function () {
					stopChatIconBlink();
				}, KXL.config.chatFlashInterval * 4);
			}
		}

		function getUnreadThreadCount () {
			var unreadThreadCount = 0;
			chatThreads.each(function (thread) {
				unreadThreadCount += thread.get('unreadMessages') > 0 ? 1 : 0;
			});
			return unreadThreadCount;
		}

		function updateChatIconBlinkStatus() {
			var unreadThreadCount = getUnreadThreadCount();
			if (unreadThreadCount === 0) {
				stopChatIconBlink();
				stopDocumentTitleFlash();
			}
		}

		var threadsToMarkAsRead = {};
		var debouncedMarkThreadsAsRead = _.debounce(function () {
			_.each(threadsToMarkAsRead, function (thread, threadId) {
				chatInterface.markChatAsRead(threadId);
				thread.trigger('blink:stop');
			});
			threadsToMarkAsRead = {};
		}, 3000);


		KXL.appModel.on('change:sideBarState', function (model, value) {
			if (value === 'open' && chatInterface.isConnected() ||
				value === 'chat' && chatInterface.isConnected()) {
				debouncedMarkThreadsAsRead();
			}
		});

		chatInterface.on('unread:messages:updated', function () {

			var unreadThreadCount = 0;
			chatThreads.each(function (thread) {
				unreadThreadCount += thread.get('unreadMessages') > 0 ? 1 : 0;
			});

			KXL.vent.trigger('user:userChats:count:changed', unreadThreadCount);

			updateChatIconBlinkStatus();
		});

		function resetThreads() {
			_.each(defaultRoomSettingEvents, function (defaultRoomSettingEvent) {
				currentUserSettings.off(defaultRoomSettingEvent.eventName, defaultRoomSettingEvent.callback);
			});
			defaultRoomSettingEvents = [];
			chatDefaultThreads.reset();
			chatThreads.reset();
			threadsToMarkAsRead = {};
			stopChatIconBlink();
		}

		chatInterface.on("connection", function (status) {
			currentChatStatus = status;

			if (status === "ONLINE") {

				var defaultRooms = chatInterface.getDefaultRooms();
				var groupRooms = [];
				_.each(defaultRooms, function (defaultRoom) {
					var chatId = defaultRoom.id;

					if (currentUserSettings.get(defaultRoom.settingsKey)) {
						groupRooms.push(_.pick(defaultRoom, 'id', 'jabberId', 'type', 'title'));
					}

					var defaultRoomSettingEvent = {
						eventName: 'change:' + defaultRoom.settingsKey,
						callback: function (model, value) {
							if (value && !chatDefaultThreads.get(chatId)) {
								chatDefaultThreads.add(_.pick(defaultRoom, 'id', 'jabberId', 'type', 'title'));
							}
							else {
								if (KXL.currentUser) {
									chatInterface.leaveDefaultChatRoom(chatId);
								}
								chatDefaultThreads.remove(chatId);
							}
						}
					};
					currentUserSettings.on(defaultRoomSettingEvent.eventName, defaultRoomSettingEvent.callback);
					defaultRoomSettingEvents.push(defaultRoomSettingEvent);
				});
				chatDefaultThreads.add(groupRooms);

				heartBeatInterval = setInterval(function () {
					chatInterface.ping();
				}, chatInterface.WEBSOCKET_HEARTBEAT_INTERVAL);

				chatInterface.trigger('connected');
			}
			else if (status === "DISCONNECTED") {
				clearInterval(heartBeatInterval);
				resetThreads();
			}
		});

		function getThreadForChatRoom (chatRoom) {

			if (chatRoom.type === "default") {
				return chatDefaultThreads.get(chatRoom.id);
			}

			var thread = chatThreads.get(chatRoom.id);
			if (!thread) {
				thread = new ChatApp.Entities.Thread(_.pick(chatRoom, 'id', 'jabberId', 'type', 'title', 'lastMessageAt'));
				thread.initializeMembers(chatRoom);
				thread.listenTo(thread, 'selected', function () {
					var unreadMessages = thread.get('unreadMessages');
					if (unreadMessages && unreadMessages > 0) {
						chatInterface.markChatAsRead(thread.id);
					}
				});
				chatThreads.add(thread);
			}
			return thread;
		}

		chatInterface.on("room:presence", function (chatRoom, chatUser) {
			var thread = getThreadForChatRoom(chatRoom);
			if (chatRoom.type === "default" && chatUser.userId === chatInterface._userId) {
				thread.trigger('default:room:ready', thread);
			}
			else {
				thread.updateUserPresence(chatUser);
			}
		});

		chatInterface.on("room:update", function (chatRoom) {
			var thread = getThreadForChatRoom(chatRoom);
			thread.update(chatRoom);
		});

		chatInterface.on("room:reset:unread:messages", function (chatRoom) {
			var thread = getThreadForChatRoom(chatRoom);
			if (thread.get('lastMessageAt') !== chatRoom.lastMessageAt) {
				thread.set('lastMessageAt', chatRoom.lastMessageAt, { silent: true });
			}
			if (thread.get('unreadMessages') != 0) {
				thread.set('unreadMessages', 0);
				chatInterface.trigger('unread:messages:updated');
			}
		});

		chatInterface.on("room:left", function (chatRoom) {
			if (chatRoom && chatRoom.type !== "default") {
				chatThreads.remove(chatRoom.id);
			}
		});

		var throttle = _.throttle(function (thread) {
			thread.collection.trigger('resort');
		}, 100, { leading:false });

		chatInterface.on("room:message", function (chatRoom, chatMessage) {
			var thread = getThreadForChatRoom(chatRoom);
			var messages = thread.get('messages');
			var justReceived = (KXL.Common.Utils.DateUtils.now() - chatMessage.timestamp) < 1000;
			var messageModel = new ChatApp.Entities.Message(_.pick(chatMessage, 'userId', 'username', 'body', 'timestamp'));
			messages.add(messageModel);
			thread.timestamp = messageModel.get('timestamp');
			if (thread.get('type') !== 'default') {
				var sideBarState = KXL.appModel.get('sideBarState');
				var chatBarOpen = sideBarState === 'open' || sideBarState === 'chat';
				if (thread.selected && chatBarOpen) {
					threadsToMarkAsRead[thread.id] = thread;
					debouncedMarkThreadsAsRead();
					if (justReceived && !chatMessage.me) {
						thread.trigger('blink:twice');
					}
				}
				else {
					// Use message latency factor and then round down to the nearest second
					// to ensure that the threadLastMessageAt used is before the timestamp
					// of the first message received. We do this because real time
					// message timestamps are created client side and are therefore earlier
					// than the server generated timestamp for the initial value of the
					// thread's lastMessageAt timestamp. If we did not do this the
					// first message timestamp is less recent than the thread's lastMessageAt
					// and it gets ignored (which we don't want)
					var threadLastMessageAt =
						Math.floor((thread.get('lastMessageAt') - MESSAGE_LATENCY) / 1000) * 1000;
					var unreadMessages = messages.filter(function (message) {
						return message.get('timestamp') > threadLastMessageAt;
					}).length;
					if (thread.get('unreadMessages') !== unreadMessages) {
						thread.set('unreadMessages', unreadMessages);
						chatInterface.trigger('unread:messages:updated');

						if (!chatBarOpen && thread.selected) {
							threadsToMarkAsRead[thread.id] = thread;
						}

						if (justReceived) {
							KXL.execute('chat:play:message:audio');
						}
						if (unreadMessages > 0) {
							startDocumentTitleFlash(thread);
							thread.trigger('blink');
							if (!chatBarOpen) {
								blinkChatIconForTwoSeconds();
							}
						}
					}
				}
				throttle(thread);
			}

		});

		function handleAuthChange() {
			if (KXL.currentUser) {
				var showOnlineStatusAs = currentUserSettings.get('showOnlineStatusAs');
				if (showOnlineStatusAs === 'online' && !chatInterface.isConnected()) {
					chatInterface.connect();
				}
			}
			else {
				if (chatInterface.isConnected()) {
					chatInterface.disconnect();
				}
				resetThreads();
			}
		}

		KXL.vent.on('auth:changed', function () {
			handleAuthChange();
		});

		KXL.addInitializer(function () {
			KXL.request('current:user:settings:entity').bind('change:showOnlineStatusAs', function (model, value) {
				if (value === 'online' && !chatInterface.isConnected()) {
					chatInterface.connect();
				}
				else if (value === 'offline' && chatInterface.isConnected()) {
					chatInterface.disconnect();
				}
			});
		});

		KXL.reqres.setHandlers({
			'get:thread:for:chat:room': function (room) {
				return getThreadForChatRoom(room);
			},
			'get:chat:default:threads': function () {
				return chatDefaultThreads;
			},
			'get:chat:threads': function () {
				return chatThreads;
			}
		});
		KXL.commands.setHandlers({
			'chat:update:icon:blink:status': function () {
				if (KXL.switches.chatEnabled) {
					updateChatIconBlinkStatus();
				}
			}
		});
	}

	KXL.reqres.setHandlers({
		'get:chat:interface': function () {
			return chatInterface;
		},
		'get:chat:status': function () {
			return currentChatStatus;
		}
	});
	KXL.commands.setHandlers({
		'chat:initialize': function () {
			if (KXL.switches.chatEnabled) {
				initializeChat();
			}
		}
	});
});

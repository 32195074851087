KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {
	Entities.NotificationItem = Entities.Model.extend();

	Entities.NotificationsCollection = Entities.Collection.extend({
		model: Entities.NotificationItem,
		parse: function (response, options) {

			delete this.paging;

			// Parse out paging helpers
			if (response.paging) {
				var
					re = /\d+/,
					paging = response.paging,
					next = paging.next,
					previous = paging.previous
				;

				this.paging = {};

				if (next) {
					this.paging.next = next.substr(next.search(re));
				}

				if (previous) {
					this.paging.previous = previous.substr(previous.search(re));
				}
			}

			// The total number of notifications available comes back as metadata
			if (response.meta) {
				this.totalAvailable = response.meta.totalAvailable;
				this.trigger('total:changed', this.totalAvailable);
			}

			// Filter out friend requests. This should be done server-side.
			return _.filter(response.data, function (notification) {
				return notification.type !== 'fr';
			});
		},
		url: function () {
			return KXL.config.API_URL + '/users/' + KXL.request('get:current:user').id + '/notifications';
		}
	});

	var getNotifications = function (options) {
		var notifications = new Entities.NotificationsCollection([], options);

		// Want the promise to resolve with NotificationsCollection, not the Ajax response.
		var deferred = $.Deferred();

		var fetchParams = {
			success: function (collection) {
				deferred.resolve(collection);
			},
			error: function (error) {
				deferred.reject(error);
			}
		};

		if (options && options.data) {
			fetchParams.data = options.data;
		}

		notifications.fetch(fetchParams);

		return deferred.promise();
	};

	var deleteNotifications = function () {
		return $.ajax({ type: "DELETE", url: KXL.config.API_URL + '/users/' + KXL.request('get:current:user').id + '/notifications' });
	};

	var API = {
		getNotifications: function (options) {
			return getNotifications(options);
		},
		deleteNotifications: function () {
			return deleteNotifications();
		}
	};

	KXL.reqres.setHandlers({
		'get:userNotifications': function (options) {
			return API.getNotifications(options);
		},
		'delete:userNotifications': function () {
			return API.deleteNotifications();
		}
	});
});
/* globals KXL */
KXL.module('Components.EditOptions', function (EditOptions, KXL, Backbone, Marionette, $, _) {
	EditOptions.itemView = Marionette.CompositeView.extend({
		template: '#edit-options-item-template',
		tagName: 'li',
		triggers: {
			'click' : 'clicked'
		},
		onRender: function () {
			this.$el.attr(_.extend({
				title: this.options.model.get(this.options.labelAttr || 'label')
			}, _.result(this, 'attributes')));
		},
		serializeData: function () {
			return {
				label: this.options.model.get(this.options.labelAttr || 'label')
			};
		}
	});
	var openMenus = [];
	function closeOtherMenus() {
		_.each(openMenus, function (menu) {
			if (menu && menu.closeMenu) {
				menu.closeMenu();
			}
		});
	}

	EditOptions.CompositeView = Marionette.CompositeView.extend({
		template: '#edit-options-template',
		className: 'kx-edit-options kx-clearfix',
		tagName: 'div',
		itemViewContainer: '.kx-edit-options-items',
		itemViewEventPrefix: 'option:item',
		ui: {
			editOptions: '.kx-edit-options-controls',
			editOptionsLabel: '.kx-edit-options-label',
			editButton: '.kx-button'
		},
		events: {
			'click .kx-edit-options-edit-btn': 'toggleMenu'
		},
		itemEvents: {
			'clicked': function () {
				this.toggleMenu();
			}
		},
		initialize: function (options) {
			var defaults = {
				// Edit options name
				name: '',
				// Custom class
				customClass: '',
				// Model attribute name
				modelAttr: '',
				// Title for the options.
				optionsTitle: '',
				// Display selected as a label on the button.
				selectedDisplay: false,
				// Collection to hold option items.
				optionItems: null,
				// Button body text
				buttonText: KXL.i18n.t('editOptions.buttonText'),
				// Button Icon class
				buttonIconClass: 'none', // arrow, default.
				// Button Style,
				buttonStyle: 'kx-alt3',
				pointerStyle: 'wrap',
				// Item model label attribute name
				labelAttr: 'label',
				// Item model value attribute name
				valueAttr: 'value',
				// Item model state attribute name
				stateAttr: 'itemState',
				// Edit options label
				optionsLabel: KXL.i18n.t('editOptions.everyone')
			};
			this.collection = options.optionItems;
			this.options = _.defaults(options || {}, defaults);
		},
		onRender: function () {
			//Set the custom class of this el
			if (this.options.customClass) {
				this.$el.addClass(this.options.customClass);
			}
		},
		buildItemView: function (item, ItemViewType, itemViewOptions) {
			var opts = this.options;

			itemViewOptions = _.extend(itemViewOptions || {}, {
				labelAttr: opts.labelAttr
			});

			var viewOptions = _.extend({model: item}, itemViewOptions);

			if (viewOptions.model.get(opts.stateAttr)) {
				viewOptions.className = 'kx-ellipsis kx-active';
			}
			// If the option value is set to the associated model attribute set the class name to active.
			if (opts.model && viewOptions.model.get(opts.valueAttr) === opts.model.get(opts.modelAttr)) {
				viewOptions.className = 'kx-ellipsis kx-active';
				// If selected display is set to true, show the label and set it to the selected.
				if (opts.selectedDisplay) {
					this.setLabel(viewOptions.model.get(opts.labelAttr));
					this.ui.editOptionsLabel.show();
				}
			}
			return new EditOptions.itemView(viewOptions);
		},
		setLabel: function (text) {
			this.ui.editOptionsLabel.html(text);
		},
		onBeforeClose: function () {
			$(window).off(this.clickEventName());
		},
		openMenu: function () {
			if (this.isOpen()) {
				return;
			}
			closeOtherMenus();
			openMenus.push(this);
			this.ui.editOptions.addClass('kx-edit-options-active');
			$(window).on(
				this.clickEventName(), this.toggleMenu.bind(this)
			);
			this.trigger('menu:opened');
		},
		clickEventName: function () {
			return 'click.dismiss.edit.options.' + this.cid;
		},
		closeMenu: function () {
			if (!this.isOpen()) {
				return;
			}
			this.ui.editOptions.removeClass('kx-edit-options-active');
			$(window).off(this.clickEventName());
			this.trigger('menu:closed');
		},
		toggleMenu: function (event) {
			event && event.stopPropagation();
			if (this.isOpen()) {
				this.closeMenu();
			} else {
				this.openMenu();
			}
		},
		isOpen: function () {
			if (this.ui.editOptions.hasClass && this.ui.editOptions.hasClass('kx-edit-options-active')) {
				return true;
			} else {
				return false;
			}
		},
		updateButtonText: function (text) {
			this.ui.editButton.html(text);
		},
		serializeData: function () {
			return {
				'optionsTitle': this.options.optionsTitle,
				'buttonText': this.options.buttonText,
				'buttonStyle': this.options.buttonStyle,
				'pointerStyle': 'kx-edit-options-pointer-style-' + this.options.pointerStyle,
				'optionsLabel': this.options.optionsLabel
			};
		}
	});
});

KXL.module('Components.LocaleSelector', function (LocaleSelector, KXL, Backbone, Marionette, $, _) {
		LocaleSelector.Controller = {
			getView: function (options) {

				var defaults = {
					locales: KXL.request('new:locales:entity', ['en_US']),
					locale: 'en_US',
					namespace: 'locale:selector:selector',
					execute: 'set:locale'
				};

				var
					opts = _.defaults(options || {}, defaults),
					locales
				;

				locales = _.extend(
					opts.locales,
					new Backbone.PickThis.SingleSelect(
						opts.locales,
						opts.namespace
					)
				);

				function selectLocaleById(id) {
					var locale = locales.get(id);
					locales.select(locale);
				}

				if (locales.selected) {
					opts.locale = locales.selected.get('locale');
				}

				selectLocaleById(opts.locale);

				var view = new LocaleSelector.View({
					collection: locales
				});

				view.on('locales:locale:clicked', function (locale) {
					locales.select(locale.model);
					if (opts.execute) {
						KXL.execute(opts.execute, locales.selected.get('locale'));
					}
				});

				return view;
			}
		}
});
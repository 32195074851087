KXL.module('Components.BootstrapCarousel.Entities', function (Entities, KXL, Backbone, Marionette, $, _) {
	"use strict";

	Entities.Carousel = Backbone.Collection.extend({
		model: Backbone.Model,
		url: function () {
			return KXL.config.API_URL + '/slides';
		},
		comparator: function (model) {
			return -(model.get('createdAt'));
		}
	});
});
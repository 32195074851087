/* globals KXL */
KXL.module('Common.Utils.CookieUtils', function (CookieUtils, KXL, Backbone, Marionette, $, _) {

		/**
	 * Retrieves a cookie from the cookie jar
	 * @param cookieName (string)
	 * @returns {*|string}
	 */
	CookieUtils.getCookie = function (cookieName, encoded) {

		var name = cookieName + "=";
		var ca = document.cookie.split(';');
		var cookie;

		for (var i = 0; i < ca.length; i++) {
			var c = ca[i].trim();
			if (c.indexOf(name) === 0) {
				cookie = c.substring(name.length, c.length);

				if (encoded) {
					return decodeURIComponent(cookie);
				}

				return cookie;
			}
		}

		return "";
	},

	/**
	 * Retrieves a JSON encoded cookie and returns the decoded data
	 * @param  {String} cookieName
	 * @return {Object}
	 */
	CookieUtils.getJSONCookie = function (cookieName) {

		var data = this.getCookie(cookieName, true);

		if (!data) {
			return;
		}

		try {
			data = JSON.parse(data);
		} catch (e) {
			return;
		}

		return data;
	},

	/**
	 * Creates a cookie
	 * @param name (string), the name of the cookie
	 * @param value (string), the value of the cookie
	 * @param days (int), the cookie expiration, in days. If omitted, defaults to session expiration
	 * @param secure (bool), set cookie security
	 * @param path (string), the relative path to associate with the cookie
	 * @param encode (bool) that determines if the value will be uri encoded
	 * @param samesite (string), the SameSite property value
	 */
	CookieUtils.setCookie = function (name, value, days, secure, path, encode, samesite) {

		var day = 60 * 60 * 24 * 1000;
		var today = new Date();
		var cookie = [
			name, '=', encode ? encodeURIComponent(value) : value, ';'
		];

		if (days) {
			var expire = new Date();
			expire.setTime(today.getTime() + (days * day));
			cookie.push('expires=', expire.toUTCString(), ';');
		}

		if (path) {
			cookie.push('path=', path, ';');
		}

		if (secure) {
			cookie.push('secure;');
		}

		if (samesite) {
			cookie.push('samesite=', samesite, ';');
		}

		document.cookie = cookie.join('');
	},

	/**
	 * JSON encodes the provided value and stores it in a cookie
	 *
	 * @param  {String} cookieName The cookies name
	 * @param  {Mixed} value The value to store
	 * @param  {Number} days The cookie expiration, in days. If omitted, defaults to session expiration
	 * @param  {Boolean} set cookie security
	 * @param  {String} the relative path to associate with the cookie
	 *
	 * @return {Object}
	 */
	CookieUtils.setJSONCookie = function (cookieName, value, days, secure, path) {

		var json;

		try {
			json = JSON.stringify(value);
		} catch (e) {
			return;
		}

		return this.setCookie(cookieName, json, days, secure, path, true);
	},

	/**
	 * Deletes a cookie
	 * @param cookieName (string), the name of the cookie
 	 * @param secure (bool), set cookie security
	 * @param path (string), the relative path to associate with the cookie
	 */
	CookieUtils.deleteCookie = function (cookieName, secure, path)
	{
		return this.setCookie(cookieName, "", -1, secure, path);
	};
});
KXL.module('GamesApp.Background', function (Background, KXL, Backbone, Marionette, $, _) {
	Background.View = Marionette.ItemView.extend({
		template: '#kx-background',
		className: 'kx-background',
		serializeData: function () {
			return this.options;
		}
	})
	Background.CMSView = Marionette.ItemView.extend({
		template: '#kx-background-cms',
		className: 'kx-background-cms',
		serializeData: function () {
			return this.options;
		}
	})
});
KXL.module('GamesApp.Battlelog', function(Battlelog, KXL, Backbone, Marionette, $, _) {


	/*
	* Empty Battle Log View
	*/
	Battlelog.EmptyView = Marionette.Layout.extend({
		template: '#kx-battlelog-empty-template',
		tagName: 'li',
		className: 'kx-battlelog-empty'
	});

	/*
	 * Battle Log Primary Layout View
	 */
	Battlelog.View = Marionette.Layout.extend({
		template: '#kx-battlelog-layout-template',
		regions: {
			battlelogListRegion: '.kx-battlelog-list-region'
		}
	});

	/*
	 * Battle Pirates Battlelog Item
	 */
	Battlelog.BattlelogBattlePiratesItem = Marionette.Layout.extend({
		template: '#kx-battlelog-battlepirates-item-template',
		tagName: 'li',
		regions: {
			avatar: '.kx-opponent-avatar'
		},
		onRender: function () {
			this.avatar.show(
				KXL.request(
					'new:avatar:view',
					this.model.get('opponentUserId'),
					{
						avatarMenu: KXL.Components.Avatar.avatarMenu.ON,
						size: 'medium',
						color: 'grey'
					}
				)
			);

			// Change the color of the resources won (red) or lost (green)
			this.$el.find('.kx-number').each(function() {
				$(this).text() >= 0 ? $(this).addClass('kx-won-resources') : $(this).addClass('kx-lost-resources');
			});

			var result = this.model.get('result');
			if (result === 'win') {
				this.$el.addClass('kx-victory');
			} else if (result === 'loss') {
				this.$el.addClass('kx-defeat');
			}
			// damage is optional in the /api/v2/titles/battlepirates/user-game-reports POST
			// so we set it to 0 if not present
			this.$el.find('.kx-percent').text((this.model.get('damage') || 0).toFixed(1).concat('%'));
		},
		serializeData: function () {
			return {
				battlelog: this.model.toJSON(),
				objective: KXL.i18n.t('games.battlelog.' + this.model.get('objective')),
				attackDate: KXL.Common.Utils.DateUtils.fuzzytime(this.model.get('end'))
			};
		}
	});

	/*
	 * War Commander Battlelog Item
	 */
	Battlelog.BattlelogWarCommanderItem = Marionette.Layout.extend({
		template: '#kx-battlelog-warcommander-item-template',
		tagName: 'li',
		regions: {
			avatar: '.kx-opponent-avatar'
		},
		onRender: function () {
			var avatarView;

			if (this.model && this.model.get('objective') === 'rogueFaction') {
				avatarView = KXL.request(
					'new:avatar:view',
					null,
					{
						avatarUrl: '/modules/game/battlelog/images/wc_rogue_faction.png',
						avatarMenu: KXL.Components.Avatar.avatarMenu.OFF,
						size: 'medium',
						color: 'grey',
						clickable: false,
						suppressDefaultClickAction: true
					}
				);
			} else {
				avatarView = KXL.request(
					'new:avatar:view',
					this.model.get('opponentUserId'),
					{
						avatarMenu: KXL.Components.Avatar.avatarMenu.ON,
						size: 'medium',
						color: 'grey'
					}
				);
			}

			this.avatar.show(avatarView);

			// Change the color of the resources won (red) or lost (green)
			this.$el.find('.kx-number').each(function() {
				$(this).text() >= 0 ? $(this).addClass('kx-won-resources') : $(this).addClass('kx-lost-resources');
			});

			var result = this.model.get('result');
			if (result === 'win') {
				this.$el.addClass('kx-victory');
			} else if (result === 'loss') {
				this.$el.addClass('kx-defeat');
			}

			this.$el.find('.kx-percent').text(this.model.get('damage').toFixed(1).concat('%'));
		},
		serializeData: function () {
			return {
				battlelog: this.model.toJSON(),
				objective: KXL.i18n.t('games.battlelog.' + this.model.get('objective')),
				attackDate: KXL.Common.Utils.DateUtils.fuzzytime(this.model.get('end'))
			};
		}
	});

	/*
	 * Vega Conflict Battlelog Item
	 */
	Battlelog.BattlelogVegaConflictItem = Marionette.Layout.extend({
		template: '#kx-battlelog-vegaconflict-item-template',
		tagName: 'li',
		regions: {
			avatar: '.kx-opponent-avatar'
		},
		onRender: function () {
			this.avatar.show(
				KXL.request(
					'new:avatar:view',
					this.model.get('opponentUserId'),
					{
						avatarMenu: KXL.Components.Avatar.avatarMenu.ON,
						size: 'medium',
						color: 'grey'
					}
				)
			);

			// Change the color of the resources won (red) or lost (green)
			this.$el.find('.kx-number').each(function() {
				$(this).text() >= 0 ? $(this).addClass('kx-won-resources') : $(this).addClass('kx-lost-resources');
			});

			var result = this.model.get('result');
			if (result === 'win') {
				this.$el.addClass('kx-victory');
			} else if (result === 'loss') {
				this.$el.addClass('kx-defeat');
			}

			this.$el.find('.kx-percent').text(this.model.get('damage').toFixed(1).concat('%'));
		},
		serializeData: function () {
			return {
				battlelog: this.model.toJSON(),
				objective: KXL.i18n.t('games.battlelog.' + this.model.get('objective')),
				attackDate: KXL.Common.Utils.DateUtils.fuzzytime(this.model.get('end'))
			};
		}
	});

	/*
	 * Battlelog Collection View
	 */
	Battlelog.CollectionView = Marionette.CollectionView.extend({
		template: _.template(''),
		tagName: 'ul',
		className: 'kx-battles',
		emptyView: Battlelog.EmptyView,
		collectionEvents: {
			'add': 'render'
		}

	});

});


KXL.module('Entities.MediaPreview', function (MediaPreview, KXL, Backbone, Marionette, $, _) {
	var mediaPreview = KXL.Entities.Model.extend({
		defaults: {
			//Custom class for this widget
			customClass: '',
			//Type of preview
			type: 'image',
			// Src image for thumbnail
			previewThumbSrc: '',
			// Src media for preview
			previewSrc: '',
			// Width for the preview
			width: false,
			// Height of the preview
			height: false
		}
	});
	KXL.reqres.setHandler(
		'mediapreview:entity',
		function (attributes) {
			return new mediaPreview(attributes);
		}
	);
});
(function () {
	"use strict";
	var originalRender = Marionette.Renderer.render;
	Marionette.Renderer.render = function (templateId, data) {
		data = _.extend(data, {
			config: KXL.config,
			switches: KXL.switches
		});
		return originalRender(templateId, data);
	};

	// Backbone templating mod for using Mustache instead of underscore
	Marionette.TemplateCache.prototype.compileTemplate = function (rawTemplate) {
		return Handlebars.compile(rawTemplate);
	};

	Marionette.Application.prototype.asyncRequest = function () {
		var args = Array.prototype.slice.apply(arguments);
		return Backbone.Wreqr.RequestResponse.prototype.asyncRequest.apply(this.reqres, args);
	};
})();
/**
 * Statically-included JS file to handle loading payments.
 */

/**
 * Load the payments module on game load
 */
KXL.vent.on('info:game:load', function () {
	KXL.asyncRequest('async:load:module', 'payment').then(function () {
		KXL.vent.trigger('payment:preinitialize');
	});
});

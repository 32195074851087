(function () {
	"use strict";
	if (Backbone && Backbone.Collection) {
		_.extend(Backbone.Collection.prototype, {
			/**
			 * Batches all models into a single save [{model}, {model}, {model}]
			 */
			save: function () {
				if (!this.url) {
					throw "This collection does not have a url to save to.";
				}
				var url;
				if (typeof this.url === 'function') {
					url = this.url();
				} else {
					url = this.url;
				}
				var
					batchSavePost = [],
					batchSavePatch = []
					;
				_.each(this.models, function (model) {
					if (model.isNew()) {
						batchSavePost.push(model.attributes);
					} else {
						if (model.hasChanged()) {
							batchSavePatch.push(model.changedAttributes());
						}
					}
				});
				var postDeferred, patchDeferred;
				if (batchSavePost.length) {
					postDeferred = Backbone.ajax({
						url: url,
						type: 'POST',
						data: JSON.stringify(batchSavePost),
						dataType: 'json',
						contentType: 'application/json'
					});
				}

				if (batchSavePatch.length) {
					patchDeferred = Backbone.ajax({
						url: url,
						type: 'PATCH',
						data: JSON.stringify(batchSavePatch),
						dataType: 'json',
						contentType: 'application/json'
					});
				}
				return {
					'postDeferred': postDeferred,
					'patchDeferred': patchDeferred
				};
			},

			/**
			 * Saves each model individually (model.save())
			 */
			saveAll: function () {
				var deferreds = [];
				_.each(this.models, function (model) {
					deferreds.push(model.save());
				});
				return deferreds;
			},
			// Search for a set of models for this collection, resetting the
			// collection when they arrive. If `reset: true` is passed, the response
			// data will be passed through the `reset` method instead of `set`.
			search: function(options) {
				return this.fetch(_.extend(options || {}, {
					headers: {
						'X-HTTP-Method-Override': 'SEARCH'
					}
				}));
			}
		});
	}
})();
KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {
	Entities.User = Entities.Model.extend({
		defaults: {
			username: '',
			email: '',
			password: ''
		},
		url: function () {
			return KXL.config.API_URL + '/users/' + this.id + '?expand=ignored';
		},
		validation: {
			username: {
				required: true
			},
			password: {
				required: true,
				minLength: 6,
				maxLength: 20
			},
			email: {
				required: true,
				pattern: 'email',
				msg: 'Please enter a valid email'
			}
		}
	});

	Entities.UsersCollection = Entities.Collection.extend({
		model: Entities.User,
		url: function () {
			return '/api/v2/users';
		}
	});

	var API = {
		getNewUserEntity: function(attrs) {
			return new Entities.User(attrs || {});
		},
		getUser: function (userId) {
			var user = new Entities.User({
				id: userId
			});
			var deferred = $.Deferred();
			user.fetch({
				success: function (model) {
					deferred.resolve(model);
				},
				error: function (error) {
					deferred.reject(error);
				}
			});
			deferred._model = user;
			return deferred;
		},
		getUsers: function (options) {
			var deferred = $.Deferred();
			var usersCollection = new KXL.Entities.UsersCollection();
			var fetchOptions = {
				success: function (collection) {
					deferred.resolve(collection);
				},
				error: function () {
					deferred.reject();
				}
			};
			if (options) {
				fetchOptions.data = options;
			}
			usersCollection.fetch(fetchOptions);
			return deferred;
		}
	};

	KXL.reqres.setHandler('new:user:entity', function (attrs) {
		return API.getNewUserEntity(attrs);
	});

	KXL.reqres.setHandler('user:entity', function (userId) {
		return API.getUser(userId);
	});

	KXL.reqres.setHandler('user:entities', function (options) {
		return API.getUsers(options);
	});
});

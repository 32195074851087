KXL.module('Components.FacebookShareButton', function (FacebookShareButton, KXL, Backbone, Marionette, $, _) {
	KXL.reqres.setHandlers({
		'new:facebook:share:button:view': function (options) {
			var btn = new KXL.Components.Button.View({
				customClass: 'kx-facebook-share-btn'
			});
			btn.listenTo(btn, 'click', function () {
				FB.ui({
					method: 'feed',
					link: options.link,
					caption: options.caption,
					name: options.name
				});
			});
			return btn;
		}
	});
});

/*
 * Initial animation controller (rough)
 * This is hardcoded to dom elements purely for demo purposes.
 * TODO @chrismartin : make this dynamic based on regions.
 */

KXL.module('Animations', function(Animations, KXL, Backbone, Marionette, $, _) {
	Animations.Tweening = Marionette.Controller.extend({

		animationCapabilities: true,

		easingStyle: "Power2.easeInOut",

		animationOverlap: "-=.75",

		animateOnlyOnIntro: true,

		initialize: function (options) {
			// Set frame rate to 30 globally
			// TODO, put this is a config.
			TweenLite.ticker.fps(30);

			// Initialize timelines
			this.timelineGamePageLoggedIn = new TimelineLite();
			this.timelineGamePageLoggedOut = new TimelineLite();
			this.timelineHomePage = new TimelineLite();
		},

		/*
		 * Animate the top header button bar.
		 */

		animateHeaderMenu: function () {
			if (KXL.config.animationCapabilities) {
				TweenLite.fromTo($("#header-region"), 1 ,
					{
						opacity: 0,
						y: -50,
						ease: this.easingStyle
					},
					{
						opacity: 1,
						y: 0,
						clearProps: "transform",
						ease: this.easingStyle
					});
			}
		},

		/*
		 * Animate page in.
		 */
		pageIn: function (pageId) {
			// Check if animation capabilities are turned on.
			if (this.animationCapabilities && KXL.config.animationCapabilities) {
				// Check if we only want to animate on the first page load.
				if ( this.animateOnlyOnIntro ) {
					this.animationCapabilities = false;
				}
				switch (pageId) {
					case 'home':
						this.animatePageHome();
						break;
					case 'gameloggedin':
						this.animatePageGameLoggedIn();
						break;
					case 'gameloggedout':
						this.animatePageGameLoggedOut();
						break;
					case 'gameLandingloggedout':
						this.animatePageGameLandingLoggedOut();
						break;
				}
			}
		},

		/*
		 * Animate page out.
		 */
		pageOut: function (pageId) {
			// Coming soon.
		},

		/*
		 * Animate the logged in game page.
		 */

		animatePageGameLoggedIn: function () {
			this.timelineGamePageLoggedIn.clear();
			this.timelineGamePageLoggedIn.add(TweenLite.fromTo($(".kx-game .game-background"), 1 ,
				{
					opacity: 0,
					ease: this.easingStyle
				},
				{
					delay: 1.5,
					opacity: 1,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineGamePageLoggedIn.add(TweenLite.fromTo($(".game-nav-region"), 1 ,
				{
					opacity: 0,
					y: 0,
					ease: this.easingStyle
				},
				{
					opacity: 1,
					y: 0,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineGamePageLoggedIn.add(TweenLite.fromTo($(".game-profile-region"), 1 ,
				{
					opacity: 0,
					y: 50,
					ease: this.easingStyle
				},
				{
					opacity: 1,
					y: 0,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineGamePageLoggedIn.add(TweenLite.fromTo($(".game-leaderboard-region"), 1 ,
				{
					opacity: 0,
					y: 50,
					ease: this.easingStyle
				},
				{
					opacity: 1,
					y: 0,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineGamePageLoggedIn.add(TweenLite.fromTo($(".game-latest-posts-region"), 1 ,
				{
					opacity: 0,
					y: 50,
					ease: this.easingStyle
				},
				{
					opacity: 1,
					y: 0,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineGamePageLoggedIn.add(TweenLite.fromTo($(".game-featured-items-region"), 1 ,
				{
					opacity: 0,
					y: 50,
					ease: this.easingStyle
				},
				{
					opacity: 1,
					y: 0,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineGamePageLoggedIn.add(TweenLite.fromTo($(".game-media-region"), 1 ,
				{
					opacity: 0,
					y: 50,
					ease: this.easingStyle
				},
				{
					opacity: 1,
					y: 0,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineGamePageLoggedIn.restart();
		},

		/*
		 * Animate the game logged out page.
		 */
		animatePageGameLoggedOut: function () {
			this.timelineGamePageLoggedOut.clear();

			this.timelineGamePageLoggedOut.add(TweenLite.fromTo($(".game-nav-region"), 1 ,
				{
					opacity: 0,
					ease: this.easingStyle
				},
				{
					delay: 1,
					opacity: 1,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineGamePageLoggedOut.add(TweenLite.fromTo($(".kx-game .game-background"), 1 ,
				{
					opacity: 0,
					ease: this.easingStyle
				},
				{
					opacity: 1,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineGamePageLoggedOut.add(TweenLite.fromTo($(".game-video-region"), 1 ,
				{
					opacity: 0,
					y: -50,
					scaleX: 5,
					scaleY: 5,
					ease: this.easingStyle
				},
				{
					opacity: 1,
					y: 0,
					scaleX: 1,
					scaleY: 1,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineGamePageLoggedOut.add(TweenLite.fromTo($(".game-signup-region"), 1 ,
				{
					opacity: 0,
					y: 50,
					ease: this.easingStyle
				},
				{
					opacity: 1,
					y: 0,
					ease: this.easingStyle,
					clearProps: "transform"
				}));

			this.timelineGamePageLoggedOut.add(TweenLite.fromTo($(".game-story-region"), 1 ,
				{
					opacity: 0,
					y: 50,
					ease: this.easingStyle
				},
				{
					opacity: 1,
					y: 0,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineGamePageLoggedOut.add(TweenLite.fromTo($(".game-screenshots-region"), 1 ,
				{
					opacity: 0,
					y: 50,
					ease: this.easingStyle
				},
				{
					delay: 0.25,
					opacity: 1,
					y: 0,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineGamePageLoggedOut.restart;
		},

		/*
		 * Animate the game landing logged out page.
		 */
		animatePageGameLandingLoggedOut: function () {
			this.timelineGamePageLoggedOut.clear();

			this.timelineGamePageLoggedOut.add(TweenLite.fromTo($(".kx-game .game-background"), 1 ,
				{
					opacity: 0,
					ease: this.easingStyle
				},
				{
					opacity: 1,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineGamePageLoggedOut.add(TweenLite.fromTo($(".game-screenshots-region"), 1 ,
				{
					opacity: 0,
					y: -50,
					scaleX: 5,
					scaleY: 5,
					ease: this.easingStyle
				},
				{
					delay: 0.5,
					opacity: 1,
					y: 0,
					scaleX: 1,
					scaleY: 1,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineGamePageLoggedOut.add(TweenLite.fromTo($(".game-signup-region"), 1 ,
				{
					opacity: 0,
					x: 100,
					ease: this.easingStyle
				},
				{
					opacity: 1,
					x: 0,
					ease: this.easingStyle,
					clearProps: "transform"
				}));

			this.timelineGamePageLoggedOut.restart;
		},

		/*
			Animate home page
		 */
		animatePageHome: function () {
			this.timelineHomePage.clear();
			this.timelineHomePage.add(TweenLite.fromTo($(".kx-home-pager"), 1 ,
				{
					opacity: 0,
					y: 50,
					ease: this.easingStyle
				},
				{
					delay: 1,
					opacity: 1,
					y: 0,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineHomePage.add(TweenLite.fromTo($(".kx-home-games-container"), 1 ,
				{
					opacity: 0,
					y: 50,
					ease: this.easingStyle
				},
				{
					opacity: 1,
					y: 0,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineHomePage.add(TweenLite.fromTo($(".kx-home-game-signup, .kx-home-logged-out h2"), 1 ,
				{
					opacity: 0,
					y: 50,
					ease: this.easingStyle
				},
				{
					opacity: 1,
					y: 0,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineHomePage.add(TweenLite.fromTo($(".kx-home-posts-container"), 1 ,
				{
					opacity: 0,
					y: 50,
					ease: this.easingStyle
				},
				{
					opacity: 1,
					y: 0,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineHomePage.add(TweenLite.fromTo($(".kx-home-user-game-profiles"), 1 ,
				{
					opacity: 0,
					y: 50,
					ease: this.easingStyle
				},
				{
					opacity: 1,
					y: 0,
					ease: this.easingStyle,
					clearProps: "transform"
				}), this.animationOverlap);

			this.timelineHomePage.restart();
		}
	})
});


KXL.module('Chat', function (Chat, KXL, Backbone, Marionette, $, _) {

	Chat.InterfaceBase = Backbone.Wreqr.EventAggregator.extend({

		PRESENCE: {
			AVAILABLE: "available",
			UNAVAILABLE: "unavailable",
			UNSUBSCRIBE: "unsubscribe",
			UNKNOWN: "unknown"
		},

		CONNECTION_STATUS: {
			0: "ERROR",
			1: "CONNECTING",
			2: "CONNFAIL",
			3: "AUTHENTICATING",
			4: "AUTHFAIL",
			5: "CONNECTED",
			6: "DISCONNECTED",
			7: "DISCONNECTING",
			8: "ATTACHED",
			9: "REBINDFAILED"
		},

		SUBDOMAIN: 'kxl',

		WEBSOCKET_HEARTBEAT_INTERVAL: 20000, //20 seconds

		RETRY_DELAY: 3000,
		
		_connection: null,
		_jabberId: null,
		_bareJabberId: null,

		_locked: false,
		_presenceEventQueue: [],
		_messageEventQueue: [],

		isConnected: function () {

			if (this._connection && this._connection.connected && this._connection.authenticated) {
				return true;
			}
			return false;

		},

		ping: function () {
			if (this.isConnected()) {
				this._connection.send($iq({}).c("ping", { xmlns: "urn:xmpp:ping" }));
			}
		},

		_lock: function () {
			this._locked = true;
		},

		_unlock: function () {
			this._locked = false;
			this._processEventQueues();
		},

		_emptyEventQueues: function () {
			this._presenceEventQueue = [];
			this._messageEventQueue = [];
		},

		_enqueuePresence: function (stanza) {
			this._presenceEventQueue.push(stanza);
			return true;
		},

		_enqueueMessage: function (stanza) {
			this._messageEventQueue.push(stanza);
			return true;
		},

		_processEventQueues: function () {

			var self = this;

			var presenceStanzas = _.clone(self._presenceEventQueue);
			var messageStanzas = _.clone(self._messageEventQueue);

			self._emptyEventQueues();

			_.each(presenceStanzas, function (stanza) {
				self._onPresence(stanza);
			});

			_.each(messageStanzas, function (stanza) {
				self._onMessage(stanza);
			});
		
		},

		_getFirstTokenFromResource: function (jid) {

			var resource = Strophe.getResourceFromJid(jid);

			if (resource && resource.indexOf("~") !== -1) {
				return resource.split("~")[0];
			}

			return resource;
		},

		_getSecondTokenFromResource: function (jid) {

			var resource = Strophe.getResourceFromJid(jid);

			if (resource && resource.indexOf("~") !== -1) {
				return resource.split("~")[1];
			}

			return resource;
		},

		_sendMessageStanza: function(jabberId, message, type) {

			if (message && this.isConnected()) {

				var msg = $msg({

					to: jabberId,
					type: type

				}).c("body").t(message);

				this._connection.send(msg);

			}
		}

	});

	/**
	 * Chat.User
	 */
	Chat.User = function (userId, status) {

		this.userId = userId;
		this.status = status;
		this._resources = [];

	};

	Chat.User.prototype._addResource = function (resource) {
		this._resources = _.union(this._resources, [resource]);
	};

	Chat.User.prototype._removeResource = function (resource) {
		this._resources = _.without(this._resources, resource);
	};

	Chat.User.prototype._clearResources = function () {
		this._resources = [];
	};

	/**
	 * Chat.Message
	 */
	Chat.Message = function (interface, stanza) {

		this._interface = interface;
		this.timestamp = null;
		this.userId = null;
		this.username = null;
		this.type = null;
		this.body = "";
		this.errorCode = null;
		this.me = false;

		this.parseStanza(stanza);

	};

	Chat.Message.prototype.parseStanza = function (stanza) {

		var self = this;

		self.type = stanza.getAttribute("type");
		var from = stanza.getAttribute("from");
		var history = false;

		$(stanza).find("delay").each(function () {

			var stamp = $(this).attr("stamp");
			var date = new Date(stamp);
			self.timestamp = date.getTime();
			history = true;

		});

		if (!self.timestamp) {
			self.timestamp = KXL.Common.Utils.DateUtils.now();
		}

		$(stanza).find("body").each(function () {
			self.body = $('<div></div>').html(Strophe.getText(this)).text();
		});

		if (self.type === "error") {

			$(stanza).find("error").each(function () {
				self.errorCode = parseInt($(this).attr("code"), null) || -1;
			});

		}
		else {

			if (from) {

				var resource = Strophe.getResourceFromJid(from);
				var domain = Strophe.getDomainFromJid(from);
				var isRoomMessage = domain.indexOf(self._interface.SUBDOMAIN) !== -1;

				if (resource) {

					if (isRoomMessage) {

						if (history && resource.indexOf("~") === -1) {
							self.username = resource;
							$(stanza).find("address").each(function () {
								var jid = $(this).attr("jid");
								self.userId = self._interface._getUserIdFromJid(jid);
							});

						} else {
							self.userId = self._interface._getFirstTokenFromResource(from);
							self.username = self._interface._getSecondTokenFromResource(from);
						}
					}
					else {

						self.userId = Strophe.getNodeFromJid(from);
						self.username = self._interface._getFirstTokenFromResource(from);

					}

					self.me = self.userId === self._interface._userId;

				}

			}

		}
	};

	/**
	 * Chat.Room
	 */
	Chat.Room = function (interface, chat) {

		this._interface = interface;
		this.id = chat.id;
		this.jabberId = chat.jabberId;
		this.type = chat.type;
		this.title = chat.title;
		this.members = chat.members;
		this.lastMessageAt = chat.lastMessageAt;
		this.users = {};
	};

	Chat.Room.prototype._update = function (chat) {

		var oldMemberUserIds = _.pluck(chat.members, "userId");

		this.title = chat.title;
		this.lastMessageAt = chat.lastMessageAt;
		this.members = chat.members;

		var memberUserIds = _.pluck(this.members, "userId");
		var removedMembers = _.difference(memberUserIds, oldMemberUserIds);
		this.users = _.omit(this.users, removedMembers);

	};

	Chat.Room.prototype._onMessage = function (stanza) {

		if (!this.isIgnoredChat()) {
			var chatMessage = new Chat.Message(this._interface, stanza);
			if (!KXL.request('is:user:ignored', chatMessage.userId)) {
				this._interface.trigger("room:message", this, chatMessage);
			}
		}
	};

	Chat.Room.prototype._onPresence = function (userId, status) {

		var chatUser = this.users[userId];

		if (!chatUser) {

			chatUser = new Chat.User(userId);
			this.users[userId] = chatUser;

		}

		if (status === this._interface.PRESENCE.AVAILABLE) {

			if (chatUser.status !== status) {
				chatUser.status = status;

				if (!this.isIgnoredChat()) {
					this._interface.trigger("room:presence", this, chatUser);
				}
			}

		}
		else if (status === this._interface.PRESENCE.UNAVAILABLE) {

			chatUser.status = status;
			if (!this.isIgnoredChat()) {
				this._interface.trigger("room:presence", this, chatUser);
			}
		}
	};

	Chat.Room.prototype.isIgnoredChat = function () {
		if (this.type === 'private') {
			var members = _.pluck(this.members, 'userId');
			var peerUserId = _.first(_.without(members, this._interface._userId));

			if (KXL.request('is:user:ignored', peerUserId)) {
				return true;
			}
		}
		else if (this.type === 'restricted') {
			return true;
		}
		return false;
	};

	Chat.Room.prototype._callOfflineMessageEmails = _.debounce(function (room, message) {

		if (room.type === 'private' || room.type === 'group') {
			$.ajax({
				type: "POST",
				url: KXL.config.API_URL.concat('/chats/', room.id, '/offline-message-emails'),
				contentType: "application/json",
				data: JSON.stringify({
					content: message
				})
			});
		}
	}, 1000);

	Chat.Room.prototype.sendMessage = function (message) {

		var inactiveMember = _.find(this.members, function (chatMember) {
			return !chatMember.active;
		});

		if (inactiveMember) {
			var chatEvent = {
				"type": "message",
				"chatId": this.id
			};

			$.ajax({
				type: "POST",
				url: KXL.config.API_URL + "/chat-room-events",
				contentType: "application/json",
				data: JSON.stringify(chatEvent)
			});
		}

		this._callOfflineMessageEmails(this, message);

		this._interface._sendMessageStanza(this.jabberId, message, "groupchat");
	};

});

KXL.module('ProfileApp.Friends', function (Friends, KXL, Backbone, Marionette, $, _) {
	Friends.View = Marionette.CompositeView.extend({
		template: '#profile-friends-template',
		className: 'kx-profile-friends kx-clearfix',
		itemViewContainer: '.kx-friends-list',
		buildItemView: function (item, ItemViewType, itemViewOptions) {
			return KXL.request(
				'new:avatar:view',
				item.get('userId'),
				_.extend(itemViewOptions, {
					model: KXL.request('new:user:entity', item.get('user'))
				})
			);
		},
		itemViewOptions: {
			idAttribute: 'userId',
			size: 'medium',
			color: KXL.Components.Avatar.borderColors.GREY,
			avatarMenu: KXL.Components.Avatar.avatarMenu.ON
		},
		collectionEvents: {
			'add remove reset': 'updateFriendCnt',
			'destroy': 'render'
		},
		ui: {
			friendCnt: '.kx-friend-count',
			friendList: '.kx-friends-list',
			privsPanel: '.kx-friend-privileges',
			privsBtn: '.kx-friend-privileges'
		},
		events: {
			'click .kx-friend-privileges': 'privsBtnClicked'
		},
		checkScrollPosition: function () {
			if ((this.ui.friendList[0].scrollHeight -
				this.ui.friendList.innerHeight()) ===
				this.ui.friendList.scrollTop())
			{
				this.trigger('fetch:more:friends');
			}
		},
		onRender: function () {
			var self = this;
			this.ui.friendList.scroll(function () {
				self.checkScrollPosition();
			});
			this.updateFriendCnt();
		},
		privsBtnClicked: function () {
			this.ui.privsPanel.toggleClass('kx-friend-privileges-edit-active');
		},
		updateFriendCnt: function () {
			var friendCount = KXL.request('get:service:friends:count', this.options.id);
			if (friendCount) {
				this.ui.friendCnt.show().html(friendCount);
			}
		},
		serializeData: function () {
			return {
				options: this.options
			};
		}
	});
});

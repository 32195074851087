KXL.module('Components.Avatar', function (Avatar, KXL, Backbone, Marionette, $, _) {
	Avatar.Controller = {
		getAvatar: function (userId, options) {
			var defaults = {
				size: Avatar.size.SMALL,
				color: Avatar.borderColors.RED,
				avatarMenu: Avatar.avatarMenu.OFF,
				menuPosition: 'bottom-right',
				clickable: true,
				suppressDefaultClickAction: false,
				friendCard: false,
				avatarUsernameOverlay: false,
				presenceOn: false,
				presence: Avatar.presence.WEBSITE,
				stopClickPropagation: false,
				radiusStyle: 'square'
			};
			var avatarOpts = _.defaults({}, options, defaults);
			var friendRequestSent = false;
			var avatarUrlSize = avatarOpts.size;

			if (avatarUrlSize.split('-')[1]) {
				avatarUrlSize = avatarOpts.size.split('-')[1];
			}

			if (!avatarOpts.avatarUrl) {
				if (avatarUrlSize === Avatar.size.SMALL ||
					avatarUrlSize === Avatar.size.XSMALL) {
					// Use the medium size image file for the small avatar
					// which we then scale down. The small size image file
					// is too small for our small avatar.
					avatarUrlSize = Avatar.size.MEDIUM;
				}
				var avatarUrl = KXL.request('get:service:avatars', userId, avatarUrlSize);
				avatarOpts.avatarUrl = avatarUrl ||
					KXL.config.PUBLIC_API_URL.concat('/users/', userId, '/avatars/', avatarUrlSize);
			}

			if (!avatarOpts.model) {
				// The caller has not passed in a user model, so go fetch it.
				avatarOpts.model = KXL.request('user:entity', userId)._model;
			}

			var avatar = new Avatar.View(avatarOpts);
			if (!avatarOpts.suppressDefaultClickAction) {
				avatar.on('clicked', function (args) {
					KXL.execute('profile:show', args.model.id, _.pick(options, 'gameId'));
				});
			}

			avatar.on('ignore:clicked', function (args) {
				var ignoreElement = avatar.$el.find('.kx-avatar-menu-ignore');
				if (KXL.request('is:user:ignored', userId)) {
					KXL.request('unignore:user', userId).then(function () {
						avatar.$el.removeClass('kx-avatar-menu-ignored');
						ignoreElement.attr('title', KXL.i18n.t('avatar.menu.ignore.title'));
						ignoreElement.html(KXL.i18n.t('avatar.menu.ignore'));
					});
				}
				else {
					KXL.request('ignore:user', userId).then(function () {
						avatar.$el.addClass('kx-avatar-menu-ignored');
						ignoreElement.attr('title', KXL.i18n.t('avatar.menu.unignore.title'));
						ignoreElement.html(KXL.i18n.t('avatar.menu.unignore'));
					});
				}
			});
			avatar.on('friend-request:clicked', function (args) {
				if (!friendRequestSent && !KXL.request('is:user:ignored', userId)) {
					KXL.request('user:friend:request:send', userId).then(function () {
						avatar.trigger('user:friend:request:exists');
						KXL.vent.trigger('user:friend:request:sent', userId);
					});
				}
			});
			avatar.on('friend:request:check:sent', function (modelId) {
				KXL.request('current:user:sent:friend:request:entities').then(function (sentFriendRequests) {
					var friendRequestModel = sentFriendRequests.findWhere({'targetId': modelId});
					if (friendRequestModel) {
						avatar.trigger('user:friend:request:exists');
					}
					else {
						avatar.trigger('user:friend:request:does:not:exist');
					}
				});
			});
			avatar.on('user:friend:request:exists', function () {
				friendRequestSent = true;
				var addButton = avatar.$el.find('.kx-avatar-menu-add');
				if (!addButton.hasClass('friend-request-sent')) {
					addButton.addClass('friend-request-sent');
				}
			});

			avatar.on('user:friend:request:does:not:exist', function () {
				friendRequestSent = false;
				var addButton = avatar.$el.find('.kx-avatar-menu-add');
				if (addButton.hasClass('friend-request-sent')) {
					addButton.removeClass('friend-request-sent');
				}
			});

			avatar.on('friend-chat:clicked', function (args) {
				if (!KXL.request('is:user:ignored', userId)) {
					KXL.execute('chat:start:private', userId);
				}
			});

			return avatar;
		}
	};
});

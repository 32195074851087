KXL.module('ProfileApp.Friends', function (Friends, KXL, Backbone, Marionette, $, _) {

	var
		_friendsPerPage = 50,
		_friendsDisplayed = _friendsPerPage
	;

	Friends.Controller = {

		friendsView: null,
		userId: null,
		fetching: false,
		fetchExhausted: false,

		fetchMoreFriends: function () {
			var self = this;

			if (this.fetching || this.fetchExhausted) {
				return;
			}

			this.fetching = true;

			var moreFriendsPromise = KXL.request(
				'user:friend:entities',
				this.userId,
				{
					promise: true
				}
			);

			$.when(moreFriendsPromise).done(function (pagedCollection) {
				pagedCollection.each(function (friend) {
					self.friendsView.collection.add(friend);
				});
				self.fetching = false;
				self.fetchExhausted = (pagedCollection.length === 0);
			});

		},

		getFriendsView: function (userId, options) {
			var self = this;
			this.userId = userId;
			this.fetchExhausted = false;

			options = _.extend(
				{
					id: userId,
					isSelf: false,
					showEditFriendsSettings: false
				},
				options
			);

			if (options.isSelf && options.showEditFriendsSettings) {
				options.showEditFriendsSettings = true;
			} else {
				options.showEditFriendsSettings = false;
			}

			if (options.isSelf) {
				var friendsCollection = KXL.request('current:user:friend:entities')._collection;
				options.collection = new Backbone.Obscura(friendsCollection);
				options.collection.setPerPage(50);
			} else if (!options.collection) {
				options.collection =
					KXL.request('user:friend:entities', userId, options)._collection;
			}

			this.friendsView = new Friends.View(options);

			this.friendsView.on('fetch:more:friends', _.debounce(function () {
				if (options.isSelf && friendsCollection) {
					if (friendsCollection.length !== options.collection.length) {
						_friendsDisplayed += _friendsPerPage;
						options.collection.setPerPage(_friendsDisplayed);
					}
				} else {
					self.fetchMoreFriends();
				}
			}, 250));

			return this.friendsView;
		}
	};
});

(function () {
	Handlebars.registerHelper('i18n', function (key, interpolationOptions) {
		if (key) {
			return new Handlebars.SafeString(KXL.i18n.t(key, interpolationOptions.hash));
		}
	});

	Handlebars.registerHelper('nl2br', function (text) {
		return new Handlebars.SafeString(
			KXL.Common.Utils.StringUtils.nl2br(
				Handlebars.Utils.escapeExpression(text)
			)
		);
	});

	Handlebars.registerHelper('escapeUrlize', function (text) {
		return new Handlebars.SafeString(
			$.fn.urlize(
				Handlebars.Utils.escapeExpression(text)
			)
		);
	});

	Handlebars.registerHelper('htmlDecode', function (chars) {
		return new Handlebars.SafeString(
			KXL.Common.Utils.StringUtils.htmlDecode(chars)
		);
	});

	Handlebars.registerHelper('ucFirst', function (text) {
		return new Handlebars.SafeString(
			KXL.Common.Utils.StringUtils.ucFirst(text)
		);
	});

	Handlebars.registerHelper('fuzzytime', function (timestamp) {
		return new Handlebars.SafeString(KXL.Common.Utils.DateUtils.fuzzytime(timestamp));
	});

	/**
	 * Fuzzytime with the first character lowercase.
	 */
	Handlebars.registerHelper('lcfuzzytime', function (timestamp) {
		return new Handlebars.SafeString(KXL.Common.Utils.StringUtils.lcFirst(KXL.Common.Utils.DateUtils.fuzzytime(timestamp)));
	});

	Handlebars.registerHelper('chain', function () {
		var helpers = Array.prototype.slice.call(arguments, 0, arguments.length - 2),
			value = arguments[arguments.length - 2],
			interpolationOptions = arguments[arguments.length - 1];

		_.forEach(helpers, function (helper, i) {
			if (Handlebars.helpers[helper]) {
				value = Handlebars.helpers[helper](value, interpolationOptions);
			}
		});
		return value;
	});

})();
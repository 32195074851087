KXL.module('Entities', function (Entities) {
	Entities.GeoLocation = Backbone.Model.extend({
		defaults: {
			'countryShortName': null,
			'countryLongName': null
		},
		url: function () {
			return KXL.config.API_URL + '/geo-countries/here'
		}
	});

	KXL.reqres.setHandler('geo:location:entity', function () {
		return new Entities.GeoLocation();
	});
});
KXL.module('Common.Utils.DateUtils', function (DateUtils, KXL, Backbone, Marionette, $, _) {

	/**

	function:
	fuzzytime

	Description:
	Returns the fuzzy time for a date specified in Unix time, GMT.

	@params
	timeVal: Unix time, GMT - the value to convert to fuzzytime

	Logic for fuzzytime
	< 3 minutes            : 'just now'
	< 7 minutes            : 'a few minutes ago'
	< 50 minutes ago   : 'x minutes ago'
	~1 hour ago            : 'about an hour ago'
	> 1 hour ago       : 'x hours ago'
	yesterday          : 'yesterday around x AM|PM'
	< 5 days           : '<day of week> morning|afternoon|evening|night'
	1-3 weeks ago      : 'about one|two|three weeks ago'
	1-11.5 months ago  : 'about x months ago'
	1-10 years ago     : 'about x years ago'
	> 10 years         : 'more than 10 years ago'

	 */
	DateUtils.fuzzytime = function (timeVal) {
		var result = "";
		if (timeVal) {
			var elTime = new Date();
			elTime.setTime(timeVal);
			var timestring = elTime.getTime();
			var time = new Date();
			var diff = Math.ceil((time.getTime() - timestring) / 1000);
			time.setSeconds(time.getSeconds() - diff);

			// Beginning of day of 'time'
			var start_time = new Date(time);
			start_time.setHours(0, 0, 0, 0);

			// Beginning of today
			var start_now = new Date().setHours(0, 0, 0, 0);

			var days = (start_now - start_time) / 86400000;

			// Today
			if (days === 0) {
				// Within the last three minutes
				if (diff < 60 * 3) {
					result = KXL.i18n.t('fuzzytime.justnow');
				}
				// Less then seven minutes ago
				else if (diff < 60 * 7) {
					result = KXL.i18n.t('fuzzytime.afewminutesago');
				}
				// Less than 50 minutes ago
				else if (diff < 60 * 50) {
					result = KXL.i18n.t('fuzzytime.minutesago', { minutes: Math.ceil(diff / 60) });
				}
				// x hours ago
				else if (diff > 60 * 50) {
					var timetmp = Math.ceil(diff / (60 * 60)).toString();
					if (timetmp === '1') {
						result = KXL.i18n.t('fuzzytime.aboutanhourago');
					} else {
						result = KXL.i18n.t('fuzzytime.hoursago', { hours: timetmp });
					}
				}
			}

			// Yesterday
			else if (days <= 1) {
				// Round up to the hour if minutes > 30
				var tmpDate = new Date(time);

				if (time.getMinutes() < 30) {
					tmpDate.setMinutes(time.getMinutes() + 30);
				}

				var hours = tmpDate.getHours();
				result = KXL.i18n.t('fuzzytime.yesterdayaround') + ' ' + (hours > 12 ? hours - 12 : hours) + (hours < 13 ? KXL.i18n.t('fuzzytime.am') : KXL.i18n.t('fuzzytime.pm'));
			}

			// Within the last five days
			else if (days <= 5) {

				var weekday = new Array(7);
				weekday[0] = KXL.i18n.t('fuzzytime.day.sunday');
				weekday[1] = KXL.i18n.t('fuzzytime.day.monday');
				weekday[2] = KXL.i18n.t('fuzzytime.day.tuesday');
				weekday[3] = KXL.i18n.t('fuzzytime.day.wednesday');
				weekday[4] = KXL.i18n.t('fuzzytime.day.thursday');
				weekday[5] = KXL.i18n.t('fuzzytime.day.friday');
				weekday[6] = KXL.i18n.t('fuzzytime.day.saturday');

				var timeStr = weekday[time.getDay()];
				var hour = time.getHours();

				if (hour < 12) {
					timeStr += ' '  + KXL.i18n.t('fuzzytime.day.part.morning');
				} else if (hour < 17) {
					timeStr += ' '  + KXL.i18n.t('fuzzytime.day.part.afternoon');
				} else if (hour < 20) {
					timeStr += ' '  + KXL.i18n.t('fuzzytime.day.part.evening');
				} else {
					timeStr += ' '  + KXL.i18n.t('fuzzytime.day.part.night');
				}

				result = timeStr;
			}

			// Number of weeks (1 - 3)
			else if (days <= 7 * 3.5) {
				// Within a week and a half
				if (days < 7 * 1.5) {
					result = KXL.i18n.t('fuzzytime.aboutaweekago');
				} else if (days < 7 * 2.5) {
					result = KXL.i18n.t('fuzzytime.abouttwoweeksago');
				} else {
					result = KXL.i18n.t('fuzzytime.aboutthreeweeksago');
				}
			}

			// Number of months (~1-11)
			else if (days < (31 * 11.5)) {
				var months = Math.round(days / 31);
				result = KXL.i18n.t("fuzzytime.aboutxmonthsago", {smart_count: months});
			}

			// Years (< 10)
			else if (days < (7 * 520)) {
				var years = Math.round(days / 365);
				result = KXL.i18n.t('fuzzytime.aboutxyearsago', {smart_count: years});
			} else {
				result = KXL.i18n.t('fuzzytime.morethantenyearsago');
			}
		}
		return result;
	};

	/**

	 function:
	 fuzzytime2

	 Description:
	 Returns the fuzzy time for a date specified in Unix time, GMT.

	 @params
	 timeVal: Unix time, GMT - the value to convert to fuzzytime

	 Logic for fuzzytime2
	 < today            : time, in hours:minutes, eg:  '2:43 PM'
	 < 7 days           : days ago, eg: '4d'
	 1-11 months ago	: date '10/23'
	 > 11 months ago	: years , eg: '10/23/13'
	 */
	DateUtils.fuzzytime2 = function (timeVal) {
		var result = "";
		if (timeVal) {
			var elTime = new Date();
			elTime.setTime(timeVal);
			var timestring = elTime.getTime();
			var time = new Date();
			var diff = Math.ceil((time.getTime() - timestring) / 1000);
			time.setSeconds(time.getSeconds() - diff);

			// Beginning of day of 'time'
			var start_time = new Date(time);
			var start_time_day = start_time.getDay();
			start_time.setHours(0, 0, 0, 0);

			// Beginning of today
			var start_now = new Date();
			var start_now_day = start_now.getDay();
			start_now = start_now.setHours(0, 0, 0, 0);

			var days = (start_now - start_time) / 86400000;
			var day_has_changed = start_time_day !== start_now_day;

			// Today
			if (days === 0 && !day_has_changed) {
				result = moment(timeVal).format('h:mm A');
			}

			// Within the last seven days
			else if (days <= 6 && day_has_changed) {
				result = (days | 0) + 'd';
			}

			// Otherwise month/day (10/23)
			else if (days < 365) {
				result = moment(timeVal).format('M/DD');
			}

			// Years
			else {
				result = moment(timeVal).format('M/DD/YY');
			}
		}
		return result;
	};

	DateUtils.now = function () {
		return new Date().getTime();
	};

	DateUtils.isoString = function (timeVal) {
		return new Date(parseInt(timeVal, 0)).toISOString();
	};

	/**
	 * Returns a date object with {year, month, day, hours, and minutes} based on a given timestamp (timeVal)
	 * @param timeVal
	 * @returns {*}
	 */
	DateUtils.getDateVars = function(timeVal) {
		var dateObj = null;
		if (timeVal) {
			var date = new Date(timeVal);
			dateObj = {
				year: date.getFullYear(),
				month: KXL.Common.Utils.StringUtils.datePartPadZero(date.getMonth()+1),
				day: KXL.Common.Utils.StringUtils.datePartPadZero(date.getDate()),
				hours: date.getHours(),
				minutes: KXL.Common.Utils.StringUtils.datePartPadZero(date.getMinutes())
			};
		}
		return dateObj;
	};

	/**
	 * Makes a formatted date string YYYY-MM-DD (HH:mm) for a given timestamp value.
	 * @param timeVal (timestamp)
	 * @returns {*|string}
	 */
	DateUtils.kixDateStringFormat1 = function (timeVal) {

		var dateString = null;
		if (timeVal) {
			var dateObj = DateUtils.getDateVars(timeVal);
			if (dateObj) {
				dateString = dateObj.year + '-' + dateObj.month + '-' + dateObj.day + ' (' + dateObj.hours + ':' + dateObj.minutes + ')';
			}
		}
		return dateString || 'invalid date';
	};

	/**
	 * Makes a formatted date string MM/DD HH:mm for a given timestamp value.
	 * @param timeVal (timestamp)
	 * @returns {*|string}
	 */
	DateUtils.kixDateStringFormat2 = function (timeVal) {

		var dateString = null;
		if (timeVal) {
			var dateObj = DateUtils.getDateVars(timeVal);
			if (dateObj) {
				dateString = dateObj.month + '/' + dateObj.day + ' ' + dateObj.hours + ':' + dateObj.minutes;
			}
		}
		return dateString || 'invalid date';
	};
});
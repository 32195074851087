/* globals KXL, XFrameMessager */
/* jshint -W015 */ // Disable indentation checking for this file, it uses funky KXL.request calls that I don't want to fix right now
KXL.module('GamesApp.Game', function (Game, KXL, Backbone, Marionette, $, _) {
	var commonNameMap = {
		bp: 'battlepirates',
		wc: 'warcommander',
		vc: 'vegaconflict',
		wcra: 'warcommanderrogueassault',
		io: 'imperiaonline'
	};

	var getGameContent = function (commonName) {
		var staticGame = KXL.staticGames.get(commonNameMap[commonName]);
		var gameContent = new Backbone.Collection([
			{
				commonName: 'io',
				headline: KXL.i18n.t('games.io.headline'),
				summary: KXL.i18n.t('games.io.summary'),
				points: '<ul>' +
				'<li>' + KXL.i18n.t('games.io.points1') + '</li>' +
				'<li>' + KXL.i18n.t('games.io.points2') + '</li>' +
				'<li>' + KXL.i18n.t('games.io.points3') + '</li>' +
				'<li>' + KXL.i18n.t('games.io.points4') + '</li>' +
				'<li>' + KXL.i18n.t('games.io.points5') + '</li>' +
				'</ul>',
				numImages: 11
			},
			{
				commonName: 'bp',
				headline: KXL.i18n.t('games.bp.headline'),
				summary: KXL.i18n.t('games.bp.summary'),
				points: '<ul>' +
				'<li>' + KXL.i18n.t('games.bp.points1') + '</li>' +
				'<li>' + KXL.i18n.t('games.bp.points2') + '</li>' +
				'<li>' + KXL.i18n.t('games.bp.points3') + '</li>' +
				'<li>' + KXL.i18n.t('games.bp.points4') + '</li>' +
				'<li>' + KXL.i18n.t('games.bp.points5') + '</li>' +
				'</ul>',
				numImages: 6
			},
			{
				commonName: 'wc',
				headline: KXL.i18n.t('games.wc.headline'),
				summary: KXL.i18n.t('games.wc.summary'),
				points: '<ul>' +
				'<li>' + KXL.i18n.t('games.wc.points1') + '</li>' +
				'<li>' + KXL.i18n.t('games.wc.points2') + '</li>' +
				'<li>' + KXL.i18n.t('games.wc.points3') + '</li>' +
				'<li>' + KXL.i18n.t('games.wc.points4') + '</li>' +
				'<li>' + KXL.i18n.t('games.wc.points5') + '</li>' +
				'</ul>',
				numImages: 6
			},
			{
				commonName: 'wcra',
				headline: KXL.i18n.t('games.wcra.headline'),
				points: '<ul>' +
				'<li>' + KXL.i18n.t('games.wcra.points1') + '</li>' +
				'<li>' + KXL.i18n.t('games.wcra.points2') + '</li>' +
				'<li>' + KXL.i18n.t('games.wcra.points3') + '</li>' +
				'</ul>',
				numImages: 5
			},
			{
				commonName: 'vc',
				headline: KXL.i18n.t('games.vc.headline'),
				summary: KXL.i18n.t('games.vc.summary'),
				points: '<ul>' +
				'<li>' + KXL.i18n.t('games.vc.points1') + '</li>' +
				'<li>' + KXL.i18n.t('games.vc.points2') + '</li>' +
				'<li>' + KXL.i18n.t('games.vc.points3') + '</li>' +
				'<li>' + KXL.i18n.t('games.vc.points4') + '</li>' +
				'<li>' + KXL.i18n.t('games.vc.points5') + '</li>' +
				'</ul>' +
				'<div class="kx-platform-buttons">' +
				'<a class="apple_store" href="' + staticGame.get('appleStoreHref') + '" target="_blank"></a>' +
				'<a class="google_store" href="' + staticGame.get('googleStoreHref') + '" target="_blank"></a>' +
				'<a class="steam_store" href="' + staticGame.get('steamStoreHref') + '" target="_blank"></a>' +
				'</div>',
				numImages: 4
			}
		]);

		return gameContent.findWhere({ commonName: commonName });

	};

	var getGalleryItemData = function (commonName, includeVideo) {

		var gameName = commonNameMap[commonName];
		var gameContentModel = getGameContent(commonName);
		var imageArr = [];
		if (includeVideo) {
			// Push video thumb
			imageArr.push({
				type: 'video',
				imgIconPath: KXL.config.STATIC_BASE_URL + '/modules/game/images/' + gameName + '/game_gallery/thumb_video.jpg',
				imgPath: null,
				imgLargePath: null
			});
		}
		for (var i = 1; i <= gameContentModel.get('numImages'); i++) {
			imageArr.push({
				type: 'image',
				imgIconPath: KXL.config.STATIC_BASE_URL + '/modules/game/images/' + gameName + '/game_gallery/thumb_' + i + '.jpg',
				imgPath: KXL.config.STATIC_BASE_URL + '/modules/game/images/' + gameName + '/game_gallery/preview_' + i + '.jpg',
				imgLargePath: KXL.config.STATIC_BASE_URL + '/modules/game/images/' + gameName + '/game_gallery/large_' + i + '.jpg'
			});
		}

		return imageArr;
	};

	/**
	 * Compares browser version to restricted versions.
	 * @param static game model
	 * @returns boolean
	 */
	var browserUnsupported = function (staticGame, currentPlayer) {
		var unsupported = false;
		if (!staticGame.has('unsupportedBrowsers') || currentPlayer === 'webgl') {
			return unsupported;
		}

		var unsupportedBrowsers = new Backbone.Collection(staticGame.get('unsupportedBrowsers'));
		unsupportedBrowsers.each(function (browser) {
			if (browser.get('name') === KXL.config.browser.name) {
				// No min max version, block out all versions.
				if (!browser.has('maxVersionSupported') && !browser.has('minVersionSupported')) {
					unsupported = true;
				}
				// Block browser if the version is above the max allowed.
				if (browser.has('maxVersionSupported') &&
					parseFloat(KXL.config.browser.version) > browser.get('maxVersionSupported')) {
					unsupported = true;
				}

				// Block browser if the version is below the min allowed.
				if (browser.has('minVersionSupported') &&
					parseFloat(KXL.config.browser.version) < browser.get('minVersionSupported')) {
					unsupported = true;
				}
			}
		});

		return unsupported;
	};

	var GameController = Marionette.Controller.extend({
		pageLayout: null,
		homeLayout: null,
		leaderboardLayout: null,
		currentSection: null,
		homePageLoaded: false,
		xFrameMessager: null,
		signupFormOptions: null,
		reset: function () {
			KXL.gameRegion && KXL.gameRegion.close();
			this.pageLayout = null;
		},
		showGuide: function (game, guide, path) {
			KXL.asyncRequest('async:load:module', 'guide').then(function () {
				KXL.execute('guide:show', game.get('urlName'), path);
			});
		},
		showGame: function (game, section, options) {
			var
				self = this,
				opts = _.extend({}, options),
				DEFAULT_SECTION = 'home',
				HANDLED_SECTIONS = ['home', 'play', 'guide', 'strategy', 'forum', 'support'],
				handledSections = HANDLED_SECTIONS,
				isMobileGame = KXL.mobileGames.findWhere({ commonName: game.get('commonName') }),
				currGame = game.get('commonName')
			;

			if (opts.hasOwnProperty('signupFormOptions')) {
				// Only updated signupFormOptions is it was explicitly
				// passed by the caller.
				this.signupFormOptions = opts.signupFormOptions;
			}

            if (!KXL.currentUser) {
                handledSections = _.without(handledSections, 'play');
            }
			var isHandledSection = _.contains(handledSections, section);
            if (!section || !isHandledSection) {
				if (KXL.currentUser && !isMobileGame) {
					this.playGame(game, section, options);
					return;
				} else {
					section = DEFAULT_SECTION;
				}
			}

			var sectionChanged =  self.currentSection !== section;

			function handleSectionChange() {
				if (sectionChanged) {
					if (self.nav) {
						self.nav.setActiveSection(section);
					}
					/* There is a situation that occurs where regular references of regions
					 * no longer seems to work.  But using the regions collection on that same pageLayout
					 * does work.  I've put in some failsafes to avoid js exceptions.
					 */
					if (self.currentSection && self.pageLayout[self.currentSection]) {
						self.pageLayout[self.currentSection].ensureEl();
						self.pageLayout[self.currentSection].$el.hide();
					} else if (self.currentSection && self.pageLayout.regions[self.currentSection]) {
						self.pageLayout.regions[self.currentSection].ensureEl();
						self.pageLayout.regions[self.currentSection].$el.hide();
					}
					self.currentSection = section;
					self.pageLayout[section].ensureEl();
					self.pageLayout[section].$el.show();
					KXL.vent.trigger('section:changed');
				}
			}

			if (!this.pageLayout || !KXL.gameRegion.currentView || !self.pageLayout.nav) {
				self.homePageLoaded = false;
				self.pageLayout = new Game.Layout({
					model: game
				});
				self.pageLayout.on('show', function () {
					KXL.asyncRequest('new:nav:view', game, section).then(function (navView) {
						self.nav = navView;
						if (self.nav) {
							self.pageLayout.nav.show(self.nav);
						}
						handleSectionChange();
					});
				});
				KXL.gameRegion.show(self.pageLayout);
			} else {
				handleSectionChange();
			}

			switch (section) {
			case 'home':
				$('body').removeClass('kx-organic-home');
				self.showNavRegion();
				KXL.execute('footer:show');

				// Organic Logged out Home body selector
				if (!KXL.currentUser) {
					if (_.has(KXL.config.autoRegCommonNames, currGame)) {
						var addOrganicHomeClass = KXL.config.autoRegCommonNames[currGame].organicHome;
						if (addOrganicHomeClass) {
							$('body').addClass('kx-organic-home');
						}
						var displayNav = KXL.config.autoRegCommonNames[currGame].displayNav;
						if (!displayNav) {
							self.hideNavRegion();
						}
					}
				}

				if (game.get('commonName') === 'wcra') {
					window.location = 'https://warcommander.com';
				}
				if (!self.homePageLoaded) {
					self.homePageLoaded = true;
					self.showHome(game);
				}

				break;
			case 'guide':
				self.showNavRegion();
				KXL.execute('footer:show');
				KXL.execute('playersonline:show');
				self.showGuide(game, options.guide, options.path);
				break;
			default:
				break;
			}
			KXL.execute('page:show', 'game');

			// Do not display the players online for logged out game pages
			if (KXL.currentUser) {
				KXL.execute('playersonline:show');
			} else {
				KXL.execute('playersonline:hide');
			}
		},
		playGame: function (game, canvasPathAddOn, queryParams) {
			var self = this,
			staticGame = KXL.staticGames.get(game.get('urlName'));

			if (!self.xFrameMessager) {
				self.xFrameMessager = new XFrameMessager();
				KXL.atom.set('game:xframe:ready', true);
			}
			// Show the header in the case a user is entering through a landing page where it was hidden.
			self.showHeader();

			// Show message if the current browser is unsupported by the game.
			if (staticGame && browserUnsupported(staticGame, queryParams && queryParams.currentPlayer)) {
				var browserUnsupportedView  = KXL.request(
					'new:play:message:unsupported:view',
					{
						logo: staticGame.get('lrgLogo'),
						message: KXL.i18n.t('games.unsupported.message.' + staticGame.get('urlName'))
					}
				);
				KXL.playRegion.show(browserUnsupportedView);
				KXL.execute('page:show', 'play');
				return;
			}

			if (game !== KXL.context || !KXL.request('user:playing')) {
				KXL.asyncRequest(
					'get:game:iframe:url',
					game.get('urlName'),
					{ path: canvasPathAddOn, queryParams: queryParams }
				).then(function (iframeUrl) {
					// When our game is not returning a url, we still try to load it, testing length.
					if (iframeUrl.length > 0) {
						var
							PlayGameView = Marionette.ItemView.extend({
								template: function () {
									return '';
								}
							}),
							$bodyVeil = $('#kx-body-veil')
							;

						KXL.playRegion.show(
							new PlayGameView({
								className: 'game-iframe-wrapper',
								id: 'game-iframe-wrapper'
							})
						);
						KXL.execute('page:show', 'play');

						// Lets hide the body veil in the case that you showed it for
						// organic start now, you'll know this by the class '.kx-loader-veil'
						if ($bodyVeil && $bodyVeil.hasClass('kx-loader-veil')) {
							$bodyVeil.hide();
							$bodyVeil.removeClass('kx-loader-veil');
						}

						KXL.atom.set('game:isopen', true);
						KXL.vent.trigger('info:game:load', game.get('urlName'));
						self.xFrameMessager.start('game-iframe-wrapper', iframeUrl);
					} else {
						// TODO @jaustin: Remove this conditional once we have user-game-reports and want to see landing pages
						// Prevent infinite loop of going home -> play -> home -> ...
						if (game.get('urlName') == 'imperiaonline') {
							return;
						}

						KXL.execute('game:show', game.get('urlName'), 'home');
					}
				});
			} else {
				KXL.execute('page:show', 'play');
				KXL.atom.set('game:isopen', true);
			}
		},
		showLeaderboard: function (game) {
			var defer = $.Deferred();
			if (KXL.currentUser) {
				var leaderboardLayout = new KXL.Common.Views.ContentWithNav();
				KXL.asyncRequest('async:load:module', 'game/leaderboards').then(function () {

					leaderboardLayout.on('show', function () {
						KXL.asyncRequest('new:nav:view', game, 'leaderboards').then(function (view) {
							leaderboardLayout.navRegion.show(view);
							defer.resolve();
						});
						leaderboardLayout.contentRegion.show(KXL.request('leaderboards:get:view', { stickyHeader: true }));
					});
					KXL.execute('page:show', 'leaderboard');
					KXL.leaderboardRegion.show(leaderboardLayout);

				});

			} else {
				this.showHome(game);
				defer.resolve();
			}
			return defer.promise();
		},
		showSteamLeaderboard: function () {
			var defer = $.Deferred();
			if (KXL.currentUser) {
				var leaderboardLayout = new KXL.Common.Views.ContentWithNav();
				KXL.asyncRequest('async:load:module', 'game/leaderboards').then(function () {

					leaderboardLayout.on('show', function () {
						leaderboardLayout.contentRegion.show(KXL.request('leaderboards:get:view', {
							noShare: true,
							stickyHeader: false,
							noFriends: true
						}));
						defer.resolve();
					});
					KXL.execute('page:show', 'leaderboard');
					KXL.leaderboardRegion.show(leaderboardLayout);

				});
			}
			return defer.promise();
		},
		showVideo: function (game) {
			// Video
			this.homeLayout.video.show(
				KXL.request(
					'new:contentpane:view',
					{
						headingText: KXL.i18n.t('games.trailer'),
						contentView: KXL.request(
							'new:video:view',
							{
								youTubeVideoId: game.get('youTubeVideoId'),
								autoplay: 0,
								mute: 0
							}
						)
					}
				)
			);
		},
		showGameProfile: function (userId, gameId) {
			var currentUserGames = KXL.request('current:user:game:entities');
			var userGame = currentUserGames.get(gameId);
			this.homeLayout.profile.show(
				KXL.request(
					'new:contentpane:view',
					{
						headingText: KXL.i18n.t('games.profile'),
						topView: KXL.request(
							'new:gameprofileheader:view',
							userGame
						),
						contentView: KXL.request(
							'new:gameprofile:view',
							userGame
						)
					}
				)
			);
		},

		showGameSiteContent: function (game) {
			var self = this;
			// List of games for which to show the get notfied
			// cta view. This view shows in place of the default
			// masthead view.
			var SHOW_GET_NOTIFIED_CTA_GAMES = [];

			var gameSiteContentPromise = KXL.request('get:game:site-contents', game.id);
			gameSiteContentPromise.done(function (siteContent) {
				// Background Image CMS
				self.homeLayout.background.show(
					KXL.request(
						'new:backgroundcms:view',
						{
							backgroundImageUrl: siteContent.gameBackgroundImageUrl
						}
					)
				);

				function shouldShowGetNotifiedCTA () {
					return SHOW_GET_NOTIFIED_CTA_GAMES.indexOf(game.get('commonName')) !== -1;
				}

				// Masthead
				if (shouldShowGetNotifiedCTA()) {
					// For some games we show a get notified cta
					// in place of the masthead content.
					KXL.asyncRequest(
						'async:load:module',
						'game/get_notified'
					).then(function () {
						self.homeLayout.masthead.show(
							KXL.request(
								'get:notified:new:cta:view',
								{
									caption: KXL.i18n.t('games.vega.getnotified.ctaView.caption')
								}
							)
						);
					});
				} else {
					self.homeLayout.masthead.show(
						KXL.request(
							'new:masthead:view',
							{
								bannerClickUrl: siteContent.gameBannerClickUrl,
								bannerImageUrl: siteContent.gameBannerImageUrl
							}
						)
					);
				}
			});
		},

		showAlliance: function(game) {

			var self = this;
			var fetchingUserGame = KXL.request('usergame:entity', KXL.currentUser.id, game.get('id'));

			fetchingUserGame.done(function () {
				//Display Alliance widget if user has an allianceId
				if(!fetchingUserGame._model.get('allianceId')){
					return;
				}

				self.homeLayout.alliance.show(KXL.request('new:contentpane:view', {
					headingText: KXL.i18n.t('games.alliance'),
					contentView: KXL.request(
						'new:alliance:view',
						game.get('id'),
						fetchingUserGame._model.get('allianceId')
					)
				}));
			});
		},

		showScreenShots: function (game) {
			var ssHeaderText = ((game.get('commonName') === 'wcra') ? '' : KXL.i18n.t('games.screenshots'));
			var contentPane = KXL.request('new:contentpane:view', { headingText: ssHeaderText });
			var self = this;
			KXL.asyncRequest('async:load:module', 'common/components/slider').then(function () {
				self.homeLayout.screenshots.show(contentPane);
				contentPane.setContentView(KXL.request(
					'new:gallery:view',
					getGalleryItemData(game.get('commonName'), false),
					game
				));
			});
		},

		showStory: function (game) {
			var commonGame = game.get('commonName');
			var storyModel = getGameContent(commonGame);
			var infoHeaderText = ((commonGame === 'wcra') ? '' : KXL.i18n.t('games.info'));
			var contentPane = KXL.request('new:contentpane:view', { headingText: infoHeaderText });
			this.homeLayout.story.show(contentPane);
			contentPane.setContentView(KXL.request('new:story:view', {
				model: storyModel
			}));
		},
		setupWCRAMobileHomeLoggedOut: function(game){
			var self = this;
			// appStoreCountries contains the list of countries for
			// which the app store button is displayed.
			// If no countries are in the list the app store button
			// is displayed for all countries.
			// When not showing the app store button the get
			// notified widget is shown.
			var appStoreCountries = [];
			var staticGame = KXL.staticGames.findWhere({ commonName: game.get('commonName') });
			var layout = this.homeLayout = new Game.HomeWCRAMobileLoggedOutLayout({
				appStoreLinkUrl:
					staticGame.get('appleStoreHref')
			});
			layout.on('show', function () {
				self.showGameSiteBackground();
				// App Store
				function renderGetNotified () {
					layout.appstore.show(KXL.request('new:loader:view'));
					KXL.asyncRequest('async:load:module', 'game/get_notified').then(function () {
						KXL.request(
							'get:notified:new:view',
							{
								gameUrlName: game.get('urlName'),
								showNotifyBySMS: false
							}
						).done(function (view) {
							layout.appstore.show(view);
						});
					});
				}
				if (appStoreCountries.length) {
					var geo = KXL.request('geo:location:entity');
					geo.fetch().done(function () {
						var countryShortName = geo.get('countryShortName');
						if (!countryShortName ||
							appStoreCountries.indexOf(countryShortName) === -1)
						{
							renderGetNotified();
						} else {
							layout.toggleAppStoreItems(true);
						}
					}).fail(function () {
						renderGetNotified();
					});
				} else {
					layout.toggleAppStoreItems(true);
				}

				// Screenshots
				self.showScreenShots(game);

				// Story
				self.showStory(game);

				KXL.animate.pageIn('gameloggedout');
			});
		},
		setupMobileHomeLoggedOut: function (game) {
			var urlName = game.get('urlName');
			if (urlName === 'warcommanderrogueassault') {
				this.setupWCRAMobileHomeLoggedOut(game);
			}
		},
		setupOrganicHomeLoggedOut: function(game) {
			KXL.execute('game:show', game.get('urlName'));
		},
		setupHomeLoggedOut: function (game) {
			var self = this;
			this.homeLayout = new Game.HomeLoggedOutLayout();
			this.homeLayout.on('show', function () {
				// Sign Up
				var urlName = game.get('urlName');
				var trackingData = _.defaults(
					{},
					self.signupFormOptions ? self.signupFormOptions.trackingData : null,
					{
						trackControl: 'widget',
						trackSource: urlName
					}
				);

				var captchaRequired = true;
				if (self.signupFormOptions && self.signupFormOptions.hasOwnProperty('captchaRequired')) {
					captchaRequired = self.signupFormOptions.captchaRequired;
				}

				var signupForm = KXL.request('new:inline:signup:form:view', { data: trackingData });
				signupForm.on('register:submit', function (registration) {
					KXL.execute(
						'show:register:dialog',
						{
							registration: registration,
							step: KXL.AuthApp.RegisterSteps.CREATE_ACCOUNT,
							contextGameUrlName: urlName,
							data: trackingData,
							captchaRequired: captchaRequired
						}
					);
				});
				signupForm.on('fb:api:authenticated', function (fbAccessToken) {
					KXL.request('fblogin:submit', fbAccessToken, trackingData).done(function (user, isNew) {
						if (isNew) {
							KXL.execute(
								'show:register:dialog',
								{
									step: KXL.AuthApp.RegisterSteps.COMPLETE_FACEBOOK_REGISTER,
									contextGameUrlName: game.get('urlName'),
									fbAccessToken: fbAccessToken,
									isNew: isNew
								}
							);
						}
					}).fail(function (error) {
						if (error instanceof KXL.Errors.EmailConflictError) {
							signupForm.setErrorMsg(
								KXL.i18n.t(
									'auth.loginEmailConflictError',
									{ email: error.detail.email }
								)
							);
						} else {
							signupForm.setErrorMsg(KXL.i18n.t('auth.registrationFailed'));
						}
					});
				});

				signupForm.listenTo(KXL, 'google:api:authenticated', function (googleAccessToken) {
					KXL.request('googlelogin:submit', googleAccessToken, trackingData).done(function (user, isNew, hasAvatar) {
						if (isNew) {
							if (hasAvatar) {
								KXL.execute(
									'show:register:dialog',
									{
										step: KXL.AuthApp.RegisterSteps.IMPORT_FB_GAMES_PROMPT,
										googleAccessToken: googleAccessToken,
										isNew: isNew
									}
								);
							} else {
								KXL.execute(
									'show:register:dialog',
									{
										step: KXL.AuthApp.RegisterSteps.IMPORT_FB_GAMES_PROMPT,
										googleAccessToken: googleAccessToken,
										isNew: isNew
									}
								);
							}

						}
					}).fail(function (error) {
							if (error instanceof KXL.Errors.EmailConflictError) {
								signupForm.setErrorMsg(
									KXL.i18n.t(
										'auth.loginEmailConflictError',
										{ email: error.detail.email }
									)
								);
							} else {
								signupForm.setErrorMsg(KXL.i18n.t('auth.registrationFailed'));
							}
						});
				});
				signupForm.listenTo(KXL, 'google:api:error', function (errorMessage) {
					signupForm.setErrorMsg(KXL.i18n.t('auth.registrationFailed'));
				});

				this.listenTo(signupForm, 'steam:api:authenticated', function (authPromise) {
					authPromise.fail(function (error) {
						var errorMessage;
						switch (error) {
							case 'BANNED':
								KXL.execute('show:banned:dialog', error.data);
								break;
							case 'FAILED_FRAUD_CHECK':
								errorMessage = new KXL.Errors.IOvationBlockedError().message;
								break;
							case 'NO_DEVICE_FINGERPRINT':
								errorMessage = new KXL.Errors.DeviceFingerprintError().message;
								break;
							default:
								errorMessage = KXL.i18n.t('auth.registrationFailed');
						}
						signupForm.setErrorMsg(errorMessage);
					});
				});

				self.homeLayout.signUp.show(signupForm);

				self.showVideo(game);

				// Screenshots
				self.showScreenShots(game);

				// Story
				self.showStory(game);

				KXL.animate.pageIn('gameloggedout');
			});
		},
		setupHomeLoggedOutVC: function (game) {
			var self = this;
			this.homeLayout = new Game.HomeLoggedOutLayoutVC();
			this.homeLayout.on('show', function () {
				self.showVideo(game);

				// Screenshots
				self.showScreenShots(game);

				// Story
				self.showStory(game);

				KXL.animate.pageIn('gameloggedout');
			});
		},
		showHome: function (game) {
			var
				self = this,
				currGame = game.get('commonName')
			;

			function showLoggedOutHome () {
				// Organic Logged out Home body selector
				if (_.has(KXL.config.autoRegCommonNames, currGame)) {
					if (currGame === 'vc') {
						KXL.asyncRequest('async:load:module', 'game/vega_conflict').then(function () {
							self.setupOrganicHomeLoggedOut(game);
							self.pageLayout.home.show(self.homeLayout);
						});
					} else {
						self.setupOrganicHomeLoggedOut(game);
						self.pageLayout.home.show(self.homeLayout);
					}
				} else {
					if(currGame === 'vc') {
						self.setupHomeLoggedOutVC(game);
					} else {
						self.setupHomeLoggedOut(game);
					}
					self.pageLayout.home.on('show', function () {
						self.showGameSiteBackground();
					});
					self.pageLayout.home.show(self.homeLayout);
				}
			}

			function showLoggedInHome () {
				// Game Site Content (Masthead, screenshot, video)
				self.showGameSiteContent(game);

				self.homeLayout = new Game.HomeLoggedInLayout();
				self.homeLayout.on('show', function () {

					// Profiles
					self.showGameProfile(KXL.currentUser.id, game.id);

					// Leaderboard
					self.homeLayout.leaderboard.show(KXL.request('new:contentpane:view', {
						headingText: KXL.i18n.t('games.leaderboard'),
						contentView: KXL.request(
							'new:leaderboard:view',
							{
								userId: KXL.currentUser.id,
								gameId: game.id
							}
						)
					}));

					self.homeLayout.battlelog.show(KXL.request('new:contentpane:view', {
						headingText: KXL.i18n.t('games.battleLog'),
						contentView: KXL.request(
							'new:battlelog:view',
							KXL.currentUser.id,
							game.get('id'),
							game.get('commonName')
						)
					}));

					// Alliance
					self.showAlliance(game);


					KXL.animate.pageIn('gameloggedin');
				});
				self.pageLayout.home.show(self.homeLayout);
			}
			if (KXL.mobileGames.get(game.id)) {
				this.setupMobileHomeLoggedOut(game);
				self.pageLayout.home.show(self.homeLayout);
			} else if (KXL.currentUser) {
				var userGameProfileModelDeferred = KXL.request('usergame:entity', KXL.currentUser.id, game.get('id'));
				userGameProfileModelDeferred.then(function (model) {
					showLoggedInHome();
				}).fail(function() {
					if (currGame === 'vc') {
						// VC is a special case here in that if the user does
						// not play the game we show a modified version of the
						// logged out home page.
						showLoggedOutHome();
					} else {
						self.setupHomeLoggedOut(game);
						self.homeLayout.on('show', function () {
							self.showGameSiteBackground();
							self.homeLayout.signUp.show(KXL.request('new:contentpane:view', {
								headingText: KXL.i18n.t('games.leaderboard'),
								contentView: KXL.request(
									'new:leaderboard:view',
									{
										userId: KXL.currentUser.id,
										gameId: game.id
									}
								)
							}));
						});
						self.pageLayout.home.show(self.homeLayout);
					}
				});
			} else {
				showLoggedOutHome();
			}
		},
		showGameSiteBackground: function () {
			this.homeLayout.background.show(
				KXL.request(
					'new:background:view'
				)
			);
		},
		hideNavRegion: function() {
			this.pageLayout.nav.ensureEl();
			this.pageLayout.nav.$el.hide();
		},
		showNavRegion: function() {
			this.pageLayout.nav.ensureEl();
			this.pageLayout.nav.$el.show();
		},
		hideHeader: function() {
			// Hide the header
			var headerRegion = KXL.headerRegion;
			headerRegion.ensureEl();
			if (headerRegion.$el.is(':visible')) {
				headerRegion.$el.hide();
			}
		},
		showHeader: function() {
			var headerRegion = KXL.headerRegion;
			headerRegion.ensureEl();
			if (headerRegion.$el.is(':hidden')) {
				headerRegion.$el.show();
			}
		}
	});
	Game.Controller = new GameController();

	KXL.reqres.setHandlers({
		'get:game:gallery:item:data': function (commonName, includeVideo) {
			return getGalleryItemData(commonName, includeVideo);
		}
	});

	// Attach xframe communication listeners to game frame
	KXL.atom.once('game:xframe:ready', function () {

		Game.Controller.xFrameMessager.on('kxlRegistrationBegin', function (error, data, emitter) {
			if (KXL.currentUser) {
				var status = KXL.currentUser.get('status');
				if (status === 'active') {
					KXL.vent.trigger('registration:complete:message');
					return;
				} else if (status === 'temp') {
					KXL.request('show:delayed:register:form:banner', {
						referrer: 'game',
						referringGameUrlName: KXL.context && KXL.context.get('urlName')
					});
				}
			}
		});

		KXL.vent.on('registration:failure:message', function(data) {
			Game.Controller.xFrameMessager.emit('kxlRegistrationFailed', data);

			// tells the game to close their Topup Popup dialog.
			Game.Controller.xFrameMessager.emit('kxlPopupClose', {});
		});

		KXL.vent.on('registration:complete:message', function(data) {
			Game.Controller.xFrameMessager.emit('kxlRegistrationComplete', data);
		});

		Game.Controller.xFrameMessager.on('sendgift', function (error, data, emitter) {

			KXL.asyncRequest('async:load:module', 'gifting').then(function () {
				KXL.execute('show:gifting:dialog', data, emitter);
			});

		});

		Game.Controller.xFrameMessager.on('sendinvite', function (error, data, emitter) {

			KXL.asyncRequest('async:load:module', 'invites').then(function () {
				KXL.execute(
					'show:invites:dialog',
					data,
					Game.Controller.xFrameMessager.emit.bind(Game.Controller.xFrameMessager),
					'invitesent'
				);
			});

		});

		Game.Controller.xFrameMessager.on('invitefriends', function (error, data, emitter) {

			KXL.asyncRequest('async:load:module', 'invites').then(function () {
				KXL.execute(
					'show:invites:dialog',
					data,
					Game.Controller.xFrameMessager.emit.bind(Game.Controller.xFrameMessager),
					'invitefriends'
				);
			});

		});

		Game.Controller.xFrameMessager.on('openGuideTab', function (error, data, emitter) {

			if (!data || !data.guideName) {
				return;
			}
			if (data.guideName === 'battlepirates') {
				KXL.asyncRequest('async:load:module', 'guide').then(function () {
					KXL.execute('guide:show', data.guideName);
				});
			} else {
				KXL.asyncRequest('async:load:module', 'game/guide').then(function () {
					KXL.execute('oldGuide:show', data.guideName, 'guide');
				});
			}
		});

		Game.Controller.xFrameMessager.on('displayLightbox', function (error, data, emitter) {
			// TODO: implement payments popup
		});
		// temporarily have two events listening that do the same thing... once adyenBuyCredits is removed from VC,
		// this can be removed.
		Game.Controller.xFrameMessager.on('adyenBuyCredits', function (error, data, emitter) {
			KXL.vent.trigger('payment:start', error, data, emitter);
		});

		Game.Controller.xFrameMessager.on('kxlBuyCredits', function (error, data, emitter) {
			KXL.vent.trigger('payment:start', error, data, emitter);
		});

		Game.Controller.xFrameMessager.on('gameEvent', function (error, data, emitter) {
			KXL.vent.trigger('game:event', data);
		});

		Game.Controller.xFrameMessager.on('topupShown', function (error, data, emitter) {
			KXL.vent.trigger('game:event:showtopup', data);
		});

		Game.Controller.xFrameMessager.on('clientLoadComplete', function (error, data, emitter) {
			KXL.vent.trigger('game:event:clientloadcomplete', data);
		});

		// Listen for events on our side to be sent to the game
		function emitPaymentData(data) {
			Game.Controller.xFrameMessager.emit('adyenBuyCredits', data);
			Game.Controller.xFrameMessager.emit('kxlBuyCredits', data);
		}

		KXL.vent.on('payment:success', emitPaymentData);
		KXL.vent.on('payment:pending', emitPaymentData);
		KXL.vent.on('payment:cancelled', emitPaymentData);

		KXL.vent.on('payment:banned', function(data) {
			Game.Controller.xFrameMessager.emit('kxlPaymentBanned', data);
		});

		KXL.vent.on('payment:product:error', function(data) {
			Game.Controller.xFrameMessager.emit('kxlProductError', data);
		});

		KXL.vent.on('payout:success', function(data) {
			Game.Controller.xFrameMessager.emit('kxlBuyCredits', data);
		});
		KXL.vent.on('payout:error', function(data) {
			Game.Controller.xFrameMessager.emit('kxlPayoutError', data);
		});

		KXL.vent.on('payment:modal:closed', function () {
			Game.Controller.xFrameMessager.emit('kxlPopupClose', {});
		});
		KXL.vent.on('nav:overlay:open', function () {
			Game.Controller.xFrameMessager.emit('hideFlashCallback', 'opened');
		});
		KXL.vent.on('nav:overlay:closed', function () {
			Game.Controller.xFrameMessager.emit('hideFlashCallback', 'closed');
		});
	});
});

/*---------------- Checkbox Widget ------------------
Usage:
Step 1 - Create a region in your view

	regions: {
		checkBoxRegion: '.kx-checkbox-region'
	},

Step 2 - In the onRender function of the view write this code

	var checkBoxView = new KXL.Components.CheckBox.View({
		name: 'checkColor',
		value: 'Red',
		label: 'RED',
		model: checkBoxModel,
		modelAttr: 'color'
	});

	this.checkBoxRegion.show(checkBoxView);

Step 3 - To handle change event
	checkBoxView.on('change', function(view){
		//..Some code
	});
--------------------------------------------------------*/
KXL.module('Components.CheckBox', function (CheckBox, KXL, Backbone, Marionette, $, _) {

	CheckBox.View = Marionette.Layout.extend({
		template: '#checkbox-template',
		className: 'kx-checkbox',
		ui: {
			checkBox: 'input:checkbox',
			label: 'label'
		},
		events: {
			'click': 'clickHandler'
		},
		modelEvents: function () {
			// Checkbox needs to listen to changes to the model attribute it is bound to as changes
			// to it will originate in other places other than a user check/uncheck action.
			var modelEvents = {};
			if (this.model && this.useModelAttr) {
				modelEvents['change:' + this.useModelAttr] = 'render';
			}
			return modelEvents;
		},
		tagName: function () {
			return this.options.tagName || 'span';
		},
		initialize: function(options) {
			var defaults = {
				customClass: '',  // Custom class for this widget
				name: '',         // Checkbox Name
				value: '',        // Checkbox value
				label: '',        // Checkbox label
				modelAttr: '',    // Model attribute name
				checked: false,   // Initial state.  Ignored if modelAttr and model are set.
				tagName: 'span',
				addEllipsisToLabel: true,
				// Tooltip position
				tooltipPos: KXL.Components.Tooltip.Positions.right, // right or left

				// Function to get the checked value from the model
				getCheckedValueFromModel: this.getModelValue.bind(this),

				// Function to get the checked value from the checkbox
				getModelValueFromChecked: this.getValue.bind(this)
			};

			this.options = _.defaults(options || {}, defaults);
			this.useModelAttr = this.model && this.options.modelAttr;
		},
		regions: {
			tooltipRegion: '.kx-tooltip-region'
		},
		modelEvents: {
			'validated': 'onValidated'
		},
		serializeData: function () {
			return {
				options: this.options
			}
		},
		onValidated: function (isValid, model, errors) {
			if(model.isValid(this.options.modelAttr)) {
				this.showNormal();
			}
			else if(errors && errors[this.options.modelAttr]) {
				this.showError(errors[this.options.modelAttr]);
			}
		},
		onRender: function () {
			var self = this;
			
			if (this.options.customClass) {
				this.$el.addClass(this.options.customClass);
			}

			this.ui.label.toggleClass(
				'kx-ellipsis',
				this.options.addEllipsisToLabel
			);

			var checked = this.useModelAttr ? this.options.getCheckedValueFromModel() : this.options.checked;
			this.showState(checked);
			
			if (this.model && this.options.modelAttr) {
				this.listenTo(this.model, 'invalid:' + this.options.modelAttr, function (error) {
					self.showError(error);
				});
			}
		},
		/**
		 * Handle a click on the checkbox.  Set the model attribute and trigger
		 * a 'change' event on this view.
		 */
		clickHandler: function () {
			this.showState(!this.getValue());

			if (this.useModelAttr) {
				this.model.set(
					this.options.modelAttr,
					this.options.getModelValueFromChecked()
				);
			}
			this.trigger('change');
		},
		getValue: function () {
			return this.ui.checkBox.is(':checked');
		},
		getModelValue: function () {
			return !!this.model.get(this.options.modelAttr);
		},
		_showTooltip: function (text, isError, options) {
			var opts = _.defaults(
				{},
				options,
				{
					tooltipPos: this.options.tooltipPos
				}
			);
			var tooltip = new KXL.Components.Tooltip.View({
				$elParent: this.$el,
				position: opts.tooltipPos,
				text: text
			});
			tooltip.listenTo(tooltip, 'show', function () {
				if (isError) {
					tooltip.showError(text, opts);
				} else {
					tooltip.showText(text, opts);
				}
			});
			this.tooltipRegion.show(tooltip);
			this.tooltipRegion.ensureEl();
			this.tooltipRegion.$el.show();
		},
		_hideTooltip: function () {
			this.tooltipRegion.ensureEl();
			this.tooltipRegion.$el.hide();
		},
		showError: function (text, options) {
			this._showTooltip(text, true, options);
		},
		showNormal: function () {
			this._hideTooltip();
		},
		showTooltip: function (text, options) {
			this._showTooltip(text, false, options);
		},
		showState: function (checked) {
			this.ui.checkBox.prop('checked', checked);
			this.ui.label.toggleClass('checked', checked);
		}
	});
});
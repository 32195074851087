KXL.module('Components.ContentPane', function (ContentPane, KXL, Backbone, Marionette, $, _) {

	ContentPane.Types = {
		NO_BOTTOM: 'no-bottom'
	}

	ContentPane.View = Marionette.Layout.extend({
		template: '#content-pane-template',
		className: 'kx-content-pane',
		ui: {
			content: '.kx-content-pane-content'
		},
		regions: {
			top: '.kx-content-pane-top',
			content: '.kx-content-pane-content'
		},
		initialize: function (options) {
			defaults = {
				scroll: true
			};
			this.options = _.defaults({}, options, defaults);
		},
		onRender: function () {
			if (this.options.topView) {
				this.setTopView(this.options.topView);
			}
			if (this.options.contentView) {
				this.setContentView(this.options.contentView);
			}
			if (this.options.type === ContentPane.Types.NO_BOTTOM) {
				this.$el.addClass('kx-content-pane-nobottom');
			}
		},
		setTopView: function (view) {
			this.top.show(view);
		},
		setContentView: function (view) {
			this.content.show(view);
		},
		serializeData: function () {
			var viewData = {};
			viewData.headingText = this.options.headingText || '';
			return viewData;
		}
	});
});

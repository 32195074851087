KXL.module('Common.Utils.Base64', function (Base64, KXL, Backbone, Marionette, $, _) {
	Base64.PADCHAR = '=';
	Base64.ALPHA = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
	Base64.makeDOMException = function() {
		// sadly in FF,Safari,Chrome you can't make a DOMException
		var e, tmp;

		try {
			return new DOMException(DOMException.INVALID_CHARACTER_ERR);
		} catch (tmp) {
			// not available, just passback a duck-typed equiv
			// https://developer.mozilla.org/en/Core_JavaScript_1.5_Reference/Global_Objects/Error
			// https://developer.mozilla.org/en/Core_JavaScript_1.5_Reference/Global_Objects/Error/prototype
			var ex = new Error("DOM Exception 5");

			// ex.number and ex.description is IE-specific.
			ex.code = ex.number = 5;
			ex.name = ex.description = "INVALID_CHARACTER_ERR";

			// Safari/Chrome output format
			ex.toString = function() { return 'Error: ' + ex.name + ': ' + ex.message; };
			return ex;
		}
	}

	Base64.getbyte64 = function(s,i) {
		// This is oddly fast, except on Chrome/V8.
		//  Minimal or no improvement in performance by using a
		//   object with properties mapping chars to value (eg. 'A': 0)
		var idx = Base64.ALPHA.indexOf(s.charAt(i));
		if (idx === -1) {
			throw Base64.makeDOMException();
		}
		return idx;
	}

	Base64.decode = function(s) {
		// convert to string
		s = '' + s;
		var getbyte64 = Base64.getbyte64;
		var pads, i, b10;
		var imax = s.length
		if (imax === 0) {
			return s;
		}

		if (imax % 4 !== 0) {
			throw Base64.makeDOMException();
		}

		pads = 0
		if (s.charAt(imax - 1) === Base64.PADCHAR) {
			pads = 1;
			if (s.charAt(imax - 2) === Base64.PADCHAR) {
				pads = 2;
			}
			// either way, we want to ignore this last block
			imax -= 4;
		}

		var x = [];
		for (i = 0; i < imax; i += 4) {
			b10 = (getbyte64(s,i) << 18) | (getbyte64(s,i+1) << 12) |
				(getbyte64(s,i+2) << 6) | getbyte64(s,i+3);
			x.push(String.fromCharCode(b10 >> 16, (b10 >> 8) & 0xff, b10 & 0xff));
		}

		switch (pads) {
			case 1:
				b10 = (getbyte64(s,i) << 18) | (getbyte64(s,i+1) << 12) | (getbyte64(s,i+2) << 6);
				x.push(String.fromCharCode(b10 >> 16, (b10 >> 8) & 0xff));
				break;
			case 2:
				b10 = (getbyte64(s,i) << 18) | (getbyte64(s,i+1) << 12);
				x.push(String.fromCharCode(b10 >> 16));
				break;
		}
		return x.join('');
	}

	Base64.getbyte = function(s,i) {
		var x = s.charCodeAt(i);
		if (x > 255) {
			throw Base64.makeDOMException();
		}
		return x;
	}

	Base64.encode = function(s) {
		if (arguments.length !== 1) {
			throw new SyntaxError("Not enough arguments");
		}
		var padchar = Base64.PADCHAR;
		var alpha   = Base64.ALPHA;
		var getbyte = Base64.getbyte;

		var i, b10;
		var x = [];

		// convert to string
		s = '' + s;

		var imax = s.length - s.length % 3;

		if (s.length === 0) {
			return s;
		}
		for (i = 0; i < imax; i += 3) {
			b10 = (getbyte(s,i) << 16) | (getbyte(s,i+1) << 8) | getbyte(s,i+2);
			x.push(alpha.charAt(b10 >> 18));
			x.push(alpha.charAt((b10 >> 12) & 0x3F));
			x.push(alpha.charAt((b10 >> 6) & 0x3f));
			x.push(alpha.charAt(b10 & 0x3f));
		}
		switch (s.length - imax) {
			case 1:
				b10 = getbyte(s,i) << 16;
				x.push(alpha.charAt(b10 >> 18) + alpha.charAt((b10 >> 12) & 0x3F) +
					padchar + padchar);
				break;
			case 2:
				b10 = (getbyte(s,i) << 16) | (getbyte(s,i+1) << 8);
				x.push(alpha.charAt(b10 >> 18) + alpha.charAt((b10 >> 12) & 0x3F) +
					alpha.charAt((b10 >> 6) & 0x3f) + padchar);
				break;
		}
		return x.join('');
	}
	/**
	 *
	function:
	dataURItoBlob

	Description:
	Creates a blob for posting from a Base64 uri

	@params
	dataURI: Base64 data uri

	 */
	Base64.dataURItoBlob = function (dataURI) {
		var byteString = Base64.decode(dataURI.split(',')[1]);

		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++)
		{
			ia[i] = byteString.charCodeAt(i);
		}

		var bb = new Blob([ab], { "type": mimeString });
		return bb;
	};
});
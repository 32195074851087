KXL.module('Services.Friends', function (Friends, KXL, Backbone, Marionette, $, _) {

	var _friendsCache = {};

	function _get(userId, attrib) {
		return (_friendsCache[userId] && _friendsCache[userId][attrib]) ?
			_friendsCache[userId][attrib] :
			null;
	}

	function _initCacheEntry(userId, props) {

		if (!_friendsCache[userId]) {
			_friendsCache[userId] = {};
		}

		if (!_.isEmpty(props)) {
			_.extend(_friendsCache[userId], props);
		}

	}

	KXL.reqres.setHandlers({
		'purge:service:friends:count': function (userId) {
			if (_friendsCache[userId]) {
				delete _friendsCache[userId];
			}
		},

		'get:service:friends:count': function (userId) {
			return (_get(userId, 'friendCount') || 0);
		},

		'increment:service:friends:count': function(userId) {
			if (!_friendsCache[userId]) {
				_friendsCache[userId] = { friendCount: 1 };
			} else {
				var friendCount = _friendsCache[userId].friendCount || 0;
				_friendsCache[userId].friendCount = ++friendCount;
			}
		},
		'decrement:service:friends:count': function(userId) {
			if (_friendsCache[userId]  && _.has(_friendsCache[userId], 'friendCount')) {
				var friendCount = _friendsCache[userId].friendCount || 0;
				_friendsCache[userId].friendCount = Math.max(0, --friendCount);
			}
		},
		'set:service:friends:count': function (userId, count) {
			_initCacheEntry(userId, {
				friendCount: count
			});
		}
	});

});

KXL.module('FriendsPanelApp', function (FriendsPanelApp, KXL, Backbone, Marionette, $, _) {
	var API = {
		getFriendsPanelView: function (options) {
			return FriendsPanelApp.Controller.getFriendsPanelView(options);
		}
	};
	KXL.addInitializer(function () {
		KXL.reqres.setHandler('get:friendspanel:view',
			function (options) {
				return API.getFriendsPanelView(options);
			}
		);
	});
	KXL.vent.on('auth:changed', function () {
		if (!KXL.currentUser) {
			FriendsPanelApp.Controller.destroyFriendsPanelView();
		}
	});
});
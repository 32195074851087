KXL.module('Common.Utils.Async', function (Async, KXL, Backbone, Marionette, $, _) {

	var MODULES_BASE_PATH = '/modules/';
	var VENDOR_BASE_PATH = '/vendor/';

	var
		head = null,
		ieLoadedStates = ['complete', 'loaded'],
		loadedModules = {}
	;

	function validateCallback(callback) {
		if (callback && callback.apply) {
			callback();
		}
	}

	function getHead() {
		if (!head) {
			head = document.getElementsByTagName('head')[0];
		}
		return head;
	}

	function moduleLoaded(modulePath, promise) {
		loadedModules[modulePath] = promise;
	}

	function addEvent(element, eventName, callback) {

		if (element.addEventListener) {
			return element.addEventListener(
				eventName,
				callback,
				false
			);
		}

		if (element.attachEvent) {
			return element.attachEvent('on' + eventName, callback);
		}

	}

	function getAsyncModuleConfig(modulePath) {

		if (KXL.config.asyncModules && KXL.config.asyncModules[modulePath]) {
			return KXL.config.asyncModules[modulePath];
		}

		return {};
	}

	function getFilename(modulePath, fileName) {
		return MODULES_BASE_PATH + modulePath + '/' + fileName;
	}

	function getVendorFilename(fileName) {
		return VENDOR_BASE_PATH + fileName;
	}

	function loadAsset(func, fileName) {
		func.call(
			Async,
			fileName,
			function () {
				KXL.atom.set(fileName, true);
			},
			function () {
				KXL.atom.set(fileName, false);
			}
		);
	}

	Async.loadRemoteJsUrls = function (urls) {
		var defer = $.Deferred();

		// Ensure we will provide an array for further processing.
		urls = _.isString(urls) ? [urls] : urls;
		var aggregateUrlModule = urls.join('/');

		if (loadedModules[aggregateUrlModule]) {
			return loadedModules[aggregateUrlModule];
		}
		moduleLoaded(aggregateUrlModule, defer.promise());

		var self = this;
		var jsBarriers = [];

		_.each(urls, function (url) {
			jsBarriers.push(url);
			loadAsset(self.loadJS, url);
		});

		KXL.atom.once(jsBarriers, function () {
			defer.resolve();
		});

		return loadedModules[aggregateUrlModule];

	};

	Async.loadModule = function (modulePath) {
		var defer = $.Deferred();

		if (loadedModules[modulePath]) {
			return loadedModules[modulePath];
		}
		moduleLoaded(modulePath, defer.promise());

		var self = this;
		var modulePathParts = modulePath.split('/');
		var moduleName = modulePathParts.pop();

		// Ensure all files have loaded before proceeding using the barrier pattern.
		var cssTemplateLocaleBarriers = [];
		var jsBarriers = [];

		var jsFiles = [];
		var localeTemplateFiles = [];
		var cssFiles = [];
		var includeLocale = false;

		var moduleConfig = getAsyncModuleConfig(modulePath);

		function loadModule() {
			if (moduleConfig['locale'] && _.isBoolean(moduleConfig['locale'])) {
				includeLocale = moduleConfig['locale'];
			}

			if (!KXL.config.useCompressed) {

				var jsIncludes = [];
				var cssIncludes = [];
				var vendorJsIncludes = [];
				var vendorCssIncludes = [];
				var includeTemplate = false;

				if (moduleConfig['css'] && _.isArray(moduleConfig['css'])) {
					cssIncludes = moduleConfig['css'];
				}

				if (moduleConfig['vendor-css'] && _.isArray(moduleConfig['vendor-css'])) {
					vendorCssIncludes = moduleConfig['vendor-css'];
				}

				if (moduleConfig['js'] && _.isArray(moduleConfig['js'])) {
					jsIncludes = moduleConfig['js'];
				}

				if (moduleConfig['vendor-js'] && _.isArray(moduleConfig['vendor-js'])) {
					vendorJsIncludes = moduleConfig['vendor-js'];
				}

				if (moduleConfig['template'] && _.isBoolean(moduleConfig['template'])) {
					includeTemplate = moduleConfig['template'];
				}

				_.each(vendorCssIncludes, function (vendorCssInclude) {
					var vendorCssFile = getVendorFilename(vendorCssInclude);
					cssFiles.push(vendorCssFile);
					cssTemplateLocaleBarriers.push(vendorCssFile);
				});

				_.each(cssIncludes, function (cssInclude) {
					var cssFile = getFilename(modulePath, 'css/' + cssInclude);
					cssFiles.push(cssFile);
					cssTemplateLocaleBarriers.push(cssFile);
				});

				if (includeLocale) {
					var localeFile = getFilename(
						modulePath,
							'lang/' + KXL.config.locale + '.compiled.js'
					);
					localeTemplateFiles.push(localeFile);
					cssTemplateLocaleBarriers.push(localeFile);
				}

				if (includeTemplate) {
					var templateFile = getFilename(
						modulePath,
							'templates/' + moduleName + '.compiled.js'
					);
					localeTemplateFiles.push(templateFile);
					cssTemplateLocaleBarriers.push(templateFile);
				}

				_.each(vendorJsIncludes, function (vendorInclude) {
					var vendorFile = getVendorFilename(vendorInclude);
					jsFiles.push(vendorFile);
					jsBarriers.push(vendorFile);
				});

				_.each(jsIncludes, function (jsInclude) {
					var jsFile = getFilename(modulePath, jsInclude);
					jsFiles.push(jsFile);
					jsBarriers.push(jsFile);
				});

			} else {

				// Production JS modules contain relevant locale bundle, CSS and
				// compiled handlebars template.

				var minifiedBase = MODULES_BASE_PATH +
					modulePath +
					'/';

				var jsFile =
					KXL.config.STATIC_BASE_URL +
					'/js' + minifiedBase + moduleName +
					((includeLocale) ? '.' + KXL.config.locale : '') +
					'.min.js';

				jsFiles.push(jsFile)
				jsBarriers.push(jsFile);
			}

			_.each(cssFiles, function (cssFile) {
				loadAsset(self.loadCSS, cssFile);
			});

			_.each(localeTemplateFiles, function (localeTemplateFile) {
				loadAsset(self.loadJS, localeTemplateFile);
			});

			KXL.atom.once(cssTemplateLocaleBarriers, function () {

				// Since javascript files include runtime references to templates
				// and locales ensure they are loaded before proceeding.

				_.each(jsFiles, function (jsFile) {
					loadAsset(self.loadJS, jsFile);
				});

			});

			KXL.atom.once(jsBarriers, function () {
				defer.resolve();
			});
		}

		if (moduleConfig.dependencies) {
			var dependenciesDeferreds = [];
			_.each(moduleConfig.dependencies, function (dependency) {
				dependenciesDeferreds.push(Async.loadModule(dependency));
			});
			$.when.apply($, dependenciesDeferreds).then(function () {
				loadModule();
			});
		} else {
			loadModule();
		}

		return loadedModules[modulePath];

	};

	Async.loadCSS = function (url, callback, error) {
		var link = document.createElement("link");
		link.rel = 'stylesheet';
		link.type = 'text/css';
		link.href = url;
		this.loadScript(link, callback, error);
	};

	Async.loadJS = function (url, callback, error) {
		var jsScript = document.createElement('script');
		jsScript.type = 'text/javascript';
		jsScript.async = false;
		jsScript.src = url;
		jsScript.charset= 'utf-8';
		this.loadScript(jsScript, callback, error);
	};

	Async.loadScript = function (scriptElement, callback, error) {

		// Bind all relevant events
		addEvent(
			scriptElement,
			'error',
			function () {
				validateCallback(error);
			}
		);

		if (scriptElement.addEventListener) {
			scriptElement.addEventListener(
				'load',
				function () {
					validateCallback(callback);
				},
				false
			);
		}

		if (scriptElement.attachEvent) {
			scriptElement.attachEvent('onreadystatechange', function () {
				if (scriptElement.indexOf(ieLoadedStates) !== -1) {
					validateCallback(callback);
				}
			});
		}

		getHead().appendChild(scriptElement);

    };

	KXL.reqres.setHandlers({

		'async:load:module': function (modulePath) {
			return Async.loadModule(modulePath);
		},

		'async:load:remoteJsUrls': function (urls) {
			return Async.loadRemoteJsUrls(urls);
		}

	});

});

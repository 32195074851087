KXL.module('GamesApp.MediaPreview', function (MediaPreview, KXL, Backbone, Marionette, $, _) {
	MediaPreview.View = Marionette.ItemView.extend({
		template: '#preview-template',
		className: 'kx-preview-wrapper',
		ui: {
			previewItem: '.kx-preview',
			previewThumb: '.kx-preview-thumb',
			previewHoverIcon: '.kx-preview-hover-icon',
			previewCaption: '.kx-preview-caption'
		},
		events: {
			'click .kx-preview-hover-icon.image': 'thumbClickHandler',
			'click .kx-preview-hover-icon.video': 'videoClickHandler'
			
		},
		onRender: function () {
			if (this.model.get('caption') === '') {
				if (this.model.get('type') === MediaPreview.Type.image) {
					this.model.set('caption', KXL.i18n.t('games.media.preview.screenshotOfTheDay'));
				} else if (this.model.get('type') === MediaPreview.Type.video) {
					this.model.set('caption', KXL.i18n.t('games.media.preview.videoOfTheDay'));
				}
			}
			//Set the custom class of this el
			if (this.model.get('customClass')) {
				this.$el.addClass(this.model.get('customClass'));
			}
			//Set the class for hover icon
			if (this.model.get('type') === MediaPreview.Type.video) {
				this.ui.previewHoverIcon.addClass("video");
			} else if (this.model.get('type') === MediaPreview.Type.image) {
				this.ui.previewHoverIcon.addClass("image");
			}
		},
		thumbClickHandler: function (e) {
			this.$el.colorbox({
				href: this.model.get('previewSrc'),
				rel: this.model.get('className'),
				width: this.model.get('width'),
				height: this.model.get('height'),
				transition: "fade",
				opacity: 0.6,
				onComplete:function(){
					$("#cboxTitle").hide();
				}
			});
		},
		videoClickHandler: function (e) {
			var href = this.model.get('previewSrc');
			if (href.indexOf('?rel=0') === -1) {
				// Ensure that related videos are not shown.
				href += '?rel=0';
			}
			this.$el.colorbox({
				closeButton: false,
				href: href,
				iframe: true,
				innerWidth: 640,
				innerHeight: 390
			});
		}
	});

	MediaPreview.Type = {
		image: 'image',
		video: 'video'
	};
});
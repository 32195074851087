KXL.module('Chat', function (Chat, KXL, Backbone, Marionette, $, _) {

	Chat.KXLInterface = Chat.InterfaceBase.extend({

		_userId: null,
		_contacts: {},
		_rooms: {},

		_autoRetry: true,

		_defaultRooms: {},

		_domain: null,

		connect: function () {

			var self = this;

			self._autoRetry = true;
			var currentUser = KXL.request('get:current:user');
			if (!currentUser) {
				return self._reset();
			}
			self._userId = currentUser.id;

			$.ajax({
				type: 'POST',
				url: KXL.config.API_URL + '/users/' + self._userId + '/chat-sessions',
				contentType: 'application/json',
				data: JSON.stringify({
					resource: Date.now().toString()
				}),
				success: function (response) {

					self._domain = Strophe.getDomainFromJid(response.jid);

					if (response.defaultChatRooms) {
						_.each(response.defaultChatRooms, function (defaultChatRoom) {

							var jabberId = defaultChatRoom.node + '@' + self.SUBDOMAIN + '.'+ self._domain;
							var chatRoom = new Chat.Room(self, {
								id: defaultChatRoom.node,
								jabberId: jabberId,
								type: 'default',
								title: defaultChatRoom.name,
								members: []
							});
							chatRoom.settingsKey = defaultChatRoom.settingsKey;
							chatRoom.commonName = defaultChatRoom.commonName;
							self._defaultRooms[jabberId] = chatRoom;
						});

						_.each(response.rooms, function (chat) {
							var chatRoom = new Chat.Room(self, chat)
							self._rooms[chat.jabberId] = chatRoom;
						});						
					}

					var wsService = 'wss://' + response.server + ':443';

					self._jabberId = response.jid;
					self._bareJabberId = Strophe.getBareJidFromJid(self._jabberId);

					if (!self._connection) {
						self._connection = new Strophe.Connection(wsService);
					}
					else {
						self._connection.reset();
					}
					self._connection.connect(self._jabberId, response.password, self._connectionCallback.bind(self));
				}

			}).fail(function () {

				self.trigger("connection:failed");
				if (self._autoRetry) {
					self._retry();
				}

			});
		},

		disconnect: function () {

			this._reset();

			this._autoRetry = false;

			if (this.isConnected()) {
				this._connection.disconnect();
			}

		},

		getUserPresence: function (userId) {
			if (this.isConnected()) {
				var chatUser = this._contacts[userId];
				if (chatUser) {
					return chatUser.status;
				}
			}
			return this.PRESENCE.UNKNOWN;
		},

		createChat: function (userIds) {

			var self = this;
			var deferred = $.Deferred();

			var chatPost = {
				members: _.union(userIds, [self._userId])
			};

			self._lock();

			$.ajax({
				type: "POST",
				url: KXL.config.API_URL + "/chat-rooms",
				contentType: "application/json",
				data: JSON.stringify(chatPost),

				success: function (chat) {

					var chatRoom = self._rooms[chat.jabberId];

					if (!chatRoom) {
						chatRoom = new Chat.Room(self, chat);
						self._rooms[chat.jabberId] = chatRoom;
					}

					deferred.resolve(chatRoom);
				}

			}).fail(function () {

				deferred.reject();

			}).always(function () {

				self._unlock();

			});

			return deferred;
		},

		getDefaultRooms: function () {
			return this._defaultRooms;
		},

		getDefaultRoomBySettingsKey: function (settingsKey) {
			if (!this.isConnected()) {
				return false;
			}
			return _.find(this._defaultRooms, function (defaultRoom) {
				if (settingsKey === defaultRoom.settingsKey) {
					return true;
				}
			});
		},

		markChatAsRead: function (chatId) {
			var self = this;
			var deferred = $.Deferred();

			var url = KXL.config.API_URL + "/chat-rooms/" + chatId + "/members/" + self._userId;

			var chatMemberPatch = {
				lastMessageAt: Date.now()
			};

			$.ajax({
				type: "PATCH",
				url: url,
				contentType: "application/json",
				data: JSON.stringify(chatMemberPatch),

				success: function (chat) {
					var chatRoom = self._rooms[chat.jabberId];
					if (chatRoom) {
						chatRoom._update(chat);
						self.trigger('room:reset:unread:messages', chatRoom);
					}
					deferred.resolve(chatRoom);
				}

			}).fail(deferred.reject);

			return deferred;
		},

		leaveChat: function (chatId) {

			var self = this;
			var deferred = $.Deferred();

			var url = KXL.config.API_URL + "/chat-rooms/" + chatId + "/members/" + self._userId;

			var chatMemberPatch = {
				active: false
			};

			$.ajax({
				type: "PATCH",
				url: url,
				contentType: "application/json",
				data: JSON.stringify(chatMemberPatch),

				success: function (response) {
					self.trigger('unread:messages:updated');
					deferred.resolve();
				}

			}).fail(deferred.reject);

			return deferred;

		},

		addUsersToRoom: function (chatId, userIds) {

			var self = this;
			var deferred = $.Deferred();

			var chatMembersPatch = {
				members: userIds
			};

			self._lock();

			$.ajax({
				type: "PATCH",
				url: KXL.config.API_URL + "/chat-rooms/" + chatId + "/members",
				contentType: "application/json",
				data: JSON.stringify(chatMembersPatch),

				success: function (chat) {

					var chatRoom = self._rooms[chat.jabberId] || self._defaultRooms[chat.jabberId];

					if (chatRoom && chat.type !== "default") {
						chatRoom._update(chat);
					}
					else {
						if (chat.type !== "default") {
							chatRoom = new Chat.Room(self, chat);
							self._rooms[chat.jabberId] = chatRoom;
						}
					}

					deferred.resolve(chatRoom);
				}

			}).fail(function () {

				deferred.reject();

			}).always(function () {

				self._unlock();

			});

			return deferred;

		},

		removeUserFromRoom: function (chatId, userId) {

			var self = this;
			var deferred = $.Deferred();

			var url = KXL.config.API_URL + "/chat-rooms/" + chatId + "/members/" + userId;

			$.ajax({
				type: "DELETE",
				url: url,

				success: function (response) {
					deferred.resolve();
				}

			}).fail(function () {

				deferred.reject();

			});

			return deferred;

		},

		joinDefaultChatRoom: function (chatId) {
			if (!this.isConnected()) {
				return;
			}
			return this.addUsersToRoom(chatId, [this._userId]);
		},

		leaveDefaultChatRoom: function (chatId) {
			if (!this.isConnected()) {
				return;
			}
			return this.removeUserFromRoom(chatId, this._userId);
		},

		sendPrivateMessage: function (userId, message) {

			var self = this;

			var chatRoom = _.find(self._rooms, function (chatRoom) {
				return chatRoom.type === "private" && _.contains(chatRoom.members, userId);
			});

			if (chatRoom) {
				return chatRoom.sendMessage(message);
			}

			self.createChat([userId]).then(function (newChatRoom) {
				newChatRoom.sendMessage(message);
			});

		},

		sendMessageToRoom: function (chatId, message) {

			var chatRoom = _.find(this._rooms, function (chatRoom) {
				return chatRoom.id === chatId;
			}) || _.find(this._defaultRooms, function (defaultChatRoom) {
				return defaultChatRoom.id === chatId;
			});

			if (chatRoom) {
				chatRoom.sendMessage(message);
			}

		},

		sendMessageToRoomByJabberId: function (jabberId, message) {

			var chatRoom = this._rooms[jabberId];
			
			if (chatRoom) {
				chatRoom.sendMessage(message);
			}

		},

		setRoomTitle: function (chatId, title) {

			var self = this;
			var deferred = $.Deferred();

			var chatPatch = {
				title: title
			};

			self._lock();

			$.ajax({
				type: "PATCH",
				url: KXL.config.API_URL + "/chat-rooms/" + chatId,
				contentType: "application/json",
				data: JSON.stringify(chatPatch),

				success: function () {
					deferred.resolve();
				}

			}).fail(function () {

				deferred.reject();

			}).always(function () {

				self._unlock();

			});

			return deferred;
		},

		getActionHistory: function () {

			var deferred = $.Deferred();
			var self = this;

			var url = KXL.config.API_URL + "/users/" + self._userId + "/chat-actions";

			$.ajax({
				type: "GET",
				url: url,
				dataType: "json",
				success: function (actionHistory) {
					deferred.resolve(actionHistory);
				}

			}).fail(function () {

				deferred.reject();

			});

			return deferred;

		},

		_connectionCallback: function (status) {

			var self = this;
			var statusString = self.CONNECTION_STATUS[status];

			if (status === Strophe.Status.CONNECTED || status === Strophe.Status.ATTACHED) {

				self._connection.addHandler(self._onMessage.bind(self), null, "message");
				this._connection.addHandler(self._onPresence.bind(self), null, "presence");

				self._connection.send($pres({}));
				self._lock();
				self._getContacts().then(function () {
					self.trigger("connection", "ONLINE");
					self._unlock();
				});

			}
			else {

				self.trigger("connection", statusString);

				if (status === Strophe.Status.ERROR ||
					status === Strophe.Status.CONNFAIL ||
					status === Strophe.Status.AUTHFAIL ||
					status === Strophe.Status.DISCONNECTED)
				{
					self._markContactsAsUnavailable();
					if (self._autoRetry) {
						self._retry();
					}
				}
			}
		},

		_reset: function () {

			this._markContactsAsUnavailable();
			this._userId = null;
			this._jabberId = null;
			this._contacts = {};
			this._emptyEventQueues();

		},

		_retry: function () {

			this.disconnect();

			console.log("KXLChatInterface: Retrying in " + this.RETRY_DELAY / 1000 + " seconds...");

			_.delay(this.connect.bind(this), this.RETRY_DELAY);

		},

		_markContactsAsUnavailable: function () {
			var self = this;
			_.each(self._contacts, function (chatUser) {
				chatUser.status = self.PRESENCE.UNAVAILABLE;
				self.trigger("presence", chatUser);
			});
		},

		_getUserIdFromJid: function (jabberId) {
			var node = Strophe.getNodeFromJid(jabberId);
			var tokens = node.split(".");

			if (tokens.length > 0) {
				if (tokens.length > 1) {
					return tokens[1];
				}
				return tokens[0];
			}
			return node;
		},

		_getContacts: function () {

			var self = this;
			var deferred = $.Deferred();

			if (!this.isConnected()) {
				return deferred.reject();
			}

			self._connection.sendIQ($iq({ type: "get" }).c("query", { xmlns: Strophe.NS.ROSTER }), function (stanza) {
				$(stanza).find("item").each(function () {

					var jabberId = $(this).attr("jid");
					var userId = self._getUserIdFromJid(jabberId);

					if (!self._contacts[userId]) {
						self._contacts[userId] = new Chat.User(userId, self.PRESENCE.UNAVAILABLE);
					}
				});

				deferred.resolve(self._contacts);

			}, deferred.reject);

			return deferred;
		},

		_onNotification: function (notification) {

			var self = this;

			var type = notification.type;

			if (!type) {
				return;
			}

			if (type === "update") {

				var chatId = notification.chatId;
				if (!chatId) {
					return;
				}

				self._lock();
				self._getRoomInfo(null, chatId).then(function (chat) {

					var bareJabberId = chat.jabberId;

					var chatRoom = self._rooms[bareJabberId];
					
					if (chatRoom) {

						chatRoom._update(chat);
						if (!chatRoom.isIgnoredChat()) {
							self.trigger("room:update", chatRoom);
						}

					}

				}).fail(function () {

					console.log("KXLChatInterface error: can't get room info", bareJabberId);

				}).always(function () {

					self._unlock();

				});

			}
			else if (type === "chat.removed") {
				var jabberId = notification.jabberId;
				var chatRoom = self._rooms[jabberId];

				if (chatRoom) {
					self.trigger("room:left", chatRoom);
					if (self._rooms[jabberId]) {
						delete self._rooms[jabberId];
					}
				}
			}
			else if (type === "default.chat.added") {

				var jabberId = notification.jabberId;
				var chatRoom = self._defaultRooms[jabberId];
				var chatId = notification.chatId;

				if (!chatRoom) {
					chatRoom = new Chat.Room(self, {
						id: chatId,
						type: "default",
						jabberId: jabberId,
						title: notification.name,
						members: [],
					});
					self._defaultRooms[jabberId] = chatRoom;
		
					self.trigger("default:room:added", chatRoom);
				}
			}
			else if (type === "default.chat.removed") {

				var chatId = notification.chatId;
				var jabberId = notification.jabberId;

				if (self._defaultRooms[jabberId]) {
					delete self._defaultRooms[jabberId];
					self.trigger("default:room:removed", chatId);
				}
			}
		},

		_onMessage: function (stanza) {

			if (this._locked) {
				return this._enqueueMessage(stanza);
			}

			var type = stanza.getAttribute("type");

			if (type === "notification") {

				var body = "";
				$(stanza).find("body").each(function () {
					body = Strophe.getText(this);
				});

				try {
					var json = JSON.parse(body);
					this._onNotification(json);
				}
				catch (error) {

					this.trigger("error", error);

				}

			}
			else {

				var from = stanza.getAttribute("from");
				var domain = Strophe.getDomainFromJid(from);

				if (domain.indexOf(this.SUBDOMAIN) === 0 && type !== "error") {

					var bareJabberId = Strophe.getBareJidFromJid(from);
					var chatRoom = this._rooms[bareJabberId] || this._defaultRooms[bareJabberId];

					if (chatRoom) {
						chatRoom._onMessage(stanza);
					}

				}
				else {

					var message = new Chat.Message(this, stanza);
					this.trigger("message", message);

				}

			}

			return true;
		},

		_onPresence: function (stanza) {

			if (this._locked) {
				return this._enqueuePresence(stanza);
			}

			var jabberId = stanza.getAttribute("from");
			var to = stanza.getAttribute("to");
			var domain = Strophe.getDomainFromJid(jabberId);
			var status = stanza.getAttribute("type") || this.PRESENCE.AVAILABLE;
			var bareJabberId = Strophe.getBareJidFromJid(jabberId);

			if (domain.indexOf(this.SUBDOMAIN) !== -1) {

				this._onRoomPresence(jabberId, bareJabberId, status, stanza);

			}
			else if (to === this._jabberId && bareJabberId !== this._bareJabberId) {

				this._onUserPresence(jabberId, status);

			}

			return true;
		},

		_onRoomPresence: function (jabberId, bareJabberId, status, stanza) {

			var self = this;

			var userId = self._getFirstTokenFromResource(jabberId);

			var chatRoom = self._rooms[bareJabberId] || self._defaultRooms[bareJabberId];

			if (status === "error") {

				if (chatRoom) {
					self.leaveChat(chatRoom.id);
					if (self._rooms[bareJabberId]) {
						delete self._rooms[bareJabberId];
					}
				}
				return;

			}

			var statusCodes = [];

			$(stanza).find("status").each(function () {

				statusCodes.push($(this).attr("code"));

			});

			$(stanza).find("item").each(function () {

				/**
				 * statusCode 110: Inform user that presence refers to one of its own room occupants
				 * ref: http://xmpp.org/registrar/mucstatus.html
				 *
				 * I'm using this to determine whether user left a room in one of their chat sessions.
				 * This will help with keeping all active sessions in sync (ie. multiple tabs)
				 */
				
				var ownPresence = (statusCodes.indexOf("110") !== -1) || (userId === self._userId);

				if (ownPresence && (status === self.PRESENCE.UNAVAILABLE)) {

					self.trigger("room:left", chatRoom);
					if (self._rooms[bareJabberId]) {
						delete self._rooms[bareJabberId];
					}
				}
				else {

					if (!chatRoom) {

						self._lock();

						self._getRoomInfo(bareJabberId).then(function (chat) {

							chatRoom = new Chat.Room(self, chat);
							self._rooms[bareJabberId] = chatRoom;
							chatRoom._onPresence(userId, status);

						}).fail(function () {

							console.log("KXLChatInterface error: can't get room info", bareJabberId);

						}).always(function () {

							self._unlock();

						});
					}
					else {

						chatRoom._onPresence(userId, status);

					}

				}

			});

		},

		_onUserPresence: function (jabberId, status) {

			var resource = Strophe.getResourceFromJid(jabberId);
			if (!resource) {
				console.warn("KXLChatInterface: _onUserPresence: missing resource", jabberId);
				return;
			}

			var userId = this._getUserIdFromJid(jabberId);

			var chatUser = this._contacts[userId];

			if (!chatUser) {
				chatUser = new Chat.User(userId);
				this._contacts[userId] = chatUser;
			}

			if (status === this.PRESENCE.AVAILABLE) {

				chatUser._addResource(resource);

				if (chatUser.status !== status) {
					chatUser.status = status;
					this.trigger("presence", chatUser);
				}
			}
			else if (status === this.PRESENCE.UNAVAILABLE) {

				chatUser._removeResource(resource);

				if (chatUser._resources.length === 0) {
					chatUser.status = status;
					this.trigger("presence", chatUser);
				}
			}
			else { //status === this.PRESENCE.UNSUBSCRIBE
				chatUser._clearResources();
				chatUser.status = status;
				this.trigger("presence", chatUser);
				delete this._contacts[userId];
			}
		},

		_getRoomInfo: function (jabberId, chatId) {

			var deferred = $.Deferred();
			var self = this;

			var url = KXL.config.API_URL + "/chat-rooms?userId=" + self._userId;

			if (jabberId) {
				url += "&jabberId=" + jabberId;
			}

			if (chatId) {
				url += "&chatId=" + chatId;
			}

			$.ajax({
				type: "GET",
				url: url,
				dataType: "json",
				success: function (response) {
					if (response.length === 1) {
						return deferred.resolve(response[0]);
					}
					deferred.reject();
				}
			}).fail(function () {
				deferred.reject();
			});

			return deferred;
		},
		getRoom: function (id) {
			return _.findWhere(this._rooms, { id: id });
		}
	});

});

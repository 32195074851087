KXL.module('AuthApp.DeferredReg', function (DeferredReg, KXL, Backbone, Marionette, $, _) {
	/* Nav Bar */
	DeferredReg.View = KXL.Components.Layout.extend({
		template: '#banner-deferred-reg-template',
		className: 'kx-banner-deferred-reg',
		regions: {
			message: '.kx-banner-message',
			bannerActionButton: '.kx-banner-action-button'
		},
		actionButton: null,
		initialize: function (options) {
			this.options = _.defaults(
				{},
				options,
				{
					ctaButtonOptions: {},
					message: ''
				}
			);
			if (this.options.ctaButtonOptions) {
				this.options.ctaButtonOptions = _.defaults(
					{},
					options.ctaButtonOptions,
					{
						label: '',
						theme: KXL.Components.Button.Themes.alt4o
					}
				);
			}
		},
		getActionButton: function () {
			if (this.isClosed) {
				return null;
			}
			return this.actionButton;
		},
		onShow: function() {
			var self = this;
			if (this.options.ctaButtonOptions) {
				var actionButton = this.actionButton =
					new KXL.Components.Button.View(this.options.ctaButtonOptions);
				actionButton.on('click', function () {
					self.trigger('cta:clicked');
				});
				this.bannerActionButton.show(actionButton);
			}
		},
		serializeData: function () {
			return {
				options: this.options
			};
		}
	});

	/* Deffered Registration dialog as a banner */
	DeferredReg.BannerSignUpView = KXL.Components.Layout.extend({
		template: '#banner-deferred-reg-form-template',
		className: 'kx-banner-deferred-reg-form',
		regions: {
			left: '.kx-banner-reg-panel.kx-left',
			center: '.kx-banner-reg-panel.kx-center',
			right: '.kx-banner-reg-panel.kx-right'
		},
		showLeftView: function (view) {
			this.left.show(view);
		},
		showCenterView: function (view) {
			this.center.show(view);
		},
		showRightView: function (view) {
			this.right.show(view);
		}
	});
});
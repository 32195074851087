KXL.module('AuthApp.Register.SignupForm', function (SignupForm, KXL, Backbone, Marionette, $, _) {
	SignupForm.View = KXL.Components.Layout.extend({
		template: '#signup-form-template',
		className: 'kx-signup-form',
		emailSignupSelected: null,
		socialSignupSelected: null,
		initialize: function (options) {
			var defaults = {
				tooltipPos: 'right',
				emailTooltip: '',
				socialSignUpPlacementLeft: false,
				useToggledSections: false,
				useLargeSocialButtons: false,
				showLoginLink: true,
				strings: {
					registerBtnLabel: KXL.i18n.t('auth.signup.regBtnLabel'),
					notPostOnWall: KXL.i18n.t('auth.signup.notPostOnWall'),
					facebookWillBeImported: KXL.i18n.t('auth.signup.facebookWillBeImported'),
					changeUsernameAvatarLater: KXL.i18n.t('auth.signup.changeUsernameAvatarLater'),
					registerUsingFacebook: KXL.i18n.t('auth.signup.registerUsingFacebook' + (KXL.switches.steamLoginEnabled ? '' : 'NoSteam')),
					regOr: KXL.i18n.t('auth.signup.regOr'),
					regFinePrint: KXL.i18n.t('auth.signup.regFinePrint' + (KXL.switches.steamLoginEnabled ? '' : 'NoSteam'))
				},
				registerBtnTheme: KXL.Components.Button.Themes.alt7,
				steamLoginEnabled: KXL.switches.steamLoginEnabled
			};
			var opts = this.options = _.defaults({}, options, defaults);
			this.options.strings = _.defaults({}, options.strings, defaults.strings);

			if (opts.useToggledSections) {
				opts = _.defaults(
					opts,
					{
						socialSignupSelected: true,
						emailSignupSelected: false
					}
				);
				this.emailSignupSelected = opts.emailSignupSelected;
				this.socialSignupSelected = opts.socialSignupSelected;
			}

			this.fields = {};
			this.registration = opts.registration;
			this.trackData =
				_.defaults(
					{},
					opts.data,
					{ trackControl: 'dialog', trackSource: 'navbar' }
				);
			var self = this;

			function signupFormClosed(manual, disableRegBanner) {
				// TODO: Move this logic into the auth controller.
				if (manual) {
					KXL.vent.trigger('info:register:cancel', self.trackData);
					var deferredRegView =
						KXL.request(
							'new:deferred:reg:banner:view',
							{
								ctaButtonOptions: {
									label: KXL.i18n.t('banner.cta')
								},
								message: KXL.i18n.t('banner.saveProgressMesssage')
							}
						);
					deferredRegView.listenTo(
						deferredRegView,
						'cta:clicked',
						function ()
					{
						KXL.request('banner:hide', 'delayedRegBanner', true);
						KXL.execute(
							'show:delayed:register:dialog',
							{ referrer: 'banner' }
						);
					});
					if (!disableRegBanner && self.options.delayedRegistration) {
						var region = KXL.request(
							'banner:create',
							{
								bannerId: 'delayedRegBanner',
								view: deferredRegView
							}
						);

						KXL.appModel.set('deferredRegBarShowing', true);
						KXL.vent.listenToOnce(region, 'close', function () {
							KXL.appModel.set('deferredRegBarShowing', false);
						});
					}
				}
			}

			this.listenTo(
				KXL.vent,
				'dialog:closed',
				function (manual, dlg) {
					if (dlg && dlg.dialogId === 'registerDialog') {
						signupFormClosed();
					}
				}
			);
			this.listenTo(KXL.vent, 'banner:hidden', function (options) {
				options = _.defaults(options || {}, {
					manual: false
				});
				signupFormClosed(options.manual, options.bannerId !== 'delayedRegFormBanner');
			});
		},
		ui: {
			errorMessages: '.error-messages',
			emailSignupSection: '.kx-email-signup',
			socialSignupSection: '.kx-social-signup',
			toggleToSocial: '.kx-toggle-to-social',
			toggleToEmail: '.kx-toggle-to-email',
			loginLink: '.kx-login'
		},
		modelEvents: {
			'change:state': 'update'
		},
		triggers: {
			'click .tos span': 'tos:clicked'
		},
		events: {
			'click .kx-login-link': 'loginLinkClick',
			'click .kx-toggle-section-ctrls a' : 'toggleSignupMethodClicked'
		},
		regions: {
			usernameRegion: '.kx-username-region',
			emailRegion: '.kx-email-region',
			passwordRegion: '.kx-password-region',
			termsCheckboxRegion: '.kx-terms-checkbox-region',
			policyCheckboxRegion: '.kx-policy-checkbox-region',
			registerBtnRegion: '.kx-register-btn-region',
			fbconnectBtnRegion: '.kx-btn-fb-login-region',
			googleBtnRegion: '.kx-btn-google-login-region',
			steamBtnRegion: '.kx-btn-steam-login-region'
		},
		toggleSignupMethodClicked: function (e) {
			if (e) {
				e.preventDefault;
				e.stopPropagation;
			}
			this.trigger('toggle:signup:method');
		},
		signupInteraction: function () {
			if (!this.interaction) {
				this.interaction = true;
				KXL.vent.trigger('info:register:start', this.trackData);
			}
		},
		loginLinkClick: function () {
			KXL.vent.trigger('info:register:cancel', this.trackData);
			this.trigger('login:link:clicked');
		},
		triggerRegister: function () {
			this.registration.set({
				username: this.fields.username.val(),
				password: this.fields.password.val(),
				email: this.fields.email.val()
			});
			this.trigger(
				'register:clicked',
				this.trackData
			);
		},
		update: function () {
			var states = SignupForm.Model.states;
			this.$el.find('input').prop('disabled', false);
			switch (this.model.get('state')) {
			case states.NOT_REG:
				this.fields.username.focus();
				break;
			case states.PENDING:
				this.$el.find('input').prop('disabled', true);
				this.fields.registerBtn.label('Registering...');
				break;
			}
		},
		showValidationErrors: function (errors) {
			var self = this;
			_.each(errors, function (err, key) {
				if (self.fields[key]) {
					self.fields[key].showError(err);
				}
			});
		},
		setErrorMsg: function (msg) {
			if (msg) {
				this.ui.errorMessages.html(msg);
				this.ui.errorMessages.show();
			} else {
				this.ui.errorMessages.empty();
				this.ui.errorMessages.hide();
			}
		},
		toggleEmailSignupSection: function (show) {
			if (show) {
				this.ui.toggleToSocial.show();
				this.ui.toggleToEmail.hide();
				this.ui.emailSignupSection.show();
				this.ui.socialSignupSection.hide();
				this.emailSignupSelected = true;
				this.socialSignupSelected = false;
			} else {
				this.ui.toggleToSocial.hide();
				this.ui.toggleToEmail.show();
				this.ui.emailSignupSection.hide();
				this.ui.socialSignupSection.show();
				this.emailSignupSelected = false;
				this.socialSignupSelected = true;
			}
			this.trigger('signup:method:updated');
		},
		toggleSocialSignupSection: function (show) {
			this.toggleEmailSignupSection(!show);
		},
		onRender: function () {
			var self = this;

			if (this.options.socialSignUpPlacementLeft) {
				this.$el.addClass('kx-social-signup-placement-left');
			}
			if (this.options.useToggledSections) {
				this.$el.addClass('kx-toggled-sections');
				this.toggleEmailSignupSection(this.options.emailSignupSelected);
			}

			if (this.options.hideSocialToggle) {
				this.ui.toggleToSocial.hide();
			}

			// Create username textbox widget
			this.fields.username = new KXL.Components.TextBox.View({
				model: this.registration,
				modelAttr: 'username',
				customClass: 'txt-username',
				maxLength: 20,
				placeHolder: KXL.i18n.t('auth.signup.usernamePlaceholder'),
				tooltipPos: this.options.tooltipPos
			});
			this.listenTo(this.fields.username, 'submit', this.triggerRegister.bind(this));
			// If username field already has a value on show
			// issue change on model so validation is run
			this.listenTo(this.fields.username, 'show', function() {
				if (this.fields.username.val()) {
					this.registration.trigger('change:username');
				}
			});
			this.usernameRegion.show(this.fields.username);

			// Create email textbox widget
			var email = this.fields.email = new KXL.Components.TextBox.View({
				model: this.registration,
				modelAttr: 'email',
				customClass: 'txt-email',
				placeHolder: KXL.i18n.t('auth.signup.emailPlaceholder'),
				tooltipPos: this.options.tooltipPos
			});

			if (this.options.emailTooltip) {
				email.listenTo(email, 'show', function () {
					email.showTooltip(
						self.options.emailTooltip,
						{
							noWrap: false,
							width: 300,
							fadeOut: true,
							fadeOutAfter: 10000
						}
					);
				});
			}

			// If email field already has a value on show
			// issue change on model so validation is run
			this.listenTo(this.fields.email, 'show', function() {
				if (this.fields.email.val()) {
					this.registration.trigger('change:email');
				}
			});
			this.listenTo(this.fields.email, 'submit', this.triggerRegister.bind(this));
			this.emailRegion.show(this.fields.email);

			// Create mask password input widget
			this.fields.password = new KXL.Components.TextBox.View({
				model: this.registration,
				modelAttr: 'password',
				customClass: 'txt-pwd',
				maxLength: 20,
				placeHolder: KXL.i18n.t('auth.signup.passwordPlaceholder'),
				tooltipPos: this.options.tooltipPos,
				type: 'password'
			});
			this.listenTo(this.fields.password, 'submit', this.triggerRegister.bind(this));
			this.passwordRegion.show(this.fields.password);
			
			this.fields.termsCheckbox = new KXL.Components.CheckBox.View({
				model: this.registration,
				modelAttr: 'termsCheckbox',
				label: KXL.i18n.t('auth.signup.termsAgreement'),
				addEllipsisToLabel: false,
				tooltipPos: this.options.tooltipPos
			});
			this.termsCheckboxRegion.show(this.fields.termsCheckbox);
			
			this.fields.policyCheckbox = new KXL.Components.CheckBox.View({
				model: this.registration,
				modelAttr: 'policyCheckbox',
				label: KXL.i18n.t('auth.signup.policyAgreement'),
				addEllipsisToLabel: false,
				tooltipPos: this.options.tooltipPos
			});
			this.policyCheckboxRegion.show(this.fields.policyCheckbox);

			// Create register btn
			this.fields.registerBtn = new KXL.Components.Button.View({
				label: this.options.strings.registerBtnLabel,
				theme: this.options.registerBtnTheme,
				customClass: 'btn-register'
			});
			this.listenTo(this.fields.registerBtn, 'click', this.triggerRegister.bind(this));
			this.registerBtnRegion.show(this.fields.registerBtn);

			var fbconnectBtnOptions = {
				customClass: 'btn-fbconnect',
				theme: KXL.Components.Button.Themes.alt8
			};
			if (this.options.useLargeSocialButtons) {
				this.$el.addClass('kx-showing-large-social-btns');
				fbconnectBtnOptions = _.extend(fbconnectBtnOptions, {
					theme: KXL.Components.Button.Themes.fbalt1,
					label: KXL.i18n.t('auth.signup.largeSocialFbBtnLabel')
				});
			}

			// Create fbconnect btn
			this.fields.fbconnectBtn = new KXL.Components.Button.View(fbconnectBtnOptions);
			this.fields.fbconnectBtn.on('click', function () {
				KXL.vent.trigger('info:fblogin:start', self.trackData);
				FB.login(
					function (rsp) {
						if (rsp.status === 'connected') {
							self.trigger('fb:api:authenticated', rsp.authResponse.accessToken, self.trackData);
						}
					},
					{ scope: 'email' }
				);
			});
			this.fbconnectBtnRegion.show(this.fields.fbconnectBtn);

			var googleBtnOptions = {
				customClass: 'kx-btn-google-login',
				theme: KXL.Components.Button.Themes.alt17
			};
			if (this.options.useLargeSocialButtons) {
				googleBtnOptions = _.extend(googleBtnOptions, {
					theme: KXL.Components.Button.Themes.gplusalt1,
					label: KXL.i18n.t('auth.signup.largeSocialGoogleBtnLabel')
				});
			}
			// Google auth
			this.fields.googleBtn = new KXL.Components.Button.View(googleBtnOptions);
			this.listenTo(this.fields.googleBtn, 'click', function () {
				KXL.vent.trigger('info:googlelogin:start', self.trackData);
				gapi.requestAccessToken();
			});
			this.googleBtnRegion.show(this.fields.googleBtn);

			if (this.options.steamLoginEnabled) {
				this.fields.steamBtn = new KXL.Components.Button.View({
					theme: KXL.Components.Button.Themes.alt26,
					customClass: 'kx-btn-steam-login'
				});
				this.listenTo(this.fields.steamBtn, 'click', function () {
					KXL.execute('steamlogin:start', self, self.trackData);
				});
				this.steamBtnRegion.show(this.fields.steamBtn);
			}

			// Login link
			if (!this.options.showLoginLink) {
				this.ui.loginLink.hide();
			}

			this.update();
			this.$el.find('input').placeholder(); // Placeholder fallback for IE9
		},
		onShow: function () {
			KXL.request('banner:hide', 'delayedRegBanner', true);
			this.signupInteraction();
		},
		serializeData: function () {
			return {
				options: this.options
			};
		}
	});
	SignupForm.InlineView = SignupForm.View.extend({
		template: '#signup-form-inline-template',
		className: 'kx-signup-form kx-inline-signup-form',
		initialize: function (options) {
			var
				self = this,
				defaults = {
					tooltipPos: 'right',
					emailTooltip: '',
					useToggledSections: false,
					useLargeSocialButtons: false,
					strings: {
						registerBtnLabel: KXL.i18n.t('auth.signup.regBtnLabel'),
						notPostOnWall: KXL.i18n.t('auth.signup.notPostOnWall'),
						facebookWillBeImported: KXL.i18n.t('auth.signup.facebookWillBeImported'),
						changeUsernameAvatarLater: KXL.i18n.t('auth.signup.changeUsernameAvatarLater'),
						registerUsingFacebook: KXL.i18n.t('auth.signup.registerUsingFacebook' + (KXL.switches.steamLoginEnabled ? '' : 'NoSteam')),
						regOr: KXL.i18n.t('auth.signup.regOr'),
						regFinePrint: KXL.i18n.t('auth.signup.regFinePrint' + (KXL.switches.steamLoginEnabled ? '' : 'NoSteam'))
					},
					registerBtnTheme: KXL.Components.Button.Themes.alt7,
					steamLoginEnabled: KXL.switches.steamLoginEnabled
				}
			;
			var opts = this.options = _.defaults({}, options, defaults);
			this.options.strings = _.defaults({}, options.strings, defaults.strings);

			if (opts.useToggledSections) {
				opts = _.defaults(
					opts,
					{
						socialSignupSelected: true,
						emailSignupSelected: false
					}
				);
				this.emailSignupSelected = opts.emailSignupSelected;
				this.socialSignupSelected = opts.socialSignupSelected;
			}

			this.fields = {};
			this.registration = opts.registration;
			this.listenTo(KXL.vent, 'page:shown', function () {
				self.interaction = false;
			});
			this.trackData =
				_.defaults(
					{},
					opts.data,
					{ trackControl: 'widget' }
				);
		},
		triggers: {
			'click .kx-login-link': 'login:link:clicked'
		},
		events: {
			'click .tos span': 'tosClicked',
			'keyup .kx-username-region .kx-textbox': 'signupInteraction',
			'keyup .kx-email-region .kx-textbox': 'signupInteraction',
			'keyup .kx-password-region .kx-textbox': 'signupInteraction',
			'click .kx-toggle-section-ctrls a' : 'toggleSignupMethodClicked'
		},
		tosClicked: function () {
			this.trigger('tos:clicked');
			this.signupInteraction();
		},
		onShow: function () {
			this.$el.find('input').placeholder(); // Placeholder fallback for IE9
		},
		serializeData: function () {
			return {
				options: this.options
			}
		}
	});
});

KXL.module('ProfileApp', function(ProfileApp, KXL, Backbone, Marionette, $, _) {
	ProfileApp.Router = Marionette.AppRouter.extend({
		appRoutes: {
			'profile/:userId(/:section)': 'showProfile'
		}
	});

	var API = {
		showProfile: function (userId, options) {
			// Show profile if the user is logged in, otherwise route home.
			if (KXL.currentUser) {
				KXL.execute('page:show', 'profile');
				ProfileApp.Profile.Controller.showProfile(userId, options);
				KXL.vent.trigger('profile:shown');
			} else {
				KXL.execute('home:show', true);
			}
		},
		getAddFriendView: function (userId, username) {
			return ProfileApp.AddFriend.Controller.getAddFriendView(userId, username);
		},
		getGameStatsView: function (userGames, user, defaultGameId, options) {
			return ProfileApp.GameStats.Controller.getGameStatsView(userGames, user, defaultGameId, options);
		},
		getUserInfoView: function (userId) {
			return ProfileApp.UserInfo.Controller.getUserInfoView(userId);
		},
		getFriendsView: function (userId, options) {
			return ProfileApp.Friends.Controller.getFriendsView(userId, options);
		},
		getActivityFeedView: function (userId) {
			return  ProfileApp.ActivityFeed.Controller.getActivityFeedView(userId);
		}
	};

	KXL.addInitializer(function () {
		new ProfileApp.Router({
			controller: API
		});
	});

	KXL.vent.on('auth:changed', function () {
		if (!KXL.request('get:current:user')) {
			KXL.profileRegion.close();
		}
	});

	KXL.commands.setHandlers({
		'profile:show': function (id, options) {
			options = options || {};
			KXL.navigate('/profile/' + id + (options.section ? '/' + options.section : ''));
			KXL.execute('page:show', 'profile');
			API.showProfile(id, options);
		}
	});

	KXL.reqres.setHandlers({
		'new:gamestats:view': function (userGames, user, defaultGameId, options) {
			return API.getGameStatsView(userGames, user, defaultGameId, options);
		},
		'new:addfriend:view': function (userId, username) {
			return API.getAddFriendView(userId, username);
		},
		'new:userinfo:view': function (userId) {
			return API.getUserInfoView(userId);
		},
		'new:friends:view': function (userId, options) {
			return API.getFriendsView(userId, options);
		},
		'new:activityfeed:view': function (userId) {
			return API.getActivityFeedView(userId);
		}

	});

});

KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {
	Entities.UserFriendRequest = Entities.Model.extend({
		initialize: function (options) {
			this.userId = options.userId;
		},
		url: function () {
			return KXL.config.API_URL + '/users/' + this.userId + '/friend-requests';
		}
	});

	Entities.UserFriendRequestsCollection = Entities.Collection.extend({
		options: null,
		initialize: function (models, options) {
			this.options = options;
			this.id = options.userId;
		},
		model: Entities.UserFriendRequest,
		url: function () {
			return KXL.config.API_URL + '/users/' + this.id + '/friend-requests';
		}
	});

	var API = {
		getUserFriendRequests: function (userId, options) {
			// Want the deferred to resolve with Entities.UserFriendRequestsCollection, not the Ajax response.
			var deferred = $.Deferred();

			var opts = _.defaults(
				options,
				{ sent: undefined, received: undefined }
			);

			var expand = [];
			if (opts.expandGames) {
				expand.push('games');
			}
			delete opts.expandGames;

			if (expand.length) {
				opts.expand = expand.join(',');
			}

			opts.userId = userId;

			var friendRequests = new Entities.UserFriendRequestsCollection([], { userId: userId });
			friendRequests.fetch({
				data: opts,
				success: function (collection) {
					deferred.resolve(collection);
				},
				error: function (error) {
					deferred.reject(error);
				}
			});

			deferred._collection = friendRequests;
			return deferred;
		},
		acceptUserFriendRequest: function (requestId, userId) {
			var deferred = $.Deferred();
			var friend = new Entities.Friend({ userId: userId });
			friend.save(null, {
				success: function (model) {
					KXL.request('increment:service:friends:count', KXL.currentUser.id);
					KXL.request('increment:service:friends:count', friend.get('userId'));
					KXL.request('current:user:friend:entities')._collection.add(model);
					deferred.resolve();
				},
				error: function () {
					deferred.reject();
				}
			});
			return deferred;
		},
		rejectUserFriendRequest: function (requestId) {
			var url = KXL.config.API_URL + '/friend-requests/' + requestId;
			return $.ajax({ type: "DELETE", url: url });
		},
		sendFriendRequest: function (targetId) {
			var url = KXL.config.API_URL + '/friend-requests';
			return $.ajax({
				type: 'POST',
				url: url,
				contentType: 'application/json',
				data: JSON.stringify([{userId: KXL.currentUser.id, targetId: targetId}])
			});
		}
	};

	KXL.reqres.setHandlers({
		'user:friend:request:entities': function (userId, options) {
			return API.getUserFriendRequests(userId, options);
		},
		'user:friend:request:accept': function (requestId, userId) {
			return API.acceptUserFriendRequest(requestId, userId);
		},
		'user:friend:request:reject': function (requestId) {
			return API.rejectUserFriendRequest(requestId);
		},
		'user:friend:request:send': function (targetId) {
			return API.sendFriendRequest(targetId);
		}
	});
});
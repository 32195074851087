KXL.module('Components.KixeyeShareButton', function (KixeyeShareButton, KXL, Backbone, Marionette, $, _) {
	KXL.reqres.setHandlers({
		'new:kixeye:share:button:view': function (options) {
			var defaults = {
				text: '',
				link: '',
				renableAfterSuccess: false,
				renableAfterSuccessPause: 5000
			};
			var opts = _.defaults({}, options, defaults);
			var btn = new KXL.Components.Button.View({
				customClass: 'kx-kixeye-share-btn',
				singleClick: true
			});
			btn.listenTo(btn, 'click', function () {
				btn.$el.removeClass('kx-success');
				KXL.request(
					'save:note',
					KXL.currentUser.id,
					KXL.currentUser.id,
					options.text + ' ' + opts.link
				).then(function () {
					btn.$el.removeClass('kx-disabled');
					btn.$el.addClass('kx-success');
					if (opts.renableAfterSuccess) {
						btn.enable();
						_.delay(
							function () {
								btn.$el.removeClass('kx-success');
							},
							opts.renableAfterSuccessPause
						);
					}
				}).fail(function () {
					btn.enable();
				});
			});
			return btn;
		}
	});
});

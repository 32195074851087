KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {
	Entities.FriendRequest = Entities.Model.extend({
		url: function () {
			return KXL.config.API_URL + '/friend-requests';
		}
	});

	Entities.FriendRequestsCollection = Entities.Collection.extend({
		model: Entities.FriendRequest,
		url: function () {
			return KXL.config.API_URL + '/friend-requests';
		}
	});

	var API = {
		getFriendRequests: function (options) {
			// Want the deferred to resolve with Entities.FriendRequestsCollection, not the Ajax response.
			var deferred = $.Deferred();

			var opts = _.extend({ sent: undefined, received: undefined }, options);
			var friendRequests = new Entities.FriendRequestsCollection([], opts);

			friendRequests.fetch({
				success: function (collection) {
					deferred.resolve(collection);
				},
				error: function (error) {
					deferred.reject(error);
				}
			});

			deferred._collection = friendRequests;
			return deferred;
		}
	};

	KXL.reqres.setHandler('friend:request:entities', function (options) {
		return API.getFriendRequests(options);
	});
});

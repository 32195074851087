KXL.module('Components.UserGameProfile', function (UserGameProfile, KXL, Backbone, Marionette, $, _) {
	UserGameProfile.GameProfile = Marionette.ItemView.extend({
		template: '#game-profile-template',
		className: 'kx-user-game-profile',
		modelEvents: {
			"change": "modelChanged"
		},
		serializeData: function () {
			var game = KXL.games.get(this.model.id);
			var renderForBP = false;
			var renderForBPAlliance = false;
			var renderForWC = false;
			var renderForVC = false;

			if(game) {
				switch(game.get('commonName'))
				{
					case 'bp':
						renderForBP = true;
						break;
					case 'wc':
						renderForWC = true;
						break;
					case 'vc':
						renderForVC = true;
						break;
				}
			}

			var alliance = this.model.get('alliance');
			if (alliance && alliance.name) {
				renderForBPAlliance = true;
			}

			var medals = this.model.get('medals');
			var infamy = this.model.get('infamy');

			return {
				medals: typeof medals !== 'undefined' ? medals : '-',
				infamy: typeof infamy !== 'undefined' ? infamy : '-',
				userGameProfile: this.model.toJSON(),
				renderForBP: renderForBP,
				renderForVC: renderForVC,
				renderForWC: renderForWC,
				renderForBPAlliance: renderForBPAlliance
			};
		},
		modelChanged: function() {
			this.render();
		}
	});
	UserGameProfile.GameProfileHeader = Marionette.ItemView.extend({
		template: '#game-profile-header-template',
		className: 'kx-ugp-user kx-user-game-profile',
		modelEvents: {
			"change": "modelChanged"
		},
		onRender: function () {
			KXL.request(
				'new:avatar:view',
				this.model.get('userId'),
				{
					size: 'large',
					color: KXL.Components.Avatar.borderColors.NONE,
					el: this.$el.find('.kx-ugp-user-avatar')
				}
			).render();
		},
		modelChanged: function() {
			this.render();
		}
	})
});

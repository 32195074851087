KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {
	Entities.UserSearchScopeCollection = Entities.Collection.extend({
		model: Entities.Model
	});

	Entities.UserSearch = Entities.Model.extend({
		parse: function (res, options) {
			var
				userSearch = _.extend({}, res),
				userGames = _.extend({}, userSearch.games),
				validCommonNames = ['vc', 'bp', 'wc'],
				gameSet = [],
				gameAttributes = {
					'wc' : [
						{
							id: 'playerId',
							label: 'ID'
						},
						{
							id: 'sector',
							label: 'SECTOR'
						},
						{
							id: 'level',
							label: 'LEVEL'
						},
						{
							id: 'totalXP',
							label: 'INFAMY'
						}
					],
					'vc' : [
						{
							id: 'playerId',
							label: 'ID'
						},
						{
							id: 'planet',
							label: 'PLANET'
						},
						{
							id: 'level',
							label: 'LEVEL'
						},
						{
							id: 'medals',
							label: 'MEDALS'
						}
					],
					'bp' : [
						{
							id: 'playerId',
							label: 'ID'
						},
						{
							id: 'sector',
							label: 'SECTOR'
						},
						{
							id: 'level',
							label: 'LEVEL'
						},
						{
							id: 'medals',
							label: 'MEDALS'
						}
					]
				}
			;
			//transform user
			userSearch.id = userSearch.id || (userSearch.user && userSearch.user.id);
			userSearch.username = userSearch.username || (userSearch.user && userSearch.user.username);
			delete userSearch.user;
			delete userSearch.userId;

			_.each(userSearch.games, function (game) {
				var staticGame = KXL.games.get(game.gameId);
				if (staticGame && game.alias && staticGame.get('commonName') &&
					validCommonNames.indexOf(staticGame.get('commonName')) !== -1) {

					var attributes = gameAttributes[staticGame.get('commonName')] || [];

					_.each(attributes, function (attribute) {
						attribute.value = game[attribute.id] || 'N/A';
					});

					attributes = new Backbone.Collection(attributes);

					gameSet.push({
						userId: userSearch.id,
						commonName: staticGame.get('commonName'),
						gameId: game.gameId,
						alias: game.alias,
						attributes: attributes
					});
				}
			});

			userSearch.games = gameSet;

			return userSearch;
		}
	});

	Entities.UserSearchCollection = Entities.Collection.extend({
		model: Entities.UserSearch,
		url: function () {
			var url = KXL.config.API_URL.concat('/users');
			if (this.options.scope === 'friends') {
				url = url.concat('/', this.options.userId, '/friends');
			}
			return url;
		},
		fetch: function (options) {
			options = options || {};
			options.data = _.extend(options.data || {}, {
				expand: 'games'
			});

			if (this.options.scope === 'kxl') {
				if (this.options.searchTerm && this.options.searchTerm.length)   {
					options.data.alias = this.options.searchTerm;
				}

				if (this.options.apps && this.options.apps.length) {
					options.data.gameId = this.options.apps.join();
				}
			}

			if (this.options.limit) {
				options.data.limit = this.options.limit;
			}

			if (this.options.offset) {
				options.data.offset = this.options.offset;
			}

			if (this.options.userScope) {
				options.data.userScope = this.options.userScope;
			}

			if (this.options.sector) {
				options.data.sector = this.options.sector;
			}

			if (this.options.planet) {
				options.data.planet = this.options.planet;
			}

			if (this.options.minLevel) {
				options.data.minLevel = this.options.minLevel;
			}

			if (this.options.maxLevel) {
				options.data.maxLevel = this.options.maxLevel;
			}

			if (this.options.ignoreUserIds && this.options.ignoreUserIds.length) {
				options.data.ignoreUserIds = this.options.ignoreUserIds.join(',');
			}

			return Entities.Collection.prototype.fetch.call(this, options);
		},
		initialize: function (models, options) {
			this.options = options || {};
		},
		parse: function (response, options) {

			function validateResult(searchResult) {
				var validGames = _.filter(searchResult.games, function (game) {
					return KXL.games.get(game.gameId) && !!game.alias;
				});
				if (searchResult.games && searchResult.games.length !== validGames.length) {
					searchResult.games = validGames;
				}
				return !!searchResult.username || (searchResult.games && searchResult.games.length > 0);
			}
			var userId = this.id;

			if (response.meta && response.meta.totalAvailable) {
				KXL.request(
					'set:service:friends:count',
					userId,
					response.meta.totalAvailable
				);
			}

			if (response.data) {

				var avatarCacheUpdates = {};

				_.each(response.data, function (friend) {
					var userId = friend.userId;
					if (userId && friend.user && friend.user.avatars) {
						avatarCacheUpdates[userId] = friend.user.avatars;
					}
				});

				if (!_.isEmpty(avatarCacheUpdates)) {
					// Send in batch to cache service for less event noise.
					KXL.request('set:services:avatars', avatarCacheUpdates);
				}

			}
			return (response.data) ? response.data : _.filter(response, function (searchResult) {
				return validateResult(searchResult);
			});
		}
	});

	Entities.UserSearchGame = Entities.Model.extend({});

	Entities.UserSearchGameCollection = Entities.Collection.extend({
		model: Entities.UserSearchGame
	});

	var API = {
		getSearchUsers: function (options) {
			var deferred = $.Deferred();
			var searchUsers = new Entities.UserSearchCollection([], options);
			var method = options.scope === 'friends' ? 'fetch' : 'search';
			searchUsers[method]({
				success: function (collection) {
					deferred.resolve(collection);
				},
				error: function () {
					deferred.reject();
				}
			});
			deferred._collection = searchUsers;
			return deferred;
		},
		getSearchUsersCollection: function () {
			return new Entities.UserSearchCollection();
		},
		getUserSearchGames: function (userSearchGames) {
			var	models = [];
			_.each(userSearchGames, function (game) {
				models.push(new Entities.UserSearchGame(game));
			});
			return new Entities.UserSearchGameCollection(models, { comparator: false });
		},
		getUserSearchScopes: function (scopes) {
			return new Entities.UserSearchScopeCollection(scopes);
		}
	};

	KXL.reqres.setHandlers({
		'usersearch:entities': function (options) {
			return API.getSearchUsers(options);
		},
		'usersearch:empty:entities': function (options) {
			return API.getSearchUsersCollection();
		},
		'usersearch:game:entities': function (userSearchGames) {
			return API.getUserSearchGames(userSearchGames);
		},
		'usersearch:scope:entities': function (scopes) {
			return API.getUserSearchScopes(scopes);
		}
	});
});

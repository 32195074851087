KXL.module('Facades', function(Facades, KXL, Backbone, Marionette, $, _) {

	function _post(url, data) {
		return $.ajax({
			type: 'POST',
			url: url,
			data: JSON.stringify(data),
			dataType: 'json',
			contentType: 'application/json'
		});
	}

	Facades.Kixeye = {

		logout: function () {
			return $.get('/api/auth/logout');
		},

		login: function (username, password) {
			// This creates the OAuth token for v2 API authentication
			return $.post(
				'/api/auth/login/kxl',
				{
					username: username,
					password: password
				},
				null,
				'json'
			);
		},

		fbLogin: function (accessToken) {
			var deferred = $.Deferred();
			KXL.request('fraud:fingerprint:promise:get').then(function (deviceFingerprint) {
				_post(
					'/api/auth/login/fb',
					{
						accessToken: accessToken,
						deviceFingerprint: deviceFingerprint
					}
				).done(function (rsp) {
						if (rsp && rsp.error) {
							var error;
							if (rsp.error === 401 && rsp.detail && rsp.detail.ban) {
								// 401 and user is banned
								KXL.execute('show:banned:dialog', rsp.detail.ban);
								deferred.reject(new KXL.Errors.GenericLoginError());
							} else if (rsp.error === 409) {
								error = new KXL.Errors.EmailConflictError(null, rsp.detail);
								// 401 and no device fingerprint
							} else if (rsp.error === 401 && rsp.detail && !deviceFingerprint) {
								error = new KXL.Errors.DeviceFingerprintError();
								// 401 and server fraud check told us account is inaccessible
							} else if (rsp.error === 401 &&
								rsp.detail &&
								rsp.detail.deviceFingerprintCheck === false) {
								error = new KXL.Errors.IOvationBlockedError();
							} else {
								error = new KXL.Errors.UnexpectedFacebookLoginError();
							}
							deferred.reject(error);
						} else {
							deferred.resolve(rsp);
						}
					}).fail(function (rsp) {
						deferred.reject(error || new KXL.Errors.UnexpectedFacebookLoginError());
					});
			});

			return deferred;

		},

		googleLogin: function (accessToken) {
			var deferred = $.Deferred();
			KXL.request('fraud:fingerprint:promise:get').then(function (deviceFingerprint) {
				_post(
					'/api/auth/login/google',
					{
						accessToken: accessToken,
						deviceFingerprint: deviceFingerprint
					}
				).done(function (rsp) {
						if (rsp && rsp.error) {
							if (rsp.error === 401 && rsp.detail && rsp.detail.ban) {
								// 401 and user is banned
								KXL.execute('show:banned:dialog', rsp.detail.ban);
								deferred.reject(new KXL.Errors.GenericLoginError());
							} else if (rsp.error === 401 && rsp.detail && !deviceFingerprint) {
								// 401 and no device fingerprint
								deferred.reject(new KXL.Errors.DeviceFingerprintError());
							} else if (rsp.error === 401 && rsp.detail && rsp.detail.deviceFingerprintCheck === false) {
								// 401 and server fraud check told us account is inaccessible
								deferred.reject(new KXL.Errors.IOvationBlockedError());
							} else {
								deferred.reject(new KXL.Errors.UnexpectedGoogleLoginError());
							}
						} else {
							deferred.resolve(rsp);
						}
					}).fail(function (rsp) {
						deferred.reject(new KXL.Errors.UnexpectedGoogleLoginError());
					});
			});
			return deferred;
		},

		forgotPasswordLogin: function (forgotPasswordToken) {
			return $.post(
				'/api/auth/login/forgot-password',
				{
					token: forgotPasswordToken
				}
			);
		},

		changeEmailLogin: function(changeEmailToken) {
			return $.post(
				'/api/auth/login/change-email',
				{
					token: changeEmailToken
				}
			);
		},

		emailVerificationLogin: function(emailVerificationToken) {
			var deferred = $.Deferred();
			$.post(
				'/api/auth/login/email-verification',
				{
					token: emailVerificationToken
				}
			).done(function (response) {
				if (response && response.user) {
					deferred.resolve(response.user);
				} else {
					deferred.reject(emailVerificationToken);
				}
			}).fail(function () {
				deferred.reject(emailVerificationToken);
			});
			return deferred;
		},

		validateEmail: function (email) {
			var deferred = $.Deferred();
			$.ajax({
				type: "GET",
				url: KXL.config.API_URL + '/emails/' + email,
				data: {
					email : email
				},
				dataType: 'json',
				contentType: 'application/json'
			}).done(function(response) {
				if (response.isValid) {
					if (response.isTaken) {
						deferred.resolve(email, false, new KXL.Errors.EmailInUseError());
					} else {
						deferred.resolve(email, true);
					}
				} else {
					deferred.resolve(email, false, new KXL.Errors.InvalidEmailError());
				}
			}).fail(function () {
				deferred.reject(email);
			});
			return deferred;
		},

		validateUsername: function (username, options) {
			var defaults = {
				minLength: 6,
				maxLength: 20
			};
			var deferred = $.Deferred();
			options = _.extend({}, defaults, options);
			$.ajax({
				type: 'GET',
				url: KXL.config.API_URL + '/usernames/' + encodeURIComponent(username),
				data: options
			}).done(function (response) {
				var checks = [
					'hasRestrictedCharacters',
					'hasRestrictedWords',
					'isGreaterThanMaxLength',
					'isLessThanMinLength',
					'isTaken'
				];
				var errors = [];
				_.each(checks, function (check) {
					if (response[check]) {
						errors.push(check);
					}
				});
				if (errors.length) {
					deferred.resolve(username, false, errors);
				} else {
					deferred.resolve(username, true);
				}
			}).fail(function () {
				deferred.reject(username);
			});
			return deferred;
		},

		facebookMerge: function (userId, fbAccessToken) {
			var deferred = $.Deferred();
			KXL.request('fraud:fingerprint:promise:get').then(function (deviceFingerprint) {
				_post(
					'/api/auth/facebook-merges',
					{
						accessToken: fbAccessToken,
						sourceUserId: userId,
						deviceFingerprint: deviceFingerprint
					}
				).done(function (rsp) {
					if (rsp && rsp.error) {
						var error;
						switch (rsp.error) {
							case 409:
								if (rsp.detail && rsp.detail.email) {
									error = new KXL.Errors.EmailConflictError(null, rsp.detail);
								} else {
									error = new KXL.Errors.FacebookMergeConflictError(null, rsp.detail);
								}
								break;
							default:
								error = new KXL.Errors.UnexpectedError();
						}
						deferred.reject(error);
					} else {
						deferred.resolve(rsp);
					}
				}).fail(function () {
					deferred.reject(new KXL.Errors.UnexpectedError());
				});
			});

			return deferred;
		},

		updatePassword: function (userId, password) {
			return $.ajax({
				url: "/api/auth/changePassword",
				type: "POST",
				data:JSON.stringify({
					password: password
				}),
				contentType: 'application/json'
			});
		},

		deviceFingerprints: function (userId, deviceFingerprint, originEvent) {
			return $.ajax({
				url: "/api/v2/device-fingerprints",
				type: "POST",
				data: JSON.stringify({
					userId: userId,
					deviceFingerprint: deviceFingerprint,
					originEvent: originEvent
				}),
				contentType: 'application/json'
			});
		},
		isCurrentUserMonetized: function () {
			return $.ajax({
				url: "/payment/ismonetized",
				type: "GET",
				contentType: 'application/json'
			});
		},
		getProcessorSessionByPaypalTransactionId: function (userId, transactionId) {
			return $.ajax({
				url: '/api/v2/users/' + userId + '/processor-sessions?transactionId=' +transactionId,
				method: 'GET'
			});
		},
		getPaymentOptions: function (paymentSessionId) {
			return $.ajax({
				url: "/api/v2/payment-sessions/" + paymentSessionId + "/payment-options",
				type: "GET",
				contentType: 'application/json',
				dataType: 'json'
			});
		},
		/**
		 * Create a new processor session.
		 *
		 * @param  {String}  paymentSessionId    Id of the payment session
		 * @param  {String}  paymentOptionId     Id of the payment option
		 * @param  {String}  paymentProvider     The payment provider i.e. 'paypalhub'
		 * @param  {String}  paymentType         The type of payment e.g. 'braintree'
		 * @param  {Boolean} saveToken           Whether to save a payment token for this payment
		 * @param  {Object}  paymentDetails      Object with case-specific details about the payment.
		 *                                       For credit cards this includes encryped card form data.
		 * @return {Promise}                     The request promise
		 */
		postProcessorSession: function (paymentSessionId, paymentOptionId, paymentProvider, paymentType, saveToken, paymentDetails) {
			return _post('/api/v2/processor-sessions', {
				paymentSessionId: paymentSessionId,
				paymentOptionId: paymentOptionId,
				paymentProvider: paymentProvider,
				paymentType: paymentType,
				saveToken: saveToken,
				paymentDetails: paymentDetails
			});
		},
		paymentLocalization: function (locale) {
			return $.ajax({
				url: "/payment/localization?locale=" + locale,
				type: "GET",
				contentType: 'application/json'
			});
		},
		verifyEmail: function () {
			return _post(KXL.config.API_URL + '/email-verifications');
		},
		sendDeviceVerificationCode: function (verifiedDeviceId) {
			return _post(KXL.config.API_URL + '/user-verified-devices/' + verifiedDeviceId + '/verifications', {
				null: null  // Doesn't work without passing some data
			});
		},
		checkDeviceVerificationCode: function (verifiedDeviceId, verificationCode) {
			return $.ajax({
				url: KXL.config.API_URL + '/user-verified-devices/' + verifiedDeviceId + '/verifications?verificationCode=' + verificationCode,
				type: "GET"
			});
		}
	};
});

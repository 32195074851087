KXL.module('Language', function(Language, KXL, Backbone, Marionette, $, _) {
	 Language.i18n = Marionette.Controller.extend({

		initialize: function (options) {
			options = options || {};
			this.polyglot = new Polyglot({ locale: options.locale.substring(0, 2) });
			this.polyglot.extend(options.localizations);
		},

		addLocales: function (locales) {
			this.polyglot.extend(locales || {});
		},

		t: function (key, interpolationOptions) {
			return this.polyglot.t(key, interpolationOptions);
		}
	});
});
KXL.module('ProfileApp.UserInfo', function (UserInfo, KXL, Backbone, Marionette, $, _) {
	UserInfo.Controller = {
		getUserInfoView: function (userId) {
			var user;
			if (KXL.currentUser && userId === KXL.currentUser.id) {
				user = KXL.currentUser;
			}else {
				user = KXL.request('user:entity', userId)._model;
			}

			var userInfoView = new UserInfo.View({
				user: user
			});

			return userInfoView;
		}
	};
});

/* globals KXL */
KXL.module('Entities.Inbox', function (Inbox, KXL, Backbone, Marionette, $, _) {
	"use strict";

	Inbox.Thread = KXL.Entities.Model.extend({
		urlRoot: function () {
			return KXL.config.API_URL + '/users/' + KXL.request('get:current:user').id + '/threads';
		},
		getMessages: function () {
			var messages = new Inbox.Messages();
			messages.fetch({
				data: {
					threadId: this.id,
					userId: KXL.request('get:current:user').id
				}
			});
			this.set({'messages': messages}, { suppressSort: true });
		},
		getMoreMessages:  _.throttle(function (offset, limit) {
			var self = this;
			KXL.vent.trigger('inbox:more:messages:fetching');
			var messagesPromise = API.getMoreMessages(this.id, offset, limit);
			messagesPromise.then(function(messages) {
				if(messages.length === 0) {
					self.set('moreMessages', false);
				}
				// Adding historical messages - Rip through fetched collection backwards adding each model to beginning of messages collection
				_.each(messages.last(messages.length).reverse(), function(model) {
					self.get('messages').add(model, {at: 0});
				});
				KXL.vent.trigger('inbox:more:messages:fetched');
			});
		}, 500, { trailing: false })
	});
	Inbox.Threads = KXL.Entities.Collection.extend({
		model: Inbox.Thread,
		url: function () {
			return KXL.config.API_URL + '/users/' + KXL.request('get:current:user').id + '/threads';
		}
	});

	Inbox.Message = KXL.Entities.Model.extend({
		url: function () {
			var url = KXL.config.API_URL + '/messages';
			if (this.id) {
				url += '/' + this.id;
			}
			return url;
		}
	});
	Inbox.Messages = KXL.Entities.Collection.extend({
		model: Inbox.Message,
		url: function () {
			return KXL.config.API_URL + '/messages';
		}
	});

	var API = {
		getThreads: function (options) {
			var deferred = $.Deferred();
			var threads = new Inbox.Threads();

			var fetchParams = {
				success: function (collection, response, options) {
					deferred.resolve(collection);
				},
				error: function (collection, response, options) {
					deferred.reject();
				}
			};

			if (options && options.data) {
				fetchParams.data = options.data;
			}

			threads.fetch(fetchParams);

			return deferred;
		},
		getMessages: function (threadId) {
			var deferred = $.Deferred();
			var messages = new Inbox.Messages();
			messages.fetch({
				data: {
					threadId: threadId,
					userId: KXL.appModel.get('currentUser').get('id')
				},
				success: function (data) {
					deferred.resolve(data);
				},
				error: function () {
					deferred.reject();
				}
			});
			deferred._collection = messages;
			return deferred;
		},
		getMoreMessages: function (threadId, offset, limit) {
			var deferred = $.Deferred();
			var messages = new Inbox.Messages();
			messages.fetch({
				data: {
					threadId: threadId,
					userId: KXL.appModel.get('currentUser').get('id'),
					offset: offset,
					limit: limit
				},
				success: function (data) {
					deferred.resolve(data);
				},
				error: function () {
					deferred.reject();
				}
			});
			deferred._collection = messages;
			return deferred;
		},
		sendMessage: function (message, options) {

			var threadId;
			var recipientList;

			if (options.threadId) {
				threadId = options.threadId;
			} else if (options.recipients) {
				recipientList = [];
				if (_.isArray(options.recipients)) {
					_.each(options.recipients, function (recipient, i) {
						recipientList.push(API.addSuffix(recipient));
					});
				} else {
					recipientList.push(API.addSuffix(options.recipients));
				}
			} else {
				throw "Trying to send a message without a destination (recipients or thread)";
			}

			var newMessage = new Inbox.Message({
				from: API.addSuffix(KXL.appModel.get('currentUser').get('id')),
				to: recipientList,
				body: message,
				threadId: threadId
			});

			var deferred = $.Deferred();

			newMessage.save(null, {
				success: function (data) {
					deferred.resolve(data);
				},
				error: function () {
					deferred.reject();
				}
			});

			deferred._model = newMessage;

			return deferred;
		},
		subscribe: function (listener) {

			var wrappedListener = function (message) {
				var newMessage = new Inbox.Message(message);

				listener(newMessage);
			};

			var websocket = KXL.module('Websocket');

			websocket.on('messages', wrappedListener);
		},
		stripSuffix: function (address) {
			return address.split('@')[0];
		},
		addSuffix: function (userId) {
			return userId + '@' + KXL.config.USER_MESSAGE_SUFFIX;
		}
	};

	KXL.reqres.setHandlers({
		'inbox:get:threads': API.getThreads,
		'inbox:get:messages': API.getMessages,
		'inbox:send:message': API.sendMessage,
		'inbox:add:suffix': API.addSuffix,
		'inbox:strip:suffix': API.stripSuffix
	});

	KXL.commands.setHandlers({
		'inbox:subscribe': API.subscribe
	});
});
KXL.module('Components.EditOptions', function (EditOptions, KXL, Backbone, Marionette, $, _) {

	// Create model to hold the options items
	var EditOptionsItemModel = KXL.Entities.Model.extend({
		defaults: {
			label: '',
			value: '',
			active: false
		}
	});

	// Edit options collection
	var EditOptionsItemCollection = KXL.Entities.Collection.extend({
		model: EditOptionsItemModel
	});

	// Controller for edit options
	EditOptions.Controller = {
		editOptionsView: null,
		getEditOptions: function (options) {
			var self = this;

			if (options && _.isArray(options.optionItems)) {
				options.optionItems = new EditOptionsItemCollection(options.optionItems);
			} else {
				return;
			}

			this.editOptionsView = new EditOptions.CompositeView(options);

			// On item clicked.
			this.editOptionsView.listenTo(this.editOptionsView, 'option:item:clicked', function (item) {
				if (options.model) {
					// Update associated model.
					options.model.set(options.modelAttr, item.model.get(options.valueAttr || 'value'));
				}

				// Update label
				this.setLabel(item.model.get(options.labelAttr || 'label'));

				// If the option to save the model is set, save it.
				if (options.modelSaveOnChange) {
					options.model.save();
				}

				// If there is a item event passed in, trigger it.
				if (item.model.get('itemEvent') || options.itemEvent) {
					if (options.closeOnItemEvent) {
						self.editOptionsView.ui.editOptions.removeClass('kx-edit-options-active');
					}
					this.trigger(options.itemEvent || item.model.get('itemEvent'), item.model);
				}
			});
			if (options.model) {
				options.model.bind("change:" + options.modelAttr, function() {
					// iterate over all of the options views and remove active class.
					// if the item model view matches the associated model's value change the class to active.
					self.editOptionsView.children.each(function(view){
						view.$el.removeClass('kx-active');
						if (view.model.get(options.valueAttr || 'value') === options.model.get(options.modelAttr)) {
							view.$el.addClass('kx-active');
							// Update label
							self.editOptionsView.setLabel(view.model.get(options.labelAttr || 'label'));
						}
					});
				});
			}

			return this.editOptionsView;
		}
	};
});
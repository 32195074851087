/*---------------- TextBox Widget ------------------
Usage:
Step 1 - Create a region in your view

	regions: {
		textBoxRegion: '.kx-textbox-region'
	},

Step 2 - In the onRender function of the view write this code

	var textBoxView = new KXL.Components.TextBox.View({
		name: 'username',
		value: ''
	});

	this.textBoxRegion.show(textBoxView);

Step 3 - To handle change event
	textBoxView.on('change', function(view){
		//..Some code
	});

## To show the error
	textBoxView.showError("Username can't be empty!");
## To hide the error
	textBoxView.showNormal();
--------------------------------------------------------*/
KXL.module('Components.TextBox', function (TextBox, KXL, Backbone, Marionette, $, _) {

	// References to keyDown comprise a workaround for the browser issue whereby the
	// omnibox (url bar) triggers a false keyup event. We use the keyDown variable to
	// know whether the keyup event was generated by the page (and not the omnibox).
	var keyDown = false;
	function onDocumentKeyDown () {
		keyDown = true;
	}
	document.addEventListener('keydown', onDocumentKeyDown, true);

	TextBox.View = KXL.Components.Layout.extend({
		template: '#textbox-template',
		className: 'kx-textbox-wrapper',
		tagName: 'span',
		originalValue: '',
		initialize: function(options) {
			var defaults = {
				// TextBox Name
				name: '',
				// TextBox value
				value: '',
				// TextBox validation message
				text: '',
				// Tooltip position
				tooltipPos: KXL.Components.Tooltip.Positions.right, // right or left
				// Placehoder
				placeHolder: '',
				// User defined class name
				customClass: '',
				// Show Success
				showSuccess: true,
				// Model attribute name
				modelAttr: '',
				// Input type
				type: 'text'
			};
			this.options = _.defaults(options || {}, defaults);
			this.originalValue =
				(options.model && options.modelAttr) ? options.model.get(options.modelAttr) : '';
		},
		ui: {
			textBox: '.kx-textbox',
			errorValidation: '.kx-validation'
		},
		regions: {
			tooltipRegion: '.kx-tooltip-region'
		},
		modelEvents: {
			'validated': 'onValidated'
		},
		events: {
			'keyup input': 'keyupHandler',
			'blur input': 'blurHandler',
			'focus input': 'focusHandler'
		},
		serializeData: function () {
			return {
				options: this.options
			};
		},
		onValidated: function (isValid, model, errors) {
			if (model.isValid(this.options.modelAttr)) {
				if (this.originalValue !== this.model.get(this.options.modelAttr) &&
					this.options.showSuccess)
				{
					this.showSuccess();
				}
			} else if (errors && errors[this.options.modelAttr]) {
				this.showError(errors[this.options.modelAttr]);
			}
		},
		onRender: function () {
			var self = this;
			//Set the custom class of this el
			if (this.options.customClass) {
				this.$el.addClass(this.options.customClass);
			}

			//Set the value of text box if model is present
			if (this.model && this.options.modelAttr) {
				this.listenTo(this.model, 'invalid:' + this.options.modelAttr, function (error) {
					self.showError(error);
				});
				this.listenTo(this.model, 'valid:' + this.options.modelAttr, function () {
					if (self.options.showSuccess) {
						self.showSuccess();
					}
				});
				this.listenTo(this.model, 'change:' + this.options.modelAttr, function () {
					var textBoxValue = this.model.get(this.options.modelAttr);
					if (this.ui.textBox.val() !== textBoxValue) {
						this.ui.textBox.val(textBoxValue);
					}
				});
				if (!this.options.value) {
					this.ui.textBox.val(this.model.get(this.options.modelAttr));
				}
			}

			//Set user defined class name if set in options
			if (this.options.className) {
				this.ui.textBox.addClass(this.options.className);
			}
		},
		blurHandler: function (e) {
			this.trigger('blur', e);
		},
		focusHandler: function (e) {
			this.trigger('focus', e);
		},
		// Change event handler
		keyupHandler: function (e) {
			if (!keyDown) {
				return;
			}

			keyDown = false;
			if (_.isFunction(this.options.keyupHandler)) {
				this.options.keyupHandler.call(this, e);
			}
			if (e.keyCode === 13) { // Enter key press
				this.trigger('submit');
				return;
			}
			// Set the value of model attribute
			if (this.model && this.options.modelAttr) {
				this.model.set(this.options.modelAttr, this.val());
			}
			this.trigger('change');
		},
		val: function (value) {
			if (typeof value === 'undefined') {
				return this.ui.textBox.val();
			} else {
				this.ui.textBox.val(value);
				return this.ui.textBox;
			}
		},
		_showTooltip: function (text, isError, options) {
			var opts = _.defaults(
				{},
				options,
				{
					tooltipPos: this.options.tooltipPos
				}
			);
			var tooltip = new KXL.Components.Tooltip.View({
				$elParent: this.$el,
				position: opts.tooltipPos,
				text: text
			});
			tooltip.listenTo(tooltip, 'show', function () {
				if (isError) {
					tooltip.showError(text, opts);
				} else {
					tooltip.showText(text, opts);
				}
			});
			this.tooltipRegion.show(tooltip);
			this.tooltipRegion.ensureEl();
			this.tooltipRegion.$el.show();
		},
		_hideTooltip: function () {
			this.tooltipRegion.ensureEl();
			this.tooltipRegion.$el.hide();
		},
		showError: function (text, options) {
			this._showTooltip(text, true, options);
			this.ui.errorValidation.removeClass('success');
			this.ui.errorValidation.addClass('error');
		},
		showNormal: function () {
			this._hideTooltip();
			this.ui.errorValidation.removeClass('success');
			this.ui.errorValidation.removeClass('error');
		},
		showTooltip: function (text, options) {
			this._showTooltip(text, false, options);
		},
		showSuccess: function () {
			this._hideTooltip();
			this.ui.errorValidation.removeClass('error');
			this.ui.errorValidation.addClass('success');
		},
		focus: function () {
			var self = this;
			setTimeout(function () {
				self.$el.find('.kx-textbox').focus();
			}, 0);
		},
		onClose: function () {
			document.removeEventListener('keydown', onDocumentKeyDown);
		}
	});
});
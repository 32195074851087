/* globals KXL */
KXL.module('AuthApp', function (AuthApp, KXL, Backbone, Marionette, $, _) {
	var preventDoubleEmailVerification;
	AuthApp.Router = Marionette.AppRouter.extend({
		appRoutes: {
			'login(/)': 'showLoginDialog',
			'logout(/)': 'logout',
			'register(/)(:options)': 'parseRegisterOptions',
			'tos': 'showTOS',
			'gamealerts': 'showGA',
			'help/tos': 'showTOS',
			'privacypolicy': 'showPP',
			'help/privacypolicy': 'showPP',
			'cookiepolicy': 'showCP',
			'help/cookiepolicy': 'showCP',
			'forgot-password-reset/:token': 'showForgotPasswordResetDialog',
			'confirm-email-change/:token': 'showEmailChangeConfirmationDialog',
			'email-verification/:token': 'handleEmailVerification'
		}
	});

	AuthApp.RegisterSteps = {
		SIGNUP_FORM: 'display sign up form',
		IMPORT_FB_GAMES_PROMPT: 'display import fb games prompt',
		FB_SYNC: 'do fb sync',
		CONFIRM_FB_GAMES: 'display confirm fb games',
		CAPTCHA: 'display captcha step',
		CREATE_ACCOUNT: 'creating the account',
		SUCCESS: 'display success step',
		LOGIN_FORM: 'display login form',
		FB_MERGE_CONFLICT_PROMPT: 'facebook account already used',
		FACEBOOK_REGISTER: 'perform facebook registration',
		COMPLETE_FACEBOOK_REGISTER: 'complete a facebook register',
		VERIFY_EMAIL: 'verifying email'
	};

	AuthApp.STEAM_LOGIN_URL = '/auth/steam?login';
	AuthApp.STEAM_CREDS_CHECK_URL = '/auth/steam/credentials';

	function setCurrentUser(user) {
		KXL.currentUser = KXL.request('new:user:entity', user);

		var userGameDefer = KXL.request('usergame:entities', KXL.currentUser.id);
		userGameDefer.then(function () {
			// this null check is temporary for the shadowforge page
			if (KXL.currentUserGames &&
				KXL.currentUserGames.length &&
				!KXL.appModel.get('context') &&
				KXL.appModel.get('context') !== null) {
				KXL.execute('context:set', KXL.games.get(KXL.currentUserGames.first().id));
			}
		});
		KXL.currentUserGames =  userGameDefer._collection;
		KXL.appModel.set('currentUser', KXL.currentUser);
		KXL.vent.trigger('app:reset');
		KXL.vent.trigger('auth:changed');
		return KXL.currentUser;
	}

	function triggerRegVent(ventName) {
		var data;

		if (KXL.currentUser) {
			data = {
				kxid: KXL.currentUser.id,
				username: KXL.currentUser.get('username')
			}
		}

		KXL.vent.trigger(ventName, data);
	}

	function handleLoginResponse(response, deferred) {
		if (response && response.user) {
			setCurrentUser(response.user);
			if (_.has(response), 'hasAvatar') {
				deferred.resolve(KXL.currentUser, response.isNew, response.hasAvatar);
			} else {
				deferred.resolve(KXL.currentUser, response.isNew);
			}

			// Check and see if the user has a locale set, and if different than the set locale, change it and reload the page
			// in the users set locale
			var settings = KXL.currentUser.get('settings');
			if (settings && settings.locale && settings.locale !== KXL.config.locale) {
				// Set locale
				KXL.execute('set:locale', settings.locale);
			}
		} else {
			deferred.reject(response);
		}
	}

	function showTomeSuccessStep() {
		// hide any other banners
		KXL.request('banner:hide');
		// remove any previous reg form banners...
		KXL.request('banner:remove', 'delayedRegFormBanner');
		KXL.request('banner:create', {
			bannerId: 'delayedRegFormBanner',
			view: new KXL.HeaderApp.Header.SimpleRibbon({ message: KXL.i18n.t('auth.success') }),
			className: ((KXL.currentUser && KXL.currentUser.get('acTitleId')) || '') + ' kx-success-state'
		});
		_.delay(function () {
			KXL.vent.trigger('completed:deferred:registration');
			KXL.request('banner:hide', 'delayedRegFormBanner', true);
		}, 6000);
	}

	// Create an email verification token
	function createEmailVerificationToken (email) {
		return $.ajax({
			url: KXL.config.API_URL + '/email-changes',

			data: JSON.stringify({
				newEmail: email
			}),
			type: 'POST',
			contentType: 'application/json'
		});
	}

	var kixeyeAPI = KXL.Facades.Kixeye;
	var facebookAPI = KXL.Facades.Facebook;

	var API = Marionette.Controller.extend({
		showTOS: function (options) {

			// KXL.asyncRequest('async:load:module', 'auth/terms').then(function () {
			// 	var view = KXL.AuthApp.Terms.Controller.getView(options);
			// 	KXL.request('show:default:dialog', view);
			// });
			
			window.open(KXL.config.LEGAL_URL);

		},
		showPP: function () {
			KXL.execute('show:TOS', { legal: 'showPrivacyPolicy' });
		},
		showCP: function () {
			KXL.execute('show:TOS', { legal: 'showCookiePolicy' });
		},
		showGA: function() {
			KXL.execute('show:TOS', { legal: 'showGameAlerts' });
		},
		showLoginDialog: function (options) {
			if (KXL.currentUser) {
				return;
			}
			options = _.extend(options || {}, { step: AuthApp.RegisterSteps.LOGIN_FORM });
			this.showRegisterDialog(options);
		},
		logout: function (options) {
			var defaults = {
				suppressFbLogout: false,
				// Reload the site on logout.
				reload: true
			};
			options = _.defaults({}, options, defaults);

			if (!options.suppressFbLogout) {
				facebookAPI.logout();
			}
			return kixeyeAPI.logout().done(function () {
				if (options.reload) {
					location.href = '/';
				} else {
					KXL.currentUser = null;
					KXL.appModel.set(
						'userSettings',
						KXL.request('new:user:settings:entity', {})
					);
					KXL.appModel.set('currentUser', null);
					KXL.vent.trigger('app:reset');
					KXL.vent.trigger('auth:changed');
				}
			});
		},
		autoRegister: function (gameUrlName) {
			var userPromise = KXL.request('auto:register:submit', gameUrlName);
			return userPromise.done(function (user) {
				KXL.execute('auth:register:deferred:start');
				var game = KXL.webGames.findWhere({ urlName: gameUrlName });
				var commonName = game && game.get('commonName');
				if (commonName && _.has(KXL.config.autoRegCommonNames, commonName)) {
					KXL.execute('game:play:load', gameUrlName);
				}
			});
		},
		doFbLogin: function (accessToken, data) {
			var deferred = $.Deferred();

			var fbLoginInfo = _.extend({}, data);
			kixeyeAPI.fbLogin(accessToken).done(function (response) {
				fbLoginInfo.response = response;
				KXL.vent.trigger('info:fblogin:success', fbLoginInfo);
				handleLoginResponse(response, deferred);
			}).fail(function (error) {
					fbLoginInfo.error = error;
					KXL.vent.trigger('info:fblogin:error', fbLoginInfo);
					deferred.reject(error);
			});

			return deferred;
		},
		doGoogleLogin: function (accessToken, data) {
			var deferred = $.Deferred();

			var googleLoginInfo = _.extend({}, data);
			kixeyeAPI.googleLogin(accessToken).done(function (response) {
				googleLoginInfo.response = response;
				KXL.vent.trigger('info:googlelogin:success', googleLoginInfo);
				handleLoginResponse(response, deferred);
			}).fail(function (response) {
				googleLoginInfo.error = response;
				KXL.vent.trigger('info:googlelogin:error', googleLoginInfo);
				deferred.reject(response);
			});

			return deferred;
		},
		/**
		 * Login a steam user.
		 *
		 * @param  {Object} data      Object containing isNew, hasAvatar and user properties
		 * @param  {Object} trackData Object with trackControl and trackSource properties
		 * @return {Promise}          A promise that resolves the current user model, isNew and hasAvatar flags;
		 *                            Or rejects with a string error.
		 */
		doSteamLogin: function (data, trackData) {
			var deferred = $.Deferred();

			if (data && data.user) {
				var steamLoginInfo = _.extend({ response: data }, data, trackData);
				KXL.vent.trigger('info:steamlogin:success', steamLoginInfo);
				handleLoginResponse(steamLoginInfo, deferred);
			} else if (data && data.error === 'BANNED') {
				authAPI.showBannedDialog(data.data);
				deferred.reject(data);
			} else {
				deferred.reject(data);
			}

			return deferred;
		},
		doLoginCall: function (username, password) {
			var deferred = $.Deferred();

			kixeyeAPI.login(username, password).done(
				function (rsp) {
					if (rsp && rsp.error) {
						var error;
						if (rsp.error === 429) {
							error = new KXL.Errors.TooManyLoginAttemptsError();
						} else if (rsp.error === 401 && rsp.detail && rsp.detail.ban) {
							// 401 and user is banned
							authAPI.showBannedDialog(rsp.detail.ban);
							deferred.reject();
						} else if (rsp.error === 'invalid_request' || _.contains([404, 401], rsp.error)) {
							// 401 and no device fingerprint
							if (rsp.detail && !rsp.detail.deviceFingerprint) {
								error = new KXL.Errors.DeviceFingerprintError();
							// 401 and server fraud check told us account is inaccessible
							} else if (rsp.detail && rsp.detail.deviceFingerprintCheck === false) {
								error = new KXL.Errors.IOvationBlockedError();
							} else {
								error = new KXL.Errors.InvalidCredentialsError();
							}

						} else {
							error = new KXL.Errors.GenericLoginError();
						}
						deferred.reject(error);
					} else if (rsp && rsp.user) {
						deferred.resolve(rsp);
					} else {
						deferred.reject(new KXL.Errors.GenericLoginError());
					}
				}
			).fail(function () {
				deferred.reject(new KXL.Errors.GenericLoginError());
			});

			return deferred.promise();
		},
		checkCredentials: function (username, password) {
			var deferred = $.Deferred();
			this.doLoginCall(username, password).done(function (response) {
				deferred.resolve(response.user);
			}).fail(function (error) {
				deferred.reject(error);
			});
			return deferred.promise();
		},
		doLogin: function (username, password) {
			var deferred = $.Deferred();
			this.doLoginCall(username, password).done(function (response) {
				KXL.vent.trigger('info:login:success');
				handleLoginResponse(response, deferred);
			}).fail(function (error) {
				if (error) {
					KXL.vent.trigger('info:login:error', { error: error });
					deferred.reject(error);
				}
			});
			return deferred.promise();
		},
		showForgotPasswordDialog: function (options) {

			var self = this;

			KXL.asyncRequest('async:load:module', 'auth/forgot_password').then(function () {
				self.forgotPasswordModel = new KXL.AuthApp.ForgotPassword.ForgotPasswordModel();
				var view = KXL.AuthApp.ForgotPassword.Controller.getView({
					model: self.forgotPasswordModel
				});
				KXL.request('show:default:dialog', view);
			});

		},
		sendForgotPassword: function () {
			var saveResultPromise = this.forgotPasswordModel.save();
			return saveResultPromise;
		},
		showForgotPasswordConfirmationDialog: function () {

			KXL.asyncRequest('async:load:module', 'auth/forgot_password').then(function () {
				var view = KXL.AuthApp.ForgotPassword.Controller.getConfirmationView();
				KXL.request('show:default:dialog', view);
			});

		},
		showBannedDialog: function(data) {
			KXL.asyncRequest('async:load:module', 'auth/banned_dialog').then(function () {
				KXL.AuthApp.BannedDialog.Controller.show(data);
			});
		},
		showEmailChangeConfirmationDialog: function (token) {
			kixeyeAPI.changeEmailLogin(token).done(function (response) {
				if (response && response.user) {
					setCurrentUser(response.user);
					KXL.execute('settings:email:change:success');
				} else {
					KXL.execute('settings:show');
				}
			});
		},
		handleEmailVerification: function (token) {
			var self = this;
			if (preventDoubleEmailVerification) {
				return;
			}
			preventDoubleEmailVerification = true;
			this.verifyEmail(token).done(function (user) {
				// If the user doesn't currently have another tab/window open, and they were verifying for a specific
				// game, launch said game...
				if (KXL.currentUser.get('presence') == 'unavailable' &&
					user.get('verificationRequired') &&
					user.get('acTitleId'))
				{
					var game = KXL.webGames.where({ titleId: user.get('acTitleId') })[0];
					KXL.execute('game:play:load', game.get('urlName'));
					self.showDelayedRegisterFormBanner(
						{
							step: AuthApp.RegisterSteps.SUCCESS,
							suppressSuccessStep: false
						}
					);
					KXL.GamesApp.Game.Controller.xFrameMessager.on('xfm_ready', function () {
						triggerRegVent('registration:complete:message');
						KXL.vent.trigger('info:register:success', _.extend({
							wasDeferred: true,
							trackSource: 'email',
							trackControl: 'ribbon'
						}, KXL.currentUser.toJSON()));
					});
				} else {
					KXL.execute('settings:email:verify:success');
				}
			}).fail(function () {
				KXL.execute('settings:show');
			});
		},
		verifyEmail: function (token) {
			var deferred = $.Deferred();
			kixeyeAPI.emailVerificationLogin(token).done(function(user){
				deferred.resolve(setCurrentUser(user));
			}).fail(deferred.reject);
			return deferred;
		},
		isTempUser: function () {
			return KXL.currentUser && KXL.currentUser.get('status') === 'temp';
		},
		// This user is a temp user but has entered a username/password/email and we are waiting for them to verify
		isUnverifiedUser: function () {
			return this.isTempUser() && KXL.currentUser.get('hasPassword');
		},
		hasGroupSuperAdmin: function (roles) {
			return roles.where({'roleId':"group-administrators"}).length > 0;
		},
		showForgotPasswordResetDialog: function (token) {
			if (KXL.currentUser) {
				return;
			}
			kixeyeAPI.forgotPasswordLogin(token).done(
				function (response) {
					if (response && response.user) {
						setCurrentUser(response.user);
						var userModel = new KXL.Entities.User({userId: KXL.currentUser.id});

						KXL.asyncRequest('async:load:module', 'auth/forgot_password').then(function () {
							var view = KXL.AuthApp.ForgotPassword.Controller.getResetPasswordView({
								model: userModel
							});
							KXL.request('show:default:dialog', view);
						});

					}

				});
		},
		showPasswordResetCompleteDialog: function () {

			KXL.asyncRequest('async:load:module', 'auth/forgot_password').then(function () {
				var view = KXL.AuthApp.ForgotPassword.Controller.getResetCompleteView();
				KXL.request('show:default:dialog', view);
			});

		},
		showFbSyncDialog: function (fbAccessToken) {
			KXL.asyncRequest('async:load:module', 'auth/register/import_fb_games_prompt').then(function () {

				var wizard = new KXL.AuthApp.Register.Wizard.View({
					headerText: KXL.i18n.t('auth.fbgames.dialog.heading')
				});
				KXL.request('show:default:dialog', wizard);
				var userGames = KXL.request('current:user:game:entities');

				function genericFailHandler() {
					wizard.showError(KXL.i18n.t('auth.registrationFailed'));
					stopLoadingState();
				}

				function stopLoadingState() {
					wizard.toggleLoadingState(false);
				}

				function startLoadingState() {
					wizard.toggleLoadingState(true);
				}

				function showConfirmFbGamesStep() {
					KXL.asyncRequest('async:load:module', 'auth/register/confirm_games').then(function () {

						var confirmGamesView = new KXL.AuthApp.Register.ConfirmGames.Controller.getView({
							collection: userGames,
							userId: KXL.currentUser.id
						});

						wizard.showStep(confirmGamesView);

						confirmGamesView.on('submit', function () {
							KXL.dialogRegion.closeDialog();
						});

					});
				}

				function showMergeConflict(user) {

					var fbMergeConflict =
						new KXL.AuthApp.Register.ImportFbGamesPrompt.ImportFbMergeConflictPromptExistingAccount({
							heading: KXL.i18n.t('auth.fbgames.mergeConflict.heading'),
							confirmBtnLabel:  KXL.i18n.t('auth.fbgames.mergeConflict.continueBtnLabel'),
							model: user
						});

					fbMergeConflict.on('confirm:clicked', function () {
						kixeyeAPI.logout({ suppressFbLogout: true, reload: false })
							.then(function () {
								return kixeyeAPI.fbLogin(fbAccessToken).done(function (response) {
									if (response && response.user) {
										setCurrentUser(response.user);
									}
								}).fail(function (error) {
									genericFailHandler();
								});
							})
							.done(function () {
								KXL.dialogRegion.closeDialog();
							});
					});

					fbMergeConflict.on('cancel:clicked', function () {
						KXL.dialogRegion.close();
					});

					wizard.showStep(fbMergeConflict);
				}

				function showEmailConflict(email) {
					var emailConflict =
						new KXL.AuthApp.Register.Common.ConfirmPrompt({
							heading: KXL.i18n.t('auth.loginEmailConflictError',  { email: email }),
							showConfirmBtn: false
						});
					emailConflict.on('cancel:clicked', function () {
						KXL.dialogRegion.close();
					});
					wizard.showStep(emailConflict);
				}

				function showMergeCaution() {

					var fbMergeCaution =
						new KXL.AuthApp.Register.Common.ConfirmPrompt({
							heading: KXL.i18n.t('auth.fbgames.caution.heading'),
							confirmBtnLabel:  KXL.i18n.t('auth.fbgames.caution.continueBtnLabel')
						});
					fbMergeCaution.on('confirm:clicked', function () {
						doFbSync();
					});
					fbMergeCaution.on('cancel:clicked', function () {
						KXL.dialogRegion.close();
					});
					wizard.showStep(fbMergeCaution);

				}

				function doFbSync() {
					startLoadingState();

					kixeyeAPI.facebookMerge(
						KXL.currentUser.id,
						fbAccessToken).then(function (fbSyncResp) {
							// Successfull fb sync.
							if (fbSyncResp && fbSyncResp.destinationUser) {
								setCurrentUser(fbSyncResp.destinationUser);
							}
							// Get a list of the user's games.
							return KXL.request('usergame:entities', KXL.currentUser.id).then(function (fetchedUserGames, response, options) {
								stopLoadingState();
								userGames = fetchedUserGames;
								if (userGames && userGames.length) {
									// The user plays at least one game so send to
									// confirm games step.
									showConfirmFbGamesStep();
								} else {
									// User does not play any games therefore none to confirm
									KXL.dialogRegion.closeDialog();
								}
							});
						}).fail(function (error) {
							if (error instanceof KXL.Errors.FacebookMergeConflictError) {
								showMergeConflict(
									new KXL.Entities.User({
										id: error.detail.id,
										username: error.detail.username
									})
								);
							} else if (error instanceof KXL.Errors.EmailConflictError) {
								showEmailConflict(error.detail.email);
							} else {
								genericFailHandler();
							}
						}).always(function () {
							stopLoadingState();
						});
				}
				if (userGames.length) {
					showMergeCaution();
				} else {
					doFbSync();
				}
			});
		},
		parseRegisterOptions: function (options) {
			var optionsObj = {};
			if (options && typeof options === 'string') {
				/**
				 * The reason this is being assigned to signupFormOptions is because in
				 * showSignupStep() it is all that is passed on to the
				 * KXL.AuthApp.Register.SignupForm.Controller.getSignupForm() function, which
				 * is where we want these options to be applied to.
				 */
				optionsObj.signupFormOptions = _.chain(options.split('&')).map(function (params) {
					var p = params.split('=');
					var optionVal = decodeURIComponent(p[1]);
					// detect if we need to convert these values to booleans (instead of strings)
					if (optionVal.toLowerCase() === 'true') {
						optionVal = true;
					} else if (optionVal.toLowerCase() === 'false') {
						optionVal = false;
					}
					return [p[0], optionVal];
				}).object().value();
				// Strip the options off the URL
				KXL.navigate('register');
			}
			if (!KXL.appModel.get('pages').selected) {
				KXL.execute('home:show');
			}
			this.showRegisterDialog(optionsObj);
		},
		showDelayedRegisterFormBanner: function (options) {
			var opts = options || {}, self = this;
			if (!KXL.appModel.get('deferredRegFormBannerShowing')) {
				var wizard = new KXL.AuthApp.Register.Wizard.View({});
				var deferredRegBannerView =
					KXL.request('new:deferred:reg:banner:signup:view');
				var vent = new Backbone.Wreqr.EventAggregator();
				var bannerOptions = {
					bannerId: 'delayedRegFormBanner',
					view: deferredRegBannerView,
					removeOnClose: false,
					show: true
				};
				if (KXL.context) {
					bannerOptions.className = KXL.context.get('titleId');
				} else if (KXL.currentUser && KXL.currentUser.get('acTitleId')) {
					bannerOptions.className = KXL.currentUser.get('acTitleId');
				}

				// Hide the other banners that are visible
				KXL.request('banner:hide');
				var bannerRegion = KXL.request(
					'banner:create',
					bannerOptions
				);
				var steps = AuthApp.RegisterSteps;
				bannerRegion.listenTo(vent, 'performing:step', function (step) {
					switch (step) {
						case steps.CAPTCHA:
						case steps.SIGNUP_FORM:
						case steps.SUCCESS:
						case steps.LOGIN_FORM:
						case steps.VERIFY_EMAIL:
							bannerRegion.toggleClosable(true);
							break;
						case steps.CONFIRM_FB_GAMES:
						case steps.FB_MERGE_CONFLICT_PROMPT:
						case steps.IMPORT_FB_GAMES_PROMPT:
							bannerRegion.toggleClosable(opts.startNowUrlName
								&& opts.startNowUrlName.length);
							break;
						default:
							break;
					}
				});

				bannerRegion.on('removed', function () {
					bannerRegion.stopListening(vent, 'performing:step');
					KXL.appModel.set('deferredRegFormBannerShowing', false);
				});

				bannerRegion.on('hidden', function () {
					KXL.appModel.set('deferredRegFormBannerShowing', false);
				});

				function autoBannerHide () {
					if (!self.tomeDelayedReg) {
						KXL.request('banner:hide', 'delayedRegFormBanner', false, {
							manual: true,
							disableRegBanner: false
						});
					}
				}

				deferredRegBannerView.listenTo(
					KXL.appModel.get('pages'),
					'select:one',
					autoBannerHide
				);

				deferredRegBannerView.listenTo(
					KXL.appModel,
					[
						'change:currentProfilePageUserId',
						'change:context'
					].join(' '),
					autoBannerHide
				);

				deferredRegBannerView.listenTo(vent, 'registration:form:closable', bannerRegion.toggleClosable);

				deferredRegBannerView.listenTo(vent, 'registration:wizard:close', function () {
					KXL.request('banner:hide', 'delayedRegFormBanner', true);
				});

				deferredRegBannerView.listenTo(KXL.vent, 'info:register:success', function () {
					triggerRegVent('registration:complete:message');
					KXL.request('banner:hide', 'delayedRegFormBanner', true);
					if (self.tomeDelayedReg) {
						showTomeSuccessStep();
					}
				});

				deferredRegBannerView.listenTo(KXL.vent, 'info:register:cancel', function () {
					triggerRegVent('registration:failure:message');
					KXL.request('banner:hide', 'delayedRegFormBanner', true);
				});

				deferredRegBannerView.showCenterView(wizard);

				KXL.appModel.set('deferredRegFormBannerShowing', true);

				opts.data = _.defaults(
					{},
					opts.data,
					{
						trackControl: 'ribbon',
						trackSource: opts.referrer || 'sourceunknown',
						trackGameUrlName: opts.referringGameUrlName ||
							(KXL.context && KXL.context.get('urlName'))
					}
				);

				authAPI.setupDelayedRegisterWizard(wizard, vent, opts);
			}
			return KXL.request('banner:get', 'delayedRegFormBanner');
		},
		setupDelayedRegisterWizard: function (wizard, vent, options) {
			options = options || {};
			var self = this;
			var registrationHeaderText = KXL.i18n.t('auth.delayedRegistrationSaveProgressRegister');
			var registration = new KXL.Entities.DelayedRegistration();
			var emailVerificationRequired = false;
			options.verifyEmailOptions = options.verifyEmailOptions || {};

			// if the logged in temp user is the same that started organic start now
			// then captcha is required, otherwise this is a UA temp user
			var organicStartNowKxid = KXL.Common.Utils.CookieUtils.getCookie('organicStartNow');
			var currentUserId = KXL.currentUser && KXL.currentUser.id;
			var captchaRequired = organicStartNowKxid && currentUserId && organicStartNowKxid === currentUserId;

			if (this.isUnverifiedUser())
			{
				this.tomeDelayedReg = true;
				if (KXL.request('banner:get', 'delayedRegFormBanner').currentView) {
					KXL.request('banner:get', 'delayedRegFormBanner').currentView.on('close:clicked', function () {
						KXL.appModel.set('deferredRegFormBannerShowing', false);
						var ribbonView = new KXL.HeaderApp.Header.SimpleRibbon({ message: KXL.i18n.t('auth.delayedRegistrationPlayLiveMatches') });
						ribbonView.on('click', function () {
							KXL.request('banner:hide', 'promptUserToReg');
							self.showDelayedRegisterFormBanner();
						});
						KXL.request('banner:create', {
							bannerId: 'promptUserToReg',
							view: ribbonView,
							className: KXL.context.get('titleId')
						});
					});
				}
				options.suppressSuccessStep = false;
				options.verifyEmailHeaderText = KXL.i18n.t('auth.delayedRegistrationVerify');
				registrationHeaderText = KXL.i18n.t('auth.delayedRegistrationPlayLiveMatches');
				options.verifyEmailOptions.toggledFormFields = true;
				emailVerificationRequired = KXL.switches && KXL.switches.tomeEmailVerificationEnabled;
				// If the user is temp and has a password, they have yet to verify their email address...
				if (emailVerificationRequired && this.isUnverifiedUser()) {
					options.step = AuthApp.RegisterSteps.VERIFY_EMAIL;
				}
			} else if (options &&
				options.referrer &&
				options.referrer.substr(0, 13) !== 'deferred-reg-' &&
				options.referrer !== 'banner')
			{
				// Could possibly add a different text header based on the referrer.
				registrationHeaderText = KXL.i18n.t('auth.delayedRegistrationAccountRequired');
			}
			options = _.extend(
				{
					suppressSuccessStep: true,
					captchaRequired: captchaRequired,
					registration: registration,
					skipFbGamesPrompt: true,
					registrationHeaderText: registrationHeaderText,
					emailSignupSubHeaderText: ''
				},
				options
			);

			options.signupFormOptions = _.extend(
				{},
				{
					useToggledSections: true,
					hideSocialToggle: true,
					emailSignupSelected: true,
					showLoginLink: false,
					delayedRegistration: true,
					registerBtnTheme: KXL.Components.Button.Themes.alt22,
					emailVerificationRequired: emailVerificationRequired,
					strings: {
						registerBtnLabel: KXL.i18n.t('auth.signup.createAccount.button'),
						notPostOnWall: KXL.i18n.t('auth.signup.notPostOnWall'),
						facebookWillBeImported: KXL.i18n.t('auth.signup.facebookWillBeImported'),
						registerUsingFacebook: KXL.i18n.t('auth.signup.registerUsingFacebook' + (KXL.switches.steamLoginEnabled ? '' : 'NoSteam')),
						regOr: KXL.i18n.t('auth.signup.regOr'),
						regFinePrint: KXL.i18n.t('auth.signup.regFinePrintOnlyEmail'),
						emailVerification: KXL.i18n.t('auth.signup.emailVerification')
					}
				},
				options.signupFormOptions
			);

			authAPI.setupRegisterWizard(wizard, vent, options);
		},
		showRegisterDialog: function (options, delayed) {
			var wizard = new KXL.AuthApp.Register.Wizard.View({});
			var dlg = KXL.request(
				'show:default:dialog',
				wizard,
				{ dialogId: 'registerDialog' }
			);
			var vent = new Backbone.Wreqr.EventAggregator();

			var steps = AuthApp.RegisterSteps;

			dlg.listenTo(vent, 'performing:step', function (step) {
				switch (step) {
					case steps.CAPTCHA:
					case steps.SIGNUP_FORM:
					case steps.SUCCESS:
					case steps.LOGIN_FORM:
					case steps.VERIFY_EMAIL:
						dlg.toggleClosable(true);
						break;
					case steps.CONFIRM_FB_GAMES:
					case steps.FB_MERGE_CONFLICT_PROMPT:
					case steps.IMPORT_FB_GAMES_PROMPT:
						if (options && options.startNowUrlName) {
							dlg.options.clickVeilToClose = false;
							dlg.toggleClosable(true);
						} else {
							dlg.toggleClosable(false);
						}
						break;
					default:
						break;
				}
			});

			dlg.listenTo(vent, 'registration:form:closable', function (closable) {
				dlg.toggleClosable(closable);
			});

			dlg.listenTo(vent, 'registration:wizard:close', function () {
				KXL.dialogRegion.closeDialog();
			});

			if (delayed) {
				authAPI.setupDelayedRegisterWizard(wizard, vent, options);
				return;
			}

			authAPI.setupRegisterWizard(wizard, vent, options);
		},
		setupRegisterWizard: function (wizard, vent, options) {
			var
				self = this,
				opts = _.extend({
					captchaRequired: true,
					contextGameUrlName: null,
					fbAccessToken: null,
					isNew: null,
					suppressSuccessStep: false,
					signupFormOptions: {},
					registrationHeaderText: KXL.i18n.t('auth.registrationHeaderText'),
					registrationSubHeaderText: '',
					emailSignupSubHeaderText: undefined,
					verifyEmailHeaderText: KXL.i18n.t('auth.verifyEmailHeaderText')
				}, options),
				steps = AuthApp.RegisterSteps,
				regnHeaderText = opts.registrationHeaderText,
				registration = opts.registration || new KXL.Entities.Registration(),
				wizardState = new KXL.Entities.Model({
					step: opts.step || steps.SIGNUP_FORM,
					userGames: null,
					fbAccessToken: opts.fbAccessToken,
					isNew: opts.isNew
				})
			;

			var captchaRequired =
				opts.captchaRequired &&
				(KXL.switches.captchaReCaptchaEnabled || KXL.switches.captchaAyahEnabled);

			function genericFailHandler() {
				wizard.showError(KXL.i18n.t('auth.registrationFailed'));
				stopLoadingState();
			}

			function stopLoadingState() {
				wizard.toggleLoadingState(false);
			}

			function startLoadingState() {
				wizard.toggleLoadingState(true);
			}

			function createAccount(data) {
				function register() {
					startLoadingState();
					vent.trigger('performing:step', steps.CREATE_ACCOUNT);
					var registering = authAPI.doRegister(
						registration.get('username'),
						registration.get('password'),
						registration.get('email'),
						{
							data: data,
							registration: registration
						}
					);
					registering.done(
						function (model) {
							if (model.get('user').acTitleId = "tome-immortal-arena") {
								// Send a verification email
								KXL.Facades.Kixeye.verifyEmail();
							}
							// If the user is done registering and is still temp, we are waiting for them to verify
							// their email address
							if (self.isTempUser()) {
								showVerificationStep(wizard);
							} else {
								wizardState.set('isNew', true);
								var showFbGamesPrompt = true;
								if (opts.contextGameUrlName) {
									if (KXL.webGames.findWhere({ urlName: opts.contextGameUrlName }).get('platform') !== 'fb') {
										showFbGamesPrompt = false;
									}
								}
								if (showFbGamesPrompt && !opts.skipFbGamesPrompt) {
									// If the game is also played on facebook show the facebook import games.
									showFbGamesPromptStep();
								} else {
									// KXL exclusive, ie: TOME, don't show already playing on facebook.
									showSuccessStep();
								}
							}
						}
					).fail(function (error) {
							if (error instanceof KXL.Errors.UsernameConflictError) {
								// Failed to successfully create the account, take
								// the user back to the sign up form to display errors
								// and allow re-submit.
								showSignupStep({ validationErrors: { username: error.message }});
							} else if (error instanceof KXL.Errors.RegistrationAuthorizationError) {
								// We have refused to create an account for this user.

								showSignupStep({ errorMsg: error.message });
							} else {
								showSignupStep({ errorMsg: KXL.i18n.t('auth.registrationFailed') });
							}
						});
				}
				if (captchaRequired) {
					startLoadingState();
					vent.trigger('performing:step', steps.CAPTCHA);
					wizard.setHeaderText(regnHeaderText);

					KXL.asyncRequest('async:load:module', 'auth/register/captcha').then(function () {

						var view = KXL.AuthApp.Register.Captcha.Controller.getCaptcha();

						view.on('validated', function () {
							register();
						});

						wizard.showStep(view);

						KXL.vent.trigger('info:register:captcha:start', data);

					});

				} else {
					registration.set('validated', true);
					register();
				}
			}

			function showVerificationStep() {
				startLoadingState();
				KXL.asyncRequest('async:load:module', 'auth/register/verify').then(function () {
					vent.trigger('performing:step', steps.VERIFY_EMAIL);
					wizard.setHeaderText(opts.verifyEmailHeaderText);
					var verifyStep = new KXL.AuthApp.Register.Verify.Layout(
						_.extend(opts.verifyEmailOptions || {}, { model: KXL.currentUser })
					);
					wizard.showStep(verifyStep);
					verifyStep.on(
						'verify:send:verification:email:failed',
						function (errorMsg) {
							wizard.showError(errorMsg);
						}
					);
					verifyStep.on('verify:email:changed', function () {
						wizard.clearError();
					})
				});
			}

			function showSignupStep(options) {
				var stepOptions = _.extend({}, options);
				vent.trigger('performing:step', steps.SIGNUP_FORM);
				wizard.setHeaderText(regnHeaderText);
				wizard.setSubHeaderText(opts.registrationSubHeaderText);

				var regData = stepOptions.data;

				if (!regData) {
					if (stepOptions.referrer) {
						regData = { referrer: stepOptions.referrer, trackSource: stepOptions.referrer };
						if (stepOptions.referrer == 'game' && KXL.context) {
							regData.game = KXL.context.get('commonName');
						}
					} else if (stepOptions.contextGameUrlName) {
						regData = { trackSource: stepOptions.contextGameUrlName };
					}
				}

				var signupFormOptions = _.extend(
					{
						registration: registration,
						data: regData
					},
					opts.signupFormOptions
				);
				var signupForm = KXL.AuthApp.Register.SignupForm.Controller.getSignupForm(signupFormOptions);
				signupForm.on('show', function () {
					if (stepOptions.errorMsg) {
						signupForm.setErrorMsg(stepOptions.errorMsg);
					}
					if (stepOptions.validationErrors) {
						signupForm.showValidationErrors(stepOptions.validationErrors);
					}
				});

				signupForm.on('signup:method:updated', function () {
					if (signupForm.socialSignupSelected) {
						wizard.setSubHeaderText(opts.registrationSubHeaderText);
					} else if (opts.emailSignupSubHeaderText !== undefined) {
						wizard.setSubHeaderText(opts.emailSignupSubHeaderText);
					}
				});

				wizard.showStep(signupForm);

				signupForm.on('register:completed', function () {
					showFbGamesPromptStep();
				});

				signupForm.on('login:link:clicked', function () {
					showLoginFormStep();
				});

				signupForm.on('fb:api:authenticated', function (accessToken, data) {
					wizardState.set('fbAccessToken', accessToken);
					completeFacebookRegister(data).fail(function (error) {
						vent.trigger('registration:form:closable', true);
						if (error instanceof KXL.Errors.EmailConflictError) {
							wizard.showError(
								KXL.i18n.t(
									'auth.loginEmailConflictError',
									{ email: error.detail.email }
								)
							);
						} else if (error instanceof KXL.Errors.IOvationBlockedError ||
							error instanceof KXL.Errors.DeviceFingerprintError) {

							wizard.showError(error.message);
							stopLoadingState();
						} else {
							genericFailHandler();
						}
					});
				});

				signupForm.listenTo(KXL, 'google:api:authenticated', function (accessToken) {
					wizardState.set('googleAccessToken', accessToken);
					authAPI.doGoogleLogin(accessToken).done(function (userData, isNew, hasAvatar) {
						// This user just logged in...
						if (isNew) {
							showFbGamesPromptStep();
						} else {
							vent.trigger('registration:wizard:close');
						}
					}).fail(function (error) {
						vent.trigger('registration:form:closable', true);
						if (error instanceof KXL.Errors.IOvationBlockedError ||
							error instanceof KXL.Errors.DeviceFingerprintError) {

							wizard.showError(error.message);
							stopLoadingState();
						} else {
							genericFailHandler();
						}
					});
				});
				
				signupForm.listenTo(KXL, 'google:api:error', function (errorMessage) {
					genericFailHandler();
				});

				signupForm.on('steam:api:authenticated', function (authPromise) {
					authPromise.done(function () {
						vent.trigger('registration:wizard:close');
					}).fail(function () {
						vent.trigger('registration:form:closable', true);
						genericFailHandler();
					});
				});

				signupForm.on('register:submit', function (regModel, data) {
					registration.set('username', regModel.get('username'));
					registration.set('email', regModel.get('email'));
					registration.set('password', regModel.get('password'));
					createAccount(data);
				});
			}

			function showConfirmFbGamesStep() {
				vent.trigger('performing:step', steps.CONFIRM_FB_GAMES);
				wizard.setHeaderText(regnHeaderText);
				startLoadingState();
				var userGames = wizardState.get('userGames') || KXL.request('current:user:game:entities');

				KXL.asyncRequest('async:load:module', 'auth/register/confirm_games').then(function () {

					var confirmGamesView = new KXL.AuthApp.Register.ConfirmGames.Controller.getView({
						collection: userGames,
						userId: KXL.currentUser.id
					});

					wizard.showStep(confirmGamesView);

					confirmGamesView.on('submit', function () {
						completeFacebookRegister();
					});

				});
			}

			function showFbGamesPromptStep() {
				vent.trigger('performing:step', steps.IMPORT_FB_GAMES_PROMPT);
				wizard.setHeaderText(regnHeaderText);
				startLoadingState();
				var fbGamesPromptOptions = {};
				if (opts.startNowUrlName) {
					var game = KXL.webGames.findWhere({ urlName: opts.startNowUrlName });
					fbGamesPromptOptions.startNowUrlName = opts.startNowUrlName;
					fbGamesPromptOptions.startNowGameName = game.get('name');
				}

				KXL.asyncRequest('async:load:module', 'auth/register/import_fb_games_prompt').then(function () {

					var importFbGamesPrompt = new KXL.AuthApp.Register.ImportFbGamesPrompt.View(fbGamesPromptOptions);

					wizard.showStep(importFbGamesPrompt);

					importFbGamesPrompt.on('import:games:selected', function (fbAccessToken) {
						wizardState.set('fbAccessToken', fbAccessToken);
						doFbSync();
					});

					importFbGamesPrompt.on('new:game:selected', function () {
						if (opts.startNowUrlName) {
							KXL.dialogRegion.close();
							var $bodyVeil = $('#kx-body-veil');
							$bodyVeil.addClass('kx-loader-veil');
							$bodyVeil.show();
							authAPI.autoRegister(opts.startNowUrlName).done(function (response) {
								var user = response && response.get('user');
								if (user && user.id) {
									KXL.Common.Utils.CookieUtils.setCookie('organicStartNow', user.id, 365);
								}
							});
							return;
						}
						showSuccessStep();
					});

				});
			}

			function showSuccessStep() {
				if (opts.signupFormOptions && opts.signupFormOptions.delayedRegistration) {
					showDelayedRegistrationSuccessStep();
					return;
				}
				if (opts.suppressSuccessStep) {
					KXL.dialogRegion.close();
					return;
				}
				vent.trigger('performing:step', steps.SUCCESS);
				wizard.setHeaderText(regnHeaderText);
				var userGames = wizardState.get('userGames');
				startLoadingState();
				function proceedToSuccessStep(successStepGames) {

					KXL.asyncRequest('async:load:module', 'auth/register/success').then(function () {

						KXL.AuthApp.Register.Success.Controller.getView({
							collection: successStepGames
						}).done(function (successView) {
							wizard.showStep(successView);
						});

					});

				}

				function handleSingleGame(game) {
					if (game) {
						KXL.execute('game:play:load', game.get('urlName'));
						KXL.dialogRegion.close();
						return;
					}
					proceedToSuccessStep(KXL.webGames);
				}
				if (opts.contextGameUrlName) {
					handleSingleGame(
						KXL.webGames.findWhere({ urlName: opts.contextGameUrlName })
					);
				} else if (userGames && userGames.length) {
					if (userGames.length === 1) {
						// User plays one game, go straight into the game.
						handleSingleGame(
							KXL.webGames.get(userGames.first().get('gameId'))
						);
					} else {
						var successStepGames;
						// The user plays games, so show them in the success step
						successStepGames =
							new Backbone.FilteredCollection(null, { collection: KXL.webGames });
						successStepGames.setFilter(function (game) {
							// Filter so that only the user's games are displayed (not all).
							return userGames.findWhere({ gameId: game.id });
						});
						proceedToSuccessStep(successStepGames);
					}
				} else {
					// User doesn't play any games yet, so show all games in the
					// success step.
					proceedToSuccessStep(KXL.webGames);
				}
			}

			function showDelayedRegistrationSuccessStep() {
				if (KXL.currentUser && KXL.currentUser.get('acTitleId') == 'tome-immortal-arena') {
					showTomeSuccessStep();
				} else {
					KXL.vent.trigger('completed:deferred:registration');
					// Currently we are just closing the dialog, todo show sucess step.
					KXL.dialogRegion.close();
				}

				// If we have a referrer the temp user came from a restricted feature.
				if (opts && opts.referrer) {
					// TODO, track this. Re-Open the feature upon completion.
					if (opts.paymentData) {
						if (opts.paymentData.specialOfferId) {
							KXL.vent.trigger('payment:specialoffer:start', opts.paymentData.orderInfo, opts.paymentData.specialOfferId);
						} else {
							KXL.vent.trigger('payment:start', null, opts.paymentData.orderInfo);
						}
					}
				}
			}

			function showLoginFormStep() {
				vent.trigger('performing:step', steps.LOGIN_FORM);
				wizard.setHeaderText(KXL.i18n.t('auth.loginHeaderText'));
				var loginView = KXL.request('new:login:view');
				loginView.on('authenticated', function () {
					vent.trigger('registration:wizard:close');
					if (opts && opts.loginUrlName) {
						KXL.execute('game:play:load', opts.loginUrlName);
					}
				});
				loginView.on('fb:authenticated', function (accessToken, user, isNew) {
					wizardState.set('fbAccessToken', accessToken);
					completeFacebookRegister();
					if (opts && opts.loginUrlName) {
						KXL.execute('game:play:load', opts.loginUrlName);
					}
				});
				loginView.on('google:authenticated', function (accessToken, user, isNew, hasAvatar) {
					wizardState.set('googleAccessToken', accessToken);
					if (isNew) {
						wizardState.set('isNew', isNew);
						showFbGamesPromptStep();
					} else {
						// Existing user google connected, no additional flow.
						vent.trigger('registration:wizard:close');
						if (opts && opts.loginUrlName) {
							KXL.execute('game:play:load', opts.loginUrlName);
						}
					}
				});
				loginView.on('steam:authenticated', function () {
					vent.trigger('registration:wizard:close');
					if (opts && opts.loginUrlName) {
						KXL.execute('game:play:load', opts.loginUrlName);
					}
				});

				loginView.on('register:link:clicked', function () {
					showSignupStep();
				});
				wizard.showStep(loginView);
			}

			function doFbMergeConflictPrompt(userId, username) {
				vent.trigger('performing:step', steps.FB_MERGE_CONFLICT_PROMPT);
				wizard.setHeaderText(regnHeaderText);
				startLoadingState();
				KXL.asyncRequest('async:load:module', 'auth/register/import_fb_games_prompt').then(function () {

					var importFbGamesPrompt = new KXL.AuthApp.Register.ImportFbGamesPrompt.ImportFbMergeConflictPrompt({
						conflictedUser: new KXL.Entities.User({
							id: userId,
							username: username
						})
					});

					wizard.showStep(importFbGamesPrompt);

					importFbGamesPrompt.on('submit', function () {
						var choice = importFbGamesPrompt.val();
						if (choice === 'yes') {
							// User chose to abort reg flow and log into the account associated with
							// their fb one.
							kixeyeAPI.logout({ suppressFbLogout: true, reload: false })
								.then(function () {
									return kixeyeAPI.fbLogin(wizardState.get('fbAccessToken')).done(function (response) {
										if (response && response.user) {
											setCurrentUser(response.user);
										}
									}).fail(function (error) {
										genericFailHandler();
									});
								})
								.done(function () {
									vent.trigger('registration:wizard:close');
								});
						} else {
							// User selects to skip fb sync, so go to success.
							showSuccessStep();
						}
					});

				});
			}

			function completeFacebookRegister(data) {
				vent.trigger('registration:form:closable', false);
				startLoadingState();
				return authAPI.doFbLogin(wizardState.get('fbAccessToken'), data).done(function (user, isNew) {
					if (isNew) {
						wizardState.set('isNew', isNew);
						showSuccessStep();
					} else {
						// Existing user fb connected, no additional flow.
						vent.trigger('registration:wizard:close');
					}
				});
			}

			function doFbSync() {
				vent.trigger('performing:step', steps.FB_SYNC);

				if (opts.startNowUrlName) {
					kixeyeAPI.fbLogin(wizardState.get('fbAccessToken')).done(function (response) {
						var $bodyVeil = $('#kx-body-veil');
						if (response && response.user) {
							KXL.vent.trigger('info:startnow:fb:success', opts.startNowUrlName);
							setCurrentUser(response.user);
							KXL.execute('game:play:load', opts.startNowUrlName);
						}
						vent.trigger('registration:wizard:close');
						$bodyVeil.addClass('kx-loader-veil');
						$bodyVeil.show();

					}).fail(function (error) {
						genericFailHandler();
					});
					return;
				}

				wizard.setHeaderText(regnHeaderText);

				return kixeyeAPI.facebookMerge(
					KXL.currentUser.id,
					wizardState.get('fbAccessToken')).then(function (fbSyncResp) {
						// Successfull fb sync.
						if (fbSyncResp && fbSyncResp.destinationUser) {
							setCurrentUser(fbSyncResp.destinationUser);
						}
						// Get a list of the user's games.
						return KXL.request('usergame:entities', KXL.currentUser.id).then(function onUserGamesFetched(userGames, response, options) {
							wizardState.set('userGames', userGames);
							if (userGames && userGames.length) {
								// The user plays at least one game so send to
								// confirm games step.
								showConfirmFbGamesStep();
							} else {
								// User does not play any games therefore none to confirm
								completeFacebookRegister();
							}
						});
					}).fail(function (error) {
						if (error instanceof KXL.Errors.FacebookMergeConflictError) {
							// Handle failed fb sync.
							doFbMergeConflictPrompt(
								error.detail.id,
								error.detail.username
							);
						} else {
							genericFailHandler();
						}
					});
			}

			wizard.listenTo(vent, 'performing:step', function (step) {
				wizardState.set('step', step);
				wizard.clearError();
				wizard.setHeaderText(regnHeaderText);
				wizard.setSubHeaderText('');
			});

			switch (wizardState.get('step')) {
			case steps.CREATE_ACCOUNT:
				createAccount(opts && opts.data);
				break;

			case steps.VERIFY_EMAIL:
				showVerificationStep();
				break;

			case steps.IMPORT_FB_GAMES_PROMPT:
				showFbGamesPromptStep();
				break;

			case steps.FB_SYNC:
				regnHeaderText = KXL.i18n.t('auth.fbConnectHeaderText');
				doFbSync();
				break;

			case steps.FB_MERGE_CONFLICT_PROMPT:
				doFbMergeConflictPrompt();
				break;

			case steps.CONFIRM_FB_GAMES:
				showConfirmFbGamesStep();
				break;

			case steps.COMPLETE_FACEBOOK_REGISTER:
				completeFacebookRegister({});
				break;

			case steps.SUCCESS:
				showSuccessStep();
				break;

			case steps.LOGIN_FORM:
				showLoginFormStep();
				break;

			default:
				// Default: steps.SIGNUP_FORM
				showSignupStep(opts);
			}
		},

		doRegister: function (username, password, email, options) {

			if (this.registerInProgress || this.autoRegisterInProgress) {
				return;
			}

			this.registerInProgress = true;

			var
				self = this,
				user,
				opts = _.defaults(
					{},
					options,
					{
						data: undefined,
						registration: undefined
					}
				),
				registration = opts.registration
			;

			// If registration model has set values the entity
			// has been passed as a option. So use it.
			if (registration &&
				registration.has('username') &&
				registration.has('email') &&
				registration.has('password')) {
				user = registration;
			} else {
				user = new KXL.Entities.Registration({
					username: username,
					email: email,
					password: password,
					validated: registration && registration.get('validated') || false
				});
			}

			var defer = $.Deferred();
			function saveUser (user) {
				self._saveUser(user, {
					data: opts.data
				}).then(function (model) {
					defer.resolve(model);
				}).fail(function (error) {
					defer.reject(error);
				}).always(function () {
					self.registerInProgress = false;
				});
			}

			KXL.execute('fraud:fingerprint:get', function (deviceFingerprint) {
				user.set({ deviceFingerprint: deviceFingerprint });
				saveUser(user);
			});

			return defer.promise();
		},

		_saveUser: function (user, options) {
			var opts = _.defaults(
					{},
					options,
					{
						data: {}
					}
				),
				defer = $.Deferred(),
				self = this;

			try {
				var wasDeferred = KXL.request('auth:is:temp:user');
				var infoRegister = _.extend({ wasDeferred: wasDeferred }, opts.data);
				user.bind('validated:invalid', function (model, errors) {
					// Invalid data.
					defer.reject(model, errors);
				});
				user.save(
					null,
					{
						success: function (model, response, options) {
							try {

								if (response && response.user) {
									setCurrentUser(response.user);

									if (KXL.currentUser.get('status') != 'temp') {
										_.extend(infoRegister, response.user);
										KXL.vent.trigger('info:register:success', infoRegister);
									} else {
										self.listenToOnce(KXL.currentUser, 'change:status', function () {
											if (KXL.currentUser.get('status') == 'active') {
												_.extend(infoRegister, response.user);

												KXL.vent.trigger('info:register:success', infoRegister);
											}
										});
									}
									defer.resolve(model);
								} else if (response && response.state === "authorization_failed") {
									var errorMsg;
									var error;
									if (!model.get('deviceFingerprint')) {
										errorMsg = KXL.i18n.t('auth.authorizationDenied');
									} else {
										errorMsg = KXL.i18n.t('auth.authorizationFailed');
									}
									error = new KXL.Errors.RegistrationAuthorizationError(errorMsg);
									infoRegister.error = error;
									KXL.vent.trigger('info:register:error', infoRegister);
									defer.reject(error);
								} else {
									infoRegister.error = { error: "conflict" };
									KXL.vent.trigger('info:register:error', infoRegister);
									defer.reject(model);
								}
							} catch (error) {
								defer.reject(error);
							}
						},
						error: function (model, xhr, options) {
							try {
								// Failed save.
								var error;
								switch  (xhr.status) {
									case 409:
										error = new KXL.Errors.UsernameConflictError();
										break;
									default:
										error = new KXL.Errors.BaseError();
										break;
								}
								infoRegister.error = error;
								KXL.vent.trigger('info:register:error', infoRegister);
								defer.reject(error);
							} catch (error) {
								defer.reject(error);
							}
						}
					}
				);
			} catch (error) {
				defer.reject(error);
			}

			return defer.promise();
		},

		doAutoRegister: function (gameUrlName) {

			var self = this;

			if (this.autoRegisterInProgress || this.registerInProgress) {
				return;
			}

			this.autoRegisterInProgress = true;

			var game = KXL.webGames.findWhere({ urlName: gameUrlName }),
				autoRegAttrs = {
					verificationRequired: false,
					validated: true
				};
			if (game) {
				autoRegAttrs.titleId = game.get('titleId');
				if (game.get('commonName') == 'tome' && KXL.switches && _.isBoolean(KXL.switches.tomeEmailVerificationEnabled)) {
					autoRegAttrs.verificationRequired = KXL.switches.tomeEmailVerificationEnabled;
				}
			}
			var user = new KXL.Entities.AutoRegistration(autoRegAttrs);
			var infoRegister = { };
			var defer = $.Deferred();
			user.save(
				null,
				{
					success: function (model, response, options) {

						if (response && response.user) {
							setCurrentUser(response.user, gameUrlName, true);
							KXL.vent.trigger('info:register:autoreg', gameUrlName);
							defer.resolve(model);
						} else {
							infoRegister.error = { error: "conflict" };
							defer.reject(model);
						}

						self.autoRegisterInProgress = false;

					},
					error: function (model, xhr, options) {

						// Failed save.
						var error;
						switch  (xhr.status) {
							case 409:
								error = new KXL.Errors.UsernameConflictError();
								KXL.vent.trigger('autoreg:error', error, gameUrlName);
								break;
							default:
								error = new KXL.Errors.BaseError();
								KXL.vent.trigger('autoreg:error', error, gameUrlName);
								break;
						}
						infoRegister.error = error;
						defer.reject(error);

						self.autoRegisterInProgress = false;
					}
				}
			);

			return defer.promise();
		},

		/**
		 * Open the Steam login window, process the response
		 * and trigger the steam:api:authenticated command on
		 * the most recent view to call this function.
		 *
		 * @param  {Object} viewListener The view to be notified of the login result.
		 *                               If called multiple times, only the most recent
		 *                               view is notified.
		 * @param  {Object} trackData    Object with trackControl and trackSource properties
		 * @param  {Boolean} steamCredsCheckOnly    Do steam api login but skip KXL login
		 * @return {undefined}
		 */
		startSteamLogin: function (viewListener, trackData, steamCredsCheckOnly) {
			var deferred = $.Deferred();
			var self = this;
			this.steamViewListener = viewListener;
			this.steamTrackData = trackData;

			KXL.vent.trigger('info:steamlogin:start', trackData);

			if (this.steamLoginWindow && !this.steamLoginWindow.closed) {
				this.steamLoginWindow.focus();
				return;
			}

			var url = steamCredsCheckOnly ?
					AuthApp.STEAM_CREDS_CHECK_URL:
					AuthApp.STEAM_LOGIN_URL;

			KXL.execute('fraud:fingerprint:get', function (deviceFingerprint) {
				return $.ajax({
					url: '/api/setDeviceFingerprint',
					type: 'POST',
					dataType: 'json',
					contentType: 'application/json',
					data: JSON.stringify({
						deviceFingerprint: deviceFingerprint
					})
				}).then(function() {
						self.steamLoginWindow = window.open(url, 'steamLoginWindow');
						if (!self.steamLoginWindow || self.boundSteamListener) {
							return;
						}
						self.steamLoginWindow.focus();
						self.boundSteamListener = true;

						self.listenToOnce(KXL.vent, 'steam:auth:result', function (data) {
							self.boundSteamListener = false;
							if (!self.steamLoginWindow ||
								self.steamLoginWindow.closed) {

								return;
							}

							self.steamLoginWindow.close();
							self.steamLoginWindow = undefined;

							if (_.contains(['invalid', 'cancel'], data)) {
								return;
							}

							try {
								data = JSON.parse(data);
							} catch (error) {}

							var authPromise;
							if (steamCredsCheckOnly) {
								authPromise = $.Deferred();
								authPromise.resolve(data);
							} else {
								authPromise = self.doSteamLogin(data, self.steamTrackData);
								authPromise.fail(function (error) {
									// Errors from Steam auth come back as null or an error string.
									var analyticsData = _.extend(self.steamTrackData, {
										error: (error || 'unknown_error').replace(/\s+/g, '_')
									});
									KXL.vent.trigger('info:steamlogin:error', analyticsData);
									deferred.reject();
								});
							}

							if (self.steamViewListener && !self.steamViewListener.isClosed) {
								self.steamViewListener.trigger('steam:api:authenticated', authPromise);
							}

							deferred.resolve();
						}, false);
					});

			});

			return deferred;
		}
	});

	var authAPI = new API();

	KXL.addInitializer(function () {
		new AuthApp.Router({
			controller: authAPI
		});

		KXL.commands.setHandlers({
			'show:login:dialog': function (options) {
				authAPI.showLoginDialog(options);
			},
			'logout': function (options) {
				authAPI.logout(options);
			},
			'auto:register': function (gameUrlName) {
				authAPI.autoRegister(gameUrlName);
			},
			'show:fb:sync:dialog': function (authToken) {
				return authAPI.showFbSyncDialog(authToken);
			},
			'show:register:dialog': function (options) {
				authAPI.showRegisterDialog(options);
			},
			'show:delayed:register:dialog': function (options) {
				if (KXL.context) {
					var commonName = KXL.context.get('commonName');
					var staticGame = KXL.staticGames && KXL.staticGames.findWhere({ commonName: commonName });
					if (staticGame && staticGame.get('registration') === 'banner') {
						authAPI.showDelayedRegisterFormBanner(options);
						return;
					}
				} else if (KXL.currentUser.get('hasPassword')) {
					authAPI.showDelayedRegisterFormBanner();
					return;
				}
				authAPI.showRegisterDialog(options, true);
			},
			'show:forgot:password:dialog': function (options) {
				authAPI.showForgotPasswordDialog(options);
			},
			'show:TOS': function (options) {
				authAPI.showTOS(options);
			},
			'show:forgot:password:confirmation': function () {
				authAPI.showForgotPasswordConfirmationDialog();
			},
			'show:password:reset:complete': function () {
				authAPI.showPasswordResetCompleteDialog();
			},
			'auth:register:deferred:start': function () {
				AuthApp.Register.Deferred.Controller.start();
			},
			'auth:register:deferred:show': function (options) {
				AuthApp.Register.Deferred.Controller.show(options);
			},
			'auth:register:deferred:restart': function () {
				AuthApp.Register.Deferred.Controller.restart();
			},
			'steamlogin:start': function (view, trackData, steamCredsCheckOnly) {
				authAPI.startSteamLogin(view, trackData, steamCredsCheckOnly);
			},
			'show:banned:dialog': function (data) {
				authAPI.showBannedDialog(data);
			},
		});

		KXL.reqres.setHandlers({
			'validate:username': function (username, options) {
				return kixeyeAPI.validateUsername(username, options);
			},
			'validate:email': function (email) {
				return kixeyeAPI.validateEmail(email);
			},
			'auth:create:email:verification:token': function (email) {
				return createEmailVerificationToken(email);
			},
			'fblogin:submit': function (authToken, data) {
				return authAPI.doFbLogin(authToken, data);
			},
			'googlelogin:submit': function (authToken, data) {
				return authAPI.doGoogleLogin(authToken, data);
			},
			'steamlogin:submit': function (view, trackData, steamCredsCheckOnly) {
				return authAPI.startSteamLogin(view, trackData, steamCredsCheckOnly);
			},
			'new:login:view': function (options) {
				return AuthApp.Login.Controller.getLogin(options);
			},
			'new:signup:form:view': function (options) {
				return AuthApp.Register.SignupForm.Controller.getSignupForm(options);
			},
			'new:inline:signup:form:view': function (options) {
				return AuthApp.Register.SignupForm.Controller.getInlineSignupForm(options);
			},
			'register:submit': function (username, password, email, options) {
				return authAPI.doRegister(username, password, email, options);
			},
			'auto:register:submit': function (gameUrlName) {
				return authAPI.doAutoRegister(gameUrlName);
			},
			'login:submit': function (username, password) {
				return authAPI.doLogin(username, password);
			},
			'check:credentials': function (username, password) {
				return authAPI.checkCredentials(username, password);
			},
			'send:forgot:password': function () {
				return authAPI.sendForgotPassword();
			},
			'auth:register:deferred:gate': function (options) {
				return AuthApp.Register.Deferred.Controller.gate(options);
			},
			'new:deferred:reg:banner:view': function (options) {
				return new KXL.AuthApp.DeferredReg.View(options)
			},
			'new:deferred:reg:banner:signup:view': function (options) {
				return new KXL.AuthApp.DeferredReg.BannerSignUpView(options);
			},
			'show:delayed:register:form:banner': function (options) {
				return authAPI.showDelayedRegisterFormBanner(options);
			},
			'auth:verify:email': function (token) {
				return authAPI.verifyEmail(token);
			},
			'auth:is:temp:user': function () {
				return authAPI.isTempUser();
			},
			'auth:has:group:super:admin': function (roles) {
				return authAPI.hasGroupSuperAdmin(roles);
			}
		});
	});
});

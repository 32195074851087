KXL.module('Components.Pager', function(Pager, KXL, Backbone, Marionette, $, _) {
	Pager.PagerItem = Marionette.ItemView.extend({
		template: function () {
			return '';
		},
		tagName: 'li',
		className: 'kx-pager-item',
		triggers: {
			'click': 'clicked'
		},
		modelEvents: {
			'selected': function () {
				this.$el.addClass('kx-pager-item-active');
			},
			'deselected': function () {
				this.$el.removeClass('kx-pager-item-active');
			}
		}
	});
	Pager.View = Marionette.CollectionView.extend({
		itemView: Pager.PagerItem,
		tagName: 'ul',
		itemViewEventPrefix: 'pager:item',
		className: 'kx-pager'
	});
});

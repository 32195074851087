KXL.module('HomeApp.Carousel', function(Carousel, KXL, Backbone, Marionette, $, _) {
	Carousel.CarouselItem = Marionette.Layout.extend({
		template: '#_carousel-item-template',
		className: function () {
			return 'kx-carousel-item ' + this.model.get('commonName')
		},
		carouselTimeline: null,
		DEFAULT_ANIMATION_DURATION: 0.85,
		regions: {
			playBtnRegion: '.kx-carousel-item-btn-play-region',
			getNotifiedRegion: '.kx-carousel-item-get-notified-region'
		},
		ui: {
			background: '.kx-carousel-item-background',
			content: '.kx-carousel-item-content'
		},
		triggers: {
			'mouseover .kx-carousel-item-content': 'moused:over:content',
			'mouseout .kx-carousel-item-content': 'moused:out:content',
			'click .kx-carousel-item-btn-play': 'play:clicked',
			'click .kx-carousel-item-anchor-play':'play:clicked'
		},
		initialize: function (options) {
			this.options = _.defaults(
				options || {},
				{
					animationDuration: this.DEFAULT_ANIMATION_DURATION
				}
			);
		},
		onRender: function () {
			var self = this;
			this.$el.hide();
			this.playBtnRegion.show(
				new KXL.Components.Button.View({
					customClass: 'kx-carousel-item-btn-play',
					label: self.model.get('playButtonLabel'),
					theme: self.model.get('playButtonTheme')
				})
			);

			if (this.model.get('showAppStoreButtons')) {
				var staticGame = KXL.staticGames.findWhere({ commonName: this.model.get('commonName') });
				if (staticGame) {
					KXL.asyncRequest(
						'async:load:module',
						'game/get_notified'
					).then(function() {
							KXL.request(
								'get:notified:new:view',
								{
									showPlayButtonInAppRegion: false,
									storeBtnPreText: '',
									showPlayButton: false,
									appleStoreBtnHref: staticGame.get('appleStoreHref'),
									showAppleStoreBtn: true,
									googleStoreBtnHref: staticGame.get('googleStoreHref'),
									showGoogleStoreBtn: true,
									steamStoreBtnHref: staticGame.get('steamStoreHref'),
									showSteamStoreBtn: staticGame.get('steamStoreHref') != null,
									email: KXL.currentUser ? KXL.currentUser.get('email') : '',
									country: KXL.currentUser ? KXL.currentUser.get('location') : '',
									gameUrlName: 'vegaconflict',
									showNotifyBySMS: false,
									showGetNotifiedForm: false,
									finePrint: ''
								}
							).done(function (view) {
									self.getNotifiedRegion.show(view);
								});
					});
				}
			}

			// Hide the item by default so that it does not receive click
			this.carouselTimeline = new TimelineLite({
				onStart: function () {
					self.$el.show();
				}
			});
			this.ui.background.css('background-image', 'url(' + this.model.get('background') +')');
			this.carouselTimeline.add(
				TweenLite.fromTo(
					this.ui.background,
					this.options.animationDuration,
					{
						opacity: 0,
						ease: this.easingStyle
					},
					{
						opacity: 1,
						ease: 'Power2.easeInOut',
						clearProps: 'transform'
					}
				)
			);

			this.carouselTimeline.add(
				TweenLite.fromTo(
					this.ui.content,
					this.options.animationDuration,
					{
						opacity: 0,
						ease: this.easingStyle
					},
					{
						opacity: 1,
						ease: 'Power2.easeInOut',
						clearProps: 'transform'
					}
				),
				'-=.85'
			);

			// Pause until background image is loaded.
			this.carouselTimeline.pause();
		},
		animateIn: function () {
			this.carouselTimeline.play();
		},
		animateOut: function (callback) {
			var self = this;
			self.carouselTimeline.eventCallback('onReverseComplete', null);
			if (self.carouselTimeline.reversed()) {
				self.$el.hide();
				if (callback) {
					callback();
				}
			} else {
				self.carouselTimeline.eventCallback(
					'onReverseComplete',
					function () {
						self.$el.hide();
						if (callback) {
							callback();
						}
					}
				);
				self.carouselTimeline.reverse();
			}
		},
		serializeData: function () {
			return {
				item: this.model.toJSON()
			};
		}
	});
	Carousel.View = Marionette.CollectionView.extend({
		itemView: Carousel.CarouselItem,
		className: 'kx-carousel',
		itemViewEventPrefix: 'carousel:item',
		start: function () {
			this.trigger('start:requested');
		},
		stop: function () {
			this.trigger('stop:requested');
		},
		setSelectedItemByGameCommonName: function (commonName) {
			var target = this.collection.findWhere({ commonName: commonName });
			if (target) {
				target.select();
			}
		}
	});
});

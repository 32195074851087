(function( jQuery ) {
	// Create the request object
	// (This is still attached to ajaxSettings for backward compatibility)
	jQuery.ajaxSettings.xdr = function() {
		return (window.XDomainRequest ? new window.XDomainRequest() : null);
	};

	// Determine support properties
	(function( xdr ) {
		jQuery.extend( jQuery.support, { iecors: !!xdr });
	})( jQuery.ajaxSettings.xdr() );

	// Create transport if the browser can provide an xdr
	if ( jQuery.support.iecors ) {

		jQuery.ajaxTransport(function( s ) {
			var callback;

			return {
				send: function( headers, complete ) {
					var xdr = s.xdr();

					xdr.onload = function() {
						var headers = { 'Content-Type': xdr.contentType };
						complete(200, 'OK', { text: xdr.responseText }, headers);
					};

					// Apply custom fields if provided
					if ( s.xhrFields ) {
						xhr.onerror = s.xhrFields.error;
						xhr.ontimeout = s.xhrFields.timeout;
					}

					xdr.open( s.type, s.url );

					// XDR has no method for setting headers O_o

					xdr.send( ( s.hasContent && s.data ) || null );
				},

				abort: function() {
					xdr.abort();
				}
			};
		});
	}
})( jQuery );
KXL.module('Components.ForumPosts', function (ForumPosts, KXL, Backbone, Marionette, $, _) {

	ForumPosts.Post = Marionette.Layout.extend({
		template: '#_forum-post-template',
		tagName: 'li',
		className: 'kx-item',
		regions: {
			avatar: '.kx-avatar-holder'
		},
		onRender: function () {
			this.avatar.show(
				KXL.request(
					'new:avatar:view',
					this.model.get('userId'),
					{
						model: new KXL.Entities.User({id: this.model.get('userId'), username: this.model.get('username')}),
						size: 'medium',
						avatarMenu: KXL.currentUser ? KXL.Components.Avatar.avatarMenu.ON : KXL.Components.Avatar.avatarMenu.OFF,
						color: 'grey'
					}
				)
			);
		},
		serializeData: function () {
			return {
				forumPost: this.model.toJSON(),
				postedDate: KXL.Common.Utils.DateUtils.fuzzytime(this.model.get('postedDate'))
			};
		}
	});

	ForumPosts.View = Marionette.CollectionView.extend({
		itemView: ForumPosts.Post,
		tagName: 'ul',
		className: 'kx-forum-posts'
	});

});

KXL.module('Entities.Screenshot', function (Screenshot, KXL, Backbone, Marionette, $, _) {
	"use strict";

	var Screenshot = Backbone.Model.extend({
		url: function () {
			return KXL.config.API_URL + '/screenshots';
		},
		parse: function (response) {
			if (response && response.length) {
				// just grab the first screenshot returned...
				return response[0];
			}
		},
		fetchToday: function (tags) {
			var defer = $.Deferred();
			this.fetch({
				data: {
					date: 0,
					tags: (tags || []).join(',')
				},
				success: function (model, response) {
					if (response.length) {
						defer.resolve(model);
					} else {
						defer.reject(response);
					}
				},
				error: function (model, response) {
					defer.reject(response);
				}
			});
			return defer;
		}
	});

	KXL.reqres.setHandlers({
		'screenshot:get:today': function (tags) {
			var screenshot = new Screenshot();
			return screenshot.fetchToday(tags);
		}
	})
});
/* globals KXL */
KXL.module('InboxApp.Views', function (Views, KXL, Backbone, Marionette, $, _) {
	Views.Layout = KXL.Components.Layout.extend({
		template: Templates['inbox/inbox'],
		className: 'kx-inbox',
		regions: {
			recipient: '.recipient-region',
			actions: '.actions-region',
			preloader: '.preloader-region',
			participants: '.participants-region',
			threadList: '.thread-list-region',
			threadBody: '.thread-body-region'
		},
		events: {
			'click .kx-inbox-deprecated-notice a': 'deprecatedMoreInfo'
		},
		deprecatedMoreInfo: function (e) {
			e.preventDefault();
			KXL.navigate($(e.target).attr('href'), { trigger: true });
		}
	});

	Views.Message = Marionette.Layout.extend({
		template: Templates['inbox/inbox-message'],
		className: 'message',
		regions: {
			avatarRegion: '.kx-message-avatar'
		},
		ui: {
			messageBody: '.kx-text-body'
		},
		onRender: function () {
			var fromId = this.model.get('from');
			fromId = fromId.split("@");
			fromId = (fromId.length > 0) ? fromId[0] : null;

			if (fromId !== KXL.currentUser.id) {
				this.$el.addClass('msgFrom');
			} else {
				this.$el.addClass('msgTo');
			}
			var avatar = KXL.request('new:avatar:view',
				fromId,
				{
					avatarMenu: KXL.Components.Avatar.avatarMenu.ON,
					size: 'medium',
					color: 'grey'
				}
			);
			this.avatarRegion.show(avatar);
		},
		onShow: function () {
			var threadBody = $('.thread-body-region');
			threadBody[0].scrollTop = threadBody[0].scrollHeight;
		},
		serializeData: function () {
			var data = this.model.toJSON();
			data.date = KXL.Common.Utils.DateUtils.kixDateStringFormat1(data.createdAt);
			return data;
		}
	});

	Views.Actions = Marionette.ItemView.extend({
		template: Templates['inbox/inbox-actions'],
		className: 'inbox-actions',
		events: {
			'click .start-thread': 'startChat'
		},
		startChat: function () {
			KXL.execute('chat:start:group');
		}
	});

	Views.EmptyThread = Marionette.ItemView.extend({
		template: function () {
			return '';
		}
	});

	Views.Participants = Marionette.Layout.extend({
		template: Templates['inbox/inbox-participants'],
		className: 'inbox-participants',
		serializeData: function () {
			var data = this.model.toJSON();
			var participants = [];
			_.each(data.participants, function (participant, i) {
				if (participant.id !== KXL.request('get:current:user').id) {
					participants.push(participant.name);
				}
			});
			data.participantsTitle = participants.join(', ');
			return data;
		}
	});

	/**
	 * This is the View for the full body of the thread
	 */
	Views.Thread = Marionette.CompositeView.extend({
		model: KXL.Entities.Inbox.Thread,
		collection: KXL.Entities.Inbox.Messages,
		emptyView: Views.EmptyThread,
		itemView: Views.Message,
		itemViewContainer: '.messages',
		template: Templates['inbox/inbox-thread'],
		appendHtml: function(collectionView, itemView) {
			//Overridden so we can prepend, append a message element without re-rendering entire view
			var itemIndex;
			itemIndex = collectionView.collection.indexOf(itemView.model);
			return collectionView.$('.messages').insertAt(itemIndex, itemView.$el);
		},
		events: {
			'click .message-compose-region': 'replyThread'
		},
		replyThread: function () {
			var participants = [];
			_.each(this.model.get('participants'), function (participant) {
				if (participant.id !== KXL.currentUser.id) {
					participants.push(participant.id);
				}
			});
			if (participants.length === 1) {
				KXL.execute('chat:start:private', participants[0]);
			} else if (participants.length > 1) {
				KXL.execute('chat:start:group', participants);
			}
		}
	});
	/**
	 * This is the view for listing all of the threads with a snippet
	 */
	Views.ThreadItem = Marionette.Layout.extend({
		template: Templates['inbox/inbox-thread-item'],
		model: KXL.Entities.Inbox.Thread,
		unread: false,
		ui: {
			title: 'h5',
			snippet: 'p',
			avatar: '.kx-avatar-container',
			messageSummary: '.kx-message-summary'
		},
		regions: {
			editMenu: '.kx-edit-menu'
		},
		initialize: function () {

			var self = this;

			self.listenTo(self.model, 'change', this.render);

			self.listenTo(self.model, 'selected', function () {
				self.$el.addClass('kx-selected');

			});

			self.listenTo(self.model, 'deselected', function () {
				self.$el.removeClass('kx-selected');
			});

		},
		onRender: function () {

			var self = this;

			self.ui.messageSummary.on('click', function (e) {
				KXL.execute('inbox:show:thread', self.model.id, true);
			});

			var participants = self.model.get('participants');
			var participant;

			// 1on1 conversation...
			if (participants.length < 3) {
				participant = _.find(participants, function (participantObj) {
					if (participantObj && participantObj.id && participantObj.id !== KXL.currentUser.id) {
						return true;
					}
					return false;
				});
				if (participant) {
					KXL.request(
						'new:avatar:view',
						participant.id,
						{
							size: 'medium',
							color: KXL.Components.Avatar.borderColors.NONE,
							el: self.ui.avatar,
							avatarMenu: KXL.Components.Avatar.avatarMenu.ON
						}
					);
				}
			} else {

				if (self.model.get('origin') === 'user') {

					var snippetUserId = self.model.has('snippetAddress') ?
						KXL.request('inbox:strip:suffix', self.model.get('snippetAddress')) :
						KXL.currentUser.id;

					// Last message was from an ignored user so we default back to current User avatar for thread
					if (snippetUserId !== KXL.currentUser.id  &&
						KXL.request('is:user:ignored', snippetUserId)) {
						snippetUserId = KXL.currentUser.id;
					}

					KXL.request(
						'new:avatar:view',
						snippetUserId,
						{
							size: 'medium',
							color: KXL.Components.Avatar.borderColors.NONE,
							el: self.ui.avatar,
							avatarMenu: KXL.Components.Avatar.avatarMenu.OFF
						}
					);
				}
			}

			var lastMessageAt = self.model.get('lastMessageAt') || 0;
			var lastReadAt = self.model.get('lastReadAt') || 0;
			if (lastReadAt < lastMessageAt) {
				self.$el.addClass('kx-unread');
				self.unread = true;
			} else {
				self.$el.removeClass('kx-unread');
				self.unread = false;
			}

			if (self.model.selected) {
				self.$el.addClass('kx-selected');
			}

			var itemOptionsView = new KXL.Components.EditOptions.Controller.getEditOptions({
				name: 'itemoptions',
				buttonText: '',
				buttonStyle: 'kx-alt14',
				optionItems: [
					{
						label: KXL.i18n.t('inbox.itemOptions.delete'),
						value: 'delete',
						itemEvent: 'inbox:thread:delete'
					},
					{
						label: KXL.i18n.t('inbox.itemOptions.markAsUnread'),
						value: 'unread',
						itemEvent: 'inbox:thread:markunread',
						itemState: self.unread
					}
				]
			});
			itemOptionsView.on('inbox:thread:markread', function () {
				KXL.execute('inbox:thread:markread', self.model.get('id'));
			});
			itemOptionsView.on('inbox:thread:markunread', function () {
				if (self.unread) {
					KXL.execute('inbox:thread:markread', self.model.get('id'));
				} else {
					KXL.execute('inbox:thread:markunread', self.model.get('id'));
				}
			});
			itemOptionsView.on('inbox:thread:delete', function () {
				KXL.execute('inbox:thread:delete', self.model.get('id'));
			});
			self.editMenu.show(itemOptionsView);
		},
		serializeData: function () {
			var data = this.model.toJSON();
			var participants = [];
			_.each(data.participants, function (participant, i) {
				if (participant.id !== KXL.request('get:current:user').id) {
					participants.push(participant.name);
				}
			});
			data.dateTimestamp = KXL.Common.Utils.DateUtils.kixDateStringFormat1(data.lastMessageAt);
			data.title = participants.join(', ');
			return data;
		}
	});

	Views.EmptyInbox = Marionette.ItemView.extend({
		template: Templates['inbox/inbox-empty-inbox']
	});

	Views.NoThreadSelected = Marionette.ItemView.extend({
		template: Templates['inbox/inbox-no-thread-selected']
	});

	Views.Threads = Marionette.CompositeView.extend({
		template: Templates['inbox/inbox-thread-list'],
		emptyView: Views.EmptyInbox,
		itemView: Views.ThreadItem,
		itemViewContainer: '.threads',
		collection: KXL.Entities.Inbox.Threads,
		ui: {
			threadItems: '.threads'
		},
		initialize: function () {
			this.collection.on('sort', this.render);
		},
		fetchMore: _.throttle(function (offset) {
			this.collection.fetch({
				remove: false,
				data: {
					offset: offset,
					limit: 10
				},
				sort: false
			});
		}, 500, { trailing: false }),
		checkScrollPosition: function () {
			var
				threads = this.ui.threadItems,
				offset = this.collection && this.collection.length
			;

			if (offset >= 10 && !(offset % 10) && (threads[0].scrollHeight - threads.innerHeight()) === threads.scrollTop())
			{
				this.fetchMore(offset);
			}
		},
		onRender: function () {
			var self = this;
			this.ui.threadItems.scroll(function () {
				self.checkScrollPosition();
			});
		}
	});
});

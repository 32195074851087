/* globals _ */
var KXL = new Marionette.Application();

/**
 * Scoped this way so that lockedAttrs are not available to anybody else (just appModel)
 */
(function () {
	var lockedAttrs = [];
	KXL.AppModel = Backbone.Model.extend({
		defaults: function () {
			return {
				currentUser: null,
				context: null,
				currentProfilePageUser: null,
				currentProfilePageUserId: null,
				friends: null,
				sentFriendRequests: null,
				receivedFriendRequests: null,
				deferredRegBarShowing: false,
				// Indicates whether a dialog is being shown.
				dialogShowing: false,
				// Indicates whether the auto popped reg dialog
				// has been overridden with a custom one, for example
				// the one shown in the ribbon for nux.
				deferredRegDialogOverridden: false,
				sideBarState: undefined
			};
		},
		lock: function (attr) {
			if (attr) {
				lockedAttrs.push(attr);
			}
		},
		unlock: function (attr) {
			var attrLocation;
			if (attr && (attrLocation = lockedAttrs.indexOf(attr)) !== -1) {
				lockedAttrs.splice(attrLocation, 1);
			}
		},
		set: _.wrap(Backbone.Model.prototype.set, function (origSet, key, val, options) {
			var self = this, attr, attrs, unset, changes, silent, changing, prev, current;
			if (key == null) return this;
			// Handle both `"key", value` and `{key: value}` -style arguments.
			if (typeof key === 'object') {
				attrs = key;
				options = val;
			} else {
				(attrs = {})[key] = val;
			}
			attrs = _.omit(attrs, lockedAttrs);
			return origSet.call(this, attrs, options);
		})
	});
})();

KXL.appModel = new KXL.AppModel();
KXL.atom = new atom();
KXL.Templates = {};

function setWebsocketListeners() {

	var websocket = KXL.module('Websocket');

	websocket.on('systemEvent', function (systemEvent) {
		if (systemEvent.eventType === 'forceLogout') {
			KXL.execute('logout');
		}

		if (systemEvent.eventType === 'passwordChanged') {
			if (!KXL.request('get:client:initiated:password:change')) {
				KXL.request('set:client:initiated:password:change', false);
				KXL.execute('logout');
			}
		}

	});

	websocket.on('userUpdate', function (userUpdate) {
		if (KXL.currentUser && userUpdate) {
			KXL.currentUser.set(userUpdate);
		}
	});

	websocket.on('settings', function (settingsPatch) {
		var settingsModel = KXL.request('current:user:settings:entity');
		_.each(settingsPatch, function (value, key) {
			settingsModel.set(key, value);
		});
	});

	websocket.on('games', function (userGameUpdate) {
		var userGame;
		var userGamesCollection = KXL.request('current:user:game:entities');
		if (userGameUpdate.gameId) {
			userGame = userGamesCollection.get(userGameUpdate.gameId);
		}
		if (userGame) {
			userGame.set(userGameUpdate);
		} else {
			userGamesCollection.add(new KXL.Entities.UserGame(userGameUpdate));
		}
		userGamesCollection.sort();
	});

	websocket.on('friends', function (usersFriend) {
		var deferred = KXL.request('current:user:friend:entities');
		deferred.then(function (usersFriendsCollection) {
			if (usersFriend && usersFriend.data && usersFriend.data.userId) {
				var friend = usersFriendsCollection.get(usersFriend.data.userId);
				if (friend && usersFriend.action && usersFriend.action === 'remove') {
					KXL.request('decrement:service:friends:count', usersFriend.data.userId);
					KXL.request('decrement:service:friends:count', KXL.currentUser.id);
					friend.destroy();
					KXL.execute('fetch:sent:friend:requests');
				} else if (!friend && usersFriend.action && usersFriend.action === 'add') {
					KXL.request('increment:service:friends:count', KXL.currentUser.id);
					KXL.request('increment:service:friends:count', usersFriend.data.userId);
					usersFriendsCollection.add(new KXL.Entities.Friend(usersFriend.data));
				}
			}
		});
	});

	websocket.on('battleLog', function (battleLogEntry) {
		var battlelogCollection = KXL.request('get:services:battlelog', battleLogEntry.gameId);

		if (battlelogCollection) {
			// No ID on read-only battle-log entries
			// so find based on composite key
			var localBattlelogEntry = battlelogCollection.findWhere({
				userId: battleLogEntry.userId,
				gameId: battleLogEntry.gameId,
				start: battleLogEntry.start,
				end: battleLogEntry.end
			});

			if (!localBattlelogEntry) {
				battlelogCollection.add(battleLogEntry, {at: 0});
			}
		}
	});

	websocket.on('presence', function (presence) {
		var deferred = KXL.request('current:user:friend:entities');
		var collection = deferred._collection;
		if (!collection.fetchComplete) {
			collection.sortOnFetchComplete = true;
		}
		deferred.then(function () {
			var friend = collection.get(presence.userId);
			if (friend) {
				friend.set('presence', presence.status);
			}
		});
	});

	websocket.on('chat', function (notification) {
		var chatInterface = KXL.request('get:chat:interface');
		if (chatInterface.isConnected()) {
			chatInterface._onNotification(notification);
		}
	});

	// Sent after the game has attempted to grant the payout a player for a Payments Hub purchase
	websocket.on('purchasePayout', function (payout) {
		KXL.dialogRegion.closeDialog();

		// Payout.status will be either "success" or "error"
		KXL.vent.trigger('payout:'.concat(payout.status), payout);
	});

	// Sent when a payment session begins initializing with Payments Hub
	websocket.on('paymentsHubStart', function(details) {
		var context = KXL.appModel.get('context');
		if (context.get('id') != details.appId) {
			return;
		}

		KXL.vent.trigger('payment:paymentshub:start');
	});
}

KXL.on('initialize:before', function (options) {
	KXL.configBaseInitializeBefore(options);
	// Config options for the environment.
	KXL.config.autoRegCommonNames = options.autoRegCommonNames;
	// Is the user coming from Facebook for the forums
	// Set the show games to false if they are
	KXL.config.SHOW_GAMES = options.showGames;
	// Faux zookeeper switches
	KXL.switches = options.switches;
	KXL.config.chatFlashInterval = options.chatFlashInterval;
	KXL.config.SUPPORT_URL = 'https://www.kixeye.com/support/';
	// The forum URL (inside the frame)
	KXL.config.FORUM_URL = options.forumUrl;
	// Site Content URL
	KXL.config.SITE_CONTENT_URL = options.siteContentUrl;
	// Google API Client ID for OAuth2
	KXL.config.GAPI_CLIENT_ID = options.gapiClientId;
	KXL.config.braintreeJavascriptUrl = options.braintreeJavascriptUrl;
	KXL.config.braintreeEncryptionKey = options.braintreeEncryptionKey;
	KXL.config.braintreeMerchantId = options.braintreeMerchantId;
	KXL.config.braintreeKountMerchantId = options.braintreeKountMerchantId;
	KXL.config.warcommander = {};
	KXL.config.warcommander.guide = {};
	KXL.config.warcommander.guide.url = options.warCommanderGuideUrl;
	KXL.config.warcommander.guide.path = options.warCommanderGuidePath;
	KXL.config.battlepirates = {};
	KXL.config.battlepirates.guide = {};
	KXL.config.battlepirates.guide.url = options.battlePiratesGuideUrl;
	KXL.config.battlepirates.guide.path = options.battlePiratesGuidePath;
	// Legal Site URL (TOS, Privacy Policy, Cookie Policy etc.)
	KXL.config.LEGAL_URL = options.legalUrl;
	// Email Unsub URL
	KXL.config.unsubEmailLink = options.unsubEmailLink;
	// Captcha Config
	KXL.config.RECAPTCHA_PUBLIC_KEY = options.recaptchaPublicKey;
	// Ayah event origin
	KXL.config.AYAH_EVENT_ORIGIN = options.ayahEventOrigin;
	// Inbox Messages User Suffix
	KXL.config.USER_MESSAGE_SUFFIX = options.userMessageSuffix;
	KXL.config.chatHistoryLimit = 500;
	KXL.config.staticGames = options.staticGames;
	KXL.config.groupGames = options.groupGames;
	KXL.config.avatarPresets = options.avatarPresets;
	KXL.config.adWords = JSON.parse(options.adWords);
	KXL.config.hashChange = options.hashChange;
	KXL.config.xsollaSandbox = options.xsollaSandbox;
	KXL.webGames = KXL.request('webgames:entities', KXL.games);
	KXL.pagesMeta = KXL.request('page:meta:entities', options.pagesMeta);
	KXL.config.googleAnalyticsTagID = options.googleAnalyticsTagID;
	KXL.config.includeGoogleTags = options.includeGoogleTags;
	KXL.config.googleAdsTagIDs = JSON.parse(options.googleAdsTagIDs);
	KXL.config.googleAdsConversionIDs = JSON.parse(options.googleAdsConversionIDs);
	KXL.config.includeFacebookPixel = options.includeFacebookPixel;
	KXL.config.facebookPixelID = options.facebookPixelID;
	KXL.config.facebookPixelEventNames = JSON.parse(options.facebookPixelEventNames);

	KXL.config.notificationSettings = [
		{
			settingId: 'showGroupInviteNotifications',
			filteredNotificationTypes: [
				'group-invite-added'
			]
		}, {
			settingId: 'showGroupAcceptedApplicationNotifications',
			filteredNotificationTypes: [
				'group-applicant-added',
				'group-applicant-accepted',
				'group-new-member'
			]
		}, {
			settingId: 'showGroupPromotionDemotionNotifications',
			filteredNotificationTypes: [
				'group-member-promoted',
				'group-member-demoted',
				'group-member-left',
				'group-member-booted'
			]
		}, {
			settingId: 'showAllGroupPostNotifications',
			filteredNotificationTypes: [
				'group-discussion-comment',
				'group-discussion-like',
				'group-updated',
				'group-new-discussion'
			]
		}, {
			settingId: 'showWallPosts',
			filteredNotificationTypes: [
				'notif-wall'
			]
		}, {
			settingId: 'showAttackNotifications',
			filteredNotificationTypes: [
				'notif-underattack',
				'urgent'
			]
		}, {
			settingId: 'showGiftNotifications',
			filteredNotificationTypes: [
				'gift-sent'
			]
		}, {
			settingId: 'showGameInviteNotifications',
			filteredNotificationTypes: [
				'invite'
			]
		}, {
			settingId: 'showGameUpdateNotifications',
			filteredNotificationTypes: [
				'game-update'
			]
		}, {
			settingId: 'showForumUpdatesToBookmarkedThread',
			filteredNotificationTypes: [
				'notif-thread-replied-bookmarked'
			]
		}, {
			settingId: 'showForumUpdatesToCreatedThread',
			filteredNotificationTypes: [
				'notif-thread-replied-created',
				'notif-thread-replied',
				'notif-thread'
			]
		}, {
			settingId: 'showForumUpdatesToRepliedThread',
			filteredNotificationTypes: [
				'notif-thread-replied-replied'
			]
		}, {
			settingId: 'showForumUpdatesToQuotedThread',
			filteredNotificationTypes: [
				'notif-thread-quoted'
			]
		}, {
			settingId: 'showForumUpdatesToVotedThread',
			filteredNotificationTypes: [
				'notif-thread-voted'
			]
		}
	];
	setWebsocketListeners();
	if (options.currentUser) {
		KXL.appModel.set(
			'userSettings',
			KXL.request('new:user:settings:entity', options.currentUser.settings)
		);
		delete options.currentUser.settings;

		KXL.currentUser = KXL.request('new:user:entity', options.currentUser);
		KXL.appModel.set('currentUser', KXL.currentUser);

		// TODO rtomaselli: workaround for the user/games end point returning user games for which there
		// is no games list record. We can remove once this issue is resolved.
		var sanitizedUserGames = [];
		_.each(options.userGames, function (userGame) {
			if (KXL.games.get(userGame.gameId)) {
				sanitizedUserGames.push(userGame);
			}
		});
		KXL.currentUserGames =	new KXL.Entities.UserGamesCollection(
			sanitizedUserGames,
			{ userId: KXL.currentUser.id }
		);
		if (KXL.currentUserGames.length) {
			KXL.execute('context:set', KXL.games.get(KXL.currentUserGames.first().id));
		}

	} else {
		KXL.appModel.set(
			'userSettings',
			KXL.request('new:user:settings:entity', {})
		);
		KXL.currentUser = null;
		KXL.currentUserGames = null;
	}

	KXL.appModel.on('change:currentUser', function () {
		function triggerAppResetEvents () {
			KXL.vent.trigger('app:reset');
			KXL.vent.trigger('auth:changed');
		}
		var currentUser = KXL.appModel.get('currentUser');
		// Reset the app when the user transitions between status
		// for example 'temp' to 'active'
		if (currentUser) {
			currentUser.on('change:status', triggerAppResetEvents);
		}
	});

	KXL.appModel.set(
		'supportedLocales',
		KXL.request('new:locales:entity', KXL.config.supportedLocales)
	);

	KXL.config.animationCapabilities = true;
	KXL.config.browser = options.browser;
	KXL.config.platform = options.platform;

	if (options.browser.name === 'Internet Explorer' && parseFloat(options.browser.version) < 10) {
		KXL.config.animationCapabilities = false;
	}

	KXL.animate = new KXL.Animations.Tweening({
		animationCapabilities: KXL.config.animationCapabilities,
		easingStyle: "Power2.easeInOut",
		animationOverlap: "-=.75"
	});

	KXL.staticGames = KXL.request('staticgames:entities');

	KXL.addRegions({
		headerRegion: '#header-region',
		homeRegion: '#home-region',
		gameRegion: '#game-region',
		leaderboardRegion: '#leaderboard-region',
		playRegion: '#play-region',
		inboxRegion: '#inbox-region',
		forumRegion: '#forum-region',
		guideRegion: '#guide-region',
		profileRegion: '#profile-region',
		settingsRegion: '#settings-region',
		sidebarRegion: '#sidebar-region',
		landingPageRegion: '#landing-page-region',
		landingPageV2Region: '#landing-page-v2-region',
		footerRegion: '#footer-region',
		errorRegion: '#error-region',
		groupRegion: '#group-region',
		cookieNoticeRegion: '#cookie-notice-region',
		dialogRegion: KXL.Components.DialogRegion.extend({ el: '#dialog-region' })
	});
	KXL.appModel.set('pages', new KXL.Entities.SingleSelectCollection([
		{
			id: 'home',
			region: KXL.homeRegion,
			name: 'home',
			hideOnlineCounter: true
		},
		{
			id: 'profile',
			region: KXL.profileRegion,
			name: 'profile'
		},
		{
			id: 'settings',
			region: KXL.settingsRegion,
			name: 'settings'
		},
		{
			id: 'error',
			region: KXL.errorRegion,
			name: 'error'
		},
		{
			id: 'game',
			region: KXL.gameRegion,
			name: 'game'
		},
		{
			id: 'leaderboard',
			region: KXL.leaderboardRegion,
			name: 'leaderboard'
		},
		{
			id: 'play',
			region: KXL.playRegion,
			hideByOffset: true,
			hideFooter: true,
			hideOnlineCounter: true,
			name: 'play'
		},
		{
			id: 'landing',
			region: KXL.landingPageRegion,
			name: 'landing',
			hideHeader: true,
			hideFooter: true,
			hideOnlineCounter: true
		},
		{
			id: 'landingv2',
			region: KXL.landingPageV2Region,
			name: 'landingv2',
			hideFooter: false,
			hideOnlineCounter: true
		},
		{
			id: 'forum',
			region: KXL.forumRegion,
			name: 'forum'
		},
		{
			id: 'inbox',
			region: KXL.inboxRegion
		},
		{
			id: 'guide',
			region: KXL.guideRegion,
			name: 'guide'
		}
	]));
	KXL.appModel.get('pages').on("select:one", function (selectedModel) {
		if (selectedModel) {
			var regionToShow = selectedModel.get('region');
			if (regionToShow) {
				regionToShow.ensureEl();
				if (selectedModel.get('hideByOffset')) {
					regionToShow.$el.removeClass('kx-offset-hide');
				}
				regionToShow.$el.show();
			}
		}
	});
	KXL.appModel.get('pages').on("deselect:one", function (deselectedModel) {
		if (deselectedModel) {
			var regionToHide = deselectedModel.get('region');
			if (regionToHide) {
				regionToHide.ensureEl();
				if (deselectedModel.get('hideByOffset')) {
					regionToHide.$el.addClass('kx-offset-hide');
				} else {
					regionToHide.$el.hide();
				}
			}
		}
	});

	KXL.module('FooterApp').start();
	KXL.execute('sidebar:initialize');
	KXL.execute('chat:initialize');
});

KXL.on('initialize:after', function (options) {
	if (Backbone.history) {
		Backbone.history.start({
			pushState: true,
			hashChange: KXL.config.hashChange
		});
		KXL.animate.animateHeaderMenu();
	}
	if (!KXL.config.SHOW_GAMES) {
		KXL.navigate('');
	}
	if (options.error) {
		KXL.navigate('/' + options.error, { trigger: true });
	}
	KXL.vent.on('auth:changed', function () {
		KXL.navigate(KXL.getCurrentRoute(), { trigger: true });
		KXL.execute('sidebar:check');
	});
	KXL.vent.on('completed:deferred:registration', function () {
		// Hide the banner
		KXL.request('banner:hide', 'delayedRegBanner', true);
	});
	if (KXL.currentUser && KXL.appModel.has('userSettings')) {
		KXL.execute('sidebar:check');
	}

	KXL.vent.on('forum:category:changed', function (forumCategory) {
		KXL.appModel.set('currentForumCategory', forumCategory);
	});

	KXL.vent.on('document:update:title', function (title, path) {
		var uri = window.location.pathname,
		uriArray = uri.split('/'),
		titlePrefix = 'KIXEYE - ',
		documentTitle;

		// Strip trailing slash if there is one
		if(uri.substr(-1) == '/') {
			uri = uri.substr(0, uri.length - 1);
		}

		// Title update from forum and no path, block it.
		if (uriArray[1] === 'forum' && !path) {
			return;
		}

		if (title) {

			documentTitle = titlePrefix + title;
			// save it back to the collection so if say it's a title that
			// is being set from forums and then the user navigates to another
			// page and back again we display the correct title upon return.
			KXL.pagesMeta.add({
				uri: uri,
				title: title
			});

		} else if (KXL.pagesMeta.get(uri)) {

			// get title by uri
			if (KXL.pagesMeta.get(uri).has('title')) {
				documentTitle = KXL.pagesMeta.get(uri).get('title');
			} else {
				documentTitle = KXL.i18n.t(KXL.pagesMeta.get(uri).get('titleKey'));
			}

			documentTitle = titlePrefix + documentTitle;

		} else if (uriArray[1] === 'profile') {

			documentTitle = titlePrefix + KXL.i18n.t('page.title.profile');

		} else {

			// set title to default sitename
			documentTitle = KXL.config.siteName;

		}

		// Replace history state
		if (window.history && window.history.pushState) {
			window.history.replaceState({}, documentTitle, window.location.href);
		}

		// Set document title
		document.title = documentTitle;
	});

	$(window).bind("beforeunload", function () {
		if (KXL.request('auth:is:temp:user')) {
			var context = KXL.appModel.get('context');
			if (context && context.get('name')) {
				return KXL.i18n.t('games.exitMessage', { gameName: context.get('name') });
			}
		}
	});
});

var API = {
	showDialog: function (view, options) {
		var dlg = new KXL.Components.Dialog(options);
		KXL.appModel.set('dialogShowing', true);
		dlg.listenTo(dlg, 'show', function () {
			dlg.setContent(view);
		});
		dlg.listenTo(dlg, 'close', function () {
			KXL.appModel.set('dialogShowing', false);
		});
		KXL.dialogRegion.show(dlg);
		return dlg;
	},
	showConfirmDialog: function (options) {
		var dlg = new KXL.Components.ConfirmDialog(options);
		KXL.appModel.set('dialogShowing', true);
		dlg.listenTo(dlg, 'close', function () {
			KXL.appModel.set('dialogShowing', false);
		});
		KXL.dialogRegion.show(dlg);
		return dlg;
	},
	ignoreUser: function (userId) {
		var deferred = $.Deferred();
		if (KXL.currentUser) {
			var url = KXL.config.API_URL + '/users/' + KXL.currentUser.id + '/ignored-users';
			var ignoredUserPost = { userId: userId };

			$.ajax({
				type: "POST",
				url: url,
				contentType: "application/json",
				data: JSON.stringify(ignoredUserPost),
				success: function () {
					var ignoredUsers = KXL.currentUser.get('ignoredUsers') || [];
					ignoredUsers = _.union(ignoredUsers, [userId]);
					KXL.currentUser.set('ignoredUsers', ignoredUsers);
					KXL.execute('fetch:received:friend:requests');
					return deferred.resolve();
				}
			}).fail(deferred.reject);
		}
		else {
			deferred.reject();
		}
		return deferred;
	},

	unignoreUser: function (userId) {
		var deferred = $.Deferred();
		if (KXL.currentUser) {
			var url = KXL.config.API_URL + '/users/' + KXL.currentUser.id + '/ignored-users/' + userId;
			$.ajax({
				type: "DELETE",
				url: url,
				dataType: "json",
				success: function () {
					var ignoredUsers = KXL.currentUser.get('ignoredUsers') || [];
					ignoredUsers = _.difference(ignoredUsers, [userId]);
					KXL.currentUser.set('ignoredUsers', ignoredUsers);
					KXL.execute('fetch:received:friend:requests');
					return deferred.resolve();
				}
			}).fail(deferred.reject);
		}
		else {
			deferred.reject();
		}
		return deferred;
	},

	isUserIgnored: function (userId) {
		if (KXL.currentUser) {
			var ignoredUsers = KXL.currentUser.get('ignoredUsers') || [];
			return _.contains(ignoredUsers, userId);
		}
		return false;
	},

	setLocale: function (locale) {
		// #1, Remove any existing cookies at the current path
		if (KXL.Common.Utils.CookieUtils.getCookie('kxl-locale')) {
			KXL.Common.Utils.CookieUtils.setCookie('kxl-locale', '', -1, true);
		}

		// #2, Drop a new cookie with the locale at the root, so can be referenced throughout this session
		KXL.Common.Utils.CookieUtils.setCookie('kxl-locale', locale, 365, true, '/', null, 'None');

		function reloadApp() {
			// #3, refresh the page, to load the new localized files
			window.location.reload();
		}
		if (KXL.currentUser) {
			// #3, save the user setting
			var currentUserSettings = KXL.request('current:user:settings:entity');
			currentUserSettings.set('locale', locale);

			currentUserSettings.save(null, {
				success: reloadApp,
				error: reloadApp
			});
		} else {
			reloadApp();
		}
	}
};

var fetchingCurrentFriends;
var fetchingCurrentSentFriendRequests;
var fetchingCurrentReceivedFriendRequests;
KXL.appModel.on('change:currentUser', function (app, currentUser) {
	var userSettings = KXL.request('current:user:settings:entity');
	if (currentUser) {

		KXL.request('user:settings:entity').done(function (entity) {
			userSettings.clear().set(entity.toJSON());
			// check once we have all the user's settings.
			KXL.execute('sidebar:check');
		});

		var currentUserId = KXL.currentUser.id;
		var defer = $.Deferred();
		var _fetchPagedFriends = function (options) {
			var defaults = {
				limit : 100,
				expandGames: true,
				presence: 'available'
			},
			promise = defer.promise(),
			opts = _.defaults({}, options, defaults),
			getNextPage = function (opts) {
				promise._collection.trigger('reset');
				return _fetchPagedFriends(opts);
			},
			userFriend = KXL.request(
					'user:friend:entities',
					currentUserId,
					opts
				);
			userFriend.then(function (pagedCollection) {
				promise._collection.add(pagedCollection.models, {merge: true, silent: true});
				if (KXL.request('get:user:friend:nextPageOffset:entity', currentUserId)) {
					return getNextPage(opts);
				} else if (opts.presence === 'available' && pagedCollection.length < opts.limit) {
					opts.presence = null;
					return getNextPage(opts);
				}
				promise._collection.fetchComplete = true;
				promise._collection.trigger('reset');
				defer.resolve(promise._collection);
			});
			if (!promise._collection) {
				promise._collection = userFriend._collection;
			}
			return promise;
		};

		var _fetchSentFriendRequests = function () {
			if (!fetchingCurrentSentFriendRequests) {
				fetchingCurrentSentFriendRequests = KXL.request('user:friend:request:entities', KXL.currentUser.id, { sent: true, received: false });
			}
			else {
				fetchingCurrentSentFriendRequests._collection.fetch();
			}
			KXL.appModel.set('sentFriendRequests', fetchingCurrentSentFriendRequests._collection);
		};

		var _fetchReceivedFriendRequests = function () {
			if (!fetchingCurrentReceivedFriendRequests) {
				fetchingCurrentReceivedFriendRequests = KXL.request(
					'user:friend:request:entities',
					KXL.currentUser.id,
					{
						sent: false,
						received: true,
						expandGames: true
					}
				);
			}
			else {
				fetchingCurrentReceivedFriendRequests._collection.fetch();
			}
			KXL.appModel.set('receivedFriendRequests', fetchingCurrentReceivedFriendRequests._collection);
		};

		fetchingCurrentFriends = _fetchPagedFriends();

		KXL.commands.setHandlers({
			'fetch:sent:friend:requests': function () {
				_fetchSentFriendRequests();
			},
			'fetch:received:friend:requests': function () {
				_fetchReceivedFriendRequests();
			}
		});
		KXL.vent.on('user:friend:request:sent', function () {
			_fetchSentFriendRequests();
		});
		KXL.appModel.set('friends', fetchingCurrentFriends._collection);

		$('body').addClass('kx-logged-in');
		function setUpUserRoles() {
			var defer = $.Deferred();
			var userRoles = new KXL.Entities.UserRoles(null, {userId: currentUser.id});
			userRoles.fetch({
				success: function (currentUserRoles) {
					defer.resolve(currentUserRoles);
				}
			});
			return defer;
		}
		KXL.currentUserRoles = setUpUserRoles();

	} else {

		userSettings.clear().set(userSettings.defaults);
		KXL.appModel.set('friends', null);

		$('body').removeClass('kx-logged-in');

	}
});

KXL.reqres.setHandlers({
	'current:user:friend:entities': function () {
		return fetchingCurrentFriends;
	},
	'context:change': function (context) {
		var defer = $.Deferred(),
			currentContext = KXL.appModel.get('context');
		function setContext(context) {
			if (context !== KXL.context) {
				// Clear out all of the pages, new context!
				KXL.appModel.get('pages').each(function (page) {
					page.set('route', '');
					page.get('region').reset();
				});

				KXL.appModel.set('context', context);
			}
		}
		if (currentContext && currentContext !== context && KXL.request('user:playing')) {
			KXL.asyncRequest('header:context:change:dialog', currentContext, context).then(function () {
				// User confirmed that they would like to switch contexts...
				setContext(context);
				defer.resolve();
			}).fail(function () {
				defer.reject();
			});
		} else {
			setContext(context);
			defer.resolve();
		}
		return defer.promise();
	},
	'context:get': function () {
		return KXL.appModel.get('context');
	},
	'user:playing': function () {
		return !!(KXL.playRegion.currentView);
	},
	'user:playing:active': function () {
		return KXL.request('user:playing') && KXL.request('get:current:page') === 'play';
	},
	'current:user:settings:entity': function () {
		return KXL.appModel.get('userSettings');
	},
	'current:user:sent:friend:request:entities': function () {
		if (!fetchingCurrentSentFriendRequests) {
			fetchingCurrentSentFriendRequests = KXL.request('user:friend:request:entities', KXL.currentUser.id, { sent: true, received: false });
		}
		KXL.appModel.set('sentFriendRequests', fetchingCurrentSentFriendRequests._collection);
		return fetchingCurrentSentFriendRequests;
	},
	'current:user:received:friend:request:entities': function () {
		if (!fetchingCurrentReceivedFriendRequests) {
			fetchingCurrentReceivedFriendRequests = KXL.request(
				'user:friend:request:entities',
				KXL.currentUser.id,
				{
					sent: false,
					received: true,
					expandGames: true
				}
			);
		}
		KXL.appModel.set('receivedFriendRequests', fetchingCurrentReceivedFriendRequests._collection);
		return fetchingCurrentReceivedFriendRequests;
	},
	'current:user:remove:friend': function (userId) {
		var friendsCollection = KXL.request('current:user:friend:entities')._collection;
		if (friendsCollection && friendsCollection.get(userId)) {
			var friend = friendsCollection.get(userId);
			KXL.request('decrement:service:friends:count', KXL.currentUser.id);
			KXL.request('decrement:service:friends:count', userId);
			friend.isNew = function () { return false; }; //Trick BB to DELETE
			friend.destroy();
			KXL.execute('fetch:sent:friend:requests');
		}
	},
	'get:current:user': function () {
		return KXL.currentUser;
	},
	'current:user:game:entities': function () {
		return KXL.currentUserGames;
	},
	'is:static:game': function (gameId) {
		var game = KXL.games.get(gameId);
		return game && KXL.staticGames.findWhere({ commonName: game.get('commonName') });
	},
	'current:user:achievements:entities': function () {
		if (KXL.currentUser) {
			return KXL.currentUser.get('achievements');
		}
		return null;
	},
	'get:current:page': function () {
		var selected = KXL.appModel.get('pages').selected;
		if (selected) {
			return selected.id;
		}
		return null;
	},
	'new:game:selector:view': function (options) {
		return new KXL.Components.GameSelector.Controller.getView(options);
	},
	'new:video:view': function (options) {
		return KXL.Components.Video.Controller.getVideo(options);
	},
	'new:contentpane:view': function (options) {
		return KXL.Components.ContentPane.Controller.getContentPane(options);
	},
	'new:game:logo:view': function (gameId, options) {
		// TODO: Make games a Component
		return KXL.HomeApp.Games.Controller.getLogoView(gameId, options);
	},
	'new:ticker:view': function () {
		return KXL.Components.Ticker.Controller.getTicker();
	},
	'new:playersonline:view': function () {
		return KXL.Components.PlayersOnline.Controller.getPlayersOnline();
	},
	'new:pager:view': function (items) {
		return KXL.Components.Pager.Controller.getPager(items);
	},
	'new:gameprofileheader:view': function (userGame) {
		return KXL.Components.UserGameProfile.Controller.getGameProfileHeader(userGame);
	},
	'new:gameprofile:view': function (userGame) {
		return KXL.Components.UserGameProfile.Controller.getGameProfile(userGame);
	},
	'new:leaderboard:view': function (options) {
		return new KXL.Components.Leaderboard.Controller.getLeaderboard(options);
	},
	'new:locale:selector:view': function (options) {
		return new KXL.Components.LocaleSelector.Controller.getView(options);
	},
	'new:comments:view': function (options) {
		return new KXL.Components.Comments.Controller.getComments(options);
	},
	'show:dialog': function (view, options) {
		return API.showDialog(view, options);
	},
	'show:default:dialog': function (view, options) {
		var opts = _.extend({ customClassName: 'kxl-dialog' }, options);
		return API.showDialog(view, opts);
	},
	'show:confirm:dialog': function (options) {
		var opts = _.extend({ customClassName: 'kxl-dialog', size: 'small'}, options);
		return API.showConfirmDialog(opts);
	},
	'ignore:user': function (userId) {
		KXL.request('current:user:remove:friend', userId);
		return API.ignoreUser(userId);
	},
	'unignore:user': function (userId) {
		return API.unignoreUser(userId);
	},
	'is:user:ignored': function (userId) {
		return API.isUserIgnored(userId);
	},
	'get:client:initiated:password:change': function () {
		return KXL.atom.get('clientInitiatedPasswordChange') || false;
	},
	'set:client:initiated:password:change': function (value) {
		return KXL.atom.set('clientInitiatedPasswordChange', value);
	},
	'chat:start': function () {
		var deferred = $.Deferred();

		KXL.asyncRequest('chat:show').then(function () {
			var settingsUpdate = {
					'sideBarState': 'chat'
				},
				currentSettings = KXL.request('current:user:settings:entity'),
				connectHandler,
				connectFailHandler,
				chatInterface = KXL.request('get:chat:interface');

			KXL.appModel.set('sideBarState', 'chat');
			if (chatInterface.isConnected()) {
				deferred.resolve();
				return deferred.promise();
			} else {
				connectFailHandler = function () {
					KXL.stopListening(chatInterface, 'connected', connectHandler);
					deferred.reject();
				};
				connectHandler = function() {
					KXL.stopListening(chatInterface, 'connection:failed', connectFailHandler);
					deferred.resolve();
				};
				KXL.listenToOnce(chatInterface, 'connected', connectHandler);
				KXL.listenToOnce(chatInterface, 'connection:failed', connectFailHandler);

				settingsUpdate['showOnlineStatusAs'] = 'online';
			}
			currentSettings.save(settingsUpdate, {
				silent: true
			});
			_.each(settingsUpdate, function (value, attr) {
				if (attr !== 'sideBarState') {
					currentSettings.trigger('change:' + attr, currentSettings, value);
				}
			});
		});

		return deferred.promise();
	}
});

KXL.commands.setHandlers({
	/**
	 * This should not be used unless you are handling all the 'current:user:friend:entities' changing logic
	 *
	 * Use context:change to have that logic handled for you
	 *
	 * @param context For the time being, a game model
	 */
	'context:set': function (context) {
		KXL.appModel.set('context', context);
	},
	'set:locale': function (locale) {
		API.setLocale(locale);
	},
	'page:show': function (pageId) {
		// Store the current scroll position of the current page
		var selected = KXL.appModel.get('pages').selected;
		if (selected) {
			selected.set('scrollPosition', $(document).scrollTop());
		}
		var targetPage = KXL.appModel.get('pages').get(pageId);
		if (targetPage) {
			var currentRoute = KXL.getCurrentRoute();
			targetPage.set('route', currentRoute);
			targetPage.select();
			// Assert the scroll position to the stored value for this page
			window.scrollTo(0, targetPage.get('scrollPosition') || 0);

			if (targetPage.get('hideFooter')) {
				KXL.execute('footer:hide');
			} else {
				KXL.execute('footer:show');
			}
			if (targetPage.get('hideHeader')) {
				KXL.execute('header:hide');
			} else {
				KXL.execute('header:show');
			}
			if (targetPage.get('hideOnlineCounter')) {
				KXL.execute('playersonline:hide');
			} else {
				KXL.execute('playersonline:show');
			}
		}
		KXL.vent.trigger('page:shown');
	},
	'page:close': function (pageId) {
		var targetPage = KXL.appModel.get('pages').get(pageId);
		if (targetPage) {

			if (pageId === 'game') {
				KXL.atom.set('game:isopen', false);
			}
			targetPage.get('region').close();
		}
	},
	'chat:start:private': function (userId, message) {
		KXL.asyncRequest('chat:start').then(function () {
			KXL.execute('chat:start:conversation:private', userId, message);
		});
	},
	'chat:start:group': function (userIds) {
		KXL.asyncRequest('chat:start').then(function () {
			KXL.execute('chat:start:conversation', userIds);
		});
	},

	// Send the player directly to the game or display auth modal if they are not logged in
	'play:now:clicked': function(gameUrlName) {
		if (KXL.currentUser) {
			KXL.execute('game:play:load', gameUrlName);
			return;
		}

		KXL.asyncRequest('async:load:module', 'common/components/auth_modal').then(function() {
			KXL.request('show:dialog', KXL.request('new:auth_modal:view', gameUrlName), {center: true});
		});
	}
});

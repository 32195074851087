KXL.module('ProfileApp.Profile', function(Profile, KXL, Backbone, Marionette, $, _) {
	Profile.LoggedInLayout = KXL.Components.Layout.extend({
		template: '#profile-template',
		className: 'kx-profile',
		regions: {
			userInfo: '.kx-profile-user-info-region',
			friends: '.kx-profile-friends-region',
			gameStats: '.kx-profile-game-stats',
			addFriend: '.kx-profile-add-friend-region',
			activity: '.kx-profile-activity'
		}
	});
});
KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {
	Entities.DelayedRegistration = Entities.Model.extend({
		defaults: {
			username: '',
			email: '',
			password: ''
		},
		url: '/api/auth/autoregister/convert',
		validation: {
			username: {
				required: true,
				rangeLength: [6, 20],
				msg: 'Error message'
			},
			email: {
				required: true,
				fn: function (value, attr, customValue, model) {
					if (!KXL.Common.Utils.PatternUtils.validateEmail(value)) {
						return KXL.i18n.t('settings.emailValidationMessage');
					}
				}
			},
			password: {
				required: true,
				minLength: 6,
				maxLength: 20
			}
		}
	});
});

KXL.module('GamesApp.Background', function (Background, KXL, Backbone, Marionette, $, _) {
	Background.Controller = {
		getBackground: function (options) {
			return new Background.View(options);
		},
		getBackgroundCMS: function (options) {
			return new Background.CMSView(options);
		}
	};
});

KXL.module('Common.Utils.Url', function (Url, KXL, Backbone, Marionette, $, _) {
	Url.isRelative = function (testUrl, host) {
		if (!host) {
			host = window.location.host;
		}
		var parts = this.parse(testUrl);
		var parts2 = this.parse(parts.href);
		return parts2.host === host;
	},
	Url.parse = function (url) {
		var a = document.createElement('a');
		a.href = url;
		return {
			source: url,
			protocol: a.protocol.replace(':',''),
			host: a.hostname,
			port: a.port,
			href: a.href,
			query: a.search,
			params: (function(){
				var ret = {},
					seg = a.search.replace(/^\?/,'').split('&'),
					len = seg.length, i = 0, s;
				for (;i<len;i++) {
					if (!seg[i]) { continue; }
					s = seg[i].split('=');
					ret[s[0]] = s[1];
				}
				return ret;
			})(),
			file: (a.pathname.match(/\/([^\/?#]+)$/i) || [,''])[1],
			hash: a.hash.replace('#',''),
			path: a.pathname.replace(/^([^\/])/,'/$1'),
			relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [,''])[1],
			segments: a.pathname.replace(/^\//,'').split('/')
		};
	};

	/**
	 * Build a new URL for the current host (and protocol) using the new path.
	 *
	 * @param  string path A path fragment which may or may not start with a slash e.g '/payment/start'
	 * @return string a full URL e.g. 'https://kixeye.com/payment/start'
	 */
	Url.build = function(path) {
		var host = window.location.protocol + '//' + window.location.hostname;
		var addSlash = path && path.charAt(0) !== '/';
		return host + (addSlash ? '/' : '') + (path ? path : '');
	};
});
KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {
	Entities.Collection = Backbone.Collection.extend({
		model: Entities.Model,
		constructor: function () {
			var self = this;
			this.fetched = 0;
			this.listenTo(this, 'sync', function () {
				self.fetched = new Date().getTime();
			});
			return Backbone.Collection.prototype.constructor.apply(this, arguments);
		},
		// return next model or the beginning if at
		// the end and wrap is turned on
		next: function(model, wrap) {
			var next = this.indexOf(model) + 1;
			var size = _.size(this);
			if (wrap) {
				next = next % size;
			}
			if (next >= size) {
				next = size - 1;
			}
			return this.at(next);
		},
		// return the previous model or the end if at
		// the beginning and wrap is turned on
		prev: function(model, wrap) {
			var next = this.indexOf(model) - 1;
			if (wrap) {
				next = next > -1 ? next : _.size(this) - 1;
			} else {
				next = next > -1 ? next : 0;
			}
			return this.at(next);
		}
	});
	Entities.SingleSelectCollection = Entities.Collection.extend(_.omit(Backbone.Picky.SingleSelect.prototype, 'constructor'));
});

KXL.module('ProfileApp.ActivityFeed', function(ActivityFeed, KXL, Backbone, Marionette, $, _) {
	/*
	 * Activity feed empty view
	 */
	ActivityFeed.EmptyView = KXL.Components.ItemView.extend({
		template: Templates['profile/activity_feed/activity-feed-empty'],
		tagName: 'li',
		className: 'kx-feed-empty'
	});

	/*
	 * Activity feed loading view
	 */
	ActivityFeed.LoadingView = KXL.Components.ItemView.extend({
		template: Templates['profile/activity_feed/activity-feed-loading'],
		tagName: 'li',
		className: 'kx-feed-loading'
	});

	/*
	 * Activity feed permissions denied view
	 */
	ActivityFeed.PermissionsView = KXL.Components.ItemView.extend({
		template: Templates['profile/activity_feed/activity-feed-permission-denied'],
		tagName: 'li',
		className: 'kx-feed-permissions'
	});

	/*
	 * Activity feed item base view
	 */
	ActivityFeed.FeedItemView = KXL.Components.Layout.extend({
		tagName: 'li',
		className: 'feed-item',
		comments: null,
		commentInput: null,
		entityId: null,
		regions: {
			thumbnailRegion: '.kx-feed-item-thumbnail',
			commentsRegion: '.kx-feed-item-comments',
			itemContent: '.kx-feed-item-content'
		},
		ui: {
			'itemTime': '.kx-feed-item-time',
			'itemOptions': '.kx-feed-item-delete',
			'commentBtn': '.kx-feed-item-comment-btn',
			'feedItemUsername': '.kx-feed-item-username',
			'feedItemDelete': '.kx-feed-item-delete'
		},
		events: {
			'click @ui.feedItemUsername': 'routeToUserProfile',
			'click @ui.commentBtn' : 'showComments',
			'mouseover @ui.feedItemDelete': 'itemOver',
			'mouseover' : 'itemOver',
			'mouseout' : 'itemOut'
		},
		triggers: {
			'click @ui.feedItemDelete': 'hide:post'
		},
		routeToUserProfile: function (e) {
			KXL.execute('profile:show', $(e.currentTarget).attr('id'));
		},
		initialize: function () {
			if (this.model.get('object') && this.model.get('object').entityId) {
				this.entityId = this.model.get('object').entityId;
			}
		},
		_renderComments: function () {
			var commentCollection = this.model.get('object').comments;

			this.comments = KXL.request('new:comments:view', {
				collection: commentCollection,
				canManage: KXL.currentUser.id === this.options.userId,
				canComment: !KXL.request('auth:is:temp:user'),
				showNoCommentingCTALink: true,
				commentOwnerCanManage: true,
				inputCloseOnOtherInputOpen: true,
				inputStartExpanded: this.model.get('object').comments.length
			});

			this.listenTo(this.comments, 'comments:contracted', function () {
				this.ui.commentBtn.show();
			});

			this.commentsRegion.show(this.comments);

			if (!commentCollection.length) {
				this.ui.commentBtn.show();
			}
		},
		onRender: function () {
			var self = this;
			this.$el.addClass('kx-clearfix');
			// If the user doesn't have permission to delete the feed item hide the x button.
			if (this.model.get('actor') &&
				KXL.currentUser.id !== this.model.get('actor').entityId &&
				!this.options.currentUserFeed) {
					this.ui.itemOptions.hide();
			}
			if (this.model.get('object') &&
				this.model.get('object').entityType === 'note') {
				this._renderComments();
			}
			this.ui.itemTime.timeago({locale: KXL.config.locale});
		},
		itemOver: function () {
			TweenLite.killTweensOf(this.ui.itemOptions);
			TweenLite.to(this.ui.itemOptions, 0.5,
				{
					opacity: 1,
					clearProps: 'transform'
				});
		},
		itemOut: function () {
			TweenLite.to(this.ui.itemOptions, 0.5,
				{
					opacity: 0,
					clearProps: 'transform'
				});
		},
		addComment: function (commentData) {
			this.comments.trigger('append:comment', commentData);
		},
		showComments: function () {
			this.comments.trigger('show:comment:input');
			this.ui.commentBtn.hide();
		}
	});

	/*
	 * Activity feed from app view
	 */
	ActivityFeed.FeedItemFromAppView = ActivityFeed.FeedItemView.extend({
		events: _.extend({
			'click .kx-feed-item-appname': 'routeToApp'
		}, ActivityFeed.FeedItemView.prototype.events),
		routeToApp: function (e) {
			var game = KXL.games.get($(e.currentTarget).data('id'));
			KXL.execute('game:show', game.get('urlName'));
			$(window).scrollTop(0);
		}
	});

	/*
	 * Activity feed ranked item view
	 */
	ActivityFeed.FeedItemRankedView = ActivityFeed.FeedItemFromAppView.extend({
		template: Templates['profile/activity_feed/activity-feed-item-ranked'],
		onRender: function () {
			// Initialize the game thumbnail.
			this.thumbnailRegion.show(
				KXL.request(
					'new:game:logo:view',
					this.model.get('object').hydrated.gameId
				)
			);
			// Call the base class on render.
			ActivityFeed.FeedItemView.prototype.onRender.call(this);
		},
		serializeData: function () {
			var rankText = ' is now ' +  this.model.get('object').hydrated.newRank + ' on the '
				+  this.model.get('object').hydrated.leaderboardName + ' Leaderboard!';

			return {
				'kxid': this.model.get('actor').hydrated.id,
				'username': this.model.get('actor').hydrated.username,
				'gameId': this.model.get('object').hydrated.gameId,
				'leaderboardName': this.model.get('object').hydrated.leaderboardName,
				'updatedAt': KXL.Common.Utils.DateUtils.isoString(this.model.get('updatedAt')),
				'oldRank': this.model.get('object').hydrated.oldRank,
				'newRank': this.model.get('object').hydrated.newRank,
				'text' : rankText
			};
		}
	});

	/*
	 * Activity feed gift view
	 */
	ActivityFeed.FeedItemGiftView = ActivityFeed.FeedItemFromAppView.extend({
		template: Templates['profile/activity_feed/activity-feed-item-gift'],
		onRender: function () {

			var hydratedUser = this.model.get('actor').hydrated;

			// Initialize the avatar
			this.thumbnailRegion.show(
				KXL.request(
					'new:avatar:view',
					hydratedUser.id,
					{
						avatarMenu: KXL.Components.Avatar.avatarMenu.ON,
						size: 'medium',
						color: 'grey',
						model: KXL.request('new:user:entity', hydratedUser)
					}
				)
			);
			ActivityFeed.FeedItemView.prototype.onRender.call(this);
		},
		serializeData: function () {
			return {
				'kxid': this.model.get('object').hydrated.from,
				'username': this.model.get('actor').hydrated.username,
				'text': this.model.get('object').hydrated.text,
				'updatedAt': KXL.Common.Utils.DateUtils.isoString(this.model.get('updatedAt'))
			};
		}
	});

	/*
	 * Activity feed brag view
	 */
	ActivityFeed.FeedItemBragView = ActivityFeed.FeedItemFromAppView.extend({
		template: Templates['profile/activity_feed/activity-feed-item-brag'],
		onRender: function () {
			// Initialize the game thumbnail.
			this.thumbnailRegion.show(
				KXL.request(
					'new:game:logo:view',
					this.model.get('object').hydrated.appId
				)
			);
			// Call the base class on render.
			ActivityFeed.FeedItemView.prototype.onRender.call(this);
		},
		serializeData: function () {
			return {
				'kxid': this.model.get('object').hydrated.from,
				'username': this.model.get('actor').hydrated.username,
				'text': this.model.get('object').hydrated.text,
				'updatedAt': KXL.Common.Utils.DateUtils.isoString(this.model.get('updatedAt')),
				'appTitle': this.model.get('object').hydrated.appTitle,
				'appId': this.model.get('object').hydrated.appId
			}
		}
	});

	/*
	 * Activity feed post view
	 */
	ActivityFeed.FeedItemPostView = ActivityFeed.FeedItemView.extend({
		template: Templates['profile/activity_feed/activity-feed-item-default'],
		onRender: function () {

			var hydratedUser = this.model.get('actor').hydrated;

			// Initialize the avatar
			this.thumbnailRegion.show(
				KXL.request(
					'new:avatar:view',
					hydratedUser.id,
					{
						avatarMenu: KXL.Components.Avatar.avatarMenu.ON,
						size: 'medium',
						color: 'grey',
						model: KXL.request('new:user:entity', hydratedUser)
					}
				)
			);
			// Call the base class on render.
			ActivityFeed.FeedItemView.prototype.onRender.call(this);
		},
		serializeData: function () {
			return {
				'kxid': this.model.get('object').hydrated.from,
				'username': this.model.get('actor').hydrated.username,
				'text': this.model.get('object').hydrated.text,
				'updatedAt': KXL.Common.Utils.DateUtils.isoString(this.model.get('updatedAt'))
			}
		}
	});

	/*
	 * Activity feed notification image view.
	 */
	ActivityFeed.FeedItemNotificationImageView = Marionette.ItemView.extend({
		template: Templates['profile/activity_feed/feed-item-image'],
		className: 'kx-feed-item-image-wrapper',
		templateHelpers: function () {
			var self = this;
			return {
				url: function () {
					var imageUrl = self.options.url;
					return imageUrl.match(/^\/\/kxl-site-content/) ? imageUrl : '/modules/header'.concat(imageUrl);
				}
			};
		}
	});


	/*
	 * Activity feed notification view.
	 */
	ActivityFeed.FeedItemNotificationView = ActivityFeed.FeedItemView.extend({
		template: Templates['profile/activity_feed/system-notifications'],
		ui: _.extend({
			'thumb': '.kx-feed-item-thumbnail',
			'moreInfo' : '.kx-feed-item-url'
		}, ActivityFeed.FeedItemView.prototype.ui),
		onRender: function () {
			var self = this;
			this.thumbnailRegion.show(
				new ActivityFeed.FeedItemNotificationImageView({
					url: self.model.get('imageUrl')
				})
			);

			// If we have a url attached, make the thumb clickable
			if (this.model.get('url') !== '') {
				this.ui.thumb.addClass('clickable');
				this.ui.thumb.bind('click', function() {
					KXL.navigate(this.model.get('url'));
				});
			}else {
				this.ui.moreInfo.hide();
			}

			// Call the base class on render.
			ActivityFeed.FeedItemView.prototype.onRender.call(this);
		},
		serializeData: function () {
			return {
				'publishAt' : KXL.Common.Utils.DateUtils.isoString(this.model.get('publishAt')),
				'content' : this.model.get('content'),
				'url' : this.model.get('url')
			};
		}
	});

	/*
	 * Activity feed forum post view
	 */
	ActivityFeed.ForumPostView = ActivityFeed.FeedItemView.extend({
		template: Templates['profile/activity_feed/activity-feed-item-forum-post'],
		onRender: function () {
			this.$el.addClass('kx-clearfix');

			var hydratedUser = this.model.get('actor').hydrated;

			// Initialize the avatar
			this.thumbnailRegion.show(
				KXL.request(
					'new:avatar:view',
					hydratedUser.id,
					{
						avatarMenu: KXL.Components.Avatar.avatarMenu.ON,
						size: 'medium',
						color: 'grey',
						model: KXL.request('new:user:entity', hydratedUser)
					}
				)
			);
			this.ui.itemTime.timeago({locale: KXL.config.locale});
			// Call the base class on render.
			ActivityFeed.FeedItemView.prototype.onRender.call(this);
		},
		serializeData: function () {
			//TODO @ cmartin, check if the username is YOU and display 'You' instead of the username.
			return {
				'kxid' : this.model.get('object').hydrated.userId,
				'username': this.model.get('actor').hydrated.username,
				'title': this.model.get('object').hydrated.title,
				'url': this.model.get('object').hydrated.url,
				'text': this.model.get('object').hydrated.bodyText,
				"updatedAt": KXL.Common.Utils.DateUtils.isoString(this.model.get('updatedAt'))
			}
		}
	});

	/*
	 * Activity feed forum reply view
	 */
	ActivityFeed.ForumReplyView = ActivityFeed.FeedItemView.extend({
		template: Templates['profile/activity_feed/activity-feed-item-forum-reply'],
		onRender: function () {
			this.$el.addClass('kx-clearfix');

			var hydratedUser = this.model.get('actor').hydrated;

			// Initialize the avatar
			this.thumbnailRegion.show(
				KXL.request(
					'new:avatar:view',
					hydratedUser.id,
					{
						avatarMenu: KXL.Components.Avatar.avatarMenu.ON,
						size: 'medium',
						color: 'grey',
						model: KXL.request('new:user:entity', hydratedUser)
					}
				)
			);
			this.ui.itemTime.timeago({locale: KXL.config.locale});
			// Call the base class on render.
			ActivityFeed.FeedItemView.prototype.onRender.call(this);
		},
		serializeData: function () {
			//TODO @ cmartin, check if any of the user's are YOU and display 'You' or 'Your' in place of the username.
			return {
				'usernameWhoCommentedId' : this.model.get('actor').hydrated.id,
				'usernameWhoCommented': this.model.get('actor').hydrated.username,
				'usernameWhoPostedId': this.model.get('object').hydrated.discussionUserId,
				'usernameWhoPosted': this.model.get('object').hydrated.discussionUserName,
				'originalPostTitle': this.model.get('object').hydrated.title,
				'originalPostUrl': this.model.get('object').hydrated.url,
				'comment': this.model.get('object').hydrated.bodyText,
				"updatedAt": KXL.Common.Utils.DateUtils.isoString(this.model.get('updatedAt'))
			}
		}
	});

	/*
	 * Activity feed new friend item.
	 */
	ActivityFeed.NewFriendView = ActivityFeed.FeedItemView.extend({
		template: Templates['profile/activity_feed/activity-feed-item-new-friend'],
		onRender: function () {
			// Add clear fix to the $el.
			this.$el.addClass('kx-clearfix');

			var hydratedUser = this.model.get('friendA').hydrated;

			// Initialize the avatar
			this.thumbnailRegion.show(
				KXL.request(
					'new:avatar:view',
					hydratedUser.id,
					{
						avatarMenu: KXL.Components.Avatar.avatarMenu.ON,
						size: 'medium',
						color: 'grey',
						model: KXL.request('new:user:entity', hydratedUser)
					}
				)
			);
			this.ui.itemTime.timeago({locale: KXL.config.locale});
			// Call the base class on render.
			ActivityFeed.FeedItemView.prototype.onRender.call(this);
		},
		serializeData: function () {
			// Figure out who is friend A & B.
			var actor =  this.model.get('actor');
			var object = this.model.get('object');
			var currentUserFeed = true;

			// Initially set friend A as the actor &
			// friend B the object
			this.model.set({'friendA': actor});
			this.model.set({'friendB': object});

			if (KXL.currentUser.id === actor.hydrated.id && this.options.currentUserFeed ||
				KXL.currentUser.id === object.hydrated.id && this.options.currentUserFeed) {
				// Its the current user's feed and they are one of the users in the news
				if (KXL.currentUser.id === actor.hydrated.id) {
					this.model.set({'friendA': object});
					this.model.set({'friendB': actor});
				}
			} else {
				// It's not the current users feed
				// Display the user who's feed it is last.
				currentUserFeed = false;
				if (this.options.userId === actor.hydrated.id) {
					this.model.set({'friendA': object});
					this.model.set({'friendB': actor});
				}
			}
			// Is friend B the current user?
			if (this.model.get('friendB').hydrated.id === KXL.currentUser.id) {
				// Set their username to you.
				this.model.get('friendB').hydrated.username = KXL.i18n.t('activityFeed.you');
			}

			return {
				"friendA": this.model.get('friendA'),
				"friendB": this.model.get('friendB'),
				"updatedAt": KXL.Common.Utils.DateUtils.isoString(this.model.get('updatedAt')),
				"currentUserFeed": currentUserFeed
			}
		}
	});

	/*
	 * Activity feed composite view.
	 */
	ActivityFeed.CompositeView = Marionette.CompositeView.extend({
		initialize: function (options) {
			// Pass options to the item view
			this.itemViewOptions = {
				userId: options.userId,
				currentUserFeed: options.currentUserFeed
			}
		},
		template: Templates['profile/activity_feed/activity-feed-items'],
		className: 'feed',
		emptyView: Templates['profile/activity_feed/activity-feed-empty'],
		itemViewContainer: ".kx-feed-item-list",
		itemViewEventPrefix: "feed:item",
		itemAppend: true,
		/*
		 * Override the buildItemView to allow for
		 * different views to be loaded based on the model.
		 */
		buildItemView: function(item, ItemViewType, itemViewOptions){
			if (this.collection && this.collection.length) {
				var options = _.extend({model: item}, itemViewOptions);
				switch (options.model.get('action')) {
					case 'friend':
						return new ActivityFeed.NewFriendView(options);
					case 'forum-post':
						return new ActivityFeed.ForumPostView(options);
					case 'forum-reply':
						return new ActivityFeed.ForumReplyView(options);
					case 'bookmarked-reply':
						return new ActivityFeed.ForumReplyView(options);
					case 'ranked':
						return new ActivityFeed.FeedItemRankedView(options);
					case 'brag':
						return new ActivityFeed.FeedItemBragView(options);
					case 'gift':
						return new ActivityFeed.FeedItemGiftView(options);
					case 'notification':
						return new ActivityFeed.FeedItemNotificationView(options);
					case 'post':
					default:
						return new ActivityFeed.FeedItemPostView(options);
				}
			}
			// If we made it here, its empty
			return new ActivityFeed.EmptyView();
		},
		// Overriding this method to do something other
		// then `.append`.
		appendHtml: function(cv, iv, index){
			var $container = cv.getItemViewContainer(cv);
			if (this.itemAppend) {
				$container.append(iv.$el);
			}else {
				$container.prepend(iv.$el);
				// TODO @cmartin, figure out how to make it slide down before fading in the new element.
				TweenLite.fromTo(iv.$el, 1 ,
					{
						opacity: 0,
						ease: "Power2.easeInOut"
					},
					{
						opacity: 1,
						clearProps: "transform",
						ease: "Power2.easeInOut"
					});
			}
		}
	});

	/*
	 * Activity feed main layout.
	 */
	ActivityFeed.mainLayout = KXL.Components.Layout.extend({
		isLoading: true,
		allItemsLoaded: false,
		loadPostsOnClick: false,
		loadPostsOnScroll: false,
		initialize: function () {
			var self = this;
			if (this.options.previousPostsAction === 'scroll') {
				this.loadPostsOnScroll = true;
				$(document).scroll(function () {
					self.checkScroll();
				});
			}else if (this.options.previousPostsAction === 'click') {
				this.loadPostsOnClick = true;
			}
		},
		template: Templates['profile/activity_feed/activity-feed'],
		className: 'kx-activity-feed',
		regions: {
			messageInput: '#message-input-region',
			feed: '#feed-region',
			editPrivacy: '#edit-privacy-region'
		},
		ui: {
			preloader: '.kx-more-loading',
			moreBtn: '.kx-load-more-btn'
		},
		triggers: {
			'click .kx-load-more-btn': 'load:previous:page'
		},
		checkScroll: function (e) {
			var triggerPoint = 100; // 100px from the bottom
			if (!this.isLoading &&
				!this.allItemsLoaded &&
				$(document).scrollTop() + triggerPoint > ($(document).height() - $(window).height()) ) {
				this.isLoading = true;
				this.trigger('load:previous:page');
			}
		},
		showPreloader: function () {
			if (this.loadPostsOnClick) {
				this.ui.moreBtn.hide();
			}
			this.ui.preloader.show();
		},
		hidePreloader: function (options) {
			var defaults = {
				paging: null
			};
			this.options = _.defaults(options || {}, defaults);
			this.ui.preloader.hide();
			if (this.loadPostsOnClick &&
				this.options.paging &&
				this.options.paging.previous) {
				this.ui.moreBtn.show();
			}
		},
		onClose: function () {
			if (this.loadPostsOnScroll) {
				$(document).unbind('scroll');
			}
			this.trigger('activityfeed:closed');
		}
	});

});

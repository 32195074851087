KXL.module('GamesApp.LoadingRibbon', function(LoadingRibbon, KXL, Backbone, Marionette, $, _) {
	LoadingRibbon.View = Marionette.Layout.extend({
		template: '#kx-loading-ribbon-layout-template',
		className: 'kx-loading-ribbon',
		events: {
			'click .kx-close-ribbon': 'closeClicked'
		},
		getTemplate: function () {
			return '#kx-loading-ribbon-template-' + this.model.get('urlName');
		},
		closeClicked: function () {
			this.trigger('close:clicked');
		}
	});
});

KXL.module('Services.Avatars', function (Avatars, KXL, Backbone, Marionette, $, _) {

	var
		_avatarCache = {},
		_validSizes = ['small', 'medium', 'large']
	;

	function _validateAvatar(avatar) {
		if (avatar && avatar.length) {
			return true;
		}
		return false;
	}

	KXL.reqres.setHandlers({
		'get:service:avatars': function (userId, avatarSize) {

			avatarSize = avatarSize || 'medium';

			if (_avatarCache[userId] && _.indexOf(_validSizes, avatarSize) !== -1) {
				var avatar = _.findWhere(_avatarCache[userId], {
					id: avatarSize
				});
				return avatar.url;
			}

			return null;
		},

		'set:services:avatars': function (avatars) {
			_.each(avatars, function (avatar, userId) {
				if (_validateAvatar(avatar)) {
					_avatarCache[userId] = avatar;
				}
			});
		}
	});

});

KXL.module('FooterApp.Footer', function(Footer, KXL, Backbone, Marionette, $, _) {
	Footer.View = Marionette.Layout.extend({
		template: Templates['footer/footer'],
		className: 'kx-footer',
		triggers: {
			'click .oldgroups': 'oldgroups:clicked'
		},
		ui: {
			oldgroups: '.oldgroups'
		},
		regions: {
			localesRegion: '.locales-region'
		},
		onRender: function () {
			this.localesRegion.show(
				KXL.request('new:locale:selector:view', {
					locales: KXL.appModel.get('supportedLocales'),
					locale: KXL.config.locale
				})
			);
		},
		onShow: function () {
			/\/?groups.*/i.test(KXL.getCurrentRoute()) ? this.ui.oldgroups.show() : this.ui.oldgroups.hide();
		},
		initialize: function () {
			var self = this;
			this.listenTo(KXL.vent, 'page:nav', function (route) {
				/\/?groups.*/i.test(route) ? self.ui.oldgroups.show() : self.ui.oldgroups.hide();
			});
		},
		serializeData: function () {
			return {
				currentYear: new Date().getFullYear()
			};
		}
	});
});

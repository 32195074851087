KXL.module('HeaderApp.Header.Banner', function(Banner, KXL, Backbone, Marionette, $, _) {
	Banner.Banner = KXL.Components.ContentPane.View.extend({
		template: '#banner-template',
		className: 'kx-banner',
		ui: { closeBtn: '.kx-banner-close-button' },
		initialize: function (options) {
			this.options = _.defaults(options || {},
			{
				closable: false
			});
		},
		onShow: function () {
			this.toggleClosable(this.options.closable);
		},
		toggleClosable: function (isClosable) {
			if (isClosable) {
				this.ui.closeBtn.show();
				// Close when user clicks on close button.
				var self = this;
				this.ui.closeBtn.on('click', function () {
					self.trigger('close:clicked');
				});
			} else {
				this.ui.closeBtn.hide();
			}
		}
	});
	Banner.Banners = KXL.Components.Layout.extend({
		template: function () {
			return '';
		},
		className: 'kx-banners'
	});
});

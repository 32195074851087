KXL.module('Components.PlayersOnline', function(PlayersOnline, KXL, Backbone, Marionette, $, _) {
	PlayersOnline.Controller = {
		getPlayersOnline: function () {
			var playersOnline = KXL.request('playersonline:entity')._model;
			var poller = Backbone.Poller.get(playersOnline, { delay: 1000 * 30 });
			poller.start();
			return new PlayersOnline.View({
				model: playersOnline
			});
		}
	};
});

KXL.module('AuthApp.Register.SignupForm', function (SignupForm, KXL, Backbone, Marionette, $, _) {
	SignupForm.Model = Backbone.Model.extend({
		defaults: function() {
			return {
				username: '',
				password: '',
				email: '',
				termsCheckbox: false,
				policyCheckbox: false,
				state: SignupForm.Model.states.NOT_REG,
				stateDetails: ''
			};
		}
	});
	var states = SignupForm.Model.states = {
		NOT_REG: 'Not Registered',
		PENDING: 'Pending Registration',
		VALIDATING: 'Validating',
		VALIDATION_ERRORS: 'Validation Errors',
		SUCCESS: 'Registration Success',
		UNKNOWN: 'Registration Unknown'
	};

	var registerInProgress = false;

	function getView (viewType, options) {
		options = options || {};
		options.registration = options.registration || new KXL.Entities.Registration();
		options.model = options.model || new SignupForm.Model();
		var
			registration = options.registration,
			view = new viewType(options)
		;

		// hasValue yoinked from backbone.validation. Once we enhance backbone.validation to
		// use deferreds allowing for async validation functions we can move all this validation back
		// into the model validattion configuration.
		var hasValue = function(value) {
			return !(_.isNull(value) || _.isUndefined(value) || (_.isString(value) && $.trim(value) === '') || (_.isArray(value) && _.isEmpty(value)));
		};

		function validateUsername () {
			var deferred = $.Deferred();
			var username = registration.get('username');
			if (!hasValue(username)) {
				registration.trigger('invalid:username', KXL.i18n.t('auth.signup.errInvalidUsername'));
				return deferred.reject();
			}
			// Server side validation.
			KXL.request('validate:username', registration.get('username'))
				.done(function (username, isValid, errors) {
					if (isValid) {
						registration.trigger('valid:username');
						deferred.resolve();
					} else {
						if (_.contains(errors, 'isTaken')) {
							registration.trigger('invalid:username', KXL.i18n.t('auth.signup.errUsernameNotAvailable'));
						} else {
							registration.trigger('invalid:username', KXL.i18n.t('auth.signup.errInvalidUsername'));
						}
						deferred.reject();
					}
				});
			return deferred;
		}

		registration.on('change:username', _.debounce(function (model, value, options) {
			validateUsername();
		}), 300);

		function validateEmail () {
			var deferred = $.Deferred();
			var email = registration.get('email');
			if (!hasValue(email) ||
				!email.toString().match(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i))
			{
				registration.trigger('invalid:email', KXL.i18n.t('auth.signup.errInvalidEmail'));
				return deferred.reject();
			}
			// Server side validation.
			KXL.request('validate:email', email)
				.done(function (email, isValid, error) {
					if (isValid) {
						registration.trigger('valid:email');
						deferred.resolve();
					} else {
						if (error instanceof KXL.Errors.EmailInUseError) {
							registration.trigger('invalid:email', KXL.i18n.t('auth.signup.errEmailInUseError'));
						} else {
							registration.trigger('invalid:email', KXL.i18n.t('auth.signup.errInvalidEmail'));
						}
						deferred.reject();
					}
				});
			return deferred;
		}

		registration.on('change:email', _.debounce(function (model, value, options) {
			validateEmail();
		}), 300);

		function validatePassword () {
			var deferred = $.Deferred();
			if (registration.isValid('password')) {
				registration.trigger('valid:password');
				deferred.resolve();
			} else {
				registration.trigger('invalid:password', KXL.i18n.t('auth.signup.errInvalidPassword'));
				deferred.reject();
			}
			return deferred;
		}

		registration.on('change:password', _.debounce(function (model, value, options) {
			validatePassword();
		}), 300);
		
		function validateCheckbox(attributeName) {
			var deferred = $.Deferred();
			var checkbox = registration.get(attributeName);
			
			if(checkbox) {
				registration.trigger('valid:' + attributeName);
				deferred.resolve();
			} else {
				registration.trigger('invalid:' + attributeName, KXL.i18n.t('auth.signup.checkboxInvalid'));
				deferred.reject();
			}
			
			return deferred;
		}

		view.on('tos:clicked', function () {
			KXL.execute('show:TOS');
		});

		view.on('toggle:signup:method', function () {
			if (view.emailSignupSelected) {
				view.toggleEmailSignupSection(false);
			} else {
				view.toggleEmailSignupSection(true);
			}
		});

		view.on('register:clicked', function (data) {

			if (registerInProgress) {
				return;
			}

			registerInProgress = true;

			registrationPromise = $.when(
				validateUsername(),
				validateEmail(),
				validatePassword(),
				validateCheckbox('termsCheckbox'),
				validateCheckbox('policyCheckbox')
			);
			
			registrationPromise.done(function () {
				KXL.vent.trigger('info:register:submit', data);
				view.trigger('register:submit', registration, data);
			});
			
			registrationPromise.always(function () {
				registerInProgress = false;
			});
		});

		return view;
	}

	SignupForm.Controller = {
		getInlineSignupForm: function (options) {
			return getView(SignupForm.InlineView, options);
		},
		getSignupForm: function (options) {
			return getView(SignupForm.View, options);
		}
	};

});

KXL.module('Components.PlayersOnline', function(PlayersOnline, KXL, Backbone, Marionette, $, _) {
	PlayersOnline.View = Marionette.ItemView.extend({
		template: '#players-online-template',
		className: 'kx-players-online',
		ui: {
			count: '.kx-players-online-count'
		},
		modelEvents: {
			'change': function () {
				var totalOnlinePlayers = this.model.get('totalPlayersOnline');
				if (totalOnlinePlayers && typeof totalOnlinePlayers === 'number') {
					totalOnlinePlayers = totalOnlinePlayers.toLocaleString();
					if (totalOnlinePlayers.match(/[,\.]00$/)) {
						totalOnlinePlayers = totalOnlinePlayers.substring(0, totalOnlinePlayers.length - 3);
					}
					this.ui.count.html(totalOnlinePlayers);
					if (!this.$el.is(':visible')) {
						this.$el.show();
					}
				} else {
					this.$el.hide();
				}
			}
		}
	});
});

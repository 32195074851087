KXL.module('AuthApp.Register.Common', function (Common, KXL, Backbone, Marionette, $, _) {
	Common.ConfirmPrompt = KXL.Components.Layout.extend({
		template: '#register-confirm-prompt-template',
		className: 'kx-register-confirm-prompt',
		regions: {
			confirmBtnRegion: '.kx-confirm-btn-region',
			cancelBtnRegion: '.kx-cancel-btn-region'
		},
		initialize: function (options) {
			var defaults = {
				confirmBtnLabel: KXL.i18n.t('auth.register.common.confirmPrompt.confirmBtnLabel'),
				cancelBtnLabel: KXL.i18n.t('auth.register.common.confirmPrompt.cancelBtnLabel'),
				showConfirmBtn: true
			};
			this.options = _.defaults({}, options, defaults);
		},
		renderButtons: function () {
			var self = this;
			var confirmBtn = new KXL.Components.Button.View({
				label: this.options.confirmBtnLabel,
				theme: KXL.Components.Button.Themes.alt4
			});
			confirmBtn.on('click', function () {
				self.trigger('confirm:clicked');
			});
			this.confirmBtnRegion.show(confirmBtn);

			var cancelBtn = new KXL.Components.Button.View({
				label: this.options.cancelBtnLabel,
				theme: KXL.Components.Button.Themes.alt4
			});
			cancelBtn.on('click', function () {
				self.trigger('cancel:clicked');
			});
			this.cancelBtnRegion.show(cancelBtn);
		},
		onShow: function () {
			this.renderButtons();
		},
		serializeData: function () {
			return {
				heading: this.options.heading,
				showConfirmBtn: this.options.showConfirmBtn
			};
		}
	});
});

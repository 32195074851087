KXL.module('AuthApp.Register.Wizard', function (Wizard, KXL, Backbone, Marionette, $, _) {
	Wizard.View = KXL.Components.Layout.extend({
		loader: null,
		template: '#register-wizard-template',
		className: 'kx-register-wizard',
		regions: {
			currentStep: '.kx-current-step',
			loaderRegion: '.kx-loader-region'
		},
		ui: {
			errors: '.kx-errors',
			headerText: '.kx-header-text',
			subHeaderText: '.kx-subheader-text'
		},
		initialize: function () {
			this.atom = new atom();
		},
		toggleLoadingState: function (on) {
			var self = this;
			this.atom.once('shown', function () {
				if (self.currentStep) {
					self.currentStep.ensureEl();
					self.loaderRegion.ensureEl();
					if (on) {
						if (!self.loader) {
							self.loader = KXL.request('new:loader:view');
							self.loaderRegion.show(self.loader);
						}
						self.currentStep.$el.hide();
						self.loaderRegion.$el.show();
					} else {
						self.currentStep.$el.show();
						self.loaderRegion.$el.hide();
					}
				}
			});
		},
		onShow: function () {
			if (this.options.headerText) {
				this.setHeaderText(this.options.headerText);
			}
			this.atom.set('shown');
		},
		setHeaderText: function (text) {
			var self = this;
			this.atom.once('shown', function () {
				self.ui.headerText.html(text);
			});
		},
		setSubHeaderText: function (text) {
			var self = this;
			this.atom.once('shown', function () {
				if (text) {
					self.ui.subHeaderText.show();
				} else {
					self.ui.subHeaderText.hide();
				}
				self.ui.subHeaderText.html(text);
			});
		},
		showStep: function (stepView) {
			var self = this;
			this.atom.once('shown', function () {
				self.toggleLoadingState(false);
				self.currentStep.show(stepView);
			});
		},
		showError: function (content) {
			this.ui.errors.html(content);
			this.ui.errors.show();
		},
		clearError: function () {
			this.ui.errors.empty && this.ui.errors.empty();
			this.ui.errors.empty && this.ui.errors.hide();
		}
	});
});

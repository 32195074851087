/*---------------- Loader Widget ------------------
Usage:
Step 1 - Create a region in your view
regions: {
	loaderRegion: '.kx-loader-region'
},

Step 2 - In the onRender function of the view write this code
var loaderView = new KXL.Components.Loader.View({
	size: KXL.Components.Loader.sizes.small // tiny, small, large
});

this.loaderRegion.show(loaderView);
--------------------------------------------------------*/
KXL.module('Components.Loader', function (Loader, KXL, Backbone, Marionette, $, _) {
	Loader.View = Marionette.View.extend({
		className: 'kx-loader',
		initialize: function(options) {
			var defaults = {
				lines: 13, // The number of lines to draw
				length: 20, // The length of each line
				width: 10, // The line thickness
				radius: 30, // The radius of the inner circle
				corners: 1, // Corner roundness (0..1)
				rotate: 0, // The rotation offset
				direction: 1, // 1: clockwise, -1: counterclockwise
				color: '#fff', // #rgb or #rrggbb
				speed: 1, // Rounds per second
				trail: 60, // Afterglow percentage
				shadow: false, // Whether to render a shadow
				hwaccel: false, // Whether to use hardware acceleration
				className: 'spinner', // The CSS class to assign to the spinner
				zIndex: 2e9, // The z-index (defaults to 2000000000)
				top: 'auto', // Top position relative to parent in px
				left: 'auto', // Left position relative to parent in px,
				center: true // whether to automatically center horizontally and vertically
			};
			this.options = _.defaults(options || {}, defaults);
			this.options = _.extend(this.options, this.options.size || Loader.sizes.small);
		},
		center: function () {
			var
				o = this.options,
				mid = o.radius + o.length + o.width,
				target = this.$el[0],
				tp = pos(target),
				ep = pos(target.firstChild)
			;

			function pos(el) {
				var o = { x: el.offsetLeft, y: el.offsetTop }
				while((el = el.offsetParent)) {
					o.x += el.offsetLeft, o.y += el.offsetTop
				}
				return o
		  	}

			this.$el.css({
				left: (o.left == 'auto' ? tp.x - ep.x + (target.offsetWidth >> 1) : parseInt(o.left, 10) + mid) + 'px',
				top: (o.top == 'auto' ? tp.y - ep.y + (target.offsetHeight >> 1) : parseInt(o.top, 10) + mid)  + 'px'
			});
		},
		onShow: function () {
			this.$el.spin(this.options);
			if (this.options.center) {
				var self = this;
				setTimeout(function () {
					self.center();
				}, 0);
			}
		}
	});

	//Predefined size
	Loader.sizes = {
		tiny: { lines: 8, length: 2, width: 2, radius: 3 },
		small: { lines: 8, length: 4, width: 3, radius: 5 },
		large: { lines: 10, length: 8, width: 4, radius: 8 },
		xlarge: { lines: 13, length: 20, width: 10, radius: 30 }
	};

	KXL.reqres.setHandlers({
		'new:loader:view': function (options) {
			return new Loader.View(options);
		}
	})
});
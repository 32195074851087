KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {
	Entities.PageMeta = Entities.Model.extend({
		idAttribute: 'uri'
	});
	Entities.PagesMeta = Entities.Collection.extend({
		model: Entities.PageMeta
	});
	var API = {
		getPageMeta: function (pageMetaConfig) {
			var pageMetaCollection = new Entities.PagesMeta(
				pageMetaConfig
			);

			return pageMetaCollection;
		}
	};
	KXL.reqres.setHandlers({
		'page:meta:entities': function (pageMetaConfig) {
			return API.getPageMeta(pageMetaConfig);
		}
	});
});
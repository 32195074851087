/* Routes */
KXL.module('Routes', function(Routes, KXL, Backbone, Marionette, $, _) {
	Routes.Router = Marionette.AppRouter.extend({
		appRoutes: {
			'forum(/*path)': 'forumRoute',
			'settings(/)(:section)': 'settingsRoute',
			// Former Guide pages
			'guide(/*game)': 'oldGuides'
		}
	});

	var API = {

		forumRoute: function (path) {
			function _getForumURL(gameCommonName) {
				var contextNavItems = KXL.staticGames.get(gameCommonName).get("contextNavItems");
				contextNavItems = new Backbone.Collection(contextNavItems);
				return contextNavItems.get('forum').get('url');
			}

			if (/\/war-commander-rogue-assault\/?/.test(path)) {
				window.open(_getForumURL('warcommanderrogueassault'));
			} else if (/\/battle-pirates\/?/.test(path)) {
				window.open(_getForumURL('battlepirates'));
			} else if (/\/war-commander\/?/.test(path)) {
				window.open(_getForumURL('warcommander'));
			} else if (/\/vega-conflict\/?/.test(path)) {
				window.open(_getForumURL('vegaconflict'));
			} else {
				KXL.execute('404:show', path);
			}
		},

		settingsRoute: function (section) {

			KXL.execute('settings:show', {}, section);

		},

		oldGuides: function (game) {
			KXL.navigate('game/' + game + '/guide/', { target: '_blank', trigger: true });
			//KXL.GamesApp.Game.Controller.showGuide(KXL.context, 'guides');
		}
	};

	function loadModule(module) {
		return function () {
			return KXL.asyncRequest('async:load:module', module);
		};
	}

	function removeBackboneRoute(routePath) {
		_.any(Backbone.history.handlers, function (route, i) {
			if (route.route.toString() == Backbone.Router.prototype._routeToRegExp(routePath).toString()) {
				Backbone.history.handlers.splice(i, 1);
				return true;
			}
		});
	}

	KXL.addInitializer(function () {
		new Routes.Router({
			controller: API
		});

		var moduleLoaders = {
			'settings:*': loadModule('settings'),
			'sidebar:*': loadModule('sidebar')
		};

		KXL.commands.setHandlers(moduleLoaders);
		KXL.reqres.setHandlers(moduleLoaders);
	});
});

KXL.module('Components.UserGameProfile', function(UserGameProfile, KXL, Backbone, Marionette, $, _) {
	UserGameProfile.Controller = {
		getGameProfile: function (userGame) {
			return new UserGameProfile.GameProfile({
				model: userGame
			});
		},
		getGameProfileHeader: function (userGame) {
			return new UserGameProfile.GameProfileHeader({
				model: userGame
			});
		}
	};
});

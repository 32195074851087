(function () {
	"use strict";

	/**
	 * Used by both the request and execute overrides below.
	 *
	 * @param self The callback will be executed bound to self
	 * @param callback The callback function to be called once the chain is complete
	 * @param name The name of the command/request being run
	 * @param args The original arguments object passed through to the execute/request function
	 */
	function chainWildcardHandlers(self, callback, name, args) {
		var nameSections = name.split(':');
		if (nameSections.length) {
			var chain = $.Deferred(),
				argsWithoutName = Array.prototype.slice.call(args, 1);
			chain.resolve();
			_.forEach(nameSections, function (section, i) {
				// add wildcard to the section
				var wildcardName = nameSections.slice(0, i + 1).join(':') + ':*';
				if (self.hasHandler(wildcardName)) {
					chain = chain.then(function () {
						return self.getHandler(wildcardName).apply(self, argsWithoutName);
					});
				}
			});
			chain = chain.then(function () {
				return callback.apply(self, args);
			});
			return chain.promise();
		}
		return callback.apply(self, args);
	}

	/**
	 * This wraps the Backbone.Wreqr.Commands.prototype.execute so that all KXL.execute() commands are checking for
	 * dependency-resolving commands...
	 *
	 * Example: KXL.execute('forum:show') will only function if the forum_app is loaded.
	 *
	 * If we setup a command with the key 'forum:*' and load the forum_app, then execute the original command:
	 * 'forum:show', the forum will show properly.
	 *
	 * Note that all wildcard commands ABSOLUTELY MUST RETURN A PROMISE.
	 *
	 */
	var originalWreqrExecute = Backbone.Wreqr.Commands.prototype.execute;
	Backbone.Wreqr.Commands.prototype.execute = function (name, args) {
		return chainWildcardHandlers(this, originalWreqrExecute, name, arguments);
	};

	Backbone.Wreqr.RequestResponse.prototype.asyncRequest = function (name) {
		return chainWildcardHandlers(this, Backbone.Wreqr.RequestResponse.prototype.request, name, arguments);
	};
})();
KXL.module('Components.Comments', function (Comments, KXL, Backbone, Marionette, $, _) {
	Comments.CommentView = Marionette.Layout.extend({
		template: '#kx-comment-template',
		tagName: 'li',
		className: 'kx-feed-item-comment',
		regions: {
			avatar: '.kx-feed-item-comment-avatar'
		},
		ui: {
			'itemTime' : '.kx-feed-item-comment-time',
			'itemOptions': '.kx-feed-item-comment-delete'
		},
		events: {
			'click .kx-feed-item-comment-username': 'routeToUserProfile',
			'mouseover .kx-feed-item-comment-delete': 'itemOver',
			'mouseover' : 'itemOver',
			'mouseout' : 'itemOut'
		},
		triggers: {
			'click .kx-feed-item-comment-delete': 'hide:comment'
		},
		onRender: function () {
			// Initialize the avatar
			this.avatar.show(
				KXL.request(
					'new:avatar:view',
					this.model.get('userId'), {
					avatarMenu: KXL.Components.Avatar.avatarMenu.ON,
					color: KXL.Components.Avatar.borderColors.NONE,
					size: 'small'
				})
			);

			var canManage = this.options.canManage;

			if (this.options.commentOwnerCanManage &&
				KXL.currentUser.id === this.model.get('userId')) {
				canManage = true;
			}

			// If the user doesn't have permission to delete the comment hide the x button.
			if (!canManage) {
				this.ui.itemOptions.hide();
			}

			// Initialize time ago.
			this.ui.itemTime.timeago();

		},
		itemOver: function () {
			TweenLite.killTweensOf(this.ui.itemOptions);
			TweenLite.to(this.ui.itemOptions, 0.5,
				{
					opacity: 1,
					clearProps: "transform"
				});
		},
		itemOut: function () {
			TweenLite.to(this.ui.itemOptions, 0.5,
				{
					opacity: 0,
					clearProps: "transform"
				});
		},
		routeToUserProfile: function (e) {
			KXL.execute('profile:show', $(e.currentTarget).attr('id'));
			e.preventDefault();
		},
		serializeData: function () {
			var author;
			if (this.model.has('author')) {
				author = this.model.get('author').username;
			} else {
				author = this.model.get('userId');
			}

			var content = new Handlebars.SafeString(
				$.fn.urlize(
					Handlebars.Utils.escapeExpression(
						this.model.get('content')
					)
				)
			);

			return {
				userId: this.model.get('userId'),
				author: author,
				content: content,
				updatedAt: KXL.Common.Utils.DateUtils.isoString(this.model.get('updatedAt'))
			};
		}
	});

	Comments.PagingView = Marionette.ItemView.extend({
		template: '#kx-comments-paging-template',
		className: 'kx-comments-paging',
		triggers: {
			'click .kx-comments-paging-view-more': 'previous:page'
		}
	});

	Comments.ListView = Marionette.CompositeView.extend({
		template: '#kx-comments-list-template',
		className: 'kx-comments-list-wrapper',
		itemAppend: false,
		itemViewEventPrefix: "feed:item",
		itemViewContainer: '.kx-comments-list',
		initialize: function (options) {
			this.options = options;
		},
		buildItemView: function (item, ItemViewType, itemViewOptions) {
			if (this.collection.length) {
				var options = _.extend({
						model: item,
						canManage: this.options.canManage,
						commentOwnerCanManage: this.options.commentOwnerCanManage
					}, itemViewOptions);

				return new Comments.CommentView(options);
			}
		},
		appendHtml: function (cv, iv, index) {
			var $container = cv.getItemViewContainer(cv);
			if (cv.collection.last() === iv.model && !this.isBuffering) {
				$container.append(iv.$el);
			} else {
				if (cv.children && cv.children.length === 1) {
					$container.append(iv.$el);
				} else {
					// check if the model is previous in the collection and not the
					// current model and add the element after it, otherwise add it before.
					if (cv.collection.prev(iv.model) !== iv.model &&
						cv.children.findByModel(cv.collection.prev(iv.model))) {
						cv.children.findByModel(cv.collection.prev(iv.model)).$el.after(iv.$el);
					} else if (cv.children.findByModel(cv.collection.next(iv.model))) {
						cv.children.findByModel(cv.collection.next(iv.model)).$el.before(iv.$el);
					}
				}
			}

			if (KXL.animationCapabilities) {
				TweenLite.fromTo(iv.$el, 1,
					{
						opacity: 0,
						ease: 'Power2.easeInOut'
					},
					{
						opacity: 1,
						clearProps: 'transform',
						ease: 'Power2.easeInOut'
					}
				);
			}
		}
	});

	Comments.CommentsLayout = Marionette.Layout.extend({
		template: '#kx-comments-layout-template',
		className: 'kx-comments',
		commentInputView: null,
		commentsListView: null,
		triggers: {
			'click .kx-comments-no-commenting-cta': 'comments:no:commenting:cta:clicked'
		},
		regions: {
			commentsRegion: '.kx-comments-list-region',
			commentInputRegion: '.kx-comments-input-region',
			commentPagingRegion: '.kx-comments-paging-region'
		},
		expand: function () {
			this.$el.addClass('expanded');
		},
		contract: function () {
			this.$el.removeClass('expanded');
		},
		initialize: function (options) {
			this.options = _.defaults(
				options || {},
				{
					canComment: true,
					showNoCommentingCTALink: false,
					noCommentingCTALinkText: KXL.i18n.t('comments.noCommentingCTALink')
				}
			);
		},
		onRender: function () {
			if (this.options.collection.length) {
				this.expand();
			}
		},
		serializeData: function () {
			return {
				options: this.options
			}
		}
	});

});

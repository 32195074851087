KXL.module('GamesApp.PlayMessage', function (PlayMessage, KXL, Backbone, Marionette, $, _) {
	var browsers = new Backbone.Collection([
		{
			name: 'Safari',
			icon: '/modules/game/play_message/images/safari_logo.png',
			link: 'http://support.apple.com/downloads/#safari'
		},
		{
			name: 'Firefox',
			icon: '/modules/game/play_message/images/firefox_logo.png',
			link: 'https://www.mozilla.org/en-US/firefox/new/'
		},
		{
			name: 'Chrome',
			icon: '/modules/game/play_message/images/chrome_logo.png',
			link: 'https://www.google.ca/chrome/'
		}
	]);

	PlayMessage.Controller = {
		getPlayMessageUnsupported: function (options) {
			var supportedBrowsers = new Backbone.FilteredCollection(null, { collection: browsers });

			supportedBrowsers.setFilter(function (model) {
				return model.get('name') !== KXL.config.browser.name;
			});

			var opts = {
				logo: options.logo,
				messageText: options.message,
				supportedBrowsers: supportedBrowsers
			};

			var playMessageView = new PlayMessage.PlayMessageUnsupportedLayout(opts);

			return playMessageView;
		}
	};
});
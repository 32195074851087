KXL.module('Components.ErrorPage', function(ErrorPage, KXL, Backbone, Marionette, $, _) {
	ErrorPage.View = Marionette.ItemView.extend({
		initialize: function (options) {
			this.options = options;
		},
		getTemplate: function () {
			switch(this.options.error) {
				case 404:
					return '#error-page-404-template';
				case 500:
					return '#error-page-500-template';
				default:
					return '#error-page-template';
			}
		}
	});
});


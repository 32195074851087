KXL.module('AuthApp.BannedDialog', function(BannedDialog, KXL, Backbone, Marionette, $, _) {
	var Controller = Marionette.Controller.extend({
        show: function() {
            KXL.request('show:default:dialog', new BannedDialog.View(), {
                showCloseButton: false,
                allowClose: false
            });
        }
    });

    BannedDialog.Controller = new Controller();
});

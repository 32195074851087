KXL.module('GamesApp.Game', function(Game, KXL, Backbone, Marionette, $, _) {
	Game.GameSectionRegion = Marionette.Region.extend({
		open: function (view) {
			this.$el.hide();
			this.$el.html(view.el);
			this.$el.show();
		}
	});
	Game.Layout = KXL.Components.Layout.extend({
		template: '#game-template',
		className: function () {
			return 'kx-game ' + this.model.get('commonName');
		},
		regions: {
			nav: '.game-nav-region',
			leaderboards: Game.GameSectionRegion.extend({ el: '.game-leaderboards-region' }),
			home: Game.GameSectionRegion.extend({ el: '.game-home-region' }),
			guide: Game.GameSectionRegion.extend({ el: '.game-guide-region' }),
			strategy: Game.GameSectionRegion.extend({ el: '.game-strategy-region' }),
			forum: Game.GameSectionRegion.extend({ el: '.game-forum-region' }),
			support: Game.GameSectionRegion.extend({ el: '.game-support-region' }),
			play: Game.GameSectionRegion.extend({ el: '.game-play-region' })
		}
	});
	Game.HomeMobileLoggedOutLayout = KXL.Components.Layout.extend({
		template: '#game-home-mobile-logged-out-template',
		className: 'kx-game-home-mobile-logged-out',
		regions: {
			background: '.game-background-region',
			video: '.game-video-region',
			appstore: '.game-mobile-appstore-region',
			story: '.game-story-region',
			screenshots: '.game-screenshots-region'
		}
	});
	Game.HomeWCRAMobileLoggedOutLayout = KXL.Components.Layout.extend({
		template: '#game-home-wcra-mobile-logged-out-template',
		className: 'kx-game-home-mobile-logged-out',
		regions: {
			background: '.game-background-region',
			appstore: '.game-mobile-appstore-region',
			story: '.game-story-region',
			screenshots: '.game-screenshots-region'
		},
		toggleAppStoreItems: function (on) {
			this.$el.toggleClass('kx-available', on);
		},
		serializeData: function () {
			return {
				options: this.options
			}
		}
	});

	Game.HomeLoggedOutLayout = KXL.Components.Layout.extend({
		template: '#game-home-logged-out-template',
		className: 'kx-game-home-logged-out',
		regions: {
			background: '.game-background-region',
			video: '.game-video-region',
			signUp: '.game-signup-region',
			ticker: '.game-ticker-region',
			story: '.game-story-region',
			screenshots: '.game-screenshots-region'
		}
	});
	Game.HomeLoggedOutLayoutVC = KXL.Components.Layout.extend({
		template: '#game-home-logged-out-template-vc',
		className: 'kx-game-home-logged-out',
		regions: {
			background: '.game-background-region',
			video: '.game-video-region',
			ticker: '.game-ticker-region',
			story: '.game-story-region',
			screenshots: '.game-screenshots-region'
		}
	});
	Game.HomeLoggedInLayout = KXL.Components.Layout.extend({
		template: '#game-home-logged-in-template',
		className: 'kx-game-home-logged-in',
		regions: {
			background: '.game-background-region',
			profile: '.game-profile-region',
			leaderboard: '.game-leaderboard-region',
			battlelog: '.game-battlelog-region',
			masthead: '.game-masthead-region',
			alliance: '.game-alliance-region'
		}
	});
	Game.HomeOrganicLoggedOutLayout = KXL.Components.Layout.extend({
		className: 'kx-organic-logged-out-homepage',
		defaults: {
			signupFormOptions: null,
			showGameLogoSection: false
		},
		regions: {
			signUpRegion: '.kx-inline-signup-region',
			videoRegion: '.kx-video-region',
			playersOnlineRegion: '.kx-players-online-region',
			mastheadPlayBtnRegion: '.kx-masthead-play-button-region',
			screenshotsRegion: '.kx-game-screenshots-region'
		},
		ui: {
			signUpSection: '.kx-inline-signup-section',
			modalSignUpSection: '.kx-modal-signup-section',
			gameLogoSection: '.kx-game-logo-section'
		},
		events: {
			'click .kx-masthead-play-button-region': 'topCtaClick',
			'click .kx-play-button2-region': 'bottomCtaClick',
			'click .kx-login-now-cta': 'loginHereClick',
			'click .kx-login-now-bottom-cta': 'loginHereClick',
			'click .kx-tos-blurb span': 'tosClicked'
		},
		initialize: function (options) {
			this.options = opts = _.defaults({}, options, this.defaults);
		},
		getTemplate: function () {
			return '#game-home-logged-out-template-' + this.options.game.get('urlName')
		},
		getSignUpFormOptions: function () {
			return {};
		},
		ctaClick: function (cta, ev) {
			ev.preventDefault();

			var currentGameUrlName = KXL.context && KXL.context.get('urlName');
			if (currentGameUrlName) {
				KXL.execute('show:register:dialog', {
					step: KXL.AuthApp.RegisterSteps.IMPORT_FB_GAMES_PROMPT,
					startNowUrlName: currentGameUrlName,
					registrationHeaderText: KXL.i18n.t('auth.autoStart.registrationHeaderText')
				});
			}

			KXL.vent.trigger('info:gameCTA:click', {
				cta: cta,
				game: currentGameUrlName || 'unknowngame'
			});
		},
		topCtaClick: function (ev) {
			this.ctaClick('top', ev);
		},
		bottomCtaClick: function (ev) {
			this.ctaClick('bottom', ev);
		},
		loginHereClick: function (ev) {
			var loginUrlName = KXL.context && KXL.context.get('urlName');
			var options = {};
			if (loginUrlName) {
				options.loginUrlName = loginUrlName;
			}
			KXL.execute('show:login:dialog', options);
		},
		_showMediaSlider: function () {
			var self = this;
			function getGalleryItemData (gameUrlName) {
				var imageArr = [];
				for (var i = 1; i <= 4; i++) {
					imageArr.push({
						type: 'image',
						imgIconPath: KXL.config.STATIC_BASE_URL + '/modules/landing_page/d/images/' + gameUrlName + '/gallery/' + i + 'thumb.jpg',
						imgPath: KXL.config.STATIC_BASE_URL + '/modules/landing_page/d/images/' + gameUrlName + '/gallery/' + i + 'large.jpg',
						imgLargePath: KXL.config.STATIC_BASE_URL + '/modules/landing_page/d/images/' + gameUrlName + '/gallery/' + i + 'large.jpg'
					});
				}
				return imageArr;
			}
			KXL.asyncRequest('async:load:module', 'common/components/slider').then(function () {
				self.screenshotsRegion.show(
					KXL.request(
						'new:gallery:grid:view',
						getGalleryItemData(self.options.game.get('urlName'))
					)
				);
			});
		},
		_showVideoRegion: function () {
			this.videoRegion.show(
				KXL.request(
					'new:video:view',
					{
						youTubeVideoId: this.options.game.get('youTubeVideoId'),
						autoplay: 0,
						mute: 0,
						height: 292,
						width: 471
					}
				)
			);
		},
		_showSignUpSection: function () {
			this.playersOnlineRegion.show(
				KXL.request('new:playersonline:view')
			);
			this.ui.modalSignUpSection.show();
		},
		onShow: function () {
			var
				self = this
			;

			self._showVideoRegion();
			self._showMediaSlider();
			self._showSignUpSection();

			if (self.options.showGameLogoSection) {
				self.$el.addClass('kx-showing-game-logo-section');
				self.ui.gameLogoSection.show();
			}
		},
		serializeData: function() {
			return {
				baseUrl: KXL.config.BASE_URL,
				staticBaseUrl: KXL.config.STATIC_BASE_URL,
				urlName: this.options.game.get('urlName')
			}
		},
		tosClicked: function () {
			KXL.execute('show:TOS');
		}
	});
});

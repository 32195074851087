KXL.module('Components.Leaderboard', function (Leaderboard, KXL, Backbone, Marionette, $, _) {
	Leaderboard.Controller = {
		getLeaderboard: function(options) {
			var
				leaderboardLayout = new Leaderboard.Layout({
					model: new Backbone.Model(),
					userId: options.userId,
					gameId: options.gameId
				}),
				leaderboardDef = KXL.request('leaderboard:entities', { gameId: options.gameId })
			;

			leaderboardDef.then(function (leaderboardCol) {
				if (leaderboardCol.length > 0) {
					leaderboardLayout.model.set(leaderboardCol.first().toJSON());

					leaderboardLayout.on('render', function () {
						var self = this,
							entriesPromise = KXL.request('leaderboard:entry:entities', {
								leaderboardId: this.model.id
							})
						;

						entriesPromise.then(function (entries) {
							var entriesView = new Leaderboard.EntriesView({
									collection: entries,
									gameId: self.options.gameId
								}),

							currentUserPromise = KXL.request('leaderboard:entry:entities', {
								leaderboardId: self.model.id,
								entryId: self.options.userId
							})
							;

							self.entries.show(entriesView);

							currentUserPromise.then(function (currentUser) {
								var currentUserView = new Leaderboard.EntriesView({
									collection: currentUser,
									gameId: self.options.gameId
									});

								self.currentUser.show(currentUserView);

							});
						});
					});
				}

				leaderboardLayout.render();
			});

			return leaderboardLayout;
		}
	};
});

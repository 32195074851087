KXL.module('HomeApp.Games', function(Games, KXL, Backbone, Marionette, $, _) {
	Games.Controller = {
		getGamesView: function(games) {
			var gamesView = new Games.View({
				collection: games
			});
			gamesView.on('game:pile:item:game:clicked', function (view) {
				var redirectUrl = view.model.get('redirectUrl');
				if (redirectUrl != null)
				{
					window.location = redirectUrl;
					return;
				}

				var gameName = view.model.get('urlName');
				KXL.execute('game:show', gameName, 'home');
			});
			return gamesView;
		},
		getLogoView: function (gameId, options) {
			// Get a Game model instance by gameId
			var defaults = {
				size: 'small'
			};
			options = _.defaults(options || {}, defaults);
			var Game = KXL.games.get(gameId); // TODO: Make this nicer.

			var gameLogoView = new Games.IconView({
				model: Game,
				options: options
			});

			return gameLogoView;
		}
	};
});

KXL.module('Components', function(Components, KXL, Backbone, Marionette, $, _) {
	Components.ItemView = Marionette.ItemView.extend({
		serializeData: function () {
			var data = Marionette.ItemView.prototype.serializeData.call(this);
			if (data && this.options) {
				if (data.items) {
					_.each(data.items, function (item) {
						item = _.extend({}, this.options, item);
					});
				} else {
					data = _.extend({}, this.options, data);
				}
			}
			return data;
		}
	});
});

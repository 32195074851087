KXL.module('Entities', function (Entities, KXL, Backbone, Marionette, $, _) {

	Entities.PlayersOnline = Entities.Model.extend({
		url: function () {
			return '/api/metrics';
		}
	});

	var API = {
		getPlayersOnline: function() {
			var playersOnline = new Entities.PlayersOnline();
			var deferred = playersOnline.fetch();
			deferred._model = playersOnline;
			return deferred;
		}
	};

	KXL.reqres.setHandler('playersonline:entity', function () {
		return API.getPlayersOnline();
	});

});

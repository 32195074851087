KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {
	Entities.Model = Backbone.Model.extend(_.omit(Backbone.Picky.Selectable.prototype, 'constructor'));
	Entities.Model = Entities.Model.extend({
		constructor: function () {
			var self = this;
			this.fetched = 0;
			this.listenTo(this, 'sync', function () {
				self.fetched = new Date().getTime();
			});
			return Backbone.Model.prototype.constructor.apply(this, arguments);
		}
	});
	_.extend(Entities.Model.prototype, Backbone.Validation.mixin);
	Entities.DeepModel = Backbone.DeepModel.extend(_.omit(Backbone.Picky.Selectable.prototype, 'constructor'));
	_.extend(Entities.DeepModel.prototype, Backbone.Validation.mixin);
});

KXL.module('Entities', function(Entities, KXL, Backbone, Marionette, $, _) {

	_.extend(Backbone.Validation.validators, {
		notEqualToUsername: function(value, attr, notEqualToUsername, model) {
			if(notEqualToUsername) {
				if(value === model.get('username')) {
					return this.format('{0} may not be the same as Username', this.formatLabel(attr, model));
				}
			}
		}
	});

	Entities.Registration = Entities.Model.extend({
		defaults: {
			username: '',
			email: '',
			password: '',
			termsCheckbox: false,
			policyCheckbox: false,
			validated: false
		},
		url: '/api/auth/register',
		validation: {
			password: {
				required: true,
				minLength: 6,
				maxLength: 20,
				notEqualToUsername: true
			}
		}
	});
});

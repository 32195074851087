KXL.module('Services.TabManager', function (TabManager, KXL, Backbone, Marionette, $, _) {
	var Tab = KXL.Entities.Model.extend({
		idAttribute: 'tabKey'
	});
	var Tabs = KXL.Entities.Collection.extend({
		model: Tab
	});
	TabManager.create = function TabManager(options) {
		options = options || {};
		if (!options.contentRegion) {
			throw 'TabManager requires a contentRegion';
		}
		var contentRegion = options.contentRegion;
		var tabs = new Tabs();
		tabs = _.extend(tabs, new Backbone.PickThis.SingleSelect(tabs, 'tabs'));

		tabs.on('select:one', function (tabModel) {
			var tabView = tabModel.get('tab');
			if (tabView && tabView.selected) {
				tabView.selected();
			}

			contentRegion.show(KXL.request('new:loader:view'));
			var contentView;
			if (_.isFunction(tabModel.get('content'))) {
				contentView = tabModel.get('content')();
			} else {
				contentView = tabModel.get('content');
			}

			contentView.then(function (view) {
				contentRegion.show(view);
			}).fail(function (view) {
				if (view) {
					contentRegion.show(view);
				}
			});
		});

		var lastTab;
		tabs.on('deselect:one', function (tabModel) {
			lastTab = tabModel;
			var tabView = tabModel.get('tab');
			if (tabView && tabView.deselected) {
				tabView.deselected()
			}
		});
		var TabsController = Marionette.Controller.extend({
			/**
			 *
			 * @param tab A view that has extended TabView
			 * @param content
			 */
			addTab: function (tabId, tabView, contentView) {
				var tabModel = new KXL.Entities.Model({
					id: tabId,
					content: contentView
				});
				if (_.isFunction(tabView)) {
					tabModel.set('tab', tabView(tabModel));
				} else {
					tabModel.set('tab', tabView);
				}
				tabModel.get('tab').on('select', function () {
					tabs.select(tabModel);
				});
				// Merge is so that if the tab is added again, it updates the attributes
				tabs.add(tabModel, {
					merge: true
				});
				return tabModel;
			},
			removeTab: function (tabKey) {
				if (tabs && tabs.get(tabKey)) {
					tabs.remove(tabKey);
				}
			},
			previousTab: function () {
				if (lastTab) {
					return lastTab;
				}
			},
			selectTab: function (tabId) {
				if (!tabId) {
					return;
				}

				if (tabs && tabs.get(tabId)) {
					tabs.select(tabs.get(tabId));
				}
			},
			currentTab: function () {
				if (tabs) {
					return tabs.selected;
				}
			}
		});
		return new TabsController(options);
	};
	TabManager.TabView = Marionette.Layout.extend({
		triggers: {
			'click': 'select'
		},
		selected: function () {
			this.$el.addClass('kx-active');
		},
		deselected: function () {
			this.$el.removeClass('kx-active');
		}
	});
});
KXL.module('Components', function(Components, KXL, Backbone, Marionette, $, _) {

	Components.ConfirmDialog = Components.Dialog.extend({
		template: Templates['common/components/confirm_dialog/confirm-dialog'],
		regions: _.extend({
			'cancelRegion': '.kx-confirm-dialog-cancel',
			'confirmRegion': '.kx-confirm-dialog-confirm'
		}, Components.Dialog.prototype.regions),
		initialize: function (options) {
			this.defaults = {
				title: KXL.i18n.t('dialog.confirm.defaultTitle'),
				message: '',
				confirmButtonOptions: {
					label: KXL.i18n.t('dialog.confirm.confirmButtonLabel'),
					theme: KXL.Components.Button.Themes.alt11b,
					singleClick: true
				},
				cancelButtonOptions: {
					label: KXL.i18n.t('dialog.confirm.cancelButtonLabel'),
					theme: KXL.Components.Button.Themes.alt11b,
					singleClick: true
				},
				showCancelButton: true
			};

			Components.Dialog.prototype.initialize.call(this, _.defaults(options || {}, this.defaults));
		},
		onRender: function () {
			var self = this;

			if (this.options.showCancelButton) {

				var cancelButton = new KXL.Components.Button.View(
					_.defaults(this.options.cancelButtonOptions || {}, this.defaults.cancelButtonOptions)
				);

				this.listenTo(cancelButton, 'click', function () {
					KXL.dialogRegion.close();
				});

				this.cancelRegion.show(cancelButton);

			}

			this.confirmButton = new KXL.Components.Button.View(
				_.defaults(this.options.confirmButtonOptions || {}, this.defaults.confirmButtonOptions)
			);

			this.listenTo(this.confirmButton, 'click', function () {
				self.toggleConfirmBtnLoading(true);
				self.trigger('confirm:clicked');
			});

			this.confirmRegion.show(this.confirmButton);

			this.$el.addClass('kx-confirm-dialog');

			Components.Dialog.prototype.onRender.call(this);
		},
		toggleConfirmBtnLoading: function (on) {
			this.confirmButton[on ? 'startLoading' : 'stopLoading']();
		},
		serializeData: function () {
			return {
				title: this.options.title,
				message: this.options.message,
				showCancelButton: this.options.showCancelButton
			};
		}
	});
});

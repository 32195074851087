KXL.module('Components.TwitterShareButton', function (TwitterShareButton, KXL, Backbone, Marionette, $, _) {
	function popupwindow (url, title, w, h) {
		var left = (screen.width/2)-(w/2);
		var top = (screen.height/2)-(h/2);
		return window.open(
			url,
			title,
			'toolbar=no, \
			location=no, \
			directories=no, \
			status=no, \
			menubar=no, \
			scrollbars=no, \
			resizable=no, \
			copyhistory=no, \
			width='+w+', \
			height='+h+', \
			top='+top+', \
			left='+left
		);
	}
	KXL.reqres.setHandlers({
		'new:twitter:share:button:view': function (options) {
			var btn = new KXL.Components.Button.View({
				customClass: 'kx-twitter-share-btn'
			});
			btn.listenTo(btn, 'click', function () {
				var shareUrl = _.template(
					'https://twitter.com/share?\
					url=<%= url %>\
					&text=<%= text %>\
					&related=<%= related %>',
					options
				);
				popupwindow(shareUrl, '', 575, 320);
			});
			return btn;
		}
	});
});

KXL.module('FriendsPanelApp', function (FriendsPanelApp, KXL, Backbone, Marionette, $, _) {

	FriendsPanelApp.ButtonsView = KXL.Components.ItemView.extend({
		template: '#friends-panel-buttons-template',
		templateHelpers: function () {
			var data = {};
			if (this.options.title) {
				data.headingText = this.options.title;
			}
			return data;
		}
	});

	FriendsPanelApp.AdvancedSearchParamView = KXL.Components.Layout.extend({
		template: '#friends-panel-advanced-search-param-template',
		tagName: 'div',
		className: 'kx-friends-panel-advanced-search-param',
		regions: {
			paramCheckBoxRegion: '.kx-search-param-checkbox-region',
			paramInputRegion: '.kx-search-param-input-region'
		},
		modelEvents: {
			'change:value': function () {
				if (this.model.get('checked')) {
					this.triggerSearch();
				}
			},
			'change:checked': function () {
				if (this.model.get('value')) {
					this.triggerSearch();
				}
			}
		},
		triggerSearch: _.debounce(function () {
			if (this.options.vent) {
				this.options.vent.trigger('search:trigger');
			}
		}, 300),
		onRender: function () {
			var checkBox = new KXL.Components.CheckBox.View({
				label: this.model.get('label'),
				model: this.model,
				modelAttr: 'checked',
				customClass: 'kx-search-param-checkbox'
			});

			this.paramCheckBoxRegion.show(checkBox);

			var input = new KXL.Components.TextBox.View({
				customClass: 'kx-search-param-input',
				model: this.model,
				modelAttr: 'value',
				showSuccess: false,
				maxLength: 6
			});
			this.paramInputRegion.show(input);
		}
	});

	FriendsPanelApp.AdvancedSearchParamsView = Marionette.CollectionView.extend({
		template: function () {
			return '';
		},
		tagName: 'div',
		className: 'kx-friends-panel-advanced-search-params',
		itemView: FriendsPanelApp.AdvancedSearchParamView,
		itemViewOptions: function (model) {
			return {
				model: model,
				vent: this.options.vent
			};
		}
	});

	FriendsPanelApp.AdvancedSearchView = KXL.Components.Layout.extend({
		template: '#friends-panel-advanced-search-template',
		className: 'kx-friends-panel-advanced-search',
		regions: {
			advancedSearchParamsRegion: '.kx-friend-advanced-search-params-region'
		},
		ui: {
			advancedSearchToggleBtn: '.kx-friend-search-advanced-toggle',
			ctaSpacer: '.kx-friend-search-header-spacer',
			clearBtn: '.kx-friend-search-clear-btn',
			resultsTxt: '.kx-friend-search-matched-results-txt'
		},
		events: {
			'click @ui.advancedSearchToggleBtn': '_toggleAdvancedSearchParams',
			'click @ui.clearBtn': function () {
				this.options.vent.trigger('clear:player:search');
			}
		},
		modelEvents: {
			'change:context': function () {
				// If the context changes only display the advanced
				// search if the game context supports it.
				this._displayAdvancedIfContextSupports();
			},
			'change:mode': function () {
				// On mode change, change the button text.
				var previousSearchMode = this.model.previous('mode'),
					toggleText = KXL.i18n.t('friends.panel.' + previousSearchMode);
				this.ui.advancedSearchToggleBtn.html(toggleText);
			},
			'change:resultsTxt': '_renderResultsTxt'
		},
		_toggleAdvancedSearchParams: function () {
			var searchMode = this.model.get('mode');
			if (searchMode === 'simple') {
				this._renderAdvancedSearchParams();
			} else {
				this._closeAdvancedSearchParams();
			}
			// Only re trigger the search if the params are different between advanced & simple.
			if (this.model.hasAdvancedParams()) {
				this.triggerSearch();
			}
		},
		triggerSearch: _.debounce(function () {
			if (this.options.vent) {
				this.options.vent.trigger('search:trigger');
			}
		}, 300),
		// Set the mode to simple and close the view.
		_closeAdvancedSearchParams: function () {
			this.model.set('mode', 'simple');
			this.advancedSearchParamsRegion.close();
			if (this.options.vent) {
				this.options.vent.trigger('content:size:adjust');
			}
		},
		// Check to see if the context supports advanced search
		// If not close the view and hide the button toggle
		_displayAdvancedIfContextSupports: function () {
			var advancedParams = this.model.contextGetAdvancedParams(),
				mode = this.model.get('mode');

			if (advancedParams) {
				this.ui.advancedSearchToggleBtn.show();
				this.ui.ctaSpacer.show();
			} else {
				this.ui.advancedSearchToggleBtn.hide();
				this.ui.ctaSpacer.hide();
			}
			if (mode === 'advanced' && advancedParams) {
				this._renderAdvancedSearchParams();
			} else if (mode === 'advanced' && !advancedParams) {
				this._closeAdvancedSearchParams();
			}
		},
		_renderAdvancedSearchParams: function () {
			this.model.set('mode', 'advanced');
			var advancedParams = this.model.contextGetAdvancedParams();
			if (advancedParams) {
				var searchParams = new FriendsPanelApp.AdvancedSearchParamsView({
					collection: advancedParams.get('params'),
					vent: this.options.vent
				});
				this.advancedSearchParamsRegion.show(searchParams);
			}
			if (this.options.vent) {
				this.options.vent.trigger('content:size:adjust');
			}
		},
		_renderResultsTxt: function () {
			this.ui.resultsTxt.html(this.model.get('resultsTxt'));
		},
		onRender: function () {
			this._displayAdvancedIfContextSupports();
			this._renderResultsTxt();
		}
	});

	FriendsPanelApp.SearchView = KXL.Components.Layout.extend({
		template: '#friends-panel-search-layout-template',
		className: 'kx-friends-panel',
		regions: {
			requestsList: '.friends-panel-requests-list',
			friendsList: '.friends-panel-friends-list',
			searchInputRegion: '.friends-panel-search-input-region',
			advancedSearchRegion: '.friends-panel-advanced-search-region',
			searchResults: '.friends-panel-search-results'
		},
		ui: {
			heading: '.kx-heading',
			listsWrapper: '.friends-panel-lists-wrapper',
			friendsPanelSearch: '.friends-panel-search',
			searchResults: '.friends-panel-search-results'
		},
		topBarView: null,
		requestsListView: null,
		friendsListView: null,
		searchInputRegion: null,

		initialize: function (options) {

			var self = this;

			this.options = _.defaults(
				{},
				options,
				{
					showSearch: true,
					minimumCharacterTriggerSearch: 3
				}
			);

			this.searchModel = this.options.searchModel;

			KXL.vent.on('user:friend:request:sent', function (friendId) {
				var friendModel = self.requestsListView.collection.collection.findWhere({'userId': friendId});
				if (!friendModel) {
					return;
				}
				KXL.request('user:friend:request:accept', friendModel.get('id'), friendModel.get('userId')).then(function () {
					self.options.requestsCollection.collection.remove(friendModel);
					self.requestsListView.render();
				});
			});

			self.requestsListView = new FriendsPanelApp.FriendRequestsListView({
				collection: this.options.requestsCollection,
				searchModel: this.searchModel,
				vent: this.options.vent
			});

			this.listenTo(this.options.vent, 'friend:request:remove', function (model) {
				self.options.requestsCollection.collection.remove(model.get('requestId'));
				self.requestsListView.render();
			});

			self.friendsListView = new FriendsPanelApp.FriendsListView({
				collection: this.options.friendsCollection,
				searchModel: this.searchModel
			});
			self.friendsListView.on('friendcard:username:clicked', function (view, user) {
				KXL.execute('profile:show', user.get('id'));
			});
		},
		_createSearch: function () {
			var self = this;
			KXL.asyncRequest('async:load:module', 'common/components/search_input').then(function () {
				self.searchInput = KXL.request('searchinput:get:view', {
					className: 'kx-search-wrapper',
					model: self.searchModel,
					modelAttr: 'term'
				});
				self.searchInput.on('context:change', function (context) {
					self.searchModel.set('context',
						KXL.games.findWhere({id: context}).get('commonName'));
					self._resetSearch();
					self._triggerSearch();
				});

				self.listenTo(self.options.vent, 'search:trigger', function () {
					self._resetSearch();
					self._triggerSearch();
				});

				KXL.vent.on('close:friendsearch', function () {
					self._closeSearchResults();
				});

				self.searchInputRegion.show(self.searchInput);
			});
		},
		_triggerSearch: _.debounce(function () {
			var
				flyoutView = this.searchResults.currentView,
				searchTerm = this.searchInput.ui.searchBox.val(),
				appScope = this.searchModel.get('scope'),
				planet =  this.searchModel.contextGetAdvancedParam('planet') || null,
				sector = this.searchModel.contextGetAdvancedParam('sector') || null,
				minLevel = this.searchModel.contextGetAdvancedParam('minLevel') || null,
				maxLevel = this.searchModel.contextGetAdvancedParam('maxLevel') || null,
				ignoreUserIds = [],
				offset = this.searchModel.get('offset'),
				limit = this.searchModel.get('limit'),
				source = this.searchModel.get('source'),
				self = this
				;
			if (searchTerm === '') {
				this._closeSearchResults();
				return;
			} else if (searchTerm.length < this.options.minimumCharacterTriggerSearch) {
				if (this.searchInput) {
					this.searchInput.hideLoader();
				}

				return;
			}

			if (source === 'secondary') {
				ignoreUserIds = this.searchModel.get('ignoreUserIds');
			}

			this.searchInput.showLoader();

			if (!flyoutView) {

				KXL.asyncRequest('async:load:module', 'friend_search').then(function () {

					flyoutView = KXL.request('get:friendsearch:flyout');

					self.searchResults.show(flyoutView);

					flyoutView.listenTo(flyoutView, 'usersearch:results:returned', function (results) {
						self.searchInput.hideLoader();
						self.ui.searchResults.show();

						var ignoredUserIds = self.searchModel.get('ignoreUserIds');

						if (self.searchModel.get('offset') === 0 &&
							!ignoredUserIds.length) {
							self.ui.searchResults.scrollTop(0);
						}

						// Add user results to the ignore list
						// if its the primary search results and we haven't
						// hit the ignore user id's limit
						if (results && results.length &&
							self.searchModel.get('source') === 'primary' &&
							ignoredUserIds.length < self.searchModel.get('ignoreUserLimit')) {
							results.each(function (result) {
								ignoredUserIds.push(result.get('id'));
							});
						}

						// If results are less then a page limit switch to secondary search
						// Only switch to secondary search if the context is not kxl &&
						// There are no advanced search parameters.
						if (results.length < self.searchModel.get('limit') &&
							self.searchModel.get('source') !== 'secondary' &&
							self.searchModel.get('context') !== 'kxl' &&
							!self.searchModel.hasAdvancedParams()) {

							self.searchModel.set('source', 'secondary');
							self.searchModel.set('offset', 0);

							// For secondary search, set the scope to all games except for
							// the game initially searched.
							var scope = [];

							var staticWebGames = KXL.request('static:webgames:entities');

							staticWebGames.each(function (game) {
								if (appScope[0] !== game.get('gameId')) {
									scope.push(KXL.games.findWhere({commonName: game.get('commonName')}).get('id'));
								}
							});
							scope.push(KXL.games.findWhere({commonName: 'kxl'}).get('id'));

							self.searchModel.set('scope', scope);

							// Trigger secondary search
							self._triggerSearch();
						} else if (results.length < self.searchModel.get('limit')) {
							self.searchModel.set('allResultsReturned', true);
						}

						// Store results returned in the search model.
						var resultsReturned = self.searchModel.get('numResultsReturned') + results.length;
						self.searchModel.set('numResultsReturned', resultsReturned);

						// Store results text in model
						var resultsTxt = KXL.i18n.t('friends.panel.displayingPlayerResult', {smart_count: resultsReturned});
						self.searchModel.set('resultsTxt', resultsTxt);
						self.ui.listsWrapper.hide();
					});
					flyoutView.on('username:clicked', function (user) {
						KXL.execute('profile:show', user.get('id'));
					});
					flyoutView.trigger('search:triggered', {
						limit: limit,
						offset: offset,
						source: source,
						searchTerm: searchTerm,
						restrictByAppIds: appScope,
						planet: planet,
						sector: sector,
						minLevel: minLevel,
						maxLevel: maxLevel,
						ignoreUserIds: ignoreUserIds,
						displayResultTitles: true
					});
					self.sizeAdjust();

				});

			} else {

				flyoutView.trigger('search:triggered', {
					limit: limit,
					offset: offset,
					source: source,
					searchTerm: searchTerm,
					restrictByAppIds: appScope,
					planet: planet,
					sector: sector,
					minLevel: minLevel,
					maxLevel: maxLevel,
					ignoreUserIds: ignoreUserIds,
					displayResultTitles: true
				});

				self.sizeAdjust();

			}
		}, 300),
		_resetSearch: function () {
			this.searchModel.set('offset', 0);
			this.searchModel.set('numResultsReturned', 0);
			this.searchModel.set('source', 'primary');
			this.searchModel.set('ignoreUserIds', []);
			this.searchModel.set('allResultsReturned', false);
			this.searchModel.set('scope', this.searchInput.getAppScope());
		},
		_closeSearchResults: function () {
			this.ui.searchResults.hide();
			this.searchResults.close();
			this.ui.listsWrapper.show();
			if (this.searchInput) {
				this.searchInput.hideLoader();
			}
			this.searchModel.set('resultsTxt', KXL.i18n.t('friends.panel.displayingAllFriends'));
			this._resetSearch();
		},
		_createAdvancedSearch: function () {
			var advancedSearch = new FriendsPanelApp.AdvancedSearchView({
				model: this.searchModel,
				vent: this.options.vent
			});
			this.advancedSearchRegion.show(advancedSearch);
		},
		onRender: function () {
			var self = this;
			this.requestsList.show(this.requestsListView);
			this.friendsList.show(this.friendsListView);
			this.$el.find('input').placeholder();

			if (this.options.showSearch) {
				this._createSearch();
				this._createAdvancedSearch();
			}
			this.sizeAdjust();
			this.on('container:size:adjust', function (containerHeight) {
				self.containerHeight = containerHeight;
				self.sizeAdjust(containerHeight);
			});

			this.listenTo(this.options.vent, 'clear:player:search', function () {
				self._closeSearchResults();
				if (self.searchModel) {
					self.searchModel.clearCurrentContextAdvancedParams();
				}
				self.searchModel.set('term', '');
			});

			this.listenTo(this.searchModel, 'change:term', function () {
				self._resetSearch();
				self._triggerSearch();
			});

			this.ui.searchResults.scroll(function () {
				if (self._checkScrollingAtBottom(self.ui.searchResults)) {
					self.getNextSearchPage();
				}
			});

			this.ui.listsWrapper.scroll(function () {
				if (self._checkScrollingAtBottom(self.ui.listsWrapper)) {
					self.trigger('friends:panel:get:more');
				}
			});
		},
		onShow: function () {
			this.sizeAdjust();
		},
		sizeAdjust: function (height) {
			var containerHeight = height || this.containerHeight;
			if (!containerHeight) {
				containerHeight = this.$el.outerHeight(true);
			}
			if (containerHeight) {
				// A couple magic numbers below that I will fix later
				var headerHeight = 73;
				if (this.searchModel.get('mode') === 'advanced') {
					headerHeight += 132;
				}
				this.ui.listsWrapper.css('height', (containerHeight - headerHeight) + 'px');
				this.options.vent.trigger('content:isolate:scrolling', this.ui.listsWrapper);
				var searchResults = this.ui.searchResults;

				if (searchResults) {
					var flyout = searchResults.find('.kx-friend-search-flyout');
					if (flyout) {
						flyout.css('min-height', (containerHeight - headerHeight) + 'px');
					}
					searchResults.css('height', (containerHeight - headerHeight) + 'px');
					searchResults.css('top', (headerHeight - 4) + 'px');
					this.options.vent.trigger('content:isolate:scrolling', searchResults);
				}
			}
		},
		getRequestsListView: function () {
			return this.requestsListView;
		},
		getFriendsListView: function () {
			return this.friendsListView;
		},
		getNextSearchPage: _.debounce(function () {
			var allResultsReturned = this.searchModel.get('allResultsReturned');
			if (!allResultsReturned) {
				var limit = this.searchModel.get('limit');
				var offset = this.searchModel.get('offset');
				this.searchModel.set('offset', offset += limit);
				this._triggerSearch();
			}
		}, 300),
		_checkScrollingAtBottom: function ($scrollEl) {
			var scrollBuffer = 0; // distance from the bottom to load the next page
			if ($scrollEl.scrollTop() + $scrollEl.innerHeight() >= $scrollEl.prop('scrollHeight') - scrollBuffer) {
				return true;
			}
		}
	});

	FriendsPanelApp.FriendRequestsListView = Marionette.CollectionView.extend({
		template: function () {
			return '';
		},
		tagName: 'ul',
		className: 'kx-friends-panel-list',
		itemView: KXL.Components.FriendCard.FriendRequestView,
		itemViewOptions: function () {
			return {
				userType: 'friends',
				hoverCard: 'avatar',
				cardType: 'request',
				displayScopedGameName: false,
				appScope: this.options.searchModel && this.options.searchModel.get('scope'),
				vent: this.options.vent
			};
		}
	});

	FriendsPanelApp.EmptyView = KXL.Components.ItemView.extend({
		template: '#friends-panel-empty-template',
		tagName: 'li',
		className: 'kx-friends-panel-empty'
	});

	FriendsPanelApp.FriendsListView = Marionette.CollectionView.extend({
		template: function () {
			return '';
		},
		tagName: 'ul',
		className: 'kx-friends-panel-list',
		emptyView: FriendsPanelApp.EmptyView,
		settingsModel: null,
		collectionEvents: {
			'sort': 'sortEvent',
			'destroy': 'render',
			'add': 'collectionModified'
		},
		initialize: function () {
			var self = this;
			self.searchModel = self.options.searchModel || null;
			// If scope changes and its only one game, change the featured game on the card.
			self.listenTo(self.searchModel, 'change:scope', function () {
				if (self.options.searchModel.get('scope') !== self.options.searchModel.previous('scope') &&
					self.options.searchModel.get('scope').length === 1) {
					self.render();
				}
			});
		},

		collectionModified: function () {
			var self = this;
			_.debounce(function () {
				self.collection.sort();
			}, 1000);
		},

		sortEvent: function () {
			this.render();
		},

		itemViewEventPrefix: 'friendcard',
		itemView: KXL.Components.FriendCard.View,
		itemViewOptions: function () {
			return {
				userType: 'friends',
				hoverCard: 'avatar',
				appScope: this.options.searchModel && this.options.searchModel.get('scope'),
				cardType: 'friend',
				displayChatBtn: true,
				expandable: true
			};
		}
	});

});

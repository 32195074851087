KXL.module('FooterApp.Footer', function(Footer, KXL, Backbone, Marionette, $, _) {
	Footer.Controller = {
		loadFooter: function () {
			var currentUser = KXL.request('get:current:user');
			var view = new Footer.View({ model: currentUser });
			KXL.footerRegion.show(view);
			view.on('oldgroups:clicked', function () {
				KXL.execute('groups:show');
			});
		},
		showFooter: function () {
			KXL.footerRegion.ensureEl();
			if (KXL.footerRegion.$el.is(':hidden')) {
				KXL.footerRegion.$el.show();
			}
		},
		hideFooter: function () {
			KXL.footerRegion.ensureEl();
			if (KXL.footerRegion.$el.is(':visible')) {
				KXL.footerRegion.$el.hide();
			}
		}
	};
});

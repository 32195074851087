KXL.module('Components.Pager', function(Pager, KXL, Backbone, Marionette, $, _) {
	Pager.Controller = {
		getPager: function (items) {
			var view = new Pager.View({
				collection: items
			});
			view.on('pager:item:clicked', function (view) {
				view.model.select();
			});
			return view;
		}
	};
});

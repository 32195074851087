KXL.module('Components.GameSelector', function (GameSelector, KXL, Backbone, Marionette, $, _) {
	GameSelector.ItemView = Marionette.ItemView.extend({
		tagName: 'li',
		template: '#_game-selector-item-template', 
		modelEvents: function () {
			var
				self = this;
				events = {},
				namespace = this.collection.namespace
			;
			
			events['selected:' + namespace] = function () {
				self.$el.addClass('kx-active');
			};
			events['deselected:' + namespace] = function () {
				self.$el.removeClass('kx-active');
			};
			return events;
		},
		onRender: function () {
			if (this.collection.selected === this.model) {
				this.$el.addClass('kx-active');
			}
		}
	});

	GameSelector.View = Marionette.CompositeView.extend({
		template: '#game-selector-template',
		itemViewContainer: '.kx-games',
		itemViewOptions: function (model, index) {
			return {
				collection: this.collection,
				model: model
			}
		},
		itemView: GameSelector.ItemView,
		className: 'kx-game-selector kx-clearfix kx-no-text-selection',
		modelEvents: {
			'change': 'render'
		},
		events: {
			'click .kx-left-arrow': function () {
				var prev = this.collection.prev(this.collection.selected, true);
				if (prev) {
					this.collection.select(prev);
					this.trigger('prev:clicked');
				}
			},
			'click .kx-right-arrow': function () {
				var next = this.collection.next(this.collection.selected, true);
				if (next) {
					this.collection.select(next);
					this.trigger('next:clicked');
				}
			}
		}
	});
});

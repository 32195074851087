KXL.module('ProfileApp.GameStats', function(GameStats, KXL, Backbone, Marionette, $, _) {
	var API = Marionette.Controller.extend({
		getGameStatsView: function (userGames, user, defaultGameId, options) {
			var deferred = $.Deferred();
			var opts = _.extend(
				{
					commonNamesForDisplayedGames: ['bp', 'wc', 'vc']
				},
				options
			);

			var singleSelect = new Backbone.Picky.SingleSelect(userGames);
			_.extend(userGames, singleSelect);
			function select() {
				if (userGames.length) {
					// If the user is looking at their own profile maintain context
					if (KXL.context && userGames.get(KXL.context) && !opts.overrideGameContext) {
						userGames.get(KXL.context).select();
					} else if (!defaultGameId || !userGames.findWhere({ gameId: defaultGameId })) {
						userGames.first().select();
					} else {
						userGames.findWhere({ gameId: defaultGameId }).select();
					}
				}
			}
			function getUserGamesView() {
				// Filter out games that aren't in commonNamesForDisplayedGames list 
				userGames.reset(userGames.filter(function (model) {
					var game = KXL.games.get(model.get('gameId'));
					if (game) {
						return opts.commonNamesForDisplayedGames.indexOf(game.get('commonName')) !== -1;
					}
					return false;
				}));
				if (userGames.length) {
					select();
				} else if (!userGames.fetched) {
					userGames.listenToOnce(userGames, 'sync', function () {
						select();
					});
				}
				if (userGames.length) {
					var userGameStatsView = new GameStats.View({
						user: user,
						collection: userGames
					});
					return userGameStatsView;
				} else {

					var userGameStatsEmptyView = new GameStats.EmptyView();
					return userGameStatsEmptyView;
				}
			}

			KXL.vent.on('profile:shown', function() {
				userGames.sort();
				if (KXL.context && userGames.get(KXL.context.id)) {
					userGames.get(KXL.context.id).select();
				} else if (userGames.first()) {
					userGames.first().select();
				}
			});

			this.listenTo(KXL.appModel, 'change:context', function (appModel, context) {
				if (userGames.get(context.id)) {
					userGames.get(context.id).select();
				}
			});

			deferred.resolve(getUserGamesView());

			return deferred;
		}
	});
	GameStats.Controller = new API();
});

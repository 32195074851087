KXL.module('Chat.ChatApp.Entities', function(Entities, KXL, Backbone, Marionette, $, _) {

	/**
	 * Message
	 */
	Entities.Message = Backbone.Model.extend({
		defaults: {
			userId: null,
			username: null,
			body: null,
			timestamp: null
		}
	});

	Entities.MessagesCollection = Backbone.Collection.extend({
		model: Entities.Message,
		initialize: function () {
			var self = this;
			self.listenTo(self, 'add', function () {
				var modelToNuke;
				while (self.models.length > KXL.config.chatHistoryLimit) {
					modelToNuke = self.shift();
					modelToNuke.destroy();
				}
			});
		}
	});

	/**
	 * Thread
	 */
	Entities.ThreadMember = Backbone.Model.extend({
		idAttribute: 'userId',
		defaults: {
			userId: null,
			username: null,
			active: null,
			createdAt: null,
			status: 'unavailable'
		}
	});

	Entities.ThreadMembersCollection = Backbone.Collection.extend({
		model: Entities.ThreadMember
	});


	Entities.Thread = KXL.Entities.Model.extend({
		defaults: {
			id: null,
			jabberId: null,
			type: null,
			title: null,
			members: null,
			messages: null,
			lastMessageAt: null,
			unreadMessages: 0
		},

		initialize: function () {

			var self = this;

			var members = new Entities.ThreadMembersCollection();
			var messages = new Entities.MessagesCollection();

			self.set('members', members);
			self.set('messages', messages);
		},

		initializeMembers: function (chatRoom) {

			var roomMembers = _.map(chatRoom.members, function (chatMember) {
				var member = _.pick(chatMember, 'userId', 'username', 'active', 'createdAt');
				var user = chatRoom.users[member.userId];
				if (user) {
					member.status = user.status;
				}
				return member;
			});
			this.get('members').set(roomMembers, { silent: true });
		},

		update: function (chatRoom) {
			if (this.get('title') !== chatRoom.title) {
				this.set('title', chatRoom.title);
			}

			if (this.get('lastMessageAt') !== chatRoom.lastMessageAt) {
				this.set('lastMessageAt', chatRoom.lastMessageAt);
			}

			this.get('members').set(_.map(chatRoom.members, function (chatMember) {
				var member = _.pick(chatMember, 'userId', 'username', 'active', 'createdAt');
				var user = chatRoom.users[member.userId];
				if (user) {
					member.status = user.status;
				}
				return member;
			}));
		},

		updateUserPresence: function (chatUser) {
			var member = this.get('members').get(chatUser.userId);
			if (member && member.get('status') !== chatUser.status) {
				member.set('status', chatUser.status);
			}
		}

	});

	Entities.DefaultThread = Entities.Thread.extend({
		initialize: function (options) {
			var returnVal = Entities.Thread.prototype.initialize.call(this, options);
			var chatInterface = KXL.request('get:chat:interface');
			this.on("selected", function (model) {
				if (KXL.currentUser) {
					this.get('messages').reset();
					chatInterface.joinDefaultChatRoom(model.id);
				}
			});
			this.on("deselected", function (model) {
				if (KXL.currentUser) {
					this.get('messages').reset();
					chatInterface.leaveDefaultChatRoom(model.id);
				}
			});
			return returnVal;
		}
	});

	Entities.DefaultThreadsCollection = KXL.Entities.SingleSelectCollection.extend({
		model: Entities.DefaultThread
	});

	Entities.ThreadsCollection = KXL.Entities.SingleSelectCollection.extend({
		model: Entities.Thread
	});

});
